import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Icon, Message, Input, Grid } from 'semantic-ui-react';

import {
  OverallRecommendationsStore,
  OverallRecommendationDto,
  ChangeOverallRecommendationStore
} from 'stores/configuration/overall-recommendations/overall-recommendations-store';
import { ItemState, Query, OrderDefinition } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';

import NewOverallRecommendationView from './new-overall-recommendation';
import ChangeOverallRecommendationView from './edit-overall-recommendation';

export interface OverallRecommendationsListProps extends WithTranslation, RouteComponentProps {
  overallRecommendations: OverallRecommendationsStore;
  changeOverallRecommendationStore?: ChangeOverallRecommendationStore;
}

export interface OverallRecommendationsListState {
  query: Query;
  newOverallRecommendationShown: boolean;
  changeOverallRecommendationShown: boolean;
  viewOverallRecommendationShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['overallRecommendations', OverallRecommendationsStore], ['changeOverallRecommendationStore', ChangeOverallRecommendationStore])
class OverallRecommendationsListPage extends React.Component<OverallRecommendationsListProps, OverallRecommendationsListState> {
  handleOnEnterKeydown = item => {
    const currentUserInfo = this.identityService.getUserInfo();
    const isAdmin = IdentityService.isAdmin(currentUserInfo);
    if (isAdmin) this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'name', useProfile: false }], skip: 0, take: 10 },
      newOverallRecommendationShown: false,
      changeOverallRecommendationShown: false,
      viewOverallRecommendationShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    this.load();
  }

  private load = () => {
    const { query } = this.state;
    const q = { ...query };
    q.filter = [...((query.filter as any[]) || [])];
    this.props.overallRecommendations.getAllAsync(q);
  };

  @autobind
  private onNewItem() {
    this.setState({ newOverallRecommendationShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newOverallRecommendationShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeOverallRecommendationShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeOverallRecommendationShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, () => {
      this.load();
    });
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: OverallRecommendationDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.overallRecommendations.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  handleToggleAvailability = async (item: OverallRecommendationDto) => {
    if (!item) return;
    const { id, name, description, active } = item;
    this.props.changeOverallRecommendationStore.change({ id, name, description, active: !active });
    await this.props.changeOverallRecommendationStore.update();
    this.load();
  };

  onDismiss = () => {
    const { overallRecommendations, changeOverallRecommendationStore } = this.props;
    overallRecommendations.clearMessages();
    changeOverallRecommendationStore.clearMessages();
  };

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t, overallRecommendations, changeOverallRecommendationStore } = this.props;
    const { activeFilters, someFilterOpened } = this.state;
    const currentUserInfo = this.identityService.getUserInfo();
    const isAdmin = IdentityService.isAdmin(currentUserInfo);

    let errMsgs = (overallRecommendations.state.result?.messages || [])?.map(o => o.body);
    errMsgs = [...errMsgs, ...(changeOverallRecommendationStore.state.result?.messages || [])?.map(x => x.body)];

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          tooltipRenderer: true,
          renderer: data => <>{data.name}</>,
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(e, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Name')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<OverallRecommendationDto>('name'), `contains(${nameof<OverallRecommendationDto>('name')}, '${value}')`)
              }
              onClear={() => onClear(nameof<OverallRecommendationDto>('name'))}
              active={activeFilters.includes(nameof<OverallRecommendationDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<OverallRecommendationDto>('name'), useProfile: false }
        },
        {
          title: t('Description'),
          tooltipRenderer: true,
          renderer: data => <>&nbsp;{data.description}</>,
          editor: (data, onChange) => (
            <Input
              value={data.description}
              fluid
              onChange={(e, { value }) => {
                data.description = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by description')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<OverallRecommendationDto>('description'),
                  `contains(${nameof<OverallRecommendationDto>('description')}, '${value}')`
                )
              }
              onClear={() => onClear(nameof<OverallRecommendationDto>('description'))}
              active={activeFilters.includes(nameof<OverallRecommendationDto>('description'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<OverallRecommendationDto>('description'), useProfile: false }
        }
      ],
      data: this.props.overallRecommendations.state
    } as TableModel<OverallRecommendationDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {errMsgs.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                onDismiss={this.onDismiss}
                error
                header={t('An error ocurred')}
                list={errMsgs}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newOverallRecommendationShown && !this.state.changeOverallRecommendationShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  isVisible: () => isAdmin,
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              isRowDisableLayout={(item: OverallRecommendationDto) => !item.active}
              onRefresh={this.load}
              canEdit={false}
              // canDelete={isAdmin}
              // onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={isAdmin}
              createNewButtonTitle={t('Add Overall Recommendation')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newOverallRecommendationShown && <NewOverallRecommendationView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeOverallRecommendationShown && (
            <ChangeOverallRecommendationView onClose={this.onEditItemClosed} currentOverallRecommendation={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(OverallRecommendationsListPage);
