import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message, Icon, Modal, Tab, Grid } from 'semantic-ui-react';
import { CountriesStore, CountryDto } from 'stores/configuration/locations/countries-store';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { CountryFlag } from 'widgets/bussiness/country-flag';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import PublicHolidaysTab from 'site/pages/landing-pages/shared-scheduler-components/scheduler-poc-config/config-tabs/country-public-holidays-tab';

import NewCountryView from './new-country';
import ChangeCountryView from './edit-country';

export interface CountriesListProps extends WithTranslation, RouteComponentProps {
  countries: CountriesStore;
}

export interface CountriesListState {
  query: Query;
  newCountryShown: boolean;
  changeCountryShown: boolean;
  showCountryConfigModal: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['countries', CountriesStore])
class CountriesListPage extends React.Component<CountriesListProps, CountriesListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: nameof<CountryDto>('modifiedOn'), useProfile: false }],
        skip: 0,
        take: 10
      },
      newCountryShown: false,
      changeCountryShown: false,
      showCountryConfigModal: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.countries.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, () => {
      this.load();
    });
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: CountryDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.countries.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: CountryDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.countries.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newCountryShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newCountryShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeCountryShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeCountryShown: false });
    if (isSuccess) this.load();
  }

  handleOnCountryMaintenance = (selectedItem: CountryDto) => this.setState({ selectedItem, showCountryConfigModal: true });

  closeCountryConfig = () => this.setState({ showCountryConfigModal: false, selectedItem: null });

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, selectedItem, showCountryConfigModal, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          tooltipRenderer: true,
          renderer: data => data.name,
          newClassFromComponent: data => 'table__more-text-long',
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(e, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<CountryDto>('name'), `contains(${nameof<CountryDto>('name')}, '${value}')`)}
              onClear={() => onClear(nameof<CountryDto>('name'))}
              active={activeFilters.includes(nameof<CountryDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<CountryDto>('name'),
            useProfile: false
          }
        },
        {
          title: t('Code'),
          newClassFromComponent: data => 'table__more-text-long',
          tooltipRenderer: true,
          renderer: data => data.isoCode,
          editor: (data, onChange) => (
            <Input
              value={data.isoCode}
              fluid
              onChange={(e, { value }) => {
                data.isoCode = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by code')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<CountryDto>('isoCode'), `contains(${nameof<CountryDto>('isoCode')}, '${value}')`)}
              onClear={() => onClear(nameof<CountryDto>('isoCode'))}
              active={activeFilters.includes(nameof<CountryDto>('isoCode'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<CountryDto>('isoCode'),
            useProfile: false
          }
        },
        {
          title: t('Flag'),
          tooltipRenderer: false,
          renderer: data => <>{<CountryFlag countryIsoCode={data.isoCode} countryName={data.name} />}</>
        }
      ],
      data: this.props.countries.state
    } as TableModel<CountryDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.countries.state.result && !this.props.countries.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.countries.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newCountryShown && !this.state.changeCountryShown && !showCountryConfigModal}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="cogs" /> &nbsp;{t('Country Maintenance')}
                    </>
                  ),
                  isVisible: () => true,
                  onClick: this.handleOnCountryMaintenance
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add Country')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newCountryShown && <NewCountryView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeCountryShown && <ChangeCountryView onClose={this.onEditItemClosed} currentCountry={this.state.selectedItem} />)}

        {showCountryConfigModal && (
          <Modal size="small" className="scheduler-poc-config" open closeOnEscape={true} onClose={this.closeCountryConfig}>
            <Modal.Header className="scheduler-poc-config__header">{t('Country Maintenance')}</Modal.Header>
            <Modal.Content className="scheduler-poc-config__content">
              <Tab
                className="scheduler-poc-config__tabs"
                menu={{ secondary: true, pointing: true }}
                panes={[
                  {
                    menuItem: t('Public Holidays'),
                    render: () => <PublicHolidaysTab country={selectedItem} onClose={this.closeCountryConfig} />
                  }
                ]}
              />
            </Modal.Content>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(CountriesListPage);
