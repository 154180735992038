import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import {
  Button,
  Container,
  DropdownItemProps,
  Flag,
  FlagNameValues,
  Form,
  Message,
  Modal,
  Dropdown,
  Input,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import { CreateCountryDto, NewCountryStore } from 'stores/configuration/locations/countries-store';
import { countryInfos } from 'utils/country-infos';
import { nameof } from 'utils/object';

interface NewCountryViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newCountry?: NewCountryStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewCountryViewState {
  countryOptions: DropdownItemProps[];
  userClickedSaved: boolean;
}

@connect(['newCountry', NewCountryStore])
class NewCountryView extends React.Component<NewCountryViewProps, NewCountryViewState> {
  private get newCountryStore() {
    return this.props.newCountry;
  }

  constructor(props: NewCountryViewProps) {
    super(props);
    this.newCountryStore.createNew({
      name: '',
      isoCode: '',
      description: ''
    });
    this.state = {
      userClickedSaved: false,
      countryOptions: countryInfos.map(c => ({
        key: c.name,
        text: c.name,
        content: (
          <>
            <Flag name={c.code as FlagNameValues} />
            &nbsp; {c.name}
          </>
        ),
        value: c.name
      }))
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewCountryViewProps) {
    const { result } = this.newCountryStore.state;
    if (result && result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.setState({ userClickedSaved: true });
    this.newCountryStore.submit();
  }

  @autobind
  private onCancelNewItem() {
    this.newCountryStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    this.setState({ userClickedSaved: false });
    const { item } = this.newCountryStore.state;
    const change = {};
    change[property] = value;
    this.newCountryStore.change({ ...item, ...change });
  }

  private onCountryChange = (e, { value }) => {
    this.handleValue(nameof<CreateCountryDto>('name'), value);
    const info = countryInfos.firstOrDefault(x => x.name === value);
    if (info) {
      this.handleValue(nameof<CreateCountryDto>('isoCode'), info.code);
    }
  };

  private onCountryAddition = (e, { value }) => {
    this.setState({
      countryOptions: [...this.state.countryOptions, { key: value, value, text: value }]
    });
  };

  public render() {
    const { t } = this.props;
    const { result, item } = this.newCountryStore.state;
    const { countryOptions } = this.state;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newCountryStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header className="modal__header">{t('New Country')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {result && !result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => o.body)}
              />
            )}
            {item && (
              <Form>
                <Form.Group widths="equal">
                  <Form.Field required error={!item.name && this.state.userClickedSaved}>
                    <label>{t('Country')}</label>
                    <Dropdown
                      search
                      selection
                      allowAdditions
                      options={countryOptions}
                      placeholder={t('Name')}
                      value={item.name}
                      onChange={this.onCountryChange}
                      onAddItem={this.onCountryAddition}
                      additionLabel={t('Add ')}
                    />
                  </Form.Field>
                  <Form.Field required error={!item.isoCode && this.state.userClickedSaved}>
                    <label>{t('Code')}</label>
                    <Input
                      placeholder={t('Code')}
                      value={item.isoCode}
                      onChange={(e, { value }) => this.handleValue(nameof<CreateCountryDto>('isoCode'), value)}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewCountryView);
