import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';

import {
  ChangeInstructorExpertiseStore,
  InstructorExpertiseDto,
  ChangeInstructorExpertiseDto
} from 'stores/skills/instructor-expertise-store';
import UserEditor from 'widgets/bussiness/user-by-role-editor';
import EventTypeEditor from 'widgets/bussiness/event-type-editor';
import ProfesionEditor from 'widgets/bussiness/profession-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import MachineMOdelEditor from 'widgets/bussiness/machine-model-editor';
import FunctionalSubAreaEditor from 'widgets/bussiness/functional-subarea-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import ClusterEditor from 'widgets/bussiness/cluster-editor';
import FunctionalAreaEditor from 'widgets/bussiness/functional-area-editor';
import TrainingNameEditor from 'widgets/bussiness/training-name-editor';
import { ItemReference } from 'stores/dataStore';
import MultiPlcTypeEditor from 'widgets/bussiness/multi-plc-type-editor';
import { nameof } from 'utils/object';

interface ChangeInstructorExpertiseViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeInstructorExpertise?: ChangeInstructorExpertiseStore;
  currentInstructorExpertise: InstructorExpertiseDto;
}

interface ChangeInstructorExpertiseViewState {
  instructor: ItemReference;
  eventType: ItemReference;
  role: ItemReference;
  level: ItemReference;
  cluster: ItemReference;
  isMachineRelated: boolean;
  equipmentType: ItemReference;
  machineModel: ItemReference;
  functionalSubArea: ItemReference;
  oem: ItemReference;
  nmrcluster: ItemReference;
  nmrfunctionalarea: ItemReference;
  nrmTrainingName: ItemReference;
}

@connect(['changeInstructorExpertise', ChangeInstructorExpertiseStore])
class ChangeInstructorExpertiseView extends React.Component<ChangeInstructorExpertiseViewProps, ChangeInstructorExpertiseViewState> {
  private get ChangeInstructorExpertiseStore() {
    return this.props.changeInstructorExpertise;
  }

  constructor(props: ChangeInstructorExpertiseViewProps) {
    super(props);

    this.ChangeInstructorExpertiseStore.state.result = null;

    this.ChangeInstructorExpertiseStore.change({
      id: this.props.currentInstructorExpertise.id,
      equipmentTypeId: this.props.currentInstructorExpertise.equipmentTypeId,
      eventTypeId: this.props.currentInstructorExpertise.eventTypeId,
      instructorId: this.props.currentInstructorExpertise.instructorId,
      isMachineRelated: this.props.currentInstructorExpertise.isMachineRelated,
      machineModelId: this.props.currentInstructorExpertise.machineModelId,
      machineRelatedClusterId: this.props.currentInstructorExpertise.machineRelatedClusterId,
      nmrClusterId: this.props.currentInstructorExpertise.nmrClusterId,
      nmrFunctionalAreaId: this.props.currentInstructorExpertise.nmrFunctionalAreaId,
      nmrFunctionalSubAreaId: this.props.currentInstructorExpertise.nmrFunctionalSubAreaId,
      nmrTrainingNameId: this.props.currentInstructorExpertise.nmrTrainingNameId,
      oemId: this.props.currentInstructorExpertise.oemId,
      roleId: this.props.currentInstructorExpertise.roleId,
      trainingLevelId: this.props.currentInstructorExpertise.trainingLevelId,
      plcTypes: this.props.currentInstructorExpertise.plcTypes
    });

    this.state = {
      instructor: { id: this.props.currentInstructorExpertise.instructorId, title: this.props.currentInstructorExpertise.instructor },
      eventType: { id: this.props.currentInstructorExpertise.eventTypeId, title: this.props.currentInstructorExpertise.eventTypeName },
      role: { id: this.props.currentInstructorExpertise.roleId, title: this.props.currentInstructorExpertise.roleName },
      level: { id: this.props.currentInstructorExpertise.trainingLevelId, title: this.props.currentInstructorExpertise.trainingLevelName },
      isMachineRelated: this.props.currentInstructorExpertise.isMachineRelated,
      cluster: {
        id: this.props.currentInstructorExpertise.machineRelatedClusterId,
        title: this.props.currentInstructorExpertise.machineRelatedClusterName
      },
      equipmentType: {
        id: this.props.currentInstructorExpertise.equipmentTypeId,
        title: this.props.currentInstructorExpertise.equipmentTypeName
      },
      machineModel: {
        id: this.props.currentInstructorExpertise.machineModelId,
        title: this.props.currentInstructorExpertise.machineModelName
      },
      functionalSubArea: {
        id: this.props.currentInstructorExpertise.nmrFunctionalSubAreaId,
        title: this.props.currentInstructorExpertise.nmrFunctionalSubAreaName
      },
      oem: { id: this.props.currentInstructorExpertise.oemId, title: this.props.currentInstructorExpertise.oemName },
      nmrcluster: { id: this.props.currentInstructorExpertise.nmrClusterId, title: this.props.currentInstructorExpertise.nmrClusterName },
      nmrfunctionalarea: {
        id: this.props.currentInstructorExpertise.nmrFunctionalAreaId,
        title: this.props.currentInstructorExpertise.nmrFunctionalAreaName
      },
      nrmTrainingName: {
        id: this.props.currentInstructorExpertise.nmrTrainingNameId,
        title: this.props.currentInstructorExpertise.nmrTrainingName
      }
    };
  }

  @autobind
  private onChangeItem() {
    this.ChangeInstructorExpertiseStore.update().then(x => {
      if (this.ChangeInstructorExpertiseStore.state.result && this.ChangeInstructorExpertiseStore.state.result.isSuccess) {
        this.props.onClose(true);
      }
    });
  }

  @autobind
  private onCancelChangeItem() {
    this.ChangeInstructorExpertiseStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.ChangeInstructorExpertiseStore.change({ ...this.ChangeInstructorExpertiseStore.state.item, ...change });
  }

  private isMachineRelated(mode: boolean) {
    this.ChangeInstructorExpertiseStore.state.item.isMachineRelated = mode;
    this.setState({
      isMachineRelated: mode
    });
  }

  public render() {
    const { t } = this.props as any;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.ChangeInstructorExpertiseStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('Edit instructor expertise')}</Modal.Header>
        <Modal.Content image>
          <Container>
            {this.ChangeInstructorExpertiseStore.state.result && !this.ChangeInstructorExpertiseStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.ChangeInstructorExpertiseStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.ChangeInstructorExpertiseStore.state.item && (
              <Form>
                <Form.Group widths={2}>
                  <Form.Field required>
                    <div className={`required field`}>
                      <label>{t('Instructor')}</label>
                      <UserEditor
                        nullable
                        role={'Instructor'}
                        value={this.state.instructor}
                        onChange={c => {
                          this.ChangeInstructorExpertiseStore.state.item.instructorId = c ? c.id : null;
                          this.setState({ instructor: c });
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required>
                    <div className={`required field`}>
                      <label>{t('Event Type')}</label>
                      <EventTypeEditor
                        nullable
                        value={this.state.eventType}
                        onChange={c => {
                          this.ChangeInstructorExpertiseStore.state.item.eventTypeId = c ? c.id : null;
                          this.setState({ eventType: c });
                        }}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths={2}>
                  <Form.Field required>
                    <div className={`field`}>
                      <label>{t('Role')}</label>
                      <ProfesionEditor
                        nullable
                        value={this.state.role}
                        onChange={c => {
                          this.ChangeInstructorExpertiseStore.state.item.roleId = c ? c.id : null;
                          this.setState({ role: c });
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required>
                    <div className={` field`}>
                      <label>{t('Training Level')}</label>
                      <TrainingLevelEditor
                        nullable
                        value={this.state.level}
                        onChange={c => {
                          this.ChangeInstructorExpertiseStore.state.item.trainingLevelId = c ? c.id : null;
                          this.setState({ level: c });
                        }}
                      />
                    </div>
                  </Form.Field>
                </Form.Group>

                <Form.Group width={2}>
                  <Form.Field>
                    <label>{t('Is machine related')}</label>
                    <Button.Group className="filter-button-group green" style={{ width: 120 }}>
                      <Button
                        className={this.state.isMachineRelated === true ? `active gray-border` : `gray-border`}
                        onClick={() => this.isMachineRelated(true)}
                      >
                        {t('Yes')}
                      </Button>
                      <Button
                        className={this.state.isMachineRelated === false ? `active gray-border` : `gray-border`}
                        onClick={() => this.isMachineRelated(false)}
                      >
                        {t('No')}
                      </Button>
                    </Button.Group>
                  </Form.Field>
                </Form.Group>
                {this.state.isMachineRelated && (
                  <>
                    <Form.Group widths={3}>
                      <Form.Field>
                        <label>{t('Cluster')}</label>
                        <div className={`field inline`}>
                          <MRClusterEditor
                            nullable
                            value={this.state.cluster}
                            onChange={c => {
                              this.ChangeInstructorExpertiseStore.state.item.machineRelatedClusterId = c ? c.id : null;
                              this.setState({ cluster: c });
                            }}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <label>{t('Equipment Type')}</label>
                        <div className={`field inline`}>
                          <EquipmentTypeEditor
                            nullable
                            clusterId={this.state.cluster ? this.state.cluster.id : undefined}
                            value={this.state.equipmentType}
                            onChange={c => {
                              this.ChangeInstructorExpertiseStore.state.item.equipmentTypeId = c ? c.id : null;
                              this.setState({ equipmentType: c });
                            }}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <label>{t('OEM')}</label>
                        <div className={`field inline`}>
                          <OemEditor
                            nullable
                            value={this.state.oem}
                            onChange={c => {
                              this.ChangeInstructorExpertiseStore.state.item.oemId = c ? c.id : null;
                              this.setState({ oem: c });
                            }}
                            equipmentId={this.state.equipmentType != null ? this.state.equipmentType.id : undefined}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div className={` field`}>
                          <label>{t('Machine Model')}</label>
                          <MachineMOdelEditor
                            nullable
                            value={this.state.machineModel}
                            oemId={this.state.oem ? this.state.oem.id : undefined}
                            equipmentId={this.state.equipmentType ? this.state.equipmentType.id : undefined}
                            onChange={c => {
                              this.ChangeInstructorExpertiseStore.state.item.machineModelId = c ? c.id : null;
                              this.setState({ machineModel: c });
                            }}
                          />
                        </div>
                      </Form.Field>

                      <Form.Field>
                        <div className={` field`}>
                          <label>{t('PLC Types')}</label>
                          <MultiPlcTypeEditor
                            className=""
                            value={this.ChangeInstructorExpertiseStore.state.item.plcTypes}
                            oemId={this.ChangeInstructorExpertiseStore.state.item.oemId}
                            equipmentId={this.ChangeInstructorExpertiseStore.state.item.equipmentTypeId}
                            onChange={c => this.handleValue(nameof<ChangeInstructorExpertiseDto>('plcTypes'), c)}
                            multiple
                          />
                        </div>
                      </Form.Field>
                    </Form.Group>
                  </>
                )}

                {!this.state.isMachineRelated && (
                  <>
                    <Form.Group widths={4}>
                      <Form.Field>
                        <label>{t('Cluster')}</label>
                        <div className={`field inline`}>
                          <ClusterEditor
                            nullable
                            value={this.state.nmrcluster}
                            onChange={c => {
                              this.ChangeInstructorExpertiseStore.state.item.nmrClusterId = c ? c.id : null;
                              this.setState({ nmrcluster: c });
                            }}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <label>{t('Functional Area')}</label>
                        <div className={`field inline`}>
                          <FunctionalAreaEditor
                            nullable
                            value={this.state.nmrfunctionalarea}
                            clusterId={this.state.nmrcluster ? this.state.nmrcluster.id : null}
                            onChange={c => {
                              this.ChangeInstructorExpertiseStore.state.item.nmrFunctionalAreaId = c ? c.id : null;
                              this.setState({ nmrfunctionalarea: c });
                            }}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <label>{t('Training Name')}</label>
                        <div className={`field inline`}>
                          <TrainingNameEditor
                            nullable
                            value={this.state.nrmTrainingName}
                            funcionalAreaId={this.state.nmrfunctionalarea ? this.state.nmrfunctionalarea.id : null}
                            onChange={c => {
                              this.ChangeInstructorExpertiseStore.state.item.nmrTrainingNameId = c ? c.id : null;
                              this.setState({ nrmTrainingName: c });
                            }}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <label>{t('Functional Subarea')}</label>
                        <div className={`field required inline`}>
                          <FunctionalSubAreaEditor
                            nullable
                            value={this.state.functionalSubArea}
                            trainingNameId={this.state.nrmTrainingName ? this.state.nrmTrainingName.id : null}
                            onChange={c => {
                              this.ChangeInstructorExpertiseStore.state.item.nmrFunctionalSubAreaId = c ? c.id : null;
                              this.setState({ functionalSubArea: c });
                            }}
                          />
                        </div>
                      </Form.Field>
                    </Form.Group>
                  </>
                )}
              </Form>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelChangeItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChangeInstructorExpertiseView);
