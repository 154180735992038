import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Header, Grid } from 'semantic-ui-react';
import { Query, OrderDefinition, ItemReference } from 'stores/dataStore';
import { TnaTemplatesStore, TnaTemplateDto } from 'stores/assessments/templates/tna-templates-store';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber } from 'utils/useful-functions';
import { nameof } from 'utils/object';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';

interface ChooseTnaTemplatesViewProps extends WithTranslation {
  onAddTemplate?: (template: TnaTemplateDto) => void;
  onCloseTemplateModal?: () => void;
  alreadySelectedTemplateId?: string;
  templateStore?: TnaTemplatesStore;
  profileId: string;
}

interface ChooseTemplatesFilters {
  templateTitleOrId: string;
  machineModel: string;
}

interface ChooseTnaTemplatesViewState {
  activeFilters: string[];
  query: Query;
  selectedTemplate: TnaTemplateDto;
  filters: ChooseTemplatesFilters;
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
}

@connect(['templateStore', TnaTemplatesStore])
class ChooseTnaTemplatesView extends React.Component<ChooseTnaTemplatesViewProps, ChooseTnaTemplatesViewState> {
  constructor(props: ChooseTnaTemplatesViewProps) {
    super(props);

    this.state = {
      query: {
        searchQuery: ``,
        orderBy: [],
        filter: [],
        skip: 0,
        take: 10
      },
      activeFilters: [],
      selectedTemplate: undefined,
      filters: {
        templateTitleOrId: undefined,
        machineModel: undefined
      },
      cluster: null,
      equipmentType: null,
      oem: null,
      machineModel: null
    };
  }

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  @autobind
  private handleOnAddTemplate = () => {
    const { selectedTemplate } = this.state;
    if (selectedTemplate == null) return;

    this.props.onAddTemplate && this.props.onAddTemplate(selectedTemplate);
    this.props.onCloseTemplateModal();
  };

  @autobind
  private handleOnCancel = () => {
    this.props.onCloseTemplateModal();
  };

  @autobind
  private load() {
    this.props.templateStore.getAllAsync(this.state.query);
  }

  componentDidUpdate() {
    if (!this.state.selectedTemplate) {
      const item = (this.props.templateStore.state.items || []).firstOrDefault();
      if (item && item.item) this.setState({ selectedTemplate: item.item as any });
    }
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  }

  @autobind
  private handleFilterChange(filters, oDatafilters) {
    const parameters = {};
    if (!isNullOrWhiteSpaces(filters.machineModel)) {
      parameters['machineModelName'] = filters.machineModel;
    }
    let query = { ...this.state.query, filter: oDatafilters, skip: 0, parameters };
    this.setState({ filters, query }, this.load);
  }

  private handleFilterByTemplateTitleOrId(value: string) {
    let filters = { ...this.state.filters };
    filters.templateTitleOrId = value;
    this.refreshTable(filters);
  }

  private onClusterEditorChange = (value: ItemReference) => {
    this.setState({
      cluster: value
    });
  };

  onEquipmentTypeFilterChange = (value: ItemReference) => {
    this.setState({
      equipmentType: value
    });
  };

  onOemFilterChange = (value: ItemReference) => {
    this.setState({
      oem: value
    });
  };

  onChangeMachineModelFilter = (value: ItemReference) => {
    let filters = { ...this.state.filters };
    if (value) {
      filters.machineModel = value.title;
      this.setState({ machineModel: value });
    } else {
      this.setState({ machineModel: null });
      filters.machineModel = null;
    }
    this.refreshTable(filters);
  };

  handleOnSelectedTemplate = (items: unknown[]) => {
    if (items && items.length > 0) this.setState({ selectedTemplate: items[0] as any });
  };

  private refreshTable(filters: ChooseTemplatesFilters) {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  }

  private buildODataFilter(filters?: ChooseTemplatesFilters) {
    const oDataFilters = [];

    if (!isNullOrWhiteSpaces(this.props.alreadySelectedTemplateId)) {
      oDataFilters.push({ Id: { ne: { value: this.props.alreadySelectedTemplateId, type: 'guid' } } });
    }

    if (!isNullOrWhiteSpaces(this.props.profileId)) {
      oDataFilters.push({ profileItemId: { eq: { type: 'guid', value: this.props.profileId } } });
    }

    if (!isNullOrWhiteSpaces(filters.templateTitleOrId)) {
      const input = filters.templateTitleOrId;
      const parts = [`contains(tolower(title), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'TT');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('TT')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      const f = `(${parts.join(' or ')})`;
      oDataFilters.push(f);
    }

    oDataFilters.push('isActive eq true');

    return oDataFilters;
  }

  public render() {
    const { t } = this.props as any;
    const { selectedTemplate } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Template ID'),
          tooltipRenderer: true,
          renderer: data => data.friendlyId,
          sortDefinition: {
            field: nameof<TnaTemplateDto>('friendlyId'),
            useProfile: false
          },
          selectableHeader: true
        },
        {
          title: t('Template'),
          tooltipRenderer: true,
          renderer: data => data.title
        },
        {
          title: t('Machine Model'),
          tooltipRenderer: false,
          renderer: data => {
            const allMachines = data.machineModels.map(machineModel => (
              <span
                key={machineModel.id}
                className={'question-bank__cell__tag'}
              >{`${machineModel.equipmentTypeName} | ${machineModel.name}`}</span>
            ));
            return <div className={data.machineModels && data.machineModels.length > 1 ? 'table-cell__more-width' : ''}>{allMachines}</div>;
          }
        }
      ],
      data: this.props.templateStore.state
    } as TableModel<TnaTemplateDto>;

    return (
      <Modal
        size="large"
        className="template-search__modal"
        open
        closeOnEscape={true}
        onClose={this.handleOnCancel}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="borderless-header">
          <Header as="h2" className="modal-header-title">
            {t('Select Template fdañldfas')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <Grid className="choose-template-search__first-row__column-filters">
            <Grid.Row columns="5">
              <Grid.Column width="4">
                <ClearableTimerInput
                  icon="search"
                  placeholder={t('Search in Template or ID')}
                  onChange={(event, data) => this.handleFilterByTemplateTitleOrId(data.value)}
                />
              </Grid.Column>

              <Grid.Column width="3">
                <MRClusterEditor
                  clearable={true}
                  nullable
                  value={this.state.cluster}
                  onChange={this.onClusterEditorChange}
                  placeholder={t('Cluster')}
                />
              </Grid.Column>

              <Grid.Column width="3">
                <EquipmentTypeEditor
                  nullable
                  clearable={true}
                  clusterId={this.state.cluster ? this.state.cluster.id : ''}
                  readOnly={!(this.state.cluster && this.state.cluster.id)}
                  value={this.state.equipmentType}
                  onChange={this.onEquipmentTypeFilterChange}
                  placeholder={t('Equipment Type')}
                />
              </Grid.Column>

              <Grid.Column width="3">
                <OemEditor
                  nullable
                  clearable={true}
                  value={this.state.oem}
                  readonly={!(this.state.equipmentType && this.state.equipmentType.id)}
                  onChange={this.onOemFilterChange}
                  placeholder={t('OEM')}
                  equipmentId={this.state.equipmentType != null ? this.state.equipmentType.id : undefined}
                />
              </Grid.Column>

              <Grid.Column width="3">
                <MachineModelEditor
                  cascade="true"
                  nullable
                  clearable={true}
                  oemId={this.state.oem ? this.state.oem.id : ''}
                  equipmentId={this.state.equipmentType ? this.state.equipmentType.id : ''}
                  value={this.state.machineModel}
                  onChange={data => this.onChangeMachineModelFilter(data)}
                  placeholder={t('Machine Model')}
                  readonly={!(this.state.oem && this.state.oem.id)}
                />
              </Grid.Column>

              {/* <Form.Field>
                <Input
                  icon="search"
                  placeholder={t('Search Machine Model')}
                  onChange={(event, data) => this.handleFilterByMachineModel(data.value)}
                />
              </Form.Field> */}
            </Grid.Row>
          </Grid>

          <Container className="template-list-table">
            <TableView
              model={tableModel}
              selectable={true}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              hiddeMenu={true}
              onSelectionChange={this.handleOnSelectedTemplate}
              onPageChange={this.handlePageChange}
              // onFilterChange={this.handleFilterChange}
              maxSelection={1}
              selectionType={'checkbox'}
              unselectFirstRow
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.handleOnCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" disabled={selectedTemplate == null} onClick={this.handleOnAddTemplate} positive>
            {t('Select Template')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseTnaTemplatesView);
