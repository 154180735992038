import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { EventDto, EventsStore, EventStatus } from 'stores/events/events-store';
import * as autobind from 'autobind';
import EventForm from './event-form/event-form';
import EventWizard from './event-wizard/event-wizard';
import { Message, Icon, Grid, Form, Button, Popup } from 'semantic-ui-react';
import LineSeparator from 'widgets/bussiness/line-separator';
import { Query, OrderDefinition, ItemState, ItemReference } from 'stores/dataStore';
import { nameof, getProperties } from 'utils/object';
import { CommandResult } from 'stores/types';
import { TableModel, TableView, TablePagination } from 'widgets/collections/table';
import { CountryFlag } from 'widgets/bussiness/country-flag';
import { extractFriendlyIdNumber, isNullOrWhiteSpaces } from 'utils/useful-functions';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { DateInput } from 'widgets/form/dateInput';
import { decamelCase, getRequestsLocation } from 'utils/event-utils';
import DeliveryEditor from 'widgets/bussiness/delivery-method-editor';
import { DateTimeService } from 'services/datetime-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { buildEventViewModel } from 'stores/events/event-form-store';
import { ToastComponent } from '../landing-pages/util/toast-component';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import MultiEventTypeEditor from 'widgets/bussiness/multiple-selector/multi-event-type-editor';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';
import { Category, NMClusterDto } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import MachineRelatedEditor from 'widgets/bussiness/machine-related-editor';
import MultipleNmrClustersEditor from 'widgets/bussiness/multiple-selector/multiple-nmr-cluster-editor';
import MultipleLocationEditor from 'widgets/bussiness/multiple-selector/multiple-location-editor';
import { LocationDto } from 'stores/configuration/locations/locations-store';
import { PriorityDto } from 'stores/configuration/events-n-requests/priorities-store';
import MultiplePriorityEditor from 'widgets/bussiness/multiple-selector/multiple-priority-editor';
import MultipleUsersEditor from 'widgets/bussiness/multiple-selector/multiple-user-editor';
import { UserDto } from 'stores/users/users-store';
import MultipleProfessionsEditor from 'widgets/bussiness/multiple-selector/multiple-professions-editor';
import { ProfessionDto } from 'stores/configuration/profiles/profession-roles-store';
import MultipleEventStatusEditor from 'widgets/bussiness/multiple-selector/multiple-event-status-editor';
import MultipleRegionEditor from 'widgets/bussiness/multiple-selector/multiple-region-editor';
import { RegionDto } from 'stores/configuration/locations/regions-store';
import MultiplePillarsEditor from 'widgets/bussiness/multiple-selector/multiple-pillar-editor';
import { PillarDto } from 'stores/configuration/profiles/pillars-store';
import { EventFormActions } from './event-form/buttons/action-buttons';

export interface EventsHomeProps extends WithTranslation, RouteComponentProps {
  events: EventsStore;
}

export interface EventsHomeState {
  showCreationWizard: boolean;
  query: Query;
  newEventShown: boolean;
  eventShown: boolean;
  modalEventForm: boolean;
  activeFilters: { [key: string]: any };
  selectedItem: any;
  showFilters: boolean;
  eventType: ItemReference;
  eventTypeList: ItemReference[];
  startDate: string;
  endDate: string;
  active: boolean;
  showNewEventButton: boolean;
  requestLocation: ItemReference;
  role: ItemReference;
  instructor: ItemReference;
  delivery: ItemReference;
  cluster: ItemReference;
  clusterList: NMClusterDto[];
  route?: RouteComponentProps;
  pagination: TablePagination;
  togglePagination: boolean;
  category: Category;
  isMachineRelated: boolean;
  requestingLocationList: LocationDto[];
  locationList: LocationDto[];
  priorityList: PriorityDto[];
  instructorsNameList: UserDto[];
  rolesList: ProfessionDto[];
  eventStatus: EventStatus[];
  instructorLocationsList: LocationDto[];
  requestRegionLocationList: RegionDto[];
  instructorPillarList: PillarDto[];
  loading: boolean;
  someFilterOpened: boolean;
}

@connect(['events', EventsStore])
class EventsHomePage extends Component<EventsHomeProps, EventsHomeState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  timer: any = null;
  currentUserPlannerRole: boolean = false;
  plannerCustomPageSize: any[] = [
    { text: '50 / page', value: 50 },
    { text: '100 / page', value: 100 },
    { text: '150 / page', value: 150 },
    { text: '200 / page', value: 200 }
  ];
  defaultPageSize: any[] = [
    { text: '10 / page', value: 10 },
    { text: '20 / page', value: 20 },
    { text: '30 / page', value: 30 },
    { text: '50 / page', value: 50 }
  ];

  private get EventsStore() {
    return this.props.events;
  }

  constructor(props: EventsHomeProps) {
    super(props);
    let defaultOrder: OrderDefinition[] = [{ direction: 'Descending', field: 'friendlyEventId', useProfile: false }];
    let take = 10;
    const currentUserInfo = this.identityService.getUserInfo();

    let pagination: TablePagination = null;

    if (IdentityService.isPlanner(currentUserInfo)) {
      //defaultOrder = [{ direction: 'Ascending', field: 'eventStatus', useProfile: false }];
      take = this.plannerCustomPageSize[0].value;
      this.currentUserPlannerRole = true;
      pagination = {
        activePage: 1,
        options: this.plannerCustomPageSize,
        pageSize: this.plannerCustomPageSize[0].value
      };
    } else if (IdentityService.isRegionalManufacturingVP(currentUserInfo) || IdentityService.isGlobalManufacturing(currentUserInfo)) {
      pagination = {
        activePage: 1,
        options: this.defaultPageSize,
        pageSize: this.defaultPageSize[3].value
      };
      take = this.defaultPageSize[3].value;
    }

    this.state = {
      showCreationWizard: false,
      query: { searchQuery: '', orderBy: defaultOrder, skip: 0, take: take },
      newEventShown: false,
      eventShown: false,
      modalEventForm: false,
      activeFilters: { EventStatus: this.getEventStatusFilters(true) },
      selectedItem: null,
      showFilters: false,
      eventType: null,
      eventTypeList: [],
      startDate: null,
      endDate: null,
      active: true,
      showNewEventButton: false,
      requestLocation: null,
      role: null,
      instructor: null,
      delivery: null,
      cluster: null,
      togglePagination: false,
      pagination: pagination,
      category: null,
      isMachineRelated: null,
      clusterList: [],
      requestingLocationList: [],
      locationList: [],
      priorityList: [],
      instructorsNameList: [],
      rolesList: [],
      eventStatus: [],
      instructorLocationsList: [],
      requestRegionLocationList: [],
      instructorPillarList: [],
      loading: false,
      someFilterOpened: false
    };
  }

  @autobind
  private async onClickCol(item: EventDto) {
    this.setState({ loading: true });
    let elem = await this.props.events.getById(item.id);
    this.setState({ selectedItem: elem, eventShown: true, loading: false });
  }

  @autobind
  private async onSaveRow(item: EventDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') await this.props.events.saveAsync(item, state);
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: EventDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') await this.props.events.deleteAsync(item.id, state);
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onViewDetailsItem() {
    const { selectedItem } = this.state;
    this.setState({ loading: true });
    let elem = await this.props.events.getById(selectedItem.id);
    this.setState({ eventShown: true, selectedItem: elem, loading: false });
  }

  @autobind
  private onEditItemClosed(actionPerformed?: EventFormActions, payload?: any) {
    this.setState({ eventShown: false });
    if (!(actionPerformed && actionPerformed === 'close')) {
      this.load();
    }
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    let pagination = this.currentUserPlannerRole
      ? {
          activePage: skip / take + 1,
          pageSize: take,
          options: this.plannerCustomPageSize
        }
      : null;

    this.setState(({ query }) => ({ togglePagination: true, query: { ...query, skip, take }, pagination }), this.load);
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);
    this.setState(({ query }) => ({ query: { ...query, filter, skip: 0 }, activeFilters }), this.load);
  }

  private onShowEventCreationWizard = () => {
    this.setState({ showCreationWizard: true });
  };

  private onCloseEventCreationWizard = (isSuccess: boolean) => {
    this.setState({ showCreationWizard: false });
    if (isSuccess) this.load();
  };

  private extractRequestLocation(event: EventDto): string[] {
    const location = getRequestsLocation(event);
    return location ? location.map(x => x?.location) : [];
  }

  private load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const { togglePagination } = this.state;
    const {
      friendlyId,
      title,
      profession,
      requests,
      startDate,
      endDate,
      instructors,
      cluster,
      delivery,
      EventStatus,
      machineRelated,
      nmrCluster,
      extendedRequests,
      eventLocation,
      priority,
      ExtendedEventInstructors
    } = activeFilters;
    const evType = activeFilters['EventTypeItem/OriginalEventTypeId'];
    const filter = [].concat(
      ...getProperties(activeFilters)
        .filter(({ value }) => !!value)
        .map(({ value }) => value)
    );

    let initPagination =
      !togglePagination &&
      (evType ||
        friendlyId ||
        title ||
        profession ||
        requests ||
        startDate ||
        endDate ||
        instructors ||
        cluster ||
        delivery ||
        EventStatus ||
        machineRelated ||
        nmrCluster ||
        extendedRequests ||
        eventLocation ||
        priority ||
        ExtendedEventInstructors);

    this.setState(
      ({ query }) => {
        const returnObj = { query: { ...query, filter } } as EventsHomeState;
        if (initPagination) {
          returnObj.query.skip = 0;
          returnObj.pagination = { ...returnObj.pagination, activePage: 1 };
        }
        returnObj.togglePagination = false;
        return returnObj;
      },
      () => {
        this.props.events.getAllAsync(this.state.query);
      }
    );
  };

  private toggleActive = () => {
    let active = !this.state.active;
    const activeFilters = this.state.activeFilters;

    let showFilters = this.state.showFilters;
    showFilters = showFilters ? !showFilters : showFilters;

    activeFilters['EventStatus'] = this.getEventStatusFilters(active);

    this.setState({ active, activeFilters, eventStatus: [], showFilters }, this.load);
  };

  private toggleFilters = () => {
    const { showFilters, active } = this.state;
    const activeFilters = { EventStatus: {} };

    activeFilters['EventStatus'] = this.getEventStatusFilters(active);

    this.setState(
      {
        activeFilters,
        showFilters: !showFilters,
        eventType: null,
        startDate: null,
        endDate: null,
        requestLocation: null,
        role: null,
        instructor: null,
        delivery: null,
        cluster: null,
        eventStatus: []
      },
      () => !this.state.showFilters && this.load()
    );
  };

  private onStatusFilterChange = (fieldName: string, value: EventStatus[]) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      activeFilters[fieldName] = this.getEventStatusFilters(this.state.active);

      this.setState({ ...this.state, eventStatus: [] }, this.load);
    } else {
      const filterValue = {};

      filterValue[fieldName] = { in: value };
      activeFilters[fieldName] = filterValue;
    }

    this.setState({ ...this.state, activeFilters, eventStatus: value }, this.load);
  };

  private getEventStatusFilters(active: boolean) {
    let activeFilters;
    const currentUserInfo = this.identityService.getUserInfo();

    if (active) {
      if (IdentityService.isPoc(currentUserInfo)) {
        activeFilters = {
          or: [
            {
              and: [
                { EventStatus: 'Draft' },
                {
                  or: [
                    { 'EventTypeItem/EventTypeConfiguration/RoleThatOwnEvents': 'PoC' },
                    "EventTypeItem/EventTypeConfiguration/RolesThatOwnEventsExtended/any(r:r eq 'PoC')"
                  ]
                }
              ]
            },
            { EventStatus: 'Planned' },
            { EventStatus: 'InProgress' },
            { EventStatus: 'Completed' }
          ]
        };
      } else if (IdentityService.isPlannerMTC(currentUserInfo)) {
        activeFilters = { EventStatus: { eq: 'Draft' } };
      } else {
        activeFilters = {
          or: [{ EventStatus: 'Draft' }, { EventStatus: 'Planned' }, { EventStatus: 'InProgress' }, { EventStatus: 'Completed' }]
        };
      }
    } else {
      if (IdentityService.isPlannerMTC(currentUserInfo)) {
        activeFilters = {
          or: [{ EventStatus: 'Closed' }, { EventStatus: 'Planned' }, { EventStatus: 'InProgress' }, { EventStatus: 'Completed' }]
        };
      } else activeFilters = { EventStatus: { eq: 'Closed' } };
    }

    return activeFilters;
  }

  private onEventTitleFilterChanged = (e, { value }) => {
    const activeFilters = { ...this.state.activeFilters };
    if (isNullOrWhiteSpaces(value)) delete activeFilters['title'];
    else activeFilters['title'] = { 'tolower(title)': { contains: value.toLowerCase() } };
    this.setState({ activeFilters }, this.load);
  };

  private onEventIDFilterChanged = (e, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) {
      delete activeFilters['friendlyId'];
      this.setState({ activeFilters }, this.load);
    } else {
      const friendlyId = extractFriendlyIdNumber(value, 'E');

      if (!Number.isNaN(friendlyId)) {
        activeFilters['friendlyId'] = `contains(cast(FriendlyEventId, 'Edm.String'), '${friendlyId}')`;
      }

      this.setState({ activeFilters }, this.load);
    }
  };

  private onEventTypeFilterChanged = (value, fieldName) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
    } else {
      const filterValue = {};
      filterValue[fieldName] = { in: { type: 'guid', value: value } };
      activeFilters[fieldName] = filterValue;
    }

    this.setState({ activeFilters }, this.load);
  };

  private onCategoryChange = (fieldName, value) => {
    this.setState({ category: value });
    const activeFilters = { ...this.state.activeFilters };

    if (!value) {
      delete activeFilters[fieldName];
      this.setState({ category: null });
    } else {
      activeFilters['category'] = { eventDetails: { category: { eq: value } } };
    }

    this.setState({ activeFilters }, this.load);
  };

  private onMachineRelatedChange = (fieldName: string, value: boolean) => {
    //this.setState({ isMachineRelated: value });
    const activeFilters = { ...this.state.activeFilters };

    if (value === null) {
      delete activeFilters[fieldName];
      this.setState({ isMachineRelated: null, activeFilters }, this.load);
    } else if (value === false) {
      activeFilters['machineRelated'] = { eventDetails: { isMachineRelated: { eq: false } } };
      this.setState({ isMachineRelated: false, activeFilters }, this.load);
    } else {
      activeFilters['machineRelated'] = { eventDetails: { isMachineRelated: { eq: true } } };
      this.setState({ isMachineRelated: true, activeFilters }, this.load);
    }
  };

  private onNmrClusterChange = (fieldName: string, value: NMClusterDto[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, clusterList: [] }, this.load);
    } else {
      const filterValue = {};
      filterValue['eventDetails/nmrClusterId'] = { in: { value: value.map(cluster => cluster.id), type: 'guid' } };

      activeFilters[fieldName] = filterValue;
    }
    this.setState({ ...this.state, activeFilters, clusterList: value }, this.load);
  };

  private onRequestingLocationListChange = (fieldName: string, value: LocationDto[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, requestingLocationList: [] }, this.load);
    } else {
      activeFilters['requests'] = {
        requests: { any: [{ requestingLocationId: { in: { type: 'guid', value: value.map(location => location.id) } } }] }
      };
    }
    this.setState({ ...this.state, activeFilters, requestingLocationList: value }, this.load);
  };

  private handleLocationListFilterChange = (fieldName: string, value: LocationDto[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, locationList: [] }, this.load);
    } else {
      const filterValue = {};
      filterValue['eventDetails/locationId'] = { in: { value: value.map(location => location.id), type: 'guid' } };
      activeFilters[fieldName] = filterValue;
    }
    this.setState({ ...this.state, activeFilters, locationList: value }, this.load);
  };

  private onPriorityListFilterChange = (fieldName: string, value: PriorityDto[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, priorityList: [] }, this.load);
    } else {
      const filterValue = {};
      filterValue['eventDetails/priorityId'] = { in: { value: value.map(priority => priority.id), type: 'guid' } };

      activeFilters[fieldName] = filterValue;
    }
    this.setState({ ...this.state, activeFilters, priorityList: value }, this.load);
  };

  private onInstructorsListFilterChange = (fieldName: string, value: UserDto[]) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, instructorsNameList: [] }, this.load);
    } else {
      activeFilters['instructors'] = {
        eventInstructors: { any: [{ instructorId: { in: { type: 'guid', value: value.map(location => location.id) } } }] }
      };
    }

    this.setState({ ...this.state, activeFilters, instructorsNameList: value }, this.load);
  };

  private onRolesListChange = (fieldName: string, value: ProfessionDto[]) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, rolesList: [] }, this.load);
    } else {
      const filterValue = {};
      filterValue['eventDetails/professionId'] = { in: { value: value.map(roles => roles.id), type: 'guid' } };

      activeFilters[fieldName] = filterValue;
    }

    this.setState({ ...this.state, activeFilters, rolesList: value }, this.load);
  };

  private onRequestRegionLocationChange = (fieldName: string, value: RegionDto[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, requestRegionLocationList: [] }, this.load);
    } else {
      const filterValue = {};
      filterValue[fieldName] = {
        any: [{ Location: { regionId: { in: { value: value.map(locationRegion => locationRegion.id), type: 'guid' } } } }]
      };
      activeFilters[fieldName] = filterValue;
    }
    this.setState({ ...this.state, activeFilters, requestRegionLocationList: value }, this.load);
  };

  private onInstructorPillarFilterChange = (fieldName: string, value: PillarDto[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, instructorPillarList: [] }, this.load);
    } else {
      const filterValue = {};
      filterValue[fieldName] = {
        any: [{ Pillar: { Id: { in: { value: value.map(instructorPillar => instructorPillar.id), type: 'guid' } } } }]
      };
      activeFilters[fieldName] = filterValue;
    }
    this.setState({ ...this.state, activeFilters, instructorPillarList: value }, this.load);
  };

  private startDateFilterChanged = (_, value: string) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value) {
      delete activeFilters['startDate'];
      this.setState({ activeFilters, startDate: null }, this.load);
    } else {
      activeFilters['startDate'] = { startDate: { ge: new Date(value) } };
      this.setState({ activeFilters: activeFilters, startDate: value }, this.load);
    }
  };

  private endDateFilterChanged = (_, value: string) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value) {
      delete activeFilters['endDate'];
      this.setState({ activeFilters, endDate: null }, this.load);
    } else {
      activeFilters['endDate'] = { endDate: { le: new Date(value) } };
      this.setState({ activeFilters, endDate: value }, this.load);
    }
  };

  componentDidMount() {
    this.load();
    if (this.props.match.params['id'] != null) {
      const id = this.props.match.params['id'].toString();
      this.EventsStore.getById(id)
        .then(e => {
          this.setState({ selectedItem: e }, this.onViewDetailsItem);
        })
        .catch(_ => {
          this.props.history.replace('/not-found');
        });
    }

    const currentUserInfo = this.identityService.getUserInfo();
    if (IdentityService.isAdmin(currentUserInfo)) this.setState({ showNewEventButton: true });
    else if (
      !this.identityService.isInRole(Roles.Employee) &&
      !this.identityService.isInRole(Roles.Reporting) &&
      !this.identityService.isInRole(Roles.GlobalEngineering) &&
      !this.identityService.isInRole(Roles.GlobalManufacturing) &&
      !this.identityService.isInRole(Roles.RegionalManufacturingVP)
    ) {
      this.props.events.getIfRoleIsCreator(this.identityService.getUserInfo()?.activeRole).then(res => {
        this.setState({ showNewEventButton: res });
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params['id'] !== nextProps.match.params['id'] && nextProps.match.params['id'] !== undefined) {
      const id = nextProps.match.params['id'].toString();
      this.EventsStore.getById(id)
        .then(e => {
          this.setState({ selectedItem: e }, this.onViewDetailsItem);
        })
        .catch(_ => {
          this.props.history.replace('/not-found');
        });
    }
  }

  onClusterEditorChange = (value: ItemReference) => {
    const activeFilters = this.state.activeFilters;
    if (!value) delete activeFilters['cluster'];
    else activeFilters['cluster'] = { eventDetails: { nmrClusterId: { eq: { type: 'guid', value: value.id } } } };
    this.setState({ activeFilters }, this.load);
  };

  onDeliveryMethodChange = (value: ItemReference) => {
    const activeFilters = this.state.activeFilters;
    if (!value) delete activeFilters['delivery'];
    else activeFilters['delivery'] = { eventTrainingDetails: { deliveryMethodId: { eq: { type: 'guid', value: value.id } } } };
    this.setState({ activeFilters }, this.load);
  };

  onLocationChange = (value: ItemReference): void => {
    const activeFilters = this.state.activeFilters;

    if (!value) delete activeFilters['requests'];
    else activeFilters['requests'] = { requests: { any: [{ requestingLocationId: { eq: { type: 'guid', value: value.id } } }] } };

    this.setState({ activeFilters }, this.load);
  };

  exportElements = async () => {
    this.props.events
      .downloadAll()
      .then(res => {
        ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
      });
  };

  handleOnEnterKeydown = item => {
    this.handleOnRowDoubleClick(item);
  };

  handleOnRowDoubleClick = item => {
    this.setState({ selectedItem: item }, () => this.onViewDetailsItem());
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  render() {
    const { t, match, history, location, staticContext } = this.props;
    const {
      showCreationWizard,
      showNewEventButton,
      requestLocation,
      showFilters,
      pagination,
      startDate,
      loading,
      someFilterOpened,
      ...restState
    } = this.state;
    const {
      eventTypeList,
      delivery,
      endDate,
      active,
      eventShown,
      selectedItem,
      category,
      isMachineRelated,
      clusterList,
      requestingLocationList,
      locationList,
      priorityList,
      instructorsNameList,
      rolesList,
      eventStatus,
      requestRegionLocationList,
      instructorPillarList
    } = restState;

    const route: RouteComponentProps = { match, history, location, staticContext };

    const tableModel = {
      columns: [
        {
          title: t('Event ID'),
          tooltipRenderer: true,
          renderer: data => <a className="clickable-icon">{data?.friendlyId}</a>,
          selectableHeader: true,
          sortDefinition: {
            field: 'friendlyEventId',
            useProfile: false,
            active: 'Descending'
          },
          onClick: data => this.onClickCol(data)
        },
        {
          title: t('Event Type'),
          tooltipRenderer: true,
          renderer: data => <>{data?.eventType.name}</>,
          selectableHeader: true,
          sortDefinition: { field: 'eventTypeItem/name', useProfile: false }
        },
        {
          title: t('Event Title'),
          tooltipRenderer: true,
          renderer: data => <>{data?.title}</>,
          selectableHeader: true,
          sortDefinition: { field: nameof<EventDto>('title'), useProfile: false }
        },

        {
          title: t('Requesting Location'),
          tooltipRenderer: true,
          renderer: data => (
            <>
              {this.extractRequestLocation(data)
                ? this.extractRequestLocation(data).map((item, idx) => {
                    return (
                      <div key={`${data.friendlyId}_${item}_${idx}`}>
                        <p className="no-margin">{item}</p>
                      </div>
                    );
                  })
                : ''}
            </>
          ),
          selectableHeader: true
          //sortDefinition: { field: 'requestDetailsLocation', useProfile: false }
        },

        {
          title: t('Event Location'),
          tooltipRenderer: true,
          renderer: data =>
            data?.eventDetails?.location?.location ? (
              <>
                <CountryFlag
                  countryName={
                    data?.eventDetails?.location?.code !== 'XXXX' && data?.eventDetails?.location?.code !== 'XXXXX'
                      ? data.eventDetails?.location?.countryName
                      : null
                  }
                />
                &nbsp;{data.eventDetails?.location?.location}
              </>
            ) : (
              ''
            ),
          selectableHeader: true
          //sortDefinition: { field: 'eventDetailsLocation', useProfile: false }
        },
        {
          title: t('Delivery Method'),
          tooltipRenderer: true,
          renderer: data => (
            <>
              {data.eventTrainingDetails && data.eventTrainingDetails.deliveryMethod ? (
                <>
                  {data.eventTrainingDetails.deliveryMethod.name}
                  <br />
                </>
              ) : (
                ''
              )}
            </>
          ),
          selectableHeader: true
        },
        {
          title: t('Event Start Date'),
          renderer: data => <span>{DateTimeService.toDateInputString(data?.startDate)}</span>,
          selectableHeader: true,
          sortDefinition: { field: nameof<EventDto>('startDate'), useProfile: false }
        },
        {
          title: t('Event End Date'),
          renderer: data => <span>{DateTimeService.toDateInputString(data?.endDate)}</span>,
          selectableHeader: true,
          sortDefinition: { field: nameof<EventDto>('endDate'), useProfile: false }
        },
        {
          title: t('Event Duration - Planned'),
          tooltipRenderer: true,
          //renderer: data => <>{data?.plannedDuration + (data?.plannedDuration.includes('w/d') ? '' : ' w/d')}</>,
          renderer: data => (
            <>
              {Number.isNaN(Number.parseInt(data?.plannedDuration))
                ? data?.plannedDuration
                : Number.parseInt(data?.plannedDuration) + ' w/d'}
            </>
          ),
          selectableHeader: true,
          sortDefinition: { field: nameof<EventDto>('plannedDuration'), useProfile: false }
        },
        {
          title: t('Priority'),
          tooltipRenderer: true,
          renderer: data => data?.eventDetails?.priority?.name || '',
          selectableHeader: true,
          sortDefinition: { field: 'eventDetailsPriority', useProfile: false }
        },
        {
          title: t('Instructors Name'),
          tooltipRenderer: true,
          renderer: data =>
            (data?.instructors || [])
              .filter(x => x?.instructor != null)
              .map((item, idx) => (
                <div key={`${data.friendlyId}_${item.instructorId}_${idx}`}>
                  <p className="no-margin">
                    {item.instructor.lastName}, {item.instructor.firstName}
                  </p>
                </div>
              )),
          selectableHeader: true
        },
        {
          title: t('Instructors Location'),
          tooltipRenderer: true,
          renderer: data => (
            <>
              {data.instructors
                ? data.instructors
                    .filter(x => x.instructor != null && x.instructor.location != null && x.instructor.location.location != null)
                    .map((item, idx) => {
                      return (
                        <div key={`${data.friendlyId}_${item.locationId}_${idx}`}>
                          <p className="no-margin">{item.instructor?.location?.location}</p>
                        </div>
                      );
                    })
                : ''}
            </>
          ),
          selectableHeader: true
        },
        {
          title: t('Roles'),
          tooltipRenderer: true,
          renderer: data => (
            <>
              {data.eventDetails && data.eventDetails.profession && data.eventDetails.profession.profession ? (
                <>
                  {data.eventDetails.profession.profession}
                  <br />
                </>
              ) : (
                <></>
              )}
            </>
          ),
          selectableHeader: true
        },
        {
          title: t('Students Requested'),
          tooltipRenderer: true,
          renderer: data => (
            <>
              {data.eventTrainingDetails && data.eventTrainingDetails.numStudentsRequested ? (
                <>
                  {data.eventTrainingDetails.numStudentsRequested}
                  <br />
                </>
              ) : (
                <></>
              )}
            </>
          ),
          selectableHeader: true
        },
        {
          title: t('Students Assigned'),
          tooltipRenderer: true,
          renderer: data => (
            <>
              {data.eventTrainingDetails && data.eventTrainingDetails.numStudentsAssigned ? (
                <>
                  <>{data.eventTrainingDetails.numStudentsAssigned}</>
                  <br />
                </>
              ) : (
                ''
              )}
            </>
          ),
          selectableHeader: true
        },
        {
          title: t('Status'),
          tooltipRenderer: true,
          renderer: data => <>{decamelCase(data?.status.toString())}</>,
          selectableHeader: true,
          sortDefinition: {
            field: 'eventStatus',
            useProfile: false
            //active: !IdentityService.isPlanner(currentUserInfo) ? undefined : 'Ascending'
          }
        }
      ],
      data: this.props.events.state,
      pagination
    } as TableModel<EventDto>;

    return (
      <>
        <h3>{t('Events')}</h3>
        <LineSeparator />
        <div className="event-wrapper">
          <Grid className="event-types-list-grid">
            {showCreationWizard && <EventWizard onClose={this.onCloseEventCreationWizard} />}

            {this.props.events.state.result && !this.props.events.state.result.isSuccess && (
              <Grid.Row className="event-types-list-error-row">
                <Message
                  className="error-message__style"
                  icon="exclamation circle"
                  error
                  header={t('An error ocurred')}
                  list={this.props.events.state.result.messages?.map(o => o.body)}
                />
              </Grid.Row>
            )}
            <Grid.Row className="event-types-list-items-row request-list__table-view">
              <TableView
                /////////////////For build table keyboard navegation/////////////////
                selectable={!eventShown && !showCreationWizard}
                onHideCheckbox={true}
                selectionType={'allRow'}
                maxSelection={1}
                onEnterKeydown={this.handleOnEnterKeydown}
                onRowDoubleClick={this.handleOnRowDoubleClick}
                preventEnterKeyDownEvent={someFilterOpened}
                /////////////////For build table keyboard navegation/////////////////
                model={tableModel}
                extraActions={[
                  {
                    content: (
                      <>
                        <Icon name="edit" />
                        &nbsp;{t('View details')}
                      </>
                    ),
                    onClick: this.handleOnRowDoubleClick
                  }
                ]}
                onOrderByChanged={this.handleOrderBy}
                onNewItem={this.onShowEventCreationWizard}
                onRefresh={this.load}
                canEdit={false}
                canDelete={false}
                onDeleteRow={this.onDelete}
                onSaveRow={this.onSaveRow}
                onPageChange={this.handlePageChange}
                onFilterChange={this.handleFilterChange}
                canCreateNew={false}
                createNewButtonTitle={t('New Event')}
                //customClassName={'selectable'}
                loading={loading}
                mainActionsFilters={
                  <Grid.Row className="event-types-list-filter-row event-home-list__filters-btns-wrapper" textAlign="right">
                    <Form onSubmit={() => {}}>
                      <Form.Group className={'request-event__field-all-container-height-flexible no-margin-bottom'}>
                        <div className="request-event__filter-buttons">
                          <Form.Field>
                            <Button
                              type="button"
                              className="margin-0 inverted-color-btn event-type-filter-icon-btn"
                              onClick={this.toggleActive}
                              icon
                            >
                              <Icon.Group>
                                <Icon size="large" name={!active ? 'delete calendar' : 'calendar check'} />
                              </Icon.Group>
                            </Button>
                          </Form.Field>
                          <Form.Field>
                            <Popup
                              trigger={
                                <Button
                                  type="button"
                                  icon
                                  size="medium"
                                  onClick={() => this.exportElements()}
                                  className="custom-table-upload-btn black-btn"
                                >
                                  <FontAwesomeIcon className="solid" icon={faFileDownload} size="lg" />
                                </Button>
                              }
                              content={t('Export')}
                            />
                          </Form.Field>

                          {showNewEventButton && (
                            <Form.Field className="new-event-btn-form" id="add-event-type">
                              <Button type="button" className="inverted-color-btn" onClick={this.onShowEventCreationWizard}>
                                {t('New Event')}&nbsp;
                                <Icon.Group>
                                  <Icon size="large" name="calendar check" />
                                  <Icon size="small" circular name="plus circle" />
                                </Icon.Group>
                              </Button>
                            </Form.Field>
                          )}
                          <Form.Field>
                            <Button
                              type="button"
                              className="inverted-color-btn event-type-filter-icon-btn"
                              onClick={this.toggleFilters}
                              icon
                            >
                              <Icon.Group>
                                <Icon size="large" name="filter" />
                                {showFilters && <Icon size="small" corner className="hide-filter" name="remove" />}
                              </Icon.Group>
                            </Button>
                          </Form.Field>
                        </div>
                        {showFilters && (
                          <div className="request-list__filters-wrapper event-list__new-filters-container">
                            <Form.Field>
                              <ClearableTimerInput
                                onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                className="filter margin-top-0 request__title-input"
                                icon="search"
                                placeholder={t('Event ID')}
                                onChange={this.onEventIDFilterChanged}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultiEventTypeEditor
                                schedulerRender
                                placeholder={t('Event Type')}
                                useOriginalEventTypeIdAsValue
                                value={eventTypeList.map(eventTypeItem => eventTypeItem.id)}
                                onChange={value => this.onEventTypeFilterChanged(value, 'EventTypeItem/OriginalEventTypeId')}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <ClearableTimerInput
                                onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                className="filter margin-top-0 request__title-input"
                                icon="search"
                                placeholder={t('Event Title')}
                                onChange={this.onEventTitleFilterChanged}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <CategoryFormEditor
                                readOnly={false}
                                className="filter margin-top-0 request__title-input"
                                value={category}
                                onChange={value => this.onCategoryChange('category', value)}
                                clearable
                                placeholder={t('Category')}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MachineRelatedEditor
                                direction="left"
                                placeholder={t('Machine Related')}
                                nullable
                                clearable
                                searchable
                                className=""
                                value={isMachineRelated}
                                onChange={value => this.onMachineRelatedChange('machineRelated', value)}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultipleNmrClustersEditor
                                clearable
                                category={category}
                                placeholder={t('Cluster NMR')}
                                value={clusterList}
                                onChange={value => this.onNmrClusterChange('nmrCluster', value)}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultipleLocationEditor
                                clearable
                                placeholder={t('Requesting Location')}
                                value={requestingLocationList}
                                onChange={value => this.onRequestingLocationListChange('requests', value)}
                                showCountry={true}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="request-location-region__input">
                              <MultipleRegionEditor
                                clearable
                                placeholder={t('Request Location Region')}
                                value={requestRegionLocationList}
                                onChange={value => this.onRequestRegionLocationChange('extendedRequests', value)}
                                className="events-home-page__multiple-dropdown-filter"
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultipleLocationEditor
                                clearable
                                placeholder={t('Event Location')}
                                value={locationList}
                                onChange={value => this.handleLocationListFilterChange('eventLocation', value)}
                                showCountry={true}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <DeliveryEditor
                                className="margin-top-0 request-list__filter-dropdown"
                                clearable
                                nullable
                                value={delivery}
                                onChange={this.onDeliveryMethodChange}
                                placeholder={t('Delivery Method')}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <DateInput
                                clearable
                                className="margin-top-0 request-list__date-input"
                                value={startDate || null}
                                onChange={this.startDateFilterChanged}
                                placeholder={t('Start Date')}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>
                            <Form.Field>
                              <DateInput
                                clearable
                                className="margin-top-0 request-list__date-input"
                                value={endDate || null}
                                onChange={this.endDateFilterChanged}
                                placeholder={t('End Date')}
                                initialValue={startDate || null}
                                minDate={startDate || null}
                                // onBlur={this.onBlurHandler}
                                // onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultiplePriorityEditor
                                placeholder={t('Priority')}
                                clearable
                                value={priorityList}
                                onChange={value => this.onPriorityListFilterChange('priority', value)}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultipleUsersEditor
                                clearable
                                placeholder={t('Instructor Name')}
                                role="Instructor"
                                value={instructorsNameList}
                                onChange={value => this.onInstructorsListFilterChange('instructors', value)}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultiplePillarsEditor
                                clearable
                                placeholder={t("Instructor's Pillar")}
                                value={instructorPillarList}
                                onChange={pillars => this.onInstructorPillarFilterChange('ExtendedEventInstructors', pillars)}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultipleProfessionsEditor
                                clearable
                                className=""
                                placeholder={t('Roles')}
                                value={rolesList}
                                onChange={value => this.onRolesListChange('profession', value)}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultipleEventStatusEditor
                                clearable
                                statusFilterActive={this.state.active}
                                placeholder={t('Status')}
                                value={eventStatus}
                                onChange={value => this.onStatusFilterChange('EventStatus', value)}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>
                          </div>
                        )}
                      </Form.Group>
                    </Form>
                  </Grid.Row>
                }
              />
            </Grid.Row>
          </Grid>
        </div>
        {eventShown && (
          <EventForm mode="ViewDetails" route={route} onClose={this.onEditItemClosed} event={buildEventViewModel(selectedItem)} />
        )}
      </>
    );
  }
}

export default withTranslation()(EventsHomePage);
