import { BaseDto } from '../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../dataStore';
import { FormStore } from '../formStore';

export interface eventCheckListRoleUserCheck {
  description: string;
  checked: boolean;
}

export interface eventCheckListRoleUserDto extends BaseDto {
  id: string;
  eventId: string;
  role: string;
  userId: string;
  locationId: string;
  roleUserChecks: eventCheckListRoleUserCheck[];
}

export interface CreateEventCheckListRoleUserDto {
  eventId: string;
  role: string;
  userId: string;
  locationId: string;
  roleUserChecks: eventCheckListRoleUserCheck[];
}

export interface ChangeEventCheckListRoleUserDto {
  id: string;
  eventId: string;
  role: string;
  userId: string;
  locationId: string;
  roleUserChecks: eventCheckListRoleUserCheck[];
}

export class CreateEventCheckListRoleUserValidator extends AbstractValidator<CreateEventCheckListRoleUserDto> {
  constructor() {
    super();

    this.validateIfString(o => o.role)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Role is required'));

    this.validateIfString(o => o.eventId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Id is required'));

    this.validateIfIterable(o => o.roleUserChecks)
      .isNotEmpty()
      .withFailureMessage(i18n.t('checkList is required'));
  }
}

export class ChangeEventCheckListRoleUserValidator extends AbstractValidator<ChangeEventCheckListRoleUserDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.eventId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Type Id is required'));

    this.validateIfIterable(o => o.roleUserChecks)
      .isNotEmpty()
      .withFailureMessage(i18n.t('checkList is required'));
  }
}

@repository('@@EVENTCHECKLISTSROLEUSER', 'eventchecklistsroleuser.summary')
export class EventCheckListRoleUserStore extends DataStore<eventCheckListRoleUserDto> {
  baseUrl = 'events/v1';
  //baseUrl = `events/v1`
  createPath = 'create-roleUserChecklist';
  retrievePath = 'get-roleUserChecklists';
  updatePath = 'update-roleUserChecklist';
  deletePath = '';

  protected validate(item: eventCheckListRoleUserDto) {
    return new ChangeEventCheckListRoleUserValidator().validate(item);
  }

  constructor() {
    super('EVENTCHECKLISTROLEUSER', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@EVENTCHECKLISTSROLEUSER', 'eventchecklistroleuser.new')
export class NewEventCheckListRoleUserStore extends FormStore<CreateEventCheckListRoleUserDto> {
  baseUrl = 'events/v1';
  //baseUrl = `events/v1`
  createPath = 'create-roleUserChecklist';
  retrievePath = 'get-roleUserChecklists';
  updatePath = 'update-roleUserChecklist';

  protected validate(item: CreateEventCheckListRoleUserDto) {
    return new CreateEventCheckListRoleUserValidator().validate(item);
  }

  constructor() {
    super('NEW_EVENTCHECKLISTSROLEUSER', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@EVENTCHECKLISTSROLEUSER', 'eventchecklistroleuser.change')
export class ChangeEventCheckListRoleUserStore extends FormStore<ChangeEventCheckListRoleUserDto> {
  baseUrl = 'events/v1';
  //baseUrl = `events/v1`
  createPath = 'create-roleUserChecklist';
  retrievePath = 'get-roleUserChecklists';
  updatePath = 'update-roleUserChecklist';

  protected validate(item: ChangeEventCheckListRoleUserDto) {
    return new ChangeEventCheckListRoleUserValidator().validate(item);
  }

  constructor() {
    super('CHANGE_EVENTCHECKLISTSROLEUSER', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
