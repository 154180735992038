import { AbstractValidator, ValidationResult } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';

import i18n from '../../i18n';
import { DataStore } from '../dataStore';
import { FormStore } from '../formStore';

import { BaseDto } from '../types';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export enum ExpertiseRequestStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export interface InstructorExpertiseRequestDto extends BaseDto {
  id: string;
  instructorId: string;
  instructor: string;
  eventTypeId: string;
  eventTypeName: string;
  trainingLevelId: string;
  trainingLevelName: string;

  isMachineRelated: boolean;

  machineModelId: string;
  machineModelName: string;
  machineRelatedClusterId: string;
  machineRelatedClusterName: string;
  equipmentTypeId: string;
  equipmentTypeName: string;
  oemId: string;
  oemName: string;
  plcTypes: string[];

  nmrClusterId: string;
  nmrClusterName: string;
  nmrFunctionalAreaId: string;
  nmrFunctionalAreaName: string;
  nmrFunctionalSubAreaId: string;
  nmrFunctionalSubAreaName: string;
  nmrTrainingNameId: string;
  nmrTrainingName: string;

  roleId: string;
  roleName: string;

  status: ExpertiseRequestStatus;
}

export interface CreateInstructorExpertiseRequestDto {
  originalEventTypeId: string;
  eventTypeId: string;
  trainingLevelId: string;
  roleId: string;

  isMachineRelated: boolean;

  machineModelId: string;
  machineRelatedClusterId: string;
  equipmentTypeId: string;
  oemId: string;
  plcTypes: string[];

  nmrClusterId: string;
  nmrFunctionalAreaId: string;
  nmrFunctionalSubAreaId: string;
  nmrTrainingNameId: string;
}

export interface ChangeInstructorExpertiseRequestDto {
  id: string;
  nextStatus: 'Approved' | 'Rejected';
}

export class CreateExpertiseRequestValidator extends AbstractValidator<CreateInstructorExpertiseRequestDto> {
  constructor() {
    super();

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('Invalid data'));

    this.validateIf(o => o)
      .fulfills(
        ({ eventTypeId, originalEventTypeId }) => eventTypeId && originalEventTypeId && eventTypeId != null && originalEventTypeId != null
      )
      .withFailureMessage(i18n.t('Event Type is required'));

    this.validateIfString(o => o.eventTypeId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.eventTypeId))
      .withFailureMessage(i18n.t('Event Type is invalid'));

    this.validateIfString(o => o.originalEventTypeId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.originalEventTypeId))
      .withFailureMessage(i18n.t('Event Type is invalid'));

    this.validateIfString(o => o.trainingLevelId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.trainingLevelId))
      .withFailureMessage(i18n.t('Training Level is invalid'));

    this.validateIfString(o => o.roleId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.roleId))
      .withFailureMessage(i18n.t('Role is invalid'));

    this.validateIfString(o => o.machineRelatedClusterId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.machineRelatedClusterId))
      .withFailureMessage(i18n.t('Cluster is invalid'));

    this.validateIfString(o => o.equipmentTypeId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.equipmentTypeId))
      .withFailureMessage(i18n.t('Equipment Type is invalid'));

    this.validateIfString(o => o.oemId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.oemId))
      .withFailureMessage(i18n.t('OEM is invalid'));

    this.validateIfString(o => o.machineModelId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.machineModelId))
      .withFailureMessage(i18n.t('Machine Model is invalid'));

    this.validateIfString(o => o.nmrClusterId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.nmrClusterId))
      .withFailureMessage(i18n.t('Cluster is invalid'));

    this.validateIfString(o => o.nmrFunctionalAreaId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.nmrFunctionalAreaId))
      .withFailureMessage(i18n.t('Functional Area is invalid'));

    this.validateIfString(o => o.nmrFunctionalSubAreaId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.nmrFunctionalSubAreaId))
      .withFailureMessage(i18n.t('Functional Subarea is invalid'));

    this.validateIfString(o => o.nmrTrainingNameId)
      .isUuid('4')
      .when(x => x != null && !isNullOrWhiteSpaces(x.nmrTrainingNameId))
      .withFailureMessage(i18n.t('Training Name is invalid'));

    this.validateIfEachString(x => x.plcTypes)
      .isUuid('4')
      .when(x => x != null && (x.plcTypes || []).length !== 0)
      .withFailureMessage(i18n.t('At least one PLC Type is invalid'));
  }
}

export class ChangeExpertiseRequestValidator extends AbstractValidator<ChangeInstructorExpertiseRequestDto> {
  constructor() {
    super();
    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('Invalid data'));

    this.validateIfString(x => x.id)
      .isUuid('4')
      .when(x => x != null)
      .withFailureMessage(i18n.t('Request id is missing'));

    this.validateIfString(x => x.nextStatus)
      .isIn(['Approved', 'Rejected'])
      .when(x => x != null)
      .withFailureMessage(i18n.t('Invalid Next Status'));
  }
}

@repository('@@INSTRUCTOR_EXPERTISE_REQUESTS', 'instructorExpertiseRequests.summary')
export class InstructorExpertiseRequestsStore extends DataStore<InstructorExpertiseRequestDto> {
  baseUrl = 'skills';
  createPath = 'v1/new-instructor-expertise-request';
  retrievePath = 'v1/get-instructor-expertise-requests';
  updatePath = 'v1/update-instructor-expertise-request';
  deletePath = 'v1/delete-instructor-expertise-request';

  protected validate(item: InstructorExpertiseRequestDto) {
    return new ValidationResult();
  }

  constructor() {
    super('INSTRUCTOR_EXPERTISE_REQUESTS_LIST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: () => {}
    });
  }
}

@repository('@@INSTRUCTOR_EXPERTISE_REQUESTS', 'instructorExpertiseRequests.new')
export class NewInstructorExpertiseRequestStore extends FormStore<CreateInstructorExpertiseRequestDto> {
  baseUrl = 'skills';
  createPath = 'v1/new-instructor-expertise-request';
  retrievePath = 'v1/get-instructor-expertise-requests';
  updatePath = 'v1/update-instructor-expertise-request';
  deletePath = 'v1/delete-instructor-expertise-request';

  protected validate(item: CreateInstructorExpertiseRequestDto) {
    return new CreateExpertiseRequestValidator().validate(item);
  }

  constructor() {
    super('NEW_INSTRUCTOR_EXPERTISE_REQUEST', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@INSTRUCTOR_EXPERTISE_REQUESTS', 'instructorExpertiseRequests.change')
export class ChangeInstructorExpertiseRequestStore extends FormStore<ChangeInstructorExpertiseRequestDto> {
  baseUrl = 'skills';
  createPath = 'v1/new-instructor-expertise-request';
  retrievePath = 'v1/get-instructor-expertise-requests';
  updatePath = 'v1/update-instructor-expertise-request';
  deletePath = 'v1/delete-instructor-expertise-request';

  protected validate(item: ChangeInstructorExpertiseRequestDto) {
    return new ChangeExpertiseRequestValidator().validate(item);
  }

  constructor() {
    super('CHANGE_INSTRUCTOR_EXPERTISE_REQUEST', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
