import check from 'assets/img/check-solid-white.png';
import * as autobind from 'autobind';
import React, { Component, RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Stepper from 'react-stepper-horizontal';
import { Button, Container, Icon, Message, Modal, Dimmer, Loader, Label } from 'semantic-ui-react';
import NonMachineRelatedSubjectStep from './steps/non-machine-related-subject-step';
import MachineRelatedSubjectStep from './steps/machine-related-subject-step';
import BasicStep from './steps/basic-step';
import {
  WizardDataBasicStepValidator,
  WizardDataNonMachineRelatedStepValidator,
  EventWizardData,
  NewEventsStore,
  CreateEventDto,
  WizardDataEventDetailsStepValidator,
  WizardDataMachineRelatedStepValidator,
  WizardDataEventInstructorsStepValidator,
  WizardDataDatesStepValidator,
  WizardDataEventStudentsStepValidator,
  CreateEventDetailsDto
} from 'stores/events/creation-wizard-store';
import { Message as ErrorMessage } from 'stores/types';
import { EventFieldGroups } from 'stores/configuration/events-workflow/event-types-store';
import { isNullOrWhiteSpaces, randomString, randomInteger, isNullOrEmpty } from 'utils/useful-functions';
import EventDetailsStep from './steps/event-details-step';
import CommentsStep from './steps/comments-step';
import InstructorsStep from './steps/instructors-step';
import DatesStep from './steps/dates-step';
import { connect } from 'redux-scaffolding-ts';
import { MachineListItemViewModel } from 'widgets/items-list/machine-list-item-deprecated';
import { RequestMachinesDto } from 'stores/requests/requests-store';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import StudentsAssignTableComponent, { StudentsTabViewModel } from '../../shared/events-and-requests/tabs/students-assign-component';
import EventService, { EventDurationRequestDto } from 'stores/events/event-service';
import { resolve } from 'inversify.config';

interface EventWizardProps extends WithTranslation {
  onClose: (isSucess: boolean) => void;
  wizardStore?: NewEventsStore;
}

interface StepDefinition {
  title: string;
  icon: any;
  validator: (wizardData: EventWizardData) => ExtendedAbstractValidator<EventWizardData>;
  visible: boolean;
  realIndx: number;
  clear: Partial<EventWizardData>;
}

interface EventWizardState {
  currentStep: number;
  steps: StepDefinition[];
  errorMessages: ErrorMessage[];
  wizardData: EventWizardData;
  warningMessages: string[];
  haveWarnings: boolean;
  isBusy: boolean;
  durationCalculationWarning: boolean;
}

@connect(['wizardStore', NewEventsStore])
class EventWizard extends Component<EventWizardProps, EventWizardState> {
  refModal: RefObject<any>;

  @resolve(EventService)
  eventsService: EventService;

  private getDurationRequestDto = (): EventDurationRequestDto => {
    const data = this.state.wizardData;
    return {
      isMachineRelated: data.isMachineRelated,
      mrRequest: data.isMachineRelated
        ? {
            originalEventTypeId: data.eventType?.originalEventTypeId,
            professionId: data.profession?.id,
            rows: (data.requestMachines || []).map(rm => ({
              clusterId: rm.cluster?.id,
              equipmentTypeId: rm.equipmentType?.id,
              machineModelId: rm.machineModel?.id,
              machineUnitsId: rm.machineUnits,
              oemId: rm.oem?.id
            })),
            trainingLevelId: data.trainingLevel?.id
          }
        : null,
      nmrRequest: !data.isMachineRelated
        ? {
            nmrClusterId: data.nmrCluster?.id ?? data.nmrClusterId,
            nmrFunctionalAreaId: data.nmrFunctionalArea?.id ?? data.nMRFunctionalAreaId,
            nmrTrainingNameId: data.nmrTrainingName?.id ?? data.nMRTrainingNameId
          }
        : null
    };
  };

  private isValidRequest = (dto: EventDurationRequestDto) => {
    if (dto == null) return false;
    if (dto.isMachineRelated) {
      if (
        dto.mrRequest == null ||
        isNullOrWhiteSpaces(dto.mrRequest.originalEventTypeId) ||
        isNullOrWhiteSpaces(dto.mrRequest.professionId) ||
        isNullOrWhiteSpaces(dto.mrRequest.trainingLevelId) ||
        (dto.mrRequest.rows || []).length === 0
      )
        return false;
      return dto.mrRequest.rows.all(r => !isNullOrWhiteSpaces(r.clusterId) && !isNullOrWhiteSpaces(r.equipmentTypeId));
    } else return !isNullOrWhiteSpaces(dto.nmrRequest?.nmrClusterId) && !isNullOrWhiteSpaces(dto.nmrRequest?.nmrFunctionalAreaId);
  };

  // private recalculateEventDuration = () => {
  //   const dto = this.getDurationRequestDto();
  //   const isValid = this.isValidRequest(dto);
  //   if (!isValid) return Promise.reject();
  //   return new Promise(resolveCall => {
  //     this.setState({ isBusy: true });
  //     this.eventsService
  //       .calculateEventDuration(dto)
  //       .then(v => {
  //         const w = { ...this.state.wizardData };
  //         w.calculatedEventDuration = v.days;
  //         if (isNullOrEmpty(w.plannedDuration) || w.plannedDuration === '0 w/d' || w.plannedDuration === '0') {
  //           w.plannedDuration = `${v.days}`;
  //           w.userEventDuration = v.days;
  //         }
  //         this.setState({ durationCalculationWarning: false, isBusy: false, wizardData: { ...w } }, resolveCall);
  //       })
  //       .catch(_ => {
  //         const w = { ...this.state.wizardData };
  //         w.calculatedEventDuration = 0;
  //         if (isNullOrEmpty(w.plannedDuration)) {
  //           w.plannedDuration = '0';
  //           w.userEventDuration = 0;
  //         }
  //         this.setState({ isBusy: false, durationCalculationWarning: true, wizardData: { ...w } }, resolveCall);
  //       });
  //   });
  // };

  createEventDetails(): CreateEventDetailsDto {
    const { wizardData } = this.state;

    if (
      this.state.wizardData == null ||
      this.state.wizardData.eventType == null ||
      this.state.wizardData.eventType.requestFieldGroups == null ||
      this.state.wizardData.eventType.requestFieldGroups.indexOf('RequestDetails') < 0
    ) {
      return null;
    }

    if (
      this.state.wizardData?.eventType?.requestFieldGroups?.includes(EventFieldGroups[EventFieldGroups.RequestDetails]) &&
      this.state.wizardData?.eventType?.name.toLocaleLowerCase().includes('reserved') &&
      this.state.wizardData?.isMachineRelated == null &&
      this.state.wizardData?.category == null
    ) {
      return null;
    }

    return {
      isMachineRelated: wizardData.isMachineRelated,
      category: wizardData.category,
      locationId: wizardData.location ? wizardData.location.id : null,
      priorityId: wizardData.priority ? wizardData.priority.id : null,
      patternId: wizardData.pattern ? wizardData.pattern.id : null,
      professionId: wizardData.profession ? wizardData.profession.id : null,
      nmrClusterId: wizardData.nmrCluster && wizardData.nmrCluster.id,
      nmrFunctionalAreaId: wizardData.nmrFunctionalArea && wizardData.nmrFunctionalArea.id,
      nmrFunctionalSubAreaId: wizardData.nmrFunctionalSubArea && wizardData.nmrFunctionalSubArea.id,
      nmrTrainingNameId: wizardData.nmrTrainingName && wizardData.nmrTrainingName.id,
      requestedMachines: this.mapToRequestMachinesDto(wizardData.requestMachines)
    };
  }

  mapToRequestMachinesDto(machines: MachineListItemViewModel[]): RequestMachinesDto[] {
    if (!machines) return [];

    return machines.map(x => ({
      machineRelatedClusterId: x.cluster ? x.cluster.id : null,
      machineRelatedClusterName: x.cluster ? x.cluster.title : null,
      equipmentTypeId: x.equipmentType ? x.equipmentType.id : null,
      equipmentTypeName: x.equipmentType ? x.equipmentType.title : null,
      oemId: x.oem ? x.oem.id : null,
      oemName: x.oem ? x.oem.title : null,
      machineModelId: x.machineModel ? x.machineModel.id : null,
      machineModelName: x.machineModel ? x.machineModel.title : null,
      plcTypeRequestMachines: x.plcTypes,
      machineUnitRequestMachines: x.machineUnits
    }));
  }

  constructor(props: EventWizardProps) {
    super(props);
    const { t } = props;
    this.state = {
      haveWarnings: false,
      currentStep: 0,
      isBusy: false,
      errorMessages: [],
      durationCalculationWarning: false,
      steps: [
        {
          title: t('Basic'),
          icon: undefined,
          validator: _ => new WizardDataBasicStepValidator(),
          visible: true,
          realIndx: 0,
          clear: {
            eventType: null,
            location: null,
            priority: null,
            category: null,
            profession: null,
            isMachineRelated: undefined
          }
        },
        {
          title: t('Subject'),
          icon: undefined,
          validator: (data: EventWizardData) =>
            data.isMachineRelated ? new WizardDataMachineRelatedStepValidator() : new WizardDataNonMachineRelatedStepValidator(),
          visible: false,
          realIndx: -1,
          clear: {
            usePattern: undefined,
            hasModifiedPattern: false,
            pattern: undefined,
            nmrClusterId: undefined,
            nMRFunctionalAreaId: undefined,
            nMRTrainingNameId: undefined,
            nMRFunctionalSubAreaId: undefined
          }
        },
        {
          title: t('Event Details'),
          icon: undefined,
          validator: _ => new WizardDataEventDetailsStepValidator(),
          visible: false,
          realIndx: -1,
          clear: {
            eventTitle: '',
            customizedData: undefined,
            deliveryMethod: undefined,
            language: undefined,
            trainingLevel: undefined,
            students: []
          }
        },
        {
          title: t('Dates'),
          icon: undefined,
          validator: _ => new WizardDataDatesStepValidator(),
          visible: true,
          realIndx: 1,
          clear: {
            eventDuration: '',
            dateFrom: undefined,
            dateTo: undefined,
            plannedDuration: ''
          }
        },
        {
          title: t('Students'),
          icon: undefined,
          validator: _ => new WizardDataEventStudentsStepValidator(),
          visible: false,
          realIndx: -1,
          clear: {}
        },
        {
          title: t('Instructors'),
          icon: undefined,
          validator: _ => new WizardDataEventInstructorsStepValidator(),
          visible: false,
          realIndx: -1,
          clear: {}
        },
        {
          title: t('Comments'),
          icon: undefined,
          validator: _ => undefined,
          visible: true,
          realIndx: 2,
          clear: {
            comments: ''
          }
        }
      ],
      wizardData: { students: [], numStudentsAssigned: 0, calculatedEventDuration: 0 } as EventWizardData,
      warningMessages: []
    };
    this.props.wizardStore.createNew({} as CreateEventDto);
  }

  UNSAFE_componentWillReceiveProps(nextProps: EventWizardProps) {
    const { result } = this.props.wizardStore.state;
    if (result && result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  private mapToDto(wizardData: EventWizardData): CreateEventDto {
    return {
      comments: wizardData.comments,
      startDate: wizardData.dateFrom,
      endDate: wizardData.dateTo,
      eventDuration: wizardData.eventDuration,
      eventTypeId: wizardData.eventType ? wizardData.eventType.id : '',
      eventDetails: this.createEventDetails(),
      plannedDuration: wizardData.plannedDuration,
      title: wizardData.eventTitle || this.createEventTitle(),
      eventTrainingDetails: this.createEventTrainingDetails(),
      parentRequestId: wizardData.parentRequestId,
      trainingMaterials: wizardData.trainingMaterials,
      instructors: wizardData.instructors,
      travelDays: wizardData.travelDays,
      pausePeriods: wizardData.pausePeriods,
      eventWarnings: wizardData.eventWarnings,
      students: wizardData.students,
      numStudentsAssigned: wizardData.numStudentsAssigned
    };
  }

  @autobind
  private onCreateNewItem() {
    const { wizardData } = this.state;
    const dto = this.mapToDto(wizardData);
    if (dto.instructors && dto.instructors.length > 0) {
      dto.instructors.forEach(x => {
        x.period.from = new Date(x.period.from).toUTCString();
        x.period.to = new Date(x.period.to).toUTCString();
      });
    }
    this.props.wizardStore.change(dto);
    this.props.wizardStore.submit().then(r => {
      if (r.isSuccess) {
        this.props.onClose(true);
      }
    });
  }

  @autobind
  private onCancelNewItem() {
    this.props.wizardStore.clear();
    this.setState({ wizardData: { students: [] } as EventWizardData });
    this.props.onClose(false);
  }

  private createEventTitle() {
    return `Event_${randomInteger(100, 1000000)}`;
  }

  private createEventTrainingDetails() {
    const { wizardData } = this.state;

    if (
      this.state.wizardData == null ||
      this.state.wizardData.eventType == null ||
      this.state.wizardData.eventType.requestFieldGroups == null ||
      this.state.wizardData.eventType.requestFieldGroups.indexOf('EventDetails') < 0
    ) {
      return {
        customizationDataId: null,
        languageId: null,
        deliveryMethodId: null,
        trainingLevelId: null,
        assignedStudentIds: wizardData.students ? wizardData.students.map(student => student.id) : [],
        numStudentsAssigned: wizardData.numStudentsAssigned
      };
    }
    return {
      customizationDataId: wizardData.customizedData ? wizardData.customizedData.id : null,
      languageId: wizardData.language ? wizardData.language.id : null,
      deliveryMethodId: wizardData.deliveryMethod ? wizardData.deliveryMethod.id : null,
      trainingLevelId: wizardData.trainingLevel ? wizardData.trainingLevel.id : null,
      assignedStudentIds: wizardData.students ? wizardData.students.map(student => student.id) : [],
      numStudentsAssigned: wizardData.numStudentsAssigned
    };
  }

  private getStep1Visibility(wizard: EventWizardData) {
    let visible = wizard?.eventType?.requestFieldGroups?.includes(EventFieldGroups[EventFieldGroups.RequestDetails]);
    if (visible && wizard?.eventType?.name.toLocaleLowerCase().includes('reserved')) {
      visible = visible && (wizard?.isMachineRelated != null || wizard?.category != null);
    }
    return visible;
  }

  private updateSteps = (steps: StepDefinition[], wizardData: EventWizardData) => {
    steps[1].visible = this.getStep1Visibility(wizardData);
    steps[2].visible = wizardData?.eventType?.requestFieldGroups?.includes(EventFieldGroups[EventFieldGroups.EventDetails]);
    steps[4].visible = wizardData && wizardData.eventType && wizardData.eventType.participants;
    steps[5].visible = !isNullOrWhiteSpaces(wizardData?.eventType?.instructor) && wizardData?.eventType?.instructor !== 'No';
    steps
      .filter(s => s.visible)
      .forEach((s, idx) => {
        s.realIndx = idx;
      });
    return steps;
  };

  private onValueChanged = (...changes: [string, any][]) => {
    const wizardData = { ...this.state.wizardData };
    const { currentStep } = this.state;
    (changes || []).forEach(([prop, value]) => {
      if (
        wizardData?.eventType?.requestFieldGroups?.includes(EventFieldGroups[EventFieldGroups.RequestDetails]) &&
        wizardData?.eventType?.name.toLocaleLowerCase().includes('reserved') &&
        currentStep === 0 &&
        (prop === 'category' || prop === 'isMachineRelated')
      ) {
        wizardData['category'] = wizardData['category'] ? wizardData['category'] : 20;
        wizardData['isMachineRelated'] = wizardData['isMachineRelated'] ? wizardData['isMachineRelated'] : false;
      }
      wizardData[prop] = value;
    });
    this.setState({ wizardData }, () => ({ steps: this.updateSteps(this.state.steps, wizardData) }));
  };

  private onValueChangedInstructors = (...changes: [string, any][]) => {
    let notInDate: boolean = false;
    let notValid: boolean = false;
    let notConflict: boolean = false;
    let haveWarnings: boolean = false;
    let warningMessages: string[] = [];

    const wizardData = { ...this.state.wizardData };
    changes.forEach(([prop, value]) => {
      wizardData[prop] = value;
    });
    if (wizardData.instructors.length === 0) this.setState({ haveWarnings: false, warningMessages: [] });
    else {
      for (let i = 0; i < wizardData.instructors.length; i++) {
        if (wizardData.instructors[i].notInDate !== undefined && wizardData.instructors[i].notInDate === true) notInDate = true;
        if (wizardData.instructors[i].notValid !== undefined && wizardData.instructors[i].notValid === true) notValid = true;
        if (wizardData.instructors[i].notConflict !== undefined && wizardData.instructors[i].notConflict === true) notConflict = true;
      }
    }

    if (notInDate === true) {
      warningMessages.push('There is a conflict with the Dates');
      haveWarnings = true;
    }

    if (notValid === true) {
      warningMessages.push("There is a conflict with the Instructor's Expertise");
      haveWarnings = true;
    }

    if (notConflict === true) {
      warningMessages.push("There is a conflict with others Instructor's Events");
      haveWarnings = true;
    }

    const createWarnings = warningMessages.map(key => {
      return {
        description: key
      };
    });

    wizardData.eventWarnings = createWarnings;

    this.setState({ wizardData }, () => ({ steps: this.updateSteps(this.state.steps, wizardData), warningMessages, haveWarnings }));
  };

  @autobind
  private handleNext() {
    const { steps, currentStep, wizardData } = this.state;
    let { errorMessages } = this.state;

    let nextStep = currentStep + 1;
    while (nextStep < steps.length && !steps[nextStep].visible) nextStep++;

    if (nextStep >= steps.length) return;

    const validator: ExtendedAbstractValidator<EventWizardData> = steps[currentStep].validator(wizardData);
    if (validator != null) {
      const result = validator.extendValidate(wizardData);
      errorMessages = result.isValid() ? [] : result.getFailureMessages().map(x => ({ level: 'Error', body: x }));
    }

    if (errorMessages.length !== 0) {
      this.setState({ errorMessages });
    } else {
      const updatedSteps = this.updateSteps(steps, wizardData);
      updatedSteps.forEach((s, idx) => {
        if (idx <= currentStep) {
          s.icon = check;
        }
      });

      if (steps[nextStep].title === 'Dates') {
        const dto = this.getDurationRequestDto();
        const isValid = this.isValidRequest(dto);
        if (!isValid) {
          this.setState({ currentStep: nextStep, steps: updatedSteps, errorMessages, durationCalculationWarning: true });
        } else {
          this.setState({ isBusy: true });
          this.eventsService
            .calculateEventDuration(dto)
            .then(v => {
              const w = { ...this.state.wizardData };
              w.calculatedEventDuration = v.days;
              if (isNullOrEmpty(w.plannedDuration) || w.plannedDuration === '0 w/d' || w.plannedDuration === '0') {
                w.plannedDuration = `${v.days}`;
                w.userEventDuration = v.days;
              }
              this.setState({
                currentStep: nextStep,
                steps: updatedSteps,
                errorMessages,
                durationCalculationWarning: false,
                isBusy: false,
                wizardData: { ...w }
              });
            })
            .catch(_ => {
              const w = { ...this.state.wizardData };
              w.calculatedEventDuration = 0;
              if (isNullOrEmpty(w.plannedDuration)) {
                w.plannedDuration = '0';
                w.userEventDuration = 0;
              }
              this.setState({
                currentStep: nextStep,
                steps: updatedSteps,
                errorMessages,
                durationCalculationWarning: true,
                isBusy: false,
                wizardData: { ...w }
              });
            });
        }
      } else {
        this.setState({ currentStep: nextStep, steps: updatedSteps, errorMessages });
      }
    }
  }

  private stepBack = () => {
    const { steps, currentStep } = this.state;

    let nextStep = currentStep - 1;
    while (nextStep >= 0 && !steps[nextStep].visible) {
      steps[nextStep].icon = null;
      nextStep--;
    }

    if (nextStep < 0) return;

    steps[nextStep].icon = null;
    this.setState({ currentStep: nextStep, steps });
  };

  private getStepContent = () => {
    const { wizardData, currentStep } = this.state;
    switch (currentStep) {
      case 0:
        return (
          <BasicStep
            wizardData={wizardData}
            onValueChanged={this.onValueChanged}
            recalculateEventDuration={() => {
              const dto = this.getDurationRequestDto();
              const isValid = this.isValidRequest(dto);
              if (!isValid) return Promise.reject();
              return new Promise(() => {
                this.setState({ isBusy: true });
                this.eventsService
                  .calculateEventDuration(dto)
                  .then(v => {
                    const w = { ...this.state.wizardData };
                    w.calculatedEventDuration = v.days;
                    if (isNullOrEmpty(w.plannedDuration) || w.plannedDuration === '0 w/d' || w.plannedDuration === '0') {
                      w.plannedDuration = `${v.days}`;
                      w.userEventDuration = v.days;
                    }
                    this.setState({ durationCalculationWarning: false, isBusy: false, wizardData: { ...w } });
                  })
                  .catch(_ => {
                    const w = { ...this.state.wizardData };
                    w.calculatedEventDuration = 0;
                    if (isNullOrEmpty(w.plannedDuration)) {
                      w.plannedDuration = '0';
                      w.userEventDuration = 0;
                    }
                    this.setState({ isBusy: false, durationCalculationWarning: true, wizardData: { ...w } });
                  });
              });
            }}
          />
        );
      case 1:
        return wizardData.isMachineRelated ? (
          <MachineRelatedSubjectStep wizardData={wizardData} onValueChanged={this.onValueChanged} />
        ) : (
          <NonMachineRelatedSubjectStep wizardData={wizardData} onValueChanged={this.onValueChanged} />
        );
      case 2:
        return <EventDetailsStep wizardData={wizardData} onValueChanged={this.onValueChanged} />;
      case 3:
        return (
          <DatesStep durationWarning={this.state.durationCalculationWarning} wizardData={wizardData} onValueChanged={this.onValueChanged} />
        );
      case 4:
        return this.createStudentsComponent();
      case 5:
        return <InstructorsStep wizardData={wizardData} onValueChanged={this.onValueChangedInstructors} />;
      case 6:
        return <CommentsStep wizardData={wizardData} onValueChanged={this.onValueChanged} />;
      default:
        return null;
    }
  };

  createStudentsComponent = (): React.ReactNode => {
    const { students, numStudentsAssigned } = this.state.wizardData;
    const value: StudentsTabViewModel = { students, studentsAssigned: numStudentsAssigned };

    return (
      <StudentsAssignTableComponent
        className="wizard__students-step__container"
        readOnly={false}
        value={value}
        onChange={this.handleOnStudentsChange}
      />
    );
  };

  private handleOnStudentsChange = (newValue: StudentsTabViewModel) => {
    const { wizardData } = this.state;
    wizardData.students = newValue.students;
    wizardData.numStudentsAssigned = newValue.studentsAssigned;
    this.setState({ wizardData }, () => {
      const { steps } = this.state;
      this.setState({ steps: this.updateSteps(steps, wizardData) });
    });
  };

  public render() {
    const { t, wizardStore } = this.props;
    const { currentStep, steps, errorMessages, warningMessages, haveWarnings } = this.state;
    const { result } = wizardStore.state;

    return (
      <Modal className="requests-events-wizard___modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.props.wizardStore.state.isBusy || this.state.isBusy} inverted>
          <Loader indeterminate inverted></Loader>
        </Dimmer>
        <Modal.Header className="stepper-container">
          <h2>{t('EVENT CREATION')}</h2>

          <Stepper
            steps={steps.filter(s => s.visible)}
            activeStep={steps[currentStep].realIndx}
            activeColor="#00B2A9"
            completeColor="#00B2A9"
            defaultColor="#B0B0B0"
            activeTitleColor="white"
            completeTitleColor="white"
            defaultTitleColor="white"
            circleFontColor="white"
            defaultBarColor="#B0B0B0"
            completeBarColor="#00B2A9"
            lineMarginOffset={10}
            size={24}
            circleFontSize={14}
            titleFontSize={14}
            circleTop={15}
          />
        </Modal.Header>
        <Modal.Content image>
          <Container>
            {(errorMessages || []).length !== 0 && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={errorMessages.map(o => (
                  <Message.Item key={randomString(5)}>{o.body}</Message.Item>
                ))}
              />
            )}
            {result && !result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => (
                  <Message.Item key={randomString(5)}>{o.body}</Message.Item>
                ))}
              />
            )}
            {this.getStepContent()}

            {haveWarnings && (
              <Label className="modal__action-warning wizard__action-warning">
                <Icon name="exclamation triangle" />
                <div>
                  {(warningMessages || []).map(messageToShow => (
                    <React.Fragment key={randomString(5)}>
                      {messageToShow != null && <p className="modal__action-warning__text"> {t(messageToShow)} </p>}
                    </React.Fragment>
                  ))}
                </div>
              </Label>
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions className="wizard__action-buttons-wrapper">
          <Button
            className={`form__actions__button wizard__go-back-btn ${currentStep > 0 ? '' : 'hidden'}`}
            icon="arrow left"
            content={t('Back')}
            labelPosition="left"
            onClick={this.stepBack}
          />

          <div className="form__align-right-btns">
            <Button className="form__actions__button basic" onClick={this.onCancelNewItem} basic>
              {t('Cancel')}
            </Button>

            <Button
              className="form__actions__button"
              onClick={currentStep === steps.length - 1 ? this.onCreateNewItem : this.handleNext}
              positive
            >
              {currentStep === steps.length - 1 ? t('Save') : t('Next')}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(EventWizard);
