import * as autobind from 'autobind';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Icon, Input, Divider, Grid, GridColumn, Header } from 'semantic-ui-react';
import { nameof } from 'utils/object';
import { Query, OrderDefinition, ItemReference } from 'stores/dataStore';
import { UserStore, UserDto } from 'stores/users/users-store';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import LocationEditor from 'widgets/bussiness/location-editor';
import { Roles } from 'services/identity-service';
import { LocationDto } from 'stores/configuration/locations/locations-store';

interface ChooseUserImportsViewProps extends WithTranslation {
  onAddParticipants?: (students: UserDto) => void;
  onCloseUsersModal?: () => void;
  alreadySelectedUserIds?: string[];
  title?: string;
  acceptBtnTitle?: string;
  usersStore?: UserStore;
  positionCodeId?: string;
  noFilters?: boolean;
  /** If specificLocation is provided, roleLocation should match availables roles*/
  specificLocation?: boolean;
  /** If specificLocation is provided, roleLocation should match availables roles*/
  roleLocation?: Roles;
}

interface ChooseUserImportsFilters {
  firstNameOrLastName: string;
  locationId: string;
  personnelArea: string;
  positionCodeId?: string;
}

interface ChooseUserImportsViewState {
  activeFilters: string[];
  query: Query;
  selectedUsers: UserDto[];
  filters: ChooseUserImportsFilters;
}

@connect(['usersStore', UserStore])
class ChooseUserImportsView extends Component<ChooseUserImportsViewProps, ChooseUserImportsViewState> {
  constructor(props: ChooseUserImportsViewProps) {
    super(props);
    this.state = {
      query: {
        searchQuery: ``,
        orderBy: [],
        filter: this.buildODataFilter().map(x => x.filter),
        skip: 0,
        take: 10
      },
      activeFilters: [],
      selectedUsers: [],
      filters: {
        firstNameOrLastName: '',
        locationId: '',
        personnelArea: ''
      }
    };
    this.load();
  }

  @autobind
  private AddParticipants() {
    const p = this.state.selectedUsers.firstOrDefault();
    if (p == null) return;
    this.props.onAddParticipants(p);
    this.props.onCloseUsersModal();
  }

  private onCancel = () => {
    this.props.onCloseUsersModal();
  };

  private load = () => {
    this.props.usersStore.getAllUsersWithRoleAsync(this.state.query, 'Employee');
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) });
    this.load();
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  };

  private handleFilterByPersonnelArea = (value: string) => {
    const filters = { ...this.state.filters };
    filters.personnelArea = value;

    this.setState({ filters });

    this.refreshTable(filters);
  };

  private handleFilterByName(value: string) {
    const filters = { ...this.state.filters };
    filters.firstNameOrLastName = value;

    this.setState({ filters });

    this.refreshTable(filters);
  }

  private handleFilterByLocation(location: ItemReference) {
    const filters = { ...this.state.filters };
    filters.locationId = location ? location.id : null;

    this.setState({ filters });

    this.refreshTable(filters);
  }

  private refreshTable(filters: ChooseUserImportsFilters) {
    const tableFilter = this.buildODataFilter(filters);
    this.handleFilterChange(tableFilter);
  }

  private buildODataFilter(filters?: ChooseUserImportsFilters) {
    const oDataFilters = [];
    const { alreadySelectedUserIds, positionCodeId } = this.props;

    if (alreadySelectedUserIds && alreadySelectedUserIds.length > 0)
      oDataFilters.push({
        id: nameof<UserDto>('id'),
        filter: `not(${nameof<UserDto>('id')} in (${alreadySelectedUserIds.join(',')}))`
      });

    if (positionCodeId) {
      oDataFilters.push({
        id: nameof<UserDto>('positionCodeId'),
        filter: `PositionCodeId eq ${positionCodeId}`
      });
    }

    if (!filters) return oDataFilters;

    if (filters.firstNameOrLastName)
      oDataFilters.push({
        id: nameof<UserDto>('firstName'),
        filter: `(contains(tolower(surname),'${filters.firstNameOrLastName.toLocaleLowerCase()}') or contains(tolower(name),'${filters.firstNameOrLastName.toLocaleLowerCase()}'))`
      });
    if (filters.locationId) {
      oDataFilters.push({ id: nameof<UserDto>('locationId'), filter: `${nameof<UserDto>('locationId')} eq ${filters.locationId}` });
    }
    if (filters.personnelArea)
      oDataFilters.push({
        id: nameof<UserDto>('personnelArea'),
        filter: `contains(tolower(${nameof<UserDto>('personnelArea')}),'${filters.personnelArea.toLocaleLowerCase()}')`
      });
    return oDataFilters;
  }

  getRoleLocation = (role: Roles, { roles, location }: UserDto): LocationDto => {
    const currentRole = Roles[role];
    const roleItem = roles.find(({ role: item }) => item.name === currentRole);
    return roleItem?.location || location;
  };

  selectedUsers = (items: unknown[]): void => {
    this.setState({ selectedUsers: items as any });
  };

  renderSelectedIcon = (isSelected: boolean) => {
    if (!isSelected) return <></>;

    return <Icon name="check" color="black" />;
  };

  public render() {
    const { t, acceptBtnTitle, title, usersStore, noFilters } = this.props;

    const tableModel = {
      columns: [
        { title: t('Name'), tooltipRenderer: false, renderer: ({ firstName }) => firstName },
        { title: t('Surname'), tooltipRenderer: false, renderer: ({ lastName }) => lastName },
        {
          title: t('Location'),
          tooltipRenderer: false,
          renderer: data => {
            let { location } = data;
            if (this.props.specificLocation && this.props.roleLocation) location = this.getRoleLocation(this.props.roleLocation, data);
            return (
              <span>
                {location.location} - {location.countryName}
              </span>
            );
          }
        },
        { title: t('Personnel Area'), tooltipRenderer: false, renderer: data => data.personnelArea }
      ],
      data: usersStore.state
    } as TableModel<UserDto>;

    return (
      <Modal open closeOnEscape={true} onClose={this.onCancel} closeOnDimmerClick={false}>
        <Modal.Header className="borderless-header">
          <Header as="h2" className="modal-header-title">
            {title ? t(title) : t('Users Manual Search')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content user-imports">
          {!noFilters && (
            <>
              <Grid>
                <Grid.Row columns={4}>
                  <GridColumn stretched className="employee-search__first-row__column-filters">
                    <Input
                      className="employee-search-filter"
                      icon="search"
                      placeholder={t('Surname, Name')}
                      onChange={(_, { value }) => this.handleFilterByName(value)}
                    />
                  </GridColumn>
                  <GridColumn stretched className="employee-search__first-row__column-filters">
                    <LocationEditor
                      clearable
                      className="user-dropdown employee-search-filter"
                      nullable
                      value={null}
                      placeholder={t('Location')}
                      onChange={location => this.handleFilterByLocation(location)}
                    />
                  </GridColumn>
                  <GridColumn stretched className="employee-search__first-row__column-filters">
                    <Input
                      className="employee-search-filter"
                      icon="search"
                      placeholder={t('Personnel Area')}
                      onChange={(_, { value }) => this.handleFilterByPersonnelArea(value)}
                    />
                  </GridColumn>
                </Grid.Row>
              </Grid>
              <Divider />
            </>
          )}
          <Container className="student-list-table">
            <TableView
              model={tableModel}
              selectable={true}
              maxSelection={1}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onSelectionChange={users => this.selectedUsers(users)}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              hiddeMenu
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-participants-button" onClick={this.AddParticipants} positive>
            {acceptBtnTitle ? t(acceptBtnTitle) : t('Overwrite User')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseUserImportsView);
