import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message, Icon, Button, Grid } from 'semantic-ui-react';
import { PersonnelAreaDto, PersonnelAreasStore } from 'stores/configuration/locations/personnelArea-store';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import NewPersonnelAreaView from './new-personnelArea';
import ChangePersonnelAreaView from './edit-PersonnelArea';

export interface PersonnelAreaListProps extends WithTranslation, RouteComponentProps {
  personnelAreas: PersonnelAreasStore;
}

export interface PersonnelAreaListState {
  query: Query;
  newPersonnelAreaShown: boolean;
  changePersonnelAreaShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  filteredByLinked: boolean;
  someFilterOpened: boolean;
}

@connect(['personnelAreas', PersonnelAreasStore])
class PersonnelAreaListPage extends React.Component<PersonnelAreaListProps, PersonnelAreaListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };
  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newPersonnelAreaShown: false,
      changePersonnelAreaShown: false,
      activeFilters: [],
      selectedItem: null,
      filteredByLinked: false,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.personnelAreas.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: PersonnelAreaDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.personnelAreas.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: PersonnelAreaDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.personnelAreas.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newPersonnelAreaShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newPersonnelAreaShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changePersonnelAreaShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changePersonnelAreaShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onFilterLinked() {
    let searchQuery: string = '';

    if (this.state.filteredByLinked === false) {
      searchQuery = 'locationId eq ' + null;
      this.setState({ filteredByLinked: true });
    } else this.setState({ filteredByLinked: false });

    const activeFilters = [searchQuery];
    const filter = searchQuery;

    const query = Object.assign(this.state.query, { filter, skip: 0 });

    this.setState({ query, activeFilters }, () => this.load());
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, filteredByLinked, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          tooltipRenderer: false,
          renderer: data => <span>&nbsp;{data.name}</span>,
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(e, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by name')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<PersonnelAreaDto>('name'),
                  `contains(tolower(${nameof<PersonnelAreaDto>('name')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<PersonnelAreaDto>('name'))}
              active={activeFilters.includes(nameof<PersonnelAreaDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<PersonnelAreaDto>('name'),
            useProfile: false
          }
        },
        {
          title: t('Location'),
          tooltipRenderer: true,
          renderer: data => <span>{data.locationName}</span>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Location Name')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<PersonnelAreaDto>('locationName'),
                  `contains(tolower(${nameof<PersonnelAreaDto>('locationName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<PersonnelAreaDto>('locationName'))}
              active={activeFilters.includes(nameof<PersonnelAreaDto>('locationName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<PersonnelAreaDto>('locationName'),
            useProfile: false
          }
        }
      ],
      data: this.props.personnelAreas.state
    } as TableModel<PersonnelAreaDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.personnelAreas.state.result && !this.props.personnelAreas.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.personnelAreas.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newPersonnelAreaShown && !this.state.changePersonnelAreaShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil alternate" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              onNewItem={this.onNewItem}
              createNewButtonTitle={t('New Personnel Area')}
              extraMenu={[
                {
                  content: (
                    <Button className={filteredByLinked ? 'filtered-link-button' : 'inverted-color-btn'} onClick={this.onFilterLinked} icon>
                      <Icon.Group>
                        <Icon style={{ margin: 0 }} name="unlink" />
                      </Icon.Group>
                    </Button>
                  )
                }
              ]}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newPersonnelAreaShown && <NewPersonnelAreaView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changePersonnelAreaShown && (
            <ChangePersonnelAreaView onClose={this.onEditItemClosed} currentPersonnelArea={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(PersonnelAreaListPage);
