import { AbstractValidator } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, Query, QueryResult } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import { BaseDto } from 'stores/types';
import { validFlag } from 'utils/country-infos';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';

export interface CountryDto extends BaseDto {
  id: string;
  name: string;
  isoCode: string;
  description: string;
}

export interface CreateCountryDto {
  name: string;
  isoCode: string;
  description: string;
}

export interface ChangeCountryDto {
  id: string;
  name: string;
  isoCode: string;
  description: string;
}

export class CreateCountryValidator extends AbstractValidator<CreateCountryDto> {
  constructor() {
    super();

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Country name is required'));

    this.validateIfString(o => o.isoCode)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Country iso code is required'));

    this.validateIf(
      o =>
        (!isNullOrWhiteSpaces(o.name) && validFlag(o.name.toLowerCase())) ||
        (!isNullOrWhiteSpaces(o.isoCode) && validFlag(o.isoCode.toLowerCase()))
    )
      .isEqualTo(true)
      .withFailureMessage(i18n.t('Cant resolve a flag from Country name or code'));
  }
}

export class ChangeCountryValidator extends AbstractValidator<ChangeCountryDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Country id is required'));

    this.validateIfString(o => o.name)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Country name is required'));

    this.validateIfString(o => o.isoCode)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Country iso code is required'));

    this.validateIf(
      o =>
        (!isNullOrWhiteSpaces(o.name) && validFlag(o.name.toLowerCase())) ||
        (!isNullOrWhiteSpaces(o.isoCode) && validFlag(o.isoCode.toLowerCase()))
    )
      .isEqualTo(true)
      .withFailureMessage(i18n.t('Cant resolve a flag from Country name or code'));
  }
}

@repository('@@COUNTRIES', 'countries.summary')
export class CountriesStore extends DataStore<CountryDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-country';
  retrievePath = 'get-countries';
  updatePath = 'update-country';
  deletePath = 'delete-country';

  protected validate(item: CountryDto) {
    return new ChangeCountryValidator().validate(item);
  }

  constructor() {
    super('COUNTRY', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@COUNTRIES', 'countries.new')
export class NewCountryStore extends FormStore<CreateCountryDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-country';
  retrievePath = 'get-countries';
  updatePath = 'update-country';

  protected validate(item: CreateCountryDto) {
    return new CreateCountryValidator().validate(item);
  }

  constructor() {
    super('NEW_COUNTRY', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@COUNTRIES', 'countries.change')
export class ChangeCountryStore extends FormStore<ChangeCountryDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-country';
  retrievePath = 'get-countries';
  updatePath = 'update-country';

  protected validate(item: ChangeCountryDto) {
    return new ChangeCountryValidator().validate(item);
  }

  constructor() {
    super('CHANGE_COUNTRY', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@COUNTRIES', 'dropdowncountries.summary')
export class DropDownCountriesStore extends DataStore<CountryDto> {
  baseUrl = 'master-data/v1';
  createPath = '';
  retrievePath = 'get-countries';
  updatePath = '';
  deletePath = '';

  protected validate(item: CountryDto) {
    return new ChangeCountryValidator().validate(item);
  }

  constructor() {
    super('DROPDOWN_COUNTRY', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, countryIds?: string[], search?: string): Promise<QueryResult<CountryDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);
    let data: any;

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }
    let hasFilterOrParameters = DataStore.hasFilterOrParameters(query);
    if ((this.state.items || []).length > 0 && !hasFilterOrParameters) {
      let countriesDto = this.state.items.map(({ item }) => item);

      if ((countryIds || []).length > 0) {
        countriesDto = countriesDto.filter(x => countryIds.includes(x.id));
      }

      if (!isNullOrWhiteSpaces(search)) {
        countriesDto = countriesDto.filter(x => x.name.startsWith(search));
      }

      let countriesResult = { items: countriesDto, count: countriesDto.length } as QueryResult<CountryDto>;

      return new Promise<QueryResult<CountryDto>>(resolve => resolve(countriesResult));
    } else {
      if (!hasFilterOrParameters) {
        const result = await this.dispatchAsync(
          this.ENTITY_LIST_UPDATE,
          httpService.get<QueryResult<CountryDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
        );

        return result.data;
      } else {
        const result = await httpService.get<QueryResult<CountryDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data);

        return result.data;
      }
    }
  }
}
