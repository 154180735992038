import {
  InstructorExpertiseStore,
  ChangeInstructorExpertiseStore,
  NewInstructorExpertiseStore,
  InstructorTabExpertiseStore
} from './instructor-expertise-store';
import {
  InstructorExpertiseRequestsStore,
  NewInstructorExpertiseRequestStore,
  ChangeInstructorExpertiseRequestStore
} from './instructor-expertise-request-store';
import { DownloadInstructorExpertiseStore, UploadInstructorExpertiseStore } from './instructor-expertise-upload-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new InstructorExpertiseStore());
  storeBuilder.addRepository(new ChangeInstructorExpertiseStore());
  storeBuilder.addRepository(new NewInstructorExpertiseStore());
  storeBuilder.addRepository(new InstructorTabExpertiseStore());

  storeBuilder.addRepository(new InstructorExpertiseRequestsStore());
  storeBuilder.addRepository(new ChangeInstructorExpertiseRequestStore());
  storeBuilder.addRepository(new NewInstructorExpertiseRequestStore());
  storeBuilder.addRepository(new UploadInstructorExpertiseStore());
  storeBuilder.addRepository(new DownloadInstructorExpertiseStore());
}
