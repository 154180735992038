import * as autobind from 'autobind';
import React, { ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dimmer,
  Divider,
  Header,
  Icon,
  Input,
  Loader,
  Menu,
  MenuItemProps,
  Message,
  Pagination,
  Popup,
  Select,
  Table as SemanticTable,
  TableProps as SemanticTableProps,
  TableRowProps,
  Modal,
  Container,
  DropdownItemProps
} from 'semantic-ui-react';
import { SemanticCOLORS, SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { DataModel, ItemState, OrderDefinition, SortDirection } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { clone, getProperties } from 'utils/object';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFileDownload, faFileUpload, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import HttpService from 'services/http-service';
import { container } from 'inversify.config';
import TableTooltipCell from './table-tooltip-cell';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

// export interface TableSortKey {
//     key: string;
//     text: string;
// }

// export interface TableSort {
//     defaultKey: string;
//     orderBy: TableSortKey[];
//     direction: SortDirection;
// }

export interface TableColumnSortDefinition {
  field: string;
  useProfile: boolean;
  active?: SortDirection;
}

export interface TablePagination {
  pageSize: number;
  activePage?: number;
  options: DropdownItemProps[];
}

export interface TableColumn<T> {
  title: string;
  visible?: boolean;
  collapsing?: boolean;
  selectableHeader?: boolean;
  headerRenderer?: (
    title: string,
    onFilter?: (id: string, filter: any, preventReload?: boolean) => void,
    onClearFilter?: (id: string, preventReload?: boolean) => void
  ) => React.ReactNode;
  renderer?: (item: T, expanded: boolean) => React.ReactNode;
  tooltipRenderer?: boolean;
  editor?: (item: T, onChange: () => void) => React.ReactNode;
  headerClassName?: string;
  cellClassName?: string;
  sortDefinition?: TableColumnSortDefinition;
  newClassFromComponent?: (item: T) => string;
  excludeFromColumnHideTool?: boolean;
  onClick?: (item: T) => void;
}

export interface TableModel<T> {
  columns: TableColumn<T>[];
  data: DataModel<T>;
  // sort?: TableSort;
  pagination?: TablePagination;
  keySelector?: (item: T) => any;
  expandedRenderer?: (item: T) => React.ReactElement<TableRowProps>;
  showExpander?: (item: T) => boolean;
}

type RowSelectionType = 'checkbox' | 'allRow';

enum KeyboardShortCut {
  upArrow = 38,
  downArrow = 40,
  leftArrow = 37,
  rightArrow = 39,
  enter = 13
}

export interface TableProps<T> extends WithTranslation {
  title?: string;
  titleAs?: string;
  model: TableModel<T>;
  onSearchFilterChanged?: (q: string) => void;
  onOrderByChanged?: (orderBy: OrderDefinition[]) => void;
  onRefresh?: () => void;
  canCreateNew?: boolean;
  onNewItem?: () => void;
  canEdit?: boolean;
  canBulkEdit?: boolean;
  editLabel?: string;
  onSaveRow?: (item: T, state: ItemState) => Promise<CommandResult<any>>;
  onSaveRows?: (rows: { item: T; state: ItemState }[]) => Promise<CommandResult<any>>;
  canDelete?: boolean;
  deleteLabel?: string;
  onDeleteRow?: (item: T, state: ItemState) => Promise<CommandResult<any>>;
  onPageChange?: (skip: number, take: number) => void;
  onRowClick?: (item: T, state: ItemState) => void;
  showActionsConfirmModal?: boolean;
  onRowDoubleClick?: (item: T) => void;
  onEnterKeydown?: (item: T, state?: ItemState) => any;
  onFilterChange?: (filters: { id: string; filter: any }[]) => void;
  tableProps?: SemanticTableProps;
  extraMenu?: MenuItemProps[];
  isHideExtraActions?: (item: T) => boolean;
  onClickColApprovedReject?: (item: T) => void;
  extraActions?: {
    content: React.ReactNode;
    onClick: (item: T) => void;
    isVisible?: (item: T) => boolean;
    disabled?: (item: T) => boolean;
  }[];
  selectionActions?: { content: React.ReactNode; onClick: (item: T, selectedRows: any[]) => void }[];
  selectionActionMinElements?: number;
  allowHidingColumns?: boolean;
  createNewButtonTitle?: string;
  canExpandRows?: boolean;
  alwaysExpanded?: boolean;
  hiddeMenu?: boolean;
  allowOnlyOneRowExpanded?: boolean;
  extraInlineActions?: {
    content: SemanticICONS | 'toggle';
    visible: (item: T) => boolean;
    disabled?: (item: T) => boolean;
    onClick?: (item: T) => void;
    checked?: (item: T) => boolean;
    onChange?: (item: T, checked: boolean) => void;
  }[];
  selectable?: boolean;
  selectionType?: RowSelectionType;
  onSelectionChange?: (selectedRows: T[]) => void;
  bulkTemplateUrl?: string;
  exportUrl?: string;
  bulkInsertUrl?: string;
  bulkTemplateName?: string;
  uploadComplete?: (data: any) => void;
  hideMessageOnFinishUpload?: boolean;
  clearSelection?: boolean;
  maxSelection?: number;
  onHideCheckbox?: boolean;
  triggerExport?: boolean;
  isRowDisabled?: (item: T) => boolean;
  isRowDisableLayout?: (item: T) => boolean;
  areActionsDisabled?: (item: T) => boolean;
  selectedRow?: any;
  createButtonDisabled?: boolean;
  createButtonPopup?: string;
  createButtonIcon?: { hasIcon: boolean; name?: SemanticICONS };
  enableInlineEditionV2?: boolean;
  deleteConfirmationMessage?: string;
  fixedDimmer?: boolean;
  noFlexContainer?: boolean;
  loading?: boolean;
  customClassName?: string;
  mainActionsFilters?: ReactNode;
  preventEnterKeyDownEvent?: boolean;
  unselectFirstRow?: boolean;
}

export interface TableState<T> {
  columns: TableColumn<T>[];
  searchFilter: string;
  data: Row<T>[];
  result: CommandResult<any>;
  showDeleteConfirm: boolean;
  toDelete: Row<T>;
  pageSize: number;
  activePage: number;
  totalPages: number;
  filter: { [id: string]: any };
  orderBy: OrderDefinition[];
  expandedRows: { [id: string]: boolean };
  selectedRows: T[];
  busy: boolean;
  messageError: string[];
  loadingMessage: string;
  totalElements: number;
  importedElements: number;
  uploadComplete: boolean;
  uploadMenuOpen: boolean;
  totalRecords: number;
  pageOptions: DropdownItemProps[];
  row: number;
}

type RowMode = 'editor' | 'normal';

interface Row<T> {
  item: T;
  state: ItemState;
  original: T;
  mode: RowMode;
  selected: boolean;
  actionsMenuOpened: boolean;
}

class Table<T> extends React.Component<TableProps<T>, TableState<T>> {
  defaultPageOptions: DropdownItemProps[] = [
    { text: '10 / page', value: 10 },
    { text: '20 / page', value: 20 },
    { text: '30 / page', value: 30 },
    { text: '50 / page', value: 50 }
  ];

  constructor(props: TableProps<T>) {
    super(props);
    this.state = {
      columns: this.props.model.columns,
      searchFilter: '',
      data: this.BuildRows(props.model.data),
      result: undefined,
      showDeleteConfirm: false,
      toDelete: null,
      activePage: this.props.model.pagination == null ? 1 : this.props.model.pagination.activePage,
      pageSize: this.props.model.pagination == null ? 10 : this.props.model.pagination.pageSize,
      totalPages: 1,
      totalRecords: 0,
      filter: {},
      orderBy: this.props.model.columns
        .filter(c => c.sortDefinition && c.sortDefinition.active)
        .map(c => ({
          field: c.sortDefinition.field,
          direction: c.sortDefinition.active,
          useProfile: c.sortDefinition.useProfile
        })),
      expandedRows: {},
      selectedRows: [],
      busy: false,
      loadingMessage: '',
      messageError: [],
      totalElements: 0,
      importedElements: 0,
      uploadComplete: false,
      uploadMenuOpen: false,
      pageOptions: this.props.model.pagination == null ? this.defaultPageOptions : this.props.model.pagination.options,
      row: 0
    };

    this.keyboardShortcutHandler = this.keyboardShortcutHandler.bind(this);
  }

  keyboardShortcutHandler(event) {
    const { model, loading, preventEnterKeyDownEvent } = this.props;

    if ((model.data && model.data.isBusy) || loading) return;
    if (!this.props.selectable) return;
    //if (!this.props.maxSelection || this.props.maxSelection > 1) return;

    let rowIndex = this.state.row;

    if (event.keyCode === KeyboardShortCut.downArrow) {
      rowIndex = rowIndex + 1 < this.state.data.length ? rowIndex + 1 : rowIndex;
    }

    if (event.keyCode === KeyboardShortCut.upArrow) {
      rowIndex = rowIndex > 0 ? rowIndex - 1 : rowIndex;
    }

    if (rowIndex !== this.state.row) {
      if (rowIndex < this.state.data.length && rowIndex >= 0) {
        this.setState({ selectedRows: [] });
      }
      this.onSelectionChange(this.state.data[rowIndex].item);
    }

    if (this.state.data[rowIndex] && event.keyCode === KeyboardShortCut.rightArrow) {
      this.keyboardRowExpand(this.state.data[rowIndex].item);
    }
    if (this.state.data[rowIndex] && event.keyCode === KeyboardShortCut.leftArrow) {
      this.keyboardRowCollapse(this.state.data[rowIndex].item);
    }

    if ((this.state.selectedRows || []).length > 0) {
      if (this.state.data[rowIndex] && event.keyCode === KeyboardShortCut.enter && !preventEnterKeyDownEvent) {
        if (this.props.showActionsConfirmModal && this.props.canDelete && this.props.onDeleteRow)
          this.showConfirmModal(this.state.data[rowIndex]);
        this.props.onEnterKeydown && this.props.onEnterKeydown(this.state.data[rowIndex].item);
      }
    }

    this.setState({ row: rowIndex });
  }

  componentDidUpdate(prevProps) {
    const { selectedRow, selectionType } = this.props;
    const prevSelectedRow = prevProps.selectedRow;

    if (this.props.selectable) {
      if (selectionType === 'checkbox' && this.state.data && selectedRow != null && prevSelectedRow !== selectedRow) {
        let items = [];
        let index = 0;
        const selectedRows = [];

        items = this.state.data.map(items => items.item as any);
        index = (items || []).length < 0 ? 0 : items.map(item => item.id).indexOf(selectedRow.id);
        selectedRows.push(selectedRow);

        this.setState({ row: index, selectedRows: selectedRows });
      }
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyboardShortcutHandler, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyboardShortcutHandler, false);
  }

  private defaultKeySelector = (item: T) => {
    return (item as any).id;
  };

  UNSAFE_componentWillReceiveProps(nextProps: TableProps<T>) {
    const nextState = {
      data: this.BuildRows(nextProps.model.data),
      result: undefined,
      totalPages: Math.ceil((nextProps.model.data == null ? 1 : nextProps.model.data.count) / this.state.pageSize),
      columns: nextProps.model.columns,
      totalRecords: nextProps.model.data == null ? 0 : nextProps.model.data.count
    } as TableState<T>;

    const { unselectFirstRow } = this.props;

    this.initExpandedRows(nextState);

    if (this.props.triggerExport !== nextProps.triggerExport) {
      this.exportElements();
    }
    const currentSelectedRows = (this.state ? this.state.selectedRows : []) || [];
    nextState.selectedRows = currentSelectedRows;

    if (nextProps.model.pagination != null) {
      nextState.activePage = nextProps.model.pagination.activePage || this.state.activePage;
      nextState.pageSize = nextProps.model.pagination.pageSize || this.state.pageSize;
    }
    if (nextProps.clearSelection !== this.props.clearSelection) {
      nextState.selectedRows = [];
    }

    let columnsVisibilty = this.state.columns.map(x => {
      return { title: x.title, visible: x.visible } as TableColumn<T>;
    });
    nextState.columns.forEach(x => {
      x.visible = columnsVisibilty.find(y => y.title === x.title).visible;
    });

    if (!unselectFirstRow) {
      const row = this.state.row || 0;
      if (this.props.selectable) {
        if ((this.state.selectedRows || []).length <= 1 && this.state.data && this.state.data[row || 0] && this.state.data[row || 0].item) {
          nextState.selectedRows = [];
          const myData = { ...this.state.data };
          myData[row || 0].selected = true;
          nextState.selectedRows.push(myData[row || 0].item);
        }
      }
    } else {
      const row = this.state.row || -1;
      this.setState({ row });
    }

    this.setState(nextState);
  }

  private initExpandedRows(nextState: any) {
    const currentExpandedRows = (this.state ? this.state.expandedRows : {}) || {};
    const getKey = this.props.model.keySelector || this.defaultKeySelector;
    nextState.expandedRows = nextState.expandedRows || {};

    if (!this.props.alwaysExpanded) {
      return getProperties(currentExpandedRows)
        .filter(({ key }) => nextState.data.any(x => getKey(x.item) === key))
        .forEach(({ key, value }) => (nextState.expandedRows[key] = value));
    }

    nextState.data.forEach(x => (nextState.expandedRows[getKey(x.item)] = true));
  }

  private uuidv4() {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
      (parseInt(c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (parseInt(c) / 4)))).toString(16)
    );
  }

  private isEmptyGUID(guid) {
    const emptyGUID = '00000000-0000-0000-0000-000000000000';
    return guid === emptyGUID;
  }

  private cloneItem = (item: T) => {
    if (!(item as any).id || this.isEmptyGUID((item as any).id)) {
      const id = this.uuidv4();
      (item as any).id = id;
    }
    return clone(item);
  };

  private BuildRows(result: DataModel<T>): Row<T>[] {
    const data = result == null ? [] : result.items || [];
    const stateData = this.state == null ? [] : this.state.data || [];
    const getKey = this.props.model.keySelector || this.defaultKeySelector;
    return data.map(o => {
      const itemInState = stateData.firstOrDefault(x => getKey(x.original) === getKey(o.item));
      return {
        state: o.state,
        item: this.cloneItem(o.item),
        original: o.item,
        mode: itemInState != null ? itemInState.mode : o.state === 'New' ? 'editor' : 'normal',
        actionsMenuOpened: false,
        selected: false
      } as Row<T>;
    });
  }

  private toggleRowExpand = (item: T) => {
    if (!this.props.canExpandRows) return;

    const getKey = this.props.model.keySelector || this.defaultKeySelector;
    const id = getKey(item);
    const expanded = !!this.state.expandedRows[id];
    if (!!this.props.allowOnlyOneRowExpanded) {
      this.setState({ expandedRows: { [id]: !expanded } });
    } else {
      const expandedRows = this.state.expandedRows;
      expandedRows[id] = !expandedRows[id];
      this.setState({ expandedRows });
    }
  };

  private keyboardRowExpand = (item: T) => {
    if (!this.props.canExpandRows) return;

    const getKey = this.props.model.keySelector || this.defaultKeySelector;
    const id = getKey(item);

    if (this.props.allowOnlyOneRowExpanded) {
      this.setState({ expandedRows: { [id]: true } });
    } else {
      const expandedRows = this.state.expandedRows;
      expandedRows[id] = true;
      this.setState({ expandedRows });
    }
  };

  private keyboardRowCollapse = (item: T) => {
    if (!this.props.canExpandRows) return;

    const getKey = this.props.model.keySelector || this.defaultKeySelector;
    const id = getKey(item);

    if (this.props.allowOnlyOneRowExpanded) {
      this.setState({ expandedRows: { [id]: false } });
    } else {
      const expandedRows = this.state.expandedRows;
      expandedRows[id] = false;
      this.setState({ expandedRows });
    }
  };

  @autobind
  private openNewForm() {
    this.props.onNewItem();
  }

  @autobind
  private async onSaveRow(row: Row<T>) {
    if (this.props.onSaveRow) {
      const result = await this.props.onSaveRow(row.item, row.state);
      if (result && result.isSuccess) {
        const getKey = this.props.model.keySelector || this.defaultKeySelector;
        const rows = this.state.data.filter(o => getKey(o.item) === getKey(row.item));
        if (rows.length > 0) {
          row = rows[0];
          row.state = 'Unchanged';
          row.mode = 'normal';
          this.forceUpdate();
        }
      }
    }
  }

  @autobind
  private async saveAllRows() {
    if (this.props.onSaveRows) {
      const items = this.state.data.filter(x => x.mode === 'editor').map(x => ({ item: x.item, state: x.state }));
      const result = await this.props.onSaveRows(items);
      if (result && result.isSuccess) {
        const getKey = this.props.model.keySelector || this.defaultKeySelector;
        for (let i = 0; i < items.length; i++) {
          const row = items[i];
          const rows = this.state.data.filter(o => getKey(o.item) === getKey(row.item));
          if (rows.length !== 0) {
            const r = rows[0];
            r.state = 'Unchanged';
            r.mode = 'normal';
          }
        }
        this.forceUpdate();
      }
    }
  }

  @autobind
  private async onDeleteRow() {
    const row = this.state.toDelete;
    if (this.props.onDeleteRow && row) {
      this.hideConfirmModal();
      const result = await this.props.onDeleteRow(row.item, row.state);
      if (result && result.isSuccess) {
        this.props.model.data.discard(row.item);
        this.setState({ data: this.state.data.filter(o => o.item !== row.item) });
      }
    }
  }

  @autobind
  private onDiscardRow(row: Row<T>) {
    if (row.state === 'New') {
      this.props.model.data.discard(row.item);
      this.setState({ data: this.state.data.filter(o => o !== row) });
    } else {
      row.mode = 'normal';
      row.selected = false;
      row.item = clone(row.original);
      this.forceUpdate();
    }
  }

  @autobind
  private hideConfirmModal() {
    this.setState({ showDeleteConfirm: false, toDelete: null });
  }

  @autobind
  private showConfirmModal(row: Row<T>) {
    row.actionsMenuOpened = false;
    this.forceUpdate();
    this.setState({ showDeleteConfirm: true, toDelete: row });
  }

  @autobind
  private handlePaginationChange(e, data) {
    let page = data.activePage < 1 ? 1 : data.activePage;
    if (data.activePage !== this.state.activePage) {
      this.setState({ activePage: page }, () => {
        if (this.props.onPageChange) {
          const skip = (page - 1) * this.state.pageSize;
          const take = this.state.pageSize;
          this.props.onPageChange(skip, take);
        }
      });
    }
  }

  @autobind
  private handlePageSizeChange(e, data) {
    if (data.value !== this.state.pageSize) {
      this.setState(
        {
          pageSize: data.value,
          activePage: 1
        },
        () => {
          if (this.props.onPageChange) {
            const skip = 0;
            const take = data.value;
            this.props.onPageChange(skip, take);
          }
        }
      );
    }
  }

  private getOnClick = (data: Row<T>) => {
    if (this.props.onRowClick == null || data.mode === 'editor') {
      return null;
    } else {
      return () => this.props.onRowClick(data.item, data.state);
    }
  };

  private getOnDoubleClick = (data: Row<T>, event?: any) => {
    event && this.props.onRowDoubleClick && this.props.onRowDoubleClick(data.item);
  };

  private addFilter = (id: string, newFilter: any, preventReload?: boolean) => {
    const filter = this.state.filter;
    filter[id] = newFilter;
    this.setState({ filter }, () => {
      if (!preventReload && this.props.onFilterChange) {
        this.setState({ activePage: 1 });
        const filters = getProperties(filter).map(kvp => ({ id: kvp.key, filter: kvp.value }));
        this.props.onFilterChange(filters);
      }
    });
  };

  private removeFilter = (id: string, preventReload?: boolean) => {
    const filter = this.state.filter;
    delete filter[id];

    this.setState({ filter }, () => {
      if (!preventReload && this.props.onFilterChange) {
        this.setState({ activePage: 1 });
        const filters = getProperties(filter).map(kvp => ({ id: kvp.key, filter: kvp.value }));
        this.props.onFilterChange(filters);
      }
    });
  };

  private clearAllFilters = () => {
    this.setState({ filter: {} }, () => {
      if (this.props.onFilterChange) {
        this.setState({ activePage: 1 });
        this.props.onFilterChange([]);
      }
    });
  };

  private clearAllOrderBy = () => {
    this.setState({ orderBy: [] }, () => {
      if (this.props.onOrderByChanged) {
        this.props.onOrderByChanged([]);
      }
    });
  };

  private onSort = (name: SemanticICONS, column: TableColumn<T>) => {
    let orderBy = this.state.orderBy;
    const def = column.sortDefinition;
    if (name === 'sort') {
      orderBy = [...orderBy, { field: def.field, useProfile: def.useProfile, direction: 'Ascending' }];
    } else if (name === 'sort down') {
      orderBy = orderBy.filter(o => o.field !== def.field);
    } else {
      const o = orderBy.find(c => c.field === def.field);
      if (o) {
        o.direction = 'Descending';
      } else {
        orderBy = [...orderBy, { field: def.field, useProfile: def.useProfile, direction: 'Descending' }];
      }
    }
    this.setState({ orderBy: [...orderBy] }, () => {
      if (this.props.onOrderByChanged) {
        this.props.onOrderByChanged(this.state.orderBy);
      }
    });
  };

  private getOrderByIcon = (column: TableColumn<T>): JSX.Element => {
    if (column.sortDefinition != null) {
      const def = column.sortDefinition;
      const orderBy = this.state.orderBy.find(x => x.field === def.field);
      let icon: SemanticICONS = 'sort';
      let color: SemanticCOLORS = 'grey';
      if (orderBy == null) {
        icon = 'sort';
      } else if (orderBy.direction === 'Ascending') {
        icon = 'sort up';
        color = null;
      } else {
        icon = 'sort down';
        color = null;
      }

      return (
        <Icon.Group className="clickable-icon" onClick={() => this.onSort(icon, column)}>
          <Icon style={{ margin: 0 }} color="grey" name="sort" />
          <Icon style={{ margin: 0 }} color={color} name={icon} />
        </Icon.Group>
      );

      // return <Icon color={color} className='clickable-icon' onClick={() => this.onSort(icon, column)} name={icon} />
    }
    return null;
  };

  private onSelectionChange = (item: T) => {
    if (!this.props.selectable) return;

    const getKey = this.props.model.keySelector || this.defaultKeySelector;
    const id = getKey(item);
    let index = 0;

    let selectedRows = this.state.selectedRows;

    const distinct = selectedRows.filter(c => getKey(c) !== id);

    if (distinct.length === selectedRows.length) {
      index = this.state.data.map(items => items.item).indexOf(item);
      selectedRows.push(item);
    } else {
      const last = (distinct || []).slice(-1).firstOrDefault();
      index = this.state.data.map(items => items.item).indexOf(last);
      selectedRows = distinct;
    }

    if (this.props.maxSelection && this.props.maxSelection > 0 && selectedRows.length > this.props.maxSelection) {
      selectedRows = selectedRows.slice(1, this.props.maxSelection + 1);
    }

    this.setState({ row: index, selectedRows: selectedRows }, () => {
      if (this.props.onSelectionChange) this.props.onSelectionChange(selectedRows);
    });
  };

  private isSelected = (item: T): boolean => {
    if (!this.props.selectable) return false;
    const getKey = this.props.model.keySelector || this.defaultKeySelector;
    const id = getKey(item);
    const selectedRows = this.state.selectedRows;
    return selectedRows.filter(c => getKey(c) === id).length > 0;
  };

  private canShowAction = (item: T): boolean => {
    if (!this.props.selectable) return false;

    const getKey = this.props.model.keySelector || this.defaultKeySelector;
    const id = getKey(item);
    const selectedRows = this.state.selectedRows;
    return (
      selectedRows.filter(c => getKey(c) === id).length > 0 &&
      (!this.props.selectionActionMinElements ||
        (this.props.selectionActionMinElements && this.props.selectionActionMinElements <= selectedRows.length))
    );
  };

  private async uploadFile(event: any) {
    this.setState({ uploadMenuOpen: false });
    if (this.props.bulkInsertUrl) {
      this.setState({ busy: true, loadingMessage: 'Uploading template...', messageError: [] });
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      let httpService = container.get<HttpService>(HttpService);
      const response = await httpService.post(this.props.bulkInsertUrl, formData);
      this.setState({
        busy: false,
        loadingMessage: '',
        messageError: (response.data as any).errors,
        totalElements: (response.data as any).totalElements ? (response.data as any).totalElements : 0,
        importedElements: (response.data as any).addedElements ? (response.data as any).addedElements : 0
      });

      if (this.props.uploadComplete) this.props.uploadComplete(response.data);
      if (!this.props.hideMessageOnFinishUpload) this.setState({ uploadComplete: true });
    }
  }

  private async downloadTemplate() {
    if (this.props.bulkTemplateUrl) {
      this.setState({ busy: true, loadingMessage: 'Downloading template...', messageError: [] });
      let httpService = container.get<HttpService>(HttpService);
      await httpService.download(this.props.bulkTemplateUrl, this.props.bulkTemplateName || 'template.xlsx');
      this.setState({ busy: false });
    }
  }

  private async exportElements() {
    if (this.props.exportUrl) {
      this.setState({ busy: true, loadingMessage: this.props.t('Exporting...'), messageError: [] });
      let httpService = container.get<HttpService>(HttpService);
      await httpService.download(this.props.exportUrl, this.props.bulkTemplateName || 'template.xlsx').catch(value => {
        this.setState({ busy: false });
        ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
      });

      this.setState({ busy: false });
      ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
    }
  }

  downloadTxtFile = () => {
    if (this.state.messageError && this.state.messageError.length > 0) {
      const element = document.createElement('a');
      const file = new Blob(this.state.messageError.map(o => o + '\n') as any, { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = 'errors.txt';
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };

  extraActionsRenderer = (data?) => {
    if (!data) return;
    const { t } = this.props;
    return (
      <Menu vertical secondary>
        {(this.props.canEdit || data.state === 'New') && (
          <Menu.Item
            key="edit"
            position="left"
            onClick={() => {
              data.actionsMenuOpened = false;
              data.mode = 'editor';
              data.selected = true;
              this.forceUpdate();
            }}
          >
            <Icon name="edit" style={{ color: '#474b4f' }} />
            &nbsp;{this.props.editLabel || t('Edit')}
          </Menu.Item>
        )}
        {this.canShowAction(data.item) &&
          (this.props.selectionActions || []).map(({ content, onClick }, idx) => (
            <Menu.Item key={`extra-action_${idx}`} content={content} onClick={() => onClick(data.item, this.state.selectedRows)} />
          ))}
        {this.props.extraActions &&
          this.props.extraActions
            .filter(x => !x.isVisible || x.isVisible(data.item))
            .map(({ content, onClick, disabled }, idx) => (
              <Menu.Item
                key={`extra-action_${idx}`}
                content={content}
                disabled={disabled != null && disabled(data.item)}
                onClick={() => onClick(data.item)}
              />
            ))}
        {this.props.canDelete && this.props.onDeleteRow && (
          <Menu.Item key="delete" onClick={() => this.showConfirmModal(data)}>
            <span className="text__red text__bold">{this.props.deleteLabel ? t(this.props.deleteLabel) : t('Delete')}</span>
            <Icon color="red" name="trash alternate" />
          </Menu.Item>
        )}
      </Menu>
    );
  };

  public render() {
    const { t, tableProps: tProps, onRowClick, canExpandRows, model, alwaysExpanded, extraInlineActions } = this.props;
    const { canEdit, canDelete, extraActions, selectionActions, enableInlineEditionV2, loading, fixedDimmer } = this.props;
    const { hiddeMenu, title, titleAs, canCreateNew, onNewItem, createButtonDisabled, exportUrl, onSearchFilterChanged } = this.props;
    const { createNewButtonTitle, createButtonIcon, createButtonPopup, bulkInsertUrl, editLabel, onRefresh } = this.props;
    const {
      onHideCheckbox,
      areActionsDisabled,
      deleteConfirmationMessage,
      onPageChange,
      noFlexContainer,
      isHideExtraActions,
      onClickColApprovedReject
    } = this.props;
    const {
      extraMenu,
      allowHidingColumns,
      canBulkEdit,
      selectionType,
      selectable,
      isRowDisabled,
      isRowDisableLayout,
      selectedRow,
      customClassName,
      mainActionsFilters
    } = this.props;

    const columns = this.state.columns.filter(c => c.visible || c.visible === undefined);
    // if (!this.props.model.sort) {
    //     this.props.model.sort = { defaultKey: '', orderBy: [], direction: 'Ascending' };
    // }
    // const orderBy = (this.props.model.sort.orderBy || []).map((o) => ({ value: o.key, text: o.text } as DropdownItemProps));
    let rowKey = 0;

    const tableProps: SemanticTableProps = tProps || ({ celled: false } as SemanticTableProps);
    if (onRowClick) {
      tableProps.selectable = true;
    }

    let canExpand = !!canExpandRows;

    if (!!alwaysExpanded) canExpand = true;
    else if (model.showExpander == null) canExpand = !!canExpandRows && !!model.expandedRenderer;
    else canExpand = !!canExpandRows && !!model.expandedRenderer && this.state.data.some(x => model.showExpander(x.item));

    const getKey = model.keySelector || this.defaultKeySelector;

    const hasExtraInlineActions = (extraInlineActions || []).length !== 0;
    const hasActions = canEdit || canDelete || (extraActions || []).length !== 0 || (selectionActions || []).length !== 0;
    const addLoadInActions = hasActions;
    const addLoadInInlineActions = !addLoadInActions && hasExtraInlineActions;
    const addEmptyLoadHeader = !addLoadInActions && !addLoadInInlineActions;
    const useInlineEditionV2 = !!enableInlineEditionV2 && !!canEdit;
    const tOptions = (this.state.pageOptions || []).map(({ text, value }) => ({ text: t(text as string), value }));
    return (
      <>
        <Dimmer
          active={(model.data && model.data.isBusy) || loading}
          style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)', position: !!fixedDimmer ? 'fixed' : undefined }}
        >
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>

        <Dimmer
          active={this.state.busy}
          style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)', position: !!fixedDimmer ? 'fixed' : undefined }}
        >
          <Loader indeterminate>{t(this.state.loadingMessage)}</Loader>
        </Dimmer>
        {this.state.result && !this.state.result.isSuccess && (
          <Message
            className="error-message__style"
            icon="exclamation circle"
            error
            header={t('An error ocurred')}
            list={this.state.result.messages.map(o => o.body)}
          />
        )}

        {!hiddeMenu && (
          <Menu className="custom-table-main-actions" floated="right" stackable secondary>
            {!isNullOrWhiteSpaces(title) && (
              <Menu.Item fitted>
                <Header as={titleAs}>{title}</Header>
              </Menu.Item>
            )}
            {mainActionsFilters && mainActionsFilters}
            {canCreateNew && onNewItem ? (
              <Menu.Item fitted>
                <Popup
                  trigger={
                    <Button
                      disabled={createButtonDisabled || false}
                      className="custom-table-add-btn"
                      icon
                      size="mini"
                      onClick={e => this.openNewForm()}
                    >
                      {createNewButtonTitle}
                      {createButtonIcon && createButtonIcon.hasIcon ? (
                        <Icon circular size="tiny" name={createButtonIcon.name || 'add'} />
                      ) : !createButtonIcon ? (
                        <Icon circular size="tiny" name="add" />
                      ) : null}
                    </Button>
                  }
                  position="top right"
                  content={createButtonPopup || t('Create new item')}
                />
              </Menu.Item>
            ) : (
              <> </>
            )}
            {bulkInsertUrl && (
              <Menu.Item className="menu-item-download">
                <Popup
                  on="click"
                  position="bottom center"
                  open={this.state.uploadMenuOpen}
                  onOpen={() => this.setState({ uploadMenuOpen: true })}
                  onClose={() => {
                    this.setState({ uploadMenuOpen: false });
                  }}
                  trigger={
                    <Button
                      icon
                      size="mini"
                      className="custom-table-upload-btn custom-table-upload-btn__up-down transparent-btn"
                      onClick={() => this.setState({ uploadMenuOpen: true })}
                    >
                      <FontAwesomeIcon className="solid" icon={faFileUpload} size="lg" />
                    </Button>
                  }
                >
                  <Menu vertical secondary>
                    <Menu.Item key="upload" position="left" as="label" htmlFor="file" className="turquose-onhover">
                      <FontAwesomeIcon className="solid float-right" icon={faFileUpload} size="lg" />
                      <input type="file" id="file" style={{ display: 'none' }} onChange={event => this.uploadFile(event)} />
                      &nbsp;{editLabel || t('Upload file')}
                    </Menu.Item>

                    <Menu.Item
                      className="turquose-onhover"
                      key="template"
                      position="left"
                      onClick={() => {
                        this.downloadTemplate();
                        this.setState({ uploadMenuOpen: false });
                      }}
                    >
                      <FontAwesomeIcon className="solid float-right" icon={faFileExcel} size="lg" />
                      &nbsp;{editLabel || t('Download Template')}
                    </Menu.Item>
                  </Menu>
                </Popup>
              </Menu.Item>
            )}

            {exportUrl && (
              <Menu.Item className="menu-item-download">
                <Popup
                  position="top right"
                  trigger={
                    <Button icon size="medium" onClick={() => this.exportElements()} className="custom-table-upload-btn black-btn">
                      <FontAwesomeIcon className="solid" icon={faFileDownload} size="lg" />
                    </Button>
                  }
                  content={t('Export')}
                />
              </Menu.Item>
            )}
            {this.state.orderBy.length !== 0 && (
              <Menu.Item fitted>
                <Popup
                  trigger={
                    <Button
                      icon
                      className="custom-table-clear-sorts custom-table-hoverable-btn"
                      basic
                      onClick={e => this.clearAllOrderBy()}
                    >
                      <Icon.Group>
                        <Icon disabled name="sort" />
                        <Icon disabled corner name="remove" />
                      </Icon.Group>
                    </Button>
                  }
                  position="top right"
                  content={t('Clear all orders')}
                />
              </Menu.Item>
            )}
            {getProperties(this.state.filter).length !== 0 && (
              <Menu.Item fitted>
                <Popup
                  trigger={
                    <Button
                      className="custom-table-clear-filters custom-table-hoverable-btn"
                      icon
                      basic
                      onClick={e => this.clearAllFilters()}
                    >
                      <Icon.Group>
                        <Icon disabled name="filter" />
                        <Icon disabled corner name="remove" />
                      </Icon.Group>
                    </Button>
                  }
                  position="top right"
                  content={t('Clear all filters')}
                />
              </Menu.Item>
            )}
            {onSearchFilterChanged && (
              <Menu.Item fitted>
                <Input
                  icon="search"
                  value={this.state.searchFilter}
                  onChange={(e, { value }) => {
                    onSearchFilterChanged(value);
                    this.setState({ searchFilter: value, activePage: 1 });
                  }}
                  placeholder={t('Search...')}
                />
              </Menu.Item>
            )}
            {/* {orderBy.length > 0 && this.props.onOrderByChanged &&
                    <Menu.Item fitted>
                        <Dropdown placeholder='Order by' defaultValue={this.props.model.sort.defaultKey} selection options={orderBy} onChange={(o, { value }) => this.props.onOrderByChanged(value as string, this.props.model.sort.direction || 'Ascending')} selectOnBlur={false} selectOnNavigation={false}/>
                    </Menu.Item>
                } */}
            {/* {this.props.onRefresh && <Menu.Item fitted>
                    <Popup
                        trigger={<Button icon basic onClick={(e) => this.props.onRefresh()}>
                            <Icon name='refresh' />
                        </Button>}
                        content={t('Refresh data')}
                    />
                </Menu.Item>} */}
            {/* {this.props.onOrderByChanged &&
                <Menu.Item fitted>
                    <Popup
                        trigger={<Button icon basic onClick={(e) => this.onSortDirection()}>
                            <Icon name={(this.props.model.sort.direction || 'Ascending') === 'Ascending' ? 'sort alphabet down' : 'sort alphabet up'} />
                        </Button>}
                        content={t('Sort data')}
                    />
                </Menu.Item>} */}
            {(extraMenu || []).map((menuProps, idx) => (
              <Menu.Item key={idx} {...menuProps} fitted />
            ))}
            {allowHidingColumns && (
              <Menu.Item fitted>
                <Popup
                  on="click"
                  position="bottom right"
                  trigger={
                    <Button
                      icon
                      size="medium"
                      className="custom-table-hide-column-btn custom-table-upload-btn black-btn custom-table-hoverable-btn"
                    >
                      <Icon name="columns" size="large" style={{ opacity: 1, color: 'white' }} />
                    </Button>
                  }
                >
                  <b>{t('Show columns')}:</b>
                  <Divider />
                  <Menu vertical secondary>
                    {this.state.columns
                      .filter(c => !c.excludeFromColumnHideTool)
                      .map(column => (
                        <Menu.Item key={column.title}>
                          <Checkbox
                            checked={column.visible || column.visible === undefined}
                            label={column.title}
                            onChange={(e, data) => {
                              column.visible = !!data.checked;
                              this.forceUpdate();
                            }}
                          />
                        </Menu.Item>
                      ))}
                  </Menu>
                </Popup>
              </Menu.Item>
            )}
            {!!canBulkEdit && this.state.data.some(x => x.mode === 'editor') && (
              <Menu.Item fitted>
                <Popup
                  position="top right"
                  trigger={
                    <Button
                      icon
                      primary
                      size="medium"
                      className="custom-table-upload-btn black-btn custom-table-hoverable-btn"
                      onClick={this.saveAllRows}
                    >
                      <Icon name="save" size="large" style={{ opacity: 1, color: 'white' }} />
                    </Button>
                  }
                  content={t('Save all changes')}
                />
              </Menu.Item>
            )}
          </Menu>
        )}

        <div className={`full-width ${!!noFlexContainer ? '' : 'table__flex-container'}`}>
          <SemanticTable key="semantic-table" className={'custom-table ' + customClassName} {...tableProps} selectable={selectable}>
            <SemanticTable.Header>
              <SemanticTable.Row>
                {selectable && <SemanticTable.HeaderCell key="empty_header">&nbsp;</SemanticTable.HeaderCell>}
                {canExpand && (
                  <SemanticTable.HeaderCell className="custom-table-expand-column" key="expander">
                    &nbsp;
                  </SemanticTable.HeaderCell>
                )}

                {columns.map((col, indx) => (
                  <SemanticTable.HeaderCell
                    key={col.title || `col_${indx}`}
                    className={col.headerRenderer || col.title ? col.headerClassName : 'no-width'}
                  >
                    {col.headerRenderer ? (
                      col.headerRenderer(col.title, this.addFilter, this.removeFilter)
                    ) : col.title ? (
                      <b>{col.title}</b>
                    ) : null}
                    {this.getOrderByIcon(col)}
                  </SemanticTable.HeaderCell>
                ))}
                {hasExtraInlineActions && (
                  <SemanticTable.HeaderCell textAlign="right" key="actions1" width={1}>
                    {addLoadInInlineActions ? <Icon name="refresh" className="clickable-icon" onClick={e => onRefresh()} /> : <>&nbsp;</>}
                  </SemanticTable.HeaderCell>
                )}
                {hasActions && (
                  <SemanticTable.HeaderCell textAlign="right" key="actions2" width={1} style={{ width: '3%' }} className="no-left-border">
                    <Icon name="refresh" className="clickable-icon" onClick={e => onRefresh()} />
                  </SemanticTable.HeaderCell>
                )}
                {addEmptyLoadHeader && (
                  <SemanticTable.HeaderCell textAlign="right" key="actions3" width={1} style={{ width: '3%' }} className="no-left-border">
                    <Icon name="refresh" className="clickable-icon" onClick={e => onRefresh()} />
                  </SemanticTable.HeaderCell>
                )}
              </SemanticTable.Row>
            </SemanticTable.Header>
            <SemanticTable.Body>
              {this.state.data.map(data => {
                const goto = this.getOnClick(data);
                return (
                  <React.Fragment key={`row_fragment_${rowKey++}`}>
                    <SemanticTable.Row
                      id={`table_row_${rowKey}`}
                      onKeyDown={this.keyboardShortcutHandler}
                      key={rowKey}
                      onDoubleClick={event => this.getOnDoubleClick(data, event)}
                      disabled={isRowDisabled && isRowDisabled(data.item)}
                      active={data.selected}
                      className={`${goto ? 'clickable-icon' : ''} ${
                        isRowDisableLayout && isRowDisableLayout(data.item) ? 'row-disable-layout' : ''
                      } ${
                        data.mode === 'normal' && canExpand && !!this.state.expandedRows[getKey(data.item)] && model.expandedRenderer
                          ? 'custom-table-expanded-row-parent'
                          : ''
                      } ${
                        this.isSelected(data.item) || (selectedRow && JSON.stringify(selectedRow) === JSON.stringify(data.item))
                          ? 'row-selected'
                          : ''
                      }`}
                    >
                      {selectable && selectionType && selectionType === 'checkbox' && (
                        <SemanticTable.Cell key={`${rowKey}_selection`}>
                          <Checkbox
                            checked={this.isSelected(data.item)}
                            onChange={(e, value) => {
                              this.onSelectionChange(data.item);
                            }}
                          />
                        </SemanticTable.Cell>
                      )}
                      {selectable && (!selectionType || selectionType === 'allRow') && (
                        <SemanticTable.Cell
                          //style={{ width: '1%' }}
                          key={`${rowKey}_notSelection`}
                          className={onHideCheckbox ? 'custom-table__checkbox-invisible' : ''} //style purposes
                          onClick={() => {
                            this.onSelectionChange(data.item);
                          }}
                        >
                          <div className="checkbox-style-faCheck">
                            <FontAwesomeIcon
                              className="solid"
                              style={{
                                visibility: this.isSelected(data.item) ? 'visible' : 'hidden'
                              }}
                              icon={faCheck}
                            />
                          </div>
                        </SemanticTable.Cell>
                      )}

                      {canExpand &&
                        (model.showExpander(data.item) ? (
                          <SemanticTable.Cell onClick={() => this.toggleRowExpand(data.item)}>
                            <Icon
                              className="custom-table-expand-icon"
                              name={!!this.state.expandedRows[getKey(data.item)] ? 'angle down' : 'angle right'}
                            />
                          </SemanticTable.Cell>
                        ) : (
                          <SemanticTable.Cell></SemanticTable.Cell>
                        ))}
                      {columns.map((col, indx) => (
                        <SemanticTable.Cell
                          className={col.cellClassName + col.onClick ? ' clickable-icon' : ''}
                          onClick={() => {
                            this.getOnClick(data);
                            if (col.onClick) {
                              col.onClick(data.item);
                            }
                            if (!selectionType || selectionType === 'allRow') this.onSelectionChange(data.item);
                          }}
                          collapsing={col.collapsing}
                          key={`${rowKey}_` + (col.title || `${indx}`)}
                        >
                          {data.mode === 'editor' && col.editor && col.editor(data.item, () => this.forceUpdate())}
                          {(data.mode === 'normal' || col.editor === undefined) &&
                            (col.renderer === undefined
                              ? data.item
                              : col.tooltipRenderer
                              ? (() =>
                                  col.newClassFromComponent ? (
                                    <TableTooltipCell
                                      key={`${rowKey}_TTCR` + (col?.title || '' + col?.renderer.toString() + indx)}
                                      newClassFromComponent={col.newClassFromComponent(data.item)}
                                      textToShow={col.renderer(data.item, !!this.state.expandedRows[getKey(data.item)])}
                                    />
                                  ) : (
                                    <TableTooltipCell
                                      key={`${rowKey}_TTC` + (col?.title || '' + col?.renderer.toString() + indx)}
                                      textToShow={col.renderer(data.item, !!this.state.expandedRows[getKey(data.item)])}
                                    />
                                  ))()
                              : col.renderer(data.item, !!this.state.expandedRows[getKey(data.item)]))}
                        </SemanticTable.Cell>
                      ))}
                      {hasExtraInlineActions && (
                        <SemanticTable.Cell
                          style={{ lineHeight: 0 }}
                          verticalAlign="middle"
                          textAlign="right"
                          className={addLoadInInlineActions ? '' : 'padding-right-zero'}
                        >
                          <Button.Group className="custom-table-extra-inline-actions" basic>
                            {extraInlineActions
                              .filter(({ visible }) => visible(data.item))
                              .map(({ content, onClick, disabled, checked, onChange }, idx) => {
                                if (content === 'toggle') {
                                  return (
                                    <Checkbox
                                      key={`extra_inline_action_${idx}`}
                                      toggle
                                      defaultChecked={checked ? checked(data.item) : undefined}
                                      disabled={disabled ? disabled(data.item) : undefined}
                                      onChange={(e, o) => onChange && onChange(data.item, o.checked)}
                                    />
                                  );
                                } else {
                                  return (
                                    <Button
                                      basic
                                      key={`extra_inline_action_${idx}`}
                                      disabled={disabled ? disabled(data.item) : undefined}
                                      onClick={() => onClick && onClick(data.item)}
                                      icon={content}
                                      style={{
                                        cursor: onClick == null ? 'default' : 'pointer'
                                      }}
                                    />
                                  );
                                }
                              })}
                          </Button.Group>
                        </SemanticTable.Cell>
                      )}
                      {hasActions && !useInlineEditionV2 && !(isHideExtraActions && isHideExtraActions(data.item)) && (
                        <SemanticTable.Cell
                          disabled={areActionsDisabled && areActionsDisabled(data.item)}
                          key={`${rowKey}_editor`}
                          textAlign="right"
                          className="no-left-border"
                        >
                          {data.mode === 'normal' && (
                            <Popup
                              key={`${rowKey}_Popup_Normal`}
                              disabled={areActionsDisabled && areActionsDisabled(data.item)}
                              on="click"
                              position="bottom right"
                              open={data.actionsMenuOpened}
                              onOpen={() => {
                                this.state.data.filter(d => d !== data).forEach(d => (d.actionsMenuOpened = false));
                                data.actionsMenuOpened = true;
                                this.forceUpdate();
                              }}
                              onClose={() => {
                                data.actionsMenuOpened = false;
                                this.forceUpdate();
                              }}
                              trigger={
                                <Icon
                                  style={{
                                    fontSize: '15px',
                                    color: areActionsDisabled && areActionsDisabled(data.item) ? '#EEEEEE' : '#474b4f'
                                  }}
                                  name="ellipsis vertical"
                                  className="clickable-icon"
                                />
                              }
                            >
                              {/* <b>{t("Actions")}:</b>
                                <Divider /> */}

                              {this.extraActionsRenderer(data)}
                            </Popup>
                          )}
                          {data.mode === 'editor' && (
                            <Menu className="custom-table-actions no-margin-bottom" stackable secondary>
                              <Menu.Item fitted>
                                <Popup
                                  key={`${rowKey}_Popup_Edit_save`}
                                  position="top right"
                                  trigger={<Button primary icon="save" onClick={e => this.onSaveRow(data)} />}
                                  content={t('Save changes')}
                                />
                              </Menu.Item>
                              <Menu.Item fitted>
                                <Popup
                                  key={`${rowKey}_Popup_Cancel`}
                                  position="top right"
                                  trigger={<Button icon="cancel" onClick={e => this.onDiscardRow(data)} />}
                                  content={t('Discard changes')}
                                />
                              </Menu.Item>
                            </Menu>
                          )}
                        </SemanticTable.Cell>
                      )}
                      {isHideExtraActions && isHideExtraActions(data.item) && (
                        <SemanticTable.Cell onClick={() => onClickColApprovedReject && onClickColApprovedReject(data.item)}>
                          <a className="clickable-icon">{'Approve/Reject'}</a>
                        </SemanticTable.Cell>
                      )}

                      {useInlineEditionV2 && (
                        <SemanticTable.Cell key={`${rowKey}_editor`} textAlign="right" className="no-left-border">
                          {data.mode === 'normal' && (
                            <Popup
                              key={`${rowKey}_Popup_Normal`}
                              position="top right"
                              trigger={
                                <Icon
                                  style={{
                                    fontSize: '14px',
                                    color: '#474b4f'
                                  }}
                                  name="pencil"
                                  className="clickable-icon edit-table-v2-btn"
                                  onClick={() => {
                                    data.actionsMenuOpened = false;
                                    data.mode = 'editor';
                                    data.selected = true;
                                    this.forceUpdate();
                                  }}
                                />
                              }
                              content={t('Edit')}
                            ></Popup>
                          )}
                          {data.mode === 'editor' && (
                            <Menu className="custom-table-actions no-margin-bottom padding-zero" stackable secondary>
                              <Menu.Item fitted>
                                <Popup
                                  key={`${rowKey}_Popup_edit_save`}
                                  position="top right"
                                  trigger={
                                    <Icon
                                      style={{ fontSize: '14px', color: '#474b4f' }}
                                      name="save"
                                      className="clickable-icon save-table-v2-btn"
                                      onClick={e => this.onSaveRow(data)}
                                    />
                                  }
                                  content={t('Save changes')}
                                />
                              </Menu.Item>
                              <Menu.Item fitted>
                                <Popup
                                  key={`${rowKey}_Popup_edit_discard`}
                                  position="top right"
                                  trigger={
                                    <Icon
                                      style={{
                                        fontSize: '14px',
                                        color: '#474b4f'
                                      }}
                                      name="cancel"
                                      className="clickable-icon discard-table-v2-btn"
                                      onClick={e => this.onDiscardRow(data)}
                                    />
                                  }
                                  content={t('Discard changes')}
                                />
                              </Menu.Item>
                            </Menu>
                          )}
                        </SemanticTable.Cell>
                      )}
                      {addEmptyLoadHeader && <SemanticTable.Cell />}
                    </SemanticTable.Row>
                    {canExpand &&
                      !!this.state.expandedRows[getKey(data.item)] &&
                      model.expandedRenderer &&
                      model.expandedRenderer(data.item)}
                  </React.Fragment>
                );
              })}
            </SemanticTable.Body>
          </SemanticTable>

          <Modal
            key="modal1"
            open={this.state.showDeleteConfirm}
            size="mini"
            className="have-warnings-popup"
            closeOnEscape={true}
            onClose={this.hideConfirmModal}
          >
            <Modal.Content className="have-warnings-popup__content">
              <p>{deleteConfirmationMessage ? deleteConfirmationMessage : t('Are you sure that you want to delete the item?')}</p>
            </Modal.Content>
            <div className="have-warnings-popup__buttons-container">
              <Button className="have-warnings-popup__btn" content={t('No')} onClick={this.hideConfirmModal} />
              <Button className="have-warnings-popup__btn have-warnings-popup__pending-btn" content={t('Yes')} onClick={this.onDeleteRow} />
            </div>
          </Modal>

          <Modal
            key="modal2"
            className="custom-table-upload-result-modal"
            open={this.state.uploadComplete}
            closeOnEscape={true}
            onClose={() => this.setState({ uploadComplete: false })}
            closeOnDimmerClick={false}
          >
            <Modal.Content>
              <Container>
                <Message
                  info={(this.state.messageError || []).length > 0}
                  success={(this.state.messageError || []).length === 0}
                  header={t('Imported Result')}
                  list={[
                    `${t('Total elements')}: ${this.state.totalElements}`,
                    `${t('Imported elements')}: ${this.state.importedElements}`
                  ]}
                />

                {this.state.messageError.length > 0 && (
                  <Button className="custom-table-add-btn" icon size="mini" onClick={e => this.downloadTxtFile()}>
                    {t('Download error logs')} &nbsp;&nbsp;
                    <Icon circular size="tiny" name="download" />
                  </Button>
                )}
                {this.state.messageError.length > 0 && (
                  <div style={{ overflow: 'auto', maxHeight: '40vh', padding: '0 1em', marginTop: '1em' }}>
                    <Message
                      className="error-message__style"
                      icon="exclamation circle"
                      error
                      header={t('Messages')}
                      list={this.state.messageError.map(o => {
                        return o === 'Machine Model already exists with the same name but different EQ Type-OEM relation.'
                          ? 'Machine Model already exists with the same name and same EQ Type-OEM relation.'
                          : o;
                      })}
                    />
                  </div>
                )}
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <Button className="positive" onClick={() => this.setState({ uploadComplete: false })}>
                {t('OK')}
              </Button>
            </Modal.Actions>
          </Modal>
        </div>

        {onPageChange && (
          <>
            <div className={`table-general__pagination-container ${!!noFlexContainer ? 'margin-top-2em' : ''}`}>
              <Pagination
                className="custom-table-pagination"
                activePage={this.state.activePage}
                size="mini"
                onPageChange={this.handlePaginationChange}
                totalPages={this.state.totalPages}
                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
              />

              <Select
                className="custom-table-pagination-select"
                fluid
                value={this.state.pageSize}
                options={tOptions}
                onChange={this.handlePageSizeChange}
                selectOnNavigation={false}
                selectOnBlur={false}
              />
              <div className="custom-table-pagination-total-rows">
                {this.state.totalRecords} {`record${this.state.totalRecords === 1 ? '' : 's'}`}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export const TableView = withTranslation()(Table);
