import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Form, Input, Message, Modal, Dimmer, Loader, Dropdown, Checkbox, Container } from 'semantic-ui-react';
import { ItemReference } from 'stores/dataStore';
import LocationEditor from 'widgets/bussiness/location-editor';
import PersonnelAreaEditor, { PersonnelAreaItemReference } from 'widgets/bussiness/personnel-area-editor';
import PositionCodeEditor from 'widgets/bussiness/position-code-editor';
import RoleEditor, { RoleLocationsOrRegionsSelection } from 'widgets/bussiness/roles-editor';
import { CreateUserDto, NewUserStore, CreateRoleInLocationDto } from '../../../stores/users/users-store';
import { nameof } from '../../../utils/object';
import '../../../assets/less/new-user-modal.less';
import { LanguageDto, DropdownLanguagesStore } from 'stores/configuration/locations/languages-store';

interface NewUserViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newUser?: NewUserStore;
  newUserFromImport?: CreateUserDto;
  languageStore?: DropdownLanguagesStore;
}

interface NewUserViewState {
  location: ItemReference;
  positionCode: ItemReference;
  personnelArea: ItemReference;
  languageOptions: { text: string; value: string }[];
  availableLanguages: { [id: string]: LanguageDto };
  userItem: CreateUserDto;
}

@connect(['newUser', NewUserStore], ['languageStore', DropdownLanguagesStore])
class NewUserView extends React.Component<NewUserViewProps, NewUserViewState> {
  private get newUserStore() {
    return this.props.newUser;
  }

  constructor(props: NewUserViewProps) {
    super(props);

    const emptyDto: CreateUserDto = {
      firstName: '',
      lastName: '',
      enabled: true,
      employeeId: '',
      personnelAreaId: '',
      personnelArea: '',
      region: '',
      origin: '',
      sfPosition: '',
      positionCodeId: '',
      positionCode: null,
      locationId: '',
      location: '',
      password: '',
      portrait: '',
      roles: [],
      userName: '',
      email: '',
      lineManager: '',
      acronym: '',
      languages: [],
      hireDate: '',
      isGlobalPoc: false
    };
    let importedDto: CreateUserDto;

    if (this.props.newUserFromImport) {
      importedDto = {
        firstName: this.props.newUserFromImport.firstName || '',
        lastName: this.props.newUserFromImport.lastName || '',
        enabled: !!this.props.newUserFromImport.enabled,
        employeeId: this.props.newUserFromImport.employeeId || '',
        personnelAreaId: this.props.newUserFromImport.personnelAreaId || '',
        personnelArea: this.props.newUserFromImport.personnelArea || '',
        region: this.props.newUserFromImport.region || '',
        origin: this.props.newUserFromImport.origin || '',
        sfPosition: this.props.newUserFromImport.sfPosition || '',
        positionCodeId: this.props.newUserFromImport.positionCodeId || '',
        positionCode: this.props.newUserFromImport.positionCode || null,
        locationId: this.props.newUserFromImport.locationId || '',
        location: this.props.newUserFromImport.location || '',
        password: '',
        portrait: '',
        roles: this.props.newUserFromImport.roles || [],
        userName: this.props.newUserFromImport.userName || '',
        email: this.props.newUserFromImport.email || '',
        lineManager: this.props.newUserFromImport.lineManager || '',
        acronym: '',
        languages: this.props.newUserFromImport.languages || [],
        hireDate: this.props.newUserFromImport.hireDate || null,
        isGlobalPoc: !!this.props.newUserFromImport.isGlobalPoc
      };
    }

    this.state = {
      location: null,
      positionCode: null,
      personnelArea: null,
      availableLanguages: {},
      languageOptions: [],
      userItem: this.props.newUserFromImport ? importedDto : emptyDto
    };
    this.newUserStore.createNew(this.state.userItem);
    this.initLanguages();
  }

  private onCreateNewItem = () => {
    this.newUserStore.createNew(this.state.userItem);
    this.newUserStore.submit().then(x => {
      if (this.newUserStore.state.result && this.newUserStore.state.result.isSuccess) {
        this.props.onClose(true);
      }
    });
  };

  @autobind
  private onCancelNewItem() {
    this.newUserStore.clear();
    this.props.onClose(false);
  }

  private handleValue = (property: keyof CreateUserDto, value: any) => {
    const change = {};
    change[property] = value;

    if (property === 'roles') {
      if (!value.any(r => r.roleName === 'PoC')) {
        const userItem = { ...this.state.userItem };
        userItem.isGlobalPoc = false;
        this.setState({ userItem });
      }
    }

    this.setState(({ userItem }) => ({ userItem: { ...userItem, ...change } }));
  };

  private handlePersonnelAreaChange = (personnelArea: PersonnelAreaItemReference) => {
    let location: ItemReference = null;

    if (personnelArea?.locationId) location = { id: personnelArea?.locationId, title: personnelArea?.locationName };

    const changes = { personnelArea: personnelArea?.title || '', personnelAreaId: personnelArea?.id || '', locationId: location?.id || '' };

    this.setState(({ userItem }) => ({ personnelArea, location, userItem: { ...userItem, ...changes } }));
  };

  private handleLocation = (property: keyof CreateUserDto, location: any) => {
    this.setState({ location }, () => this.handleValue('locationId', location?.id || null));
  };

  private handleLanguages(value: any) {
    this.handleValue(nameof<CreateUserDto>('languages'), this.mapToLanguages(value));
  }

  private mapToLanguages(value): LanguageDto[] {
    let selectedLanguages: LanguageDto[] = [];

    value.forEach(item => {
      let language = this.state.availableLanguages[item];
      selectedLanguages.push(language);
    });

    return selectedLanguages;
  }

  private get languageStore() {
    return this.props.languageStore;
  }

  private async initLanguages() {
    return await this.languageStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [
          {
            direction: 'Ascending',
            field: nameof<LanguageDto>('language'),
            useProfile: false
          }
        ]
      })
      .then(languages => {
        const dict = {};
        const options = [];

        languages.items.forEach(language => {
          language.languageId = language.id;
          dict[language.id] = language;
          options.push({ text: language.language, value: language.id });
        });

        this.setState({
          availableLanguages: dict,
          languageOptions: options
        });
      });
  }

  componentDidMount() {
    if (this.props.newUserFromImport) {
      this.setState({
        location: { id: this.props.newUserFromImport.locationId, title: this.props.newUserFromImport.location },
        positionCode: { id: this.props.newUserFromImport.positionCodeId, title: this.props.newUserFromImport.positionCode },
        personnelArea: { id: this.props.newUserFromImport.personnelAreaId, title: this.props.newUserFromImport.positionCode }
      });
    }
  }

  public render() {
    const { t } = this.props as any;
    const languageOptions = this.state.languageOptions;
    return (
      <Modal className="user-modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.newUserStore.state.isBusy} page style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('New user')}</Modal.Header>
        <Modal.Content image>
          <Container className="user-modal__wrapper">
            {this.newUserStore.state.result && !this.newUserStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.newUserStore.state.result.messages.map(o => o.body)}
              />
            )}
            {this.state.userItem && (
              <Form>
                <div className="new-user-modal__section-label">Personal Information</div>
                <div className="user-modal_checkbox-container">
                  <Form.Field className="user-modal_checkbox-field" inline>
                    <label>{t('Active')}</label>
                    <Checkbox
                      className="user-modal__checkbox-toggle"
                      toggle
                      checked={this.state.userItem.enabled}
                      onChange={(e, { checked }) => this.handleValue(nameof<CreateUserDto>('enabled'), checked)}
                    />
                  </Form.Field>
                </div>
                <div className="new-user-modal__inputs-container">
                  <div className="new-user-modal__inputs-grid-container">
                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required
                      error={this.newUserStore.state.result && !this.state.userItem.firstName}
                    >
                      <label>{t('First Name')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('First Name')}
                        value={this.state.userItem.firstName}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateUserDto>('firstName'), value)}
                      />
                    </Form.Field>
                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required
                      error={this.newUserStore.state.result && !this.state.userItem.lastName}
                    >
                      <label>{t('Last Name')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('Last Name')}
                        value={this.state.userItem.lastName}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateUserDto>('lastName'), value)}
                      />
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required
                      error={this.newUserStore.state.result && !this.state.userItem.userName}
                    >
                      <label>{t('Username')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('Username')}
                        value={this.state.userItem.userName}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateUserDto>('userName'), value)}
                      />
                    </Form.Field>
                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required
                      error={this.newUserStore.state.result && !this.state.userItem.email}
                    >
                      <label>{t('Email')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('Email')}
                        value={this.state.userItem.email}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateUserDto>('email'), value)}
                      />
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required
                      error={this.newUserStore.state.result && !this.state.userItem.password}
                    >
                      <label>{t('Password')}</label>
                      <Input
                        className="planit-users-inputs"
                        type="password"
                        error={this.newUserStore.state.result && !this.state.userItem.password}
                        placeholder={t('Password')}
                        value={this.state.userItem.password}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateUserDto>('password'), value)}
                      />
                    </Form.Field>
                    <Form.Field
                      className="planit-users-fields"
                      inline
                      required
                      error={this.newUserStore.state.result && !this.state.userItem.lineManager}
                    >
                      <label>{t('Line Manager')}</label>
                      <Input
                        className="planit-users-inputs"
                        error={this.newUserStore.state.result && !this.state.userItem.lineManager}
                        placeholder={t('Line Manager')}
                        value={this.state.userItem.lineManager}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateUserDto>('lineManager'), value)}
                      />
                    </Form.Field>

                    <Form.Field
                      className="planit-users-fields new-user-modal__location-select"
                      inline
                      required
                      error={this.newUserStore.state.result && !this.state.userItem.locationId}
                    >
                      <label className="new-user-modal__location-select-label">{t('Location')}</label>
                      <LocationEditor
                        className="location-editor__wrapper planit-users-inputs"
                        value={this.state.location}
                        nullable
                        onChange={location => this.handleLocation('locationId', location)}
                      />
                    </Form.Field>

                    <Form.Field className="planit-users-fields  new-user-modal__language" inline>
                      <label>{t('Languages')}</label>
                      <Dropdown
                        search
                        inline
                        selection
                        clearable
                        options={languageOptions}
                        multiple={true}
                        className="planit-user-dropdown planit-users-inputs"
                        onChange={(e, { value }) => this.handleLanguages(value)}
                        placeholder={t('Select languages')}
                      />
                    </Form.Field>
                  </div>
                </div>
                <div className="new-user-modal__section-label">SAP Information</div>
                <div className="new-user-modal__inputs-container">
                  <div className="new-user-modal__inputs-grid-container">
                    <Form.Field className="planit-users-fields" inline>
                      <label>{t('Employee SAP ID')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('Employee SAP ID')}
                        value={this.state.userItem.employeeId}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateUserDto>('employeeId'), value)}
                      />
                    </Form.Field>
                    <Form.Field className="planit-users-fields" inline>
                      <label>{t('Personnel Area')}</label>
                      <PersonnelAreaEditor
                        className="location-editor__wrapper planit-users-inputs"
                        nullable
                        value={this.state.personnelArea}
                        onChange={this.handlePersonnelAreaChange}
                      />
                    </Form.Field>

                    <Form.Field className="planit-users-fields" inline>
                      <label>{t('SF Position')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('SF Position')}
                        value={this.state.userItem.sfPosition}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateUserDto>('sfPosition'), value)}
                      />
                    </Form.Field>
                    <Form.Field className="planit-users-fields new-user-modal__position-code" inline>
                      <label>{t('Position Code')}</label>
                      <PositionCodeEditor
                        className=""
                        onChange={positionCode =>
                          this.handleValue(nameof<CreateUserDto>('positionCodeId'), positionCode ? positionCode.id : null)
                        }
                        value={this.state.userItem.positionCodeId}
                      />
                    </Form.Field>
                    <Form.Field className="planit-users-fields" inline>
                      <label>{t('Origin')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('Origin')}
                        value={this.state.userItem.origin}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateUserDto>('origin'), value)}
                      />
                    </Form.Field>
                    <Form.Field className="planit-users-fields" inline>
                      <label>{t('Region')}</label>
                      <Input
                        className="planit-users-inputs"
                        placeholder={t('Region')}
                        value={this.state.userItem.region}
                        onChange={(e, { value }) => this.handleValue(nameof<CreateUserDto>('region'), value)}
                      />
                    </Form.Field>
                  </div>
                </div>
                <div className="new-user-modal__section-label">Roles</div>
                <div className="new-user-modal__roles-wrapper">
                  <RoleEditor
                    userAction={'New'}
                    isSubmited={!!this.newUserStore.state.result}
                    value={this.state.userItem.roles}
                    isActiveFilter={true}
                    hideLabel={true}
                    onChange={roleInLocation => {
                      this.handleValue(nameof<CreateUserDto>('roles'), this.mapToCreateRoleInLocation(roleInLocation));
                    }}
                    onChangeGlobalPoc={isGlobalPoc => this.handleValue('isGlobalPoc', isGlobalPoc)}
                  />
                </div>
                <Modal.Actions>
                  <Button onClick={this.onCancelNewItem} basic>
                    {t('Cancel')}
                  </Button>
                  <Button onClick={this.onCreateNewItem} positive>
                    {t('Save')}
                  </Button>
                </Modal.Actions>
              </Form>
            )}
          </Container>
        </Modal.Content>
      </Modal>
    );
  }

  isValidRoleField() {
    return this.state.userItem.roles.length > 0;
  }

  mapToCreateRoleInLocation(roleInLocations: RoleLocationsOrRegionsSelection[]): CreateRoleInLocationDto[] {
    let createRoleInLocationDtos = [];

    roleInLocations.forEach(roleInLocation => {
      if (!roleInLocation.selected) return;

      if (!roleInLocation.getLocationsOrRegions() || roleInLocation.getLocationsOrRegions().length <= 0) {
        let roleWithoutLocationDto: CreateRoleInLocationDto = {
          locationId: null,
          regionId: null,
          roleName: roleInLocation.role.name
        };

        createRoleInLocationDtos.push(roleWithoutLocationDto);
        return;
      }

      roleInLocation.getLocationsOrRegions().forEach(item => {
        let createRoleInLocationDto: CreateRoleInLocationDto = {
          locationId: 'location' in item ? item.id : null,
          regionId: 'location' in item ? null : item.id,
          roleName: roleInLocation.role.name
        };

        createRoleInLocationDtos.push(createRoleInLocationDto);
      });
    });

    return createRoleInLocationDtos;
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewUserView);
