import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import i18n from 'i18n';
import { SsaDevelopmentPlanDto, SsaDevelopmentPlanStore } from 'stores/development-plan/ssa-development-plan.store';
import { TableColumn, TableModel, TableView } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import DevelopmentPlanStatus from 'site/pages/development-plan/shared/development-plan-status';
import { openEmployeePage } from 'utils/userinfo-functions';
import './individual-tab-pane-list.less';
import { Grid, Icon } from 'semantic-ui-react';
import EditDevelopmentPlanModal from './modals/edit-development-plan.modal';
import { resolve } from 'inversify.config';
import { IdentityService, Roles } from 'services/identity-service';

export interface IndividualTabPaneListProps extends WithTranslation {
  ssaDevelopmentPlanStore?: SsaDevelopmentPlanStore;
  onPageChanged?: (skip: number, take: number) => void;
  onRefresh?: () => void;
}

interface IndividualTabPaneListState {
  openEditModal: boolean;
  selectedItem: SsaDevelopmentPlanDto;
}

@connect(['ssaDevelopmentPlanStore', SsaDevelopmentPlanStore])
class IndividualTabPaneList extends Component<IndividualTabPaneListProps, IndividualTabPaneListState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: IndividualTabPaneListState = {
    openEditModal: false,
    selectedItem: null
  };

  private columns: TableColumn<SsaDevelopmentPlanDto>[] = [
    {
      title: i18n.t('Employee'),
      tooltipRenderer: true,
      renderer: data => data.user && `${data.user.firstName} ${data.user.lastName}`
    },
    {
      title: i18n.t('SAP ID'),
      tooltipRenderer: true,
      renderer: data => data.user && data.user.employeeId
    },
    {
      title: i18n.t('SF Position'),
      tooltipRenderer: true,
      renderer: data => data.user && data.user.sfPosition
    },
    {
      title: i18n.t('Location'),
      tooltipRenderer: true,
      renderer: data => data.user && data.user.location && data.user.location.location
    },
    {
      title: i18n.t('Profile'),
      tooltipRenderer: true,
      renderer: data => data.profileName
    },
    {
      title: i18n.t('Skill Category'),
      tooltipRenderer: true,
      renderer: data => data.ssaSkill && data.ssaSkill.testCategory && data.ssaSkill.testCategory.name
    },
    {
      title: i18n.t('Skill'),
      tooltipRenderer: true,
      renderer: data => data.ssaSkill && data.ssaSkill.name
    },
    {
      title: i18n.t('Activity'),
      tooltipRenderer: true,
      renderer: data => data.ssaActivityItem && data.ssaActivityItem.title
    },
    {
      title: i18n.t('Activity Details'),
      tooltipRenderer: true,
      renderer: data => data.activityDetails
    },
    {
      title: i18n.t('Year'),
      tooltipRenderer: true,
      renderer: data => data.deadlineYear
    },
    {
      title: i18n.t('Status'),
      tooltipRenderer: true,
      renderer: data => <DevelopmentPlanStatus outOfDate={data.outOfDate} status={data.status} />
    }
  ];

  private handleOnDeleteRow = (item: SsaDevelopmentPlanDto, _: any) => {
    return this.props.ssaDevelopmentPlanStore.deleteAsync(item.id);
  };

  private handleOnEditItem = item => {
    this.setState({ openEditModal: true });
  };

  private handleOnCloseEditDevelopmentPlan = () => {
    this.setState({ openEditModal: false });
    this.props.onRefresh();
  };
  handleOnEnterKeydown = item => {
    openEmployeePage((item as SsaDevelopmentPlanDto).userId);
  };
  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  render() {
    const { t } = this.props;
    const tableModel: TableModel<SsaDevelopmentPlanDto> = {
      columns: this.columns,
      data: this.props.ssaDevelopmentPlanStore.state
    };

    const canManageDevelopmentPlans = this.identityService.isInAnyRole([Roles.Admin, Roles.PoC, Roles.FactoryLead]);

    const options: { content: React.ReactNode; onClick: (item: unknown) => void; isVisible?: (item: unknown) => boolean }[] = [
      {
        content: (
          <>
            <Icon name="eye" />
            {<span className="text__bold">{t('Open Employee Card')}</span>}
          </>
        ),
        onClick: item => openEmployeePage((item as SsaDevelopmentPlanDto).userId)
      }
    ];

    if (canManageDevelopmentPlans)
      options.push({
        content: (
          <>
            <Icon name="pencil" />
            {<span className="text__bold">{t('Edit')}</span>}
          </>
        ),
        onClick: (item: SsaDevelopmentPlanDto) => {
          this.setState({ selectedItem: item }, () => this.handleOnEditItem(item));
        }
      });

    return (
      <div className="ssa-invidual-tab-pane__list">
        <Grid className="event-types-list-grid">
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.openEditModal}
              onHideCheckbox={true}
              selectionType={'allRow'}
              //maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              extraActions={options}
              model={tableModel}
              onRefresh={this.props.onRefresh}
              onPageChange={this.props.onPageChanged}
              canDelete={canManageDevelopmentPlans}
              onDeleteRow={this.handleOnDeleteRow}
            ></TableView>
          </Grid.Row>
        </Grid>
        {this.state.selectedItem && this.state.openEditModal && (
          <EditDevelopmentPlanModal
            open={this.state.openEditModal}
            developmentPlan={this.state.selectedItem}
            onClose={this.handleOnCloseEditDevelopmentPlan}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(IndividualTabPaneList);
