import { EventTypeDto, EventFieldGroups } from '../configuration/events-workflow/event-types-store';
import { Categories, RequestMachinesDto } from '../requests/requests-store';
import { AbstractValidator, ValidationFailure } from 'fluent-ts-validator';
import i18n from '../../i18n';
import { isNullOrWhiteSpaces } from '../../utils/useful-functions';
import { FormStore } from '../formStore';
import { repository } from 'redux-scaffolding-ts';
import { ItemReference } from 'stores/dataStore';
import { CreateEventTrainingDetailsDto, CreateTrainingMaterialDto, DateTimePeriod } from './events-store';
// import { MachineListItemViewModel } from 'widgets/items-list/machine-list-item-deprecated';
import { MachineListItemViewModel } from '../../site/pages/shared/events-and-requests/machine-list-item';
import { EventInstructorRow } from 'site/pages/events/event-instructors';
import { StudentDto } from 'stores/students/student-model';
import ExtendedAbstractValidator from 'utils/extended-abstract-validator';
import { DateTimeService } from 'services/datetime-service';

export interface CreateRequestMergeEventItemDto {
  requestIds: string[];
  newEvent: CreateEventDto;
}

export type DateWarningType = { type: 'DURATION' | 'CALCULATION'; text: string };
export interface EventWizardData {
  eventType: EventTypeDto;
  location: ItemReference;
  priority: ItemReference;
  category: Categories;
  profession: ItemReference;
  isMachineRelated: boolean;

  nmrClusterId: string;
  nMRFunctionalAreaId: string;
  nMRTrainingNameId: string;
  nMRFunctionalSubAreaId: string;
  nmrCluster: ItemReference;
  nmrFunctionalArea: ItemReference;
  nmrFunctionalSubArea: ItemReference;
  nmrTrainingName: ItemReference;

  instructors: EventInstructorRow[];

  usePattern: boolean;
  hasModifiedPattern: boolean;
  pattern: ItemReference;
  requestMachines: MachineListItemViewModel[];
  plcNames: string[];
  eventDuration: string;
  dateFrom: string;
  dateTo: string;
  plannedDuration: string;
  shouldRecalculate: boolean;

  userEventDuration: number;
  calculatedEventDuration: number;

  eventTitle: string;
  manualTitle: boolean;
  customizedData: ItemReference;
  deliveryMethod: ItemReference;
  language: ItemReference;
  trainingLevel: ItemReference;
  students: StudentDto[];
  numStudentsAssigned?: number;

  comments: string;
  parentRequestId: string;
  trainingMaterials: CreateTrainingMaterialDto[];
  travelDays?: DateTimePeriod;
  pausePeriods: DateTimePeriod[];
  eventWarnings: CreateEventWarningDto[];
}

export class WizardDataNonMachineRelatedStepValidator extends ExtendedAbstractValidator<EventWizardData> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('No Data Provided'));

    this.validateIfString(o => o.nmrClusterId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(o => o.nMRFunctionalAreaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Functional Area is required'));

    this.validateIfString(o => o.nMRTrainingNameId)
      .isUuid('4')
      .when(x => !isNullOrWhiteSpaces(x.nMRTrainingNameId))
      .withFailureMessage(i18n.t('Invalid Training Name'));
    this.validateIfString(o => o.nMRFunctionalSubAreaId)
      .isUuid('4')
      .when(x => !isNullOrWhiteSpaces(x.nMRFunctionalSubAreaId))
      .withFailureMessage(i18n.t('Invalid Functional Subarea'));
  }
}

export class MachineRowHasRequiredFields extends ExtendedAbstractValidator<MachineListItemViewModel> {
  constructor(idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`MachineRow at position ${idx + 1}`);
    this.validateIfString(x => x && x.cluster && x.cluster.id)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Cluster is required')}`);

    this.validateIfString(x => x.equipmentType && x.equipmentType.id)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Equipment Type is required')}`);
  }
}

export class MachineRowNoPatternHasRequiredFields extends ExtendedAbstractValidator<MachineListItemViewModel> {
  constructor(idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`MachineRow at position ${idx + 1}`);
    this.validateIfString(x => x && x.cluster && x.cluster.id)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Cluster is required')}`);

    this.validateIfString(x => x.equipmentType && x.equipmentType.id)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Equipment Type is required')}`);

    this.validateIfString(x => x && x.oem && x.oem.id)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('OEM is required')}`);

    this.validateIfString(x => x && x.machineModel && x.machineModel.id)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Machine Model is required')}`);
  }
}

export class WizardDataMachineRelatedStepValidator extends ExtendedAbstractValidator<EventWizardData> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(o => o.requestMachines)
      .isNotEmpty()
      .when(t => !t.usePattern)
      .withFailureMessage(i18n.t('Machine rows are required.'));
    this.validateIf(o => o.requestMachines)
      .isNotEmpty()
      .when(t => t.usePattern)
      .withFailureMessage(i18n.t('Pattern is required.'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(x =>
        (x.requestMachines || []).all(
          (i, idx) => i != null && new MachineRowNoPatternHasRequiredFields(idx, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(t => !t.hasModifiedPattern)
      .withFailureMessage(i18n.t('At least one machine row is invalid.'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(x =>
        (x.requestMachines || []).all(
          (i, idx) => i != null && new MachineRowHasRequiredFields(idx, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(t => t.hasModifiedPattern)
      .withFailureMessage(i18n.t('At least one machine row is invalid.'));
  }
}

export class WizardDataBasicStepValidator extends ExtendedAbstractValidator<EventWizardData> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('No Data Provided'));

    this.validateIf(x => x.eventType)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('Event Type is required'));

    this.validateIfString(x => x.eventType.id)
      .isNotEmpty()
      .isUuid('4')
      .when(x => !!x.eventType)
      .withFailureMessage(i18n.t('Event Type is required'));

    this.validateIf(x => x.isMachineRelated)
      .isNotNull()
      .isDefined()
      .when(
        x =>
          x.eventType != null &&
          (x.eventType.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.RequestDetails]) &&
          x.eventType.name.toLocaleLowerCase().includes('reserved') &&
          x.category != null
      )
      .withFailureMessage(i18n.t('No Machine relation provided'));

    this.validateIf(o => o.location)
      .isDefined()
      .isNotNull()
      .when(x => x.eventType != null && (x.eventType.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.RequestDetails]))
      .withFailureMessage(i18n.t('Event Location is required'));

    this.validateIfString(o => o.location.id)
      .isNotEmpty()
      .isUuid('4')
      .when(
        x =>
          x.eventType != null &&
          x.location != null &&
          (x.eventType.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.RequestDetails])
      )
      .withFailureMessage(i18n.t('Event Location is required'));

    this.validateIf(o => o.category)
      .isIn([Categories.Functional, Categories.Technical])
      .when(
        x =>
          x.eventType != null &&
          (x.eventType.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.RequestDetails]) &&
          x.eventType.name.toLocaleLowerCase().includes('reserved') &&
          x.isMachineRelated != null
      )
      .withFailureMessage(i18n.t('Category is required'));

    this.validateIfString(o => o.priority.id)
      .isNotNull()
      .isUuid('4')
      .when(
        x =>
          x.priority != null &&
          !isNullOrWhiteSpaces(x.priority.id) &&
          x.eventType != null &&
          (x.eventType.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.RequestDetails])
      )
      .withFailureMessage(i18n.t('Invalid Priority'));

    this.validateIf(o => o.profession)
      .isDefined()
      .isNotNull()
      .when(
        x =>
          x.eventType != null &&
          (x.eventType.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.RequestDetails]) &&
          !x.eventType.name.toLocaleLowerCase().includes('reserved')
      )
      .withFailureMessage(i18n.t('Role is required'));

    this.validateIfString(o => o.profession.id)
      .isNotEmpty()
      .isUuid('4')
      .when(
        x =>
          x.eventType != null &&
          x.profession != null &&
          (x.eventType.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.RequestDetails])
      )
      .withFailureMessage(i18n.t('Role is required'));
  }
}

export class WizardDataEventDetailsStepValidator extends ExtendedAbstractValidator<EventWizardData> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('No Data Provided'));

    this.validateIf(x => x.eventType)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('Event Type is required'));

    this.validateIfString(x => x.eventTitle)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Event Title is required'));

    this.validateIf(x => x.trainingLevel)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('Training Level is required'));

    this.validateIfString(o => o.trainingLevel.id)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x.trainingLevel != null)
      .withFailureMessage(i18n.t('Training Level is required'));

    this.validateIf(x => x.deliveryMethod)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('Delivery Method is required'));

    this.validateIfString(o => o.deliveryMethod.id)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x.deliveryMethod != null)
      .withFailureMessage(i18n.t('Delivery Method is required'));
  }
}

export class WizardDataEventStudentsStepValidator extends ExtendedAbstractValidator<EventWizardData> {
  constructor(onErrors?: (students, ...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIfNumber(t => t.numStudentsAssigned - t.students.length)
      .isGreaterThanOrEqual(0)
      .withFailureMessage(i18n.t('Students Assigned must be greater or equal than the number of student added'));

    this.validateIfNumber(x => x.numStudentsAssigned)
      .isGreaterThan(0)
      .when(x => x.eventType.participants && !x.eventType.participantsOptional)
      .withFailureMessage(i18n.t('Selected event type requires students'));

    this.validateIfEach(x => x.students)
      .isDefined()
      .isNotNull()
      .fulfills(x => !isNullOrWhiteSpaces(x.id))
      .when(x => x.eventType.participants && !x.eventType.participantsOptional);
  }
}

export class WizardDataEventInstructorsStepValidator extends ExtendedAbstractValidator<EventWizardData> {
  constructor(onErrors?: (instructors, ...failures: ValidationFailure[]) => void) {
    super(onErrors);
    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('No Data Provided'));

    this.validateIf(x => x.instructors)
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('You have to select at least one instructor.'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(x =>
        (x.instructors || []).all(
          (i, idx) => i != null && new EventInstructorRowValidator(x.dateFrom, x.dateTo, idx, this.addErrors).extendValidate(i).isValid()
        )
      )
      .withFailureMessage(i18n.t('At least one instructor is wrong.'));

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(x =>
        (x.instructors || []).all((i, idx) => {
          let filteredInstructors = [...x.instructors];
          filteredInstructors.splice(idx, 1);

          return filteredInstructors.all(
            y =>
              (y.user && i.user && y.user.id !== i.user.id) ||
              (y.user &&
                i.user &&
                y.user.id === i.user.id &&
                !DateTimeService.toMoment(y.period.from).isBetween(
                  DateTimeService.toMoment(i.period.from),
                  DateTimeService.toMoment(i.period.to),
                  'day',
                  '[]'
                ) &&
                !DateTimeService.toMoment(y.period.to).isBetween(
                  DateTimeService.toMoment(i.period.from),
                  DateTimeService.toMoment(i.period.to),
                  'day',
                  '[]'
                ))
          );
        })
      )
      .withFailureMessage(`Overlapping dates for an instructor`);
  }
}

export class WizardDataDatesStepValidator extends ExtendedAbstractValidator<EventWizardData> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('No Data Provided'));
    this.validateIf(x => x.dateTo)
      .isNotNull()
      .withFailureMessage(i18n.t('Date To is required'));
    this.validateIf(x => x.dateFrom)
      .isNotNull()
      .withFailureMessage(i18n.t('Date From is required'));
    this.validateIfDate(x => new Date(x.dateTo))
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('Date To is required'));
    this.validateIfDate(x => new Date(x.dateFrom))
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('Date From is required'));

    this.validateIf(x => x.plannedDuration)
      .isNotNull()
      .fulfills(x => parseFloat(x) !== 0 && !isNullOrWhiteSpaces(x))
      .withFailureMessage(i18n.t('Planned Duration is required'));

    this.validateIf(o => DateTimeService.toMoment(o.dateTo).isBefore(DateTimeService.toMoment(o.dateFrom), 'day'))
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date is greater than End Date'));
  }
}

export class EventInstructorRowValidator extends ExtendedAbstractValidator<EventInstructorRow> {
  constructor(dateFrom: string, dateTo: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`Instructor at position ${idx + 1}`);
    this.validateIf(x => x.user)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Name is required')}`);

    this.validateIf(x => x.role)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Role is required')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period is required')}`);

    this.validateIf(x => x.period.from)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period From Date is required')}`);

    this.validateIf(x => x.period.from)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period To Date is required')}`);

    this.validateIf(x => x.period.from)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Location is required')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.from).isSameOrBefore(DateTimeService.toMoment(x.to), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Invalid Period')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.from).isSameOrAfter(DateTimeService.toMoment(dateFrom), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period Date From is not in the Event range')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.to).isSameOrBefore(DateTimeService.toMoment(dateTo), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period Date To is not in the Event range')}`);
  }
}

export class CreateEventDtoValidator extends AbstractValidator<CreateEventDto> {
  constructor() {
    super();
    this.validateIf(x => x)
      .isDefined()
      .isNotNull()
      .withFailureMessage(i18n.t('No Data provided'));

    this.validateIfString(x => x.eventTypeId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Type is required'));
  }
}

export interface CreateEventDetailsDto {
  patternId: string;
  category: Categories;
  requestedMachines: RequestMachinesDto[];
  priorityId: string;
  locationId: string;
  nmrClusterId: string;
  nmrFunctionalAreaId: string;
  nmrFunctionalSubAreaId: string;
  nmrTrainingNameId: string;
  professionId: string;
  isMachineRelated: boolean;
}

export interface CreateEventWarningDto {
  description: string;
}

export interface CreateEventDto {
  comments: string;
  startDate: string;
  endDate: string;
  eventDuration: string;
  eventTypeId: string;
  plannedDuration: string;
  title: string;
  eventTrainingDetails: CreateEventTrainingDetailsDto;
  parentRequestId: string;
  eventDetails: CreateEventDetailsDto;
  trainingMaterials: CreateTrainingMaterialDto[];
  instructors: EventInstructorRow[];
  pausePeriods: DateTimePeriod[];
  travelDays?: DateTimePeriod;
  requestIds?: string[];
  eventWarnings: CreateEventWarningDto[];
  students: StudentDto[];
  numStudentsAssigned?: number;
}

@repository('@@EVENTS', 'events.creation-wizard')
export class NewEventsStore extends FormStore<CreateEventDto> {
  baseUrl = `events/v1`;
  createPath = 'new-event';
  retrievePath = '';
  updatePath = '';
  isDeletePressed: boolean = false;

  public setisDeletePressed(value) {
    this.isDeletePressed = value;
  }

  protected validate(item: CreateEventDto) {
    return new CreateEventDtoValidator().validate(item);
  }

  constructor() {
    super('NEW_EVENT', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@EVENTS', 'events.creation-merge')
export class NewMergeEventsStore extends FormStore<CreateRequestMergeEventItemDto> {
  baseUrl = `events/v1`;
  createPath = 'create-event-merge';
  retrievePath = '';
  updatePath = '';
  isDeletePressed: boolean = false;

  public setisDeletePressed(value) {
    this.isDeletePressed = value;
  }

  protected validate(item: CreateRequestMergeEventItemDto) {
    return new CreateEventDtoValidator().validate(item.newEvent);
  }

  constructor() {
    super('NEW_EVENT_MERGE', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}
