import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Dropdown, Grid, Input } from 'semantic-ui-react';
import LocationEditor from 'widgets/bussiness/location-editor';
import InstructorRoleEditor from 'widgets/bussiness/instructor-role-editor';
import '../../../../assets/less/edit-user-modal.less';
import { LanguageDto, DropdownLanguagesStore } from 'stores/configuration/locations/languages-store';
import { InstructorRolesStore } from 'stores/instructor-roles/instructor-roles-store';
import { ItemReference } from 'stores/dataStore';
import { nameof } from 'utils/object';
import { NewInstructorStore } from 'stores/instructors/instructors-store';
import PillarsEditor from 'widgets/bussiness/pillars-editor';
import ChooseStudentsView from 'widgets/form/choose-students-form';
import { UserDto } from 'stores/users/users-store';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify-react';
import LocationEditorWithRoleLocations from 'widgets/bussiness/location-editor-with-rolelocations';

interface NewInstructorAssignmentViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  newInstructor?: NewInstructorStore;
  languageStore?: DropdownLanguagesStore;
  instructorRolesStore?: InstructorRolesStore;
}

interface NewInstructorAssignmentViewState {
  selectedLanguages: LanguageDto[];
  languageOptions: { text: string; value: string }[];
  availableLanguages: { [id: string]: LanguageDto };

  instructor: ItemReference;
  instructorRole: ItemReference;
  location: ItemReference;
  language: ItemReference;
  pillar: ItemReference;
  isOpenedUsersModal: boolean;
}

@connect(['newInstructor', NewInstructorStore], ['languageStore', DropdownLanguagesStore], ['instructorRolesStore', InstructorRolesStore])
class NewInstructorAssignmentView extends React.Component<NewInstructorAssignmentViewProps, NewInstructorAssignmentViewState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private get NewInstructorStore() {
    return this.props.newInstructor;
  }
  constructor(props: NewInstructorAssignmentViewProps) {
    super(props);

    this.NewInstructorStore.createNew({
      userId: '',
      name: '',
      surname: '',
      locationId: '',
      languages: [],
      pillarId: null,
      instructorRoleId: null
    });

    this.state = {
      selectedLanguages: this.props.newInstructor.state.item.languages,
      availableLanguages: {},
      languageOptions: [],
      location: null,
      instructorRole: null,
      instructor: null,
      language: null,
      pillar: null,
      isOpenedUsersModal: false
    };
    this.initLanguages();
  }

  componentDidMount() {
    const currentUserInfo = this.identityService.getUserInfo();
    const isPowerInstructor = IdentityService.isPowerInstructor(currentUserInfo);
    let userDto = this.identityService.getCurrentUserDto();

    if (isPowerInstructor) {
      this.setState({ pillar: { id: userDto.pillarId, title: undefined } });
    }
  }

  @autobind
  private onCreateNewItem() {
    this.NewInstructorStore.submit().then(x => {
      if (this.NewInstructorStore.state.result && this.NewInstructorStore.state.result.isSuccess) {
        this.props.onClose(true);
      }
    });
  }

  @autobind
  private onCancelNewItem() {
    this.NewInstructorStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.NewInstructorStore.change({ ...this.NewInstructorStore.state.item, ...change });
  }

  private selectedLanguages() {
    if (this.state.selectedLanguages) return this.state.selectedLanguages.map(x => (x.languageId ? x.languageId : x.id));
    return null;
  }

  @autobind
  private handleLanguageValue(data: any) {
    let languages = data.multiple
      ? data.value.map(x => this.state.availableLanguages[x])
      : data.value === ''
      ? null
      : [this.state.availableLanguages[data.value]];

    const newSelectedLanguages = this.mapLanguages(languages);

    this.setState({
      selectedLanguages: newSelectedLanguages
    });

    const item = this.NewInstructorStore.state.item;
    item.languages = newSelectedLanguages;
    this.NewInstructorStore.change(item);
  }

  private mapLanguages(languages) {
    languages.forEach(item => {
      if (!item.languageId) item.languageId = item.id;
      if (!item.id) item.id = item.languageId;
    });
    return languages;
  }

  private async initLanguages() {
    return await this.languageStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [
          {
            direction: 'Ascending',
            field: nameof<LanguageDto>('language'),
            useProfile: false
          }
        ]
      })
      .then(languages => {
        const dict = {};
        const options = [];

        languages.items.forEach(language => {
          language.languageId = language.id;
          dict[language.id] = language;
          options.push({ text: language.language, value: language.id });
        });

        this.setState({
          availableLanguages: dict,
          languageOptions: options,
          selectedLanguages: this.props.newInstructor.state.item.languages
        });
      });
  }

  @autobind
  private get languageStore() {
    return this.props.languageStore;
  }

  @autobind
  private get instructorRolesStore() {
    return this.props.instructorRolesStore;
  }

  private onOpenUsersSelectionModal = () => {
    this.setState({
      isOpenedUsersModal: true
    });
  };

  private handleOnAddParticipants(students: UserDto[]) {
    if ((students || []).length > 0) {
      const u = students[0];
      const ir = { id: u.id, title: `${u.firstName} ${u.lastName}` };
      const item = this.props.newInstructor.state.item;
      item.languages = (u.languages || []).length === 0 ? item.languages : u.languages || [];
      item.locationId = u.location != null ? u.location.id : item.locationId;
      item.name = u.firstName;
      item.surname = u.lastName;
      item.userId = u.id;
      item.pillarId = u['pillarId'] || item.pillarId;
      item.instructorRoleId = u['instructorRoleId'] || item.instructorRoleId;

      this.NewInstructorStore.change(item);
      this.setState({
        instructorRole: { id: item.instructorRoleId, title: undefined },
        location: { id: item.locationId, title: undefined },
        pillar: { id: item.pillarId, title: undefined },
        selectedLanguages: item.languages,
        instructor: ir
      });
    }
  }

  public render() {
    const { t } = this.props as any;
    const currentUserInfo = this.identityService.getUserInfo();
    const areYouAdmin = IdentityService.isAdmin(currentUserInfo);
    const areYouPoc = this.identityService.isInRole(Roles.PoC);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];
    const areYouPowerInstructor = IdentityService.isPowerInstructor(currentUserInfo);

    return (
      <Modal className="edit-instructor__modal" open closeOnEscape={true} onClose={this.onCancelNewItem} closeOnDimmerClick={false}>
        <Dimmer active={this.NewInstructorStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('New Instructor Assignment')}</Modal.Header>
        <Modal.Content image>
          <Container className="edit-user-modal__wrapper">
            {this.NewInstructorStore.state.result && !this.NewInstructorStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.NewInstructorStore.state.result.messages.map(o => o.body)}
              />
            )}
            <Form>
              <Grid columns={4}>
                <Grid.Row>
                  <Grid.Column width={2} textAlign="right" verticalAlign="middle">
                    <div className={`required field`}>
                      <label>{t('Instructor')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    <Input
                      style={{ width: 'calc(100% - 2em - 22px)', maxWidth: '83%', paddingRight: '2em' }}
                      className="normal-looking-disabled-input"
                      disabled
                      value={this.state.instructor == null ? '' : this.state.instructor.title}
                    />
                    <Button className="inverted-btn add-user" basic icon="user plus" onClick={this.onOpenUsersSelectionModal}></Button>
                  </Grid.Column>
                  <Grid.Column width={3} textAlign="right" verticalAlign="middle">
                    <div className={`required field`}>
                      <label>{t("Instructor's Role")}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    <InstructorRoleEditor
                      nullable
                      value={this.state.instructorRole}
                      isPoC={areYouPoc}
                      onChange={c => {
                        this.handleValue('instructorRoleId', c == null ? null : c.id);
                        this.setState({ instructorRole: c });
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={2} textAlign="right" verticalAlign="middle">
                    <div className={`required field`}>
                      <label>{t('Location')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    {(areYouAdmin || areYouPowerInstructor) && (
                      <LocationEditor
                        nullable
                        value={this.state.location}
                        onChange={c => {
                          this.handleValue('locationId', c == null ? null : c.id);
                          this.setState({ location: c });
                        }}
                      />
                    )}
                    {areYouPoc && (
                      <LocationEditorWithRoleLocations
                        className="planit-user-fields__location-select  planit-users-inputs"
                        value={this.state.location ? this.state.location.id : null}
                        locationsReceived={pocLocations}
                        onChange={location => {
                          this.handleValue('locationId', location ? location.id : null);
                          this.setState({ location: { id: location.id, title: location.location } });
                        }}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column width={3} textAlign="right" verticalAlign="middle">
                    <div className={`required field`}>
                      <label>{t('Languages')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    <Dropdown
                      search
                      inline
                      selection
                      clearable
                      options={this.state.languageOptions}
                      multiple={true}
                      value={this.selectedLanguages()}
                      className="planit-user-dropdown min-width-100pc"
                      onChange={(e, data) => this.handleLanguageValue(data)}
                      placeholder={t('Select languages')}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={2} textAlign="right" verticalAlign="middle">
                    <div className={`required field`}>
                      <label>{t('Pillars')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    <PillarsEditor
                      readonly={areYouPowerInstructor}
                      reloadOnChange={true}
                      nullable
                      value={this.state.pillar?.id ? this.state.pillar : null}
                      onChange={c => {
                        this.handleValue('pillarId', c?.id == null ? null : c.id);
                        this.setState({ pillar: c });
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={3} textAlign="right" verticalAlign="middle"></Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle"></Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
            {this.state.isOpenedUsersModal && (
              <ChooseStudentsView
                onAddParticipants={users => this.handleOnAddParticipants(users)}
                onCloseUsersModal={() => this.setState({ isOpenedUsersModal: false })}
                maxSelection={1}
                addButtonText={t('Select user')}
                selectionRequired={true}
                locationIds={areYouPoc ? pocLocations : null}
              />
            )}
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelNewItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onCreateNewItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(NewInstructorAssignmentView);
