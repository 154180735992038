import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Checkbox, Input, Message, Icon, Modal, Tab, Grid } from 'semantic-ui-react';
import { LocationDto, LocationsStore, ChangeLocationStore } from 'stores/configuration/locations/locations-store';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { CountryFlag } from 'widgets/bussiness/country-flag';
import { TableModel, TableView } from 'widgets/collections/table';
import { CheckboxFilter } from 'widgets/collections/table-filters/checkbox-filter';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import NewLocationView from './new-location';
import ChangeLocationView from './edit-location';
import PublicHolidaysTab from 'site/pages/landing-pages/shared-scheduler-components/scheduler-poc-config/config-tabs/public-holidays-tab';
import { resolve } from 'inversify-react';
import { IdentityService } from 'services/identity-service';
import WorkingDaysTab from 'site/pages/landing-pages/shared-scheduler-components/scheduler-poc-config/config-tabs/working-days-tab';

export interface LocationsListProps extends WithTranslation {
  locations?: LocationsStore;
  changeLocationStore?: ChangeLocationStore;
}

export interface LocationsListState {
  query: Query;
  newLocationShown: boolean;
  changeLocationShown: boolean;
  showLocationConfigModal: boolean;
  activeFilters: string[];
  selectedItem: LocationDto;
  someFilterOpened: boolean;
}

@connect(['locations', LocationsStore], ['changeLocationStore', ChangeLocationStore])
class LocationsListPage extends Component<LocationsListProps, LocationsListState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: LocationsListState = {
    query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
    newLocationShown: false,
    changeLocationShown: false,
    showLocationConfigModal: false,
    activeFilters: [],
    selectedItem: null,
    someFilterOpened: false
  };

  handleOnEnterKeydown = (item: LocationDto) => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = (item: LocationDto) => {
    this.handleOnEnterKeydown(item);
  };

  componentDidMount() {
    this.load();
  }

  private load = () => {
    this.props.locations.getAllAsync(this.state.query);
  };

  private handleOrderBy = (newOrder: OrderDefinition[]) => {
    const orderBy: OrderDefinition[] = [...newOrder, { direction: 'Descending', field: 'modifiedOn', useProfile: false }];
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState(({ query }) => ({ query: { ...query, skip, take } }), this.load);
  };

  private onSaveRow = async (item: LocationDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') await this.props.locations.saveAsync(item, state);

    return { isSuccess: true, items: [], messages: [] };
  };

  private onDelete = async (item: LocationDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') await this.props.locations.deleteAsync(item.id, state);

    return { isSuccess: true, items: [], messages: [] };
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  };

  private onNewItem = () => {
    this.setState({ newLocationShown: true });
  };

  private onNewItemClosed = (isSuccess: boolean) => {
    this.setState({ newLocationShown: false });
    if (isSuccess) this.load();
  };

  private onEditItem = () => {
    this.setState({ changeLocationShown: true });
  };

  private onEditItemClosed = (isSuccess: boolean) => {
    this.setState({ changeLocationShown: false });
    if (isSuccess) this.load();
  };

  handleToggleAvailability = async (item: LocationDto) => {
    if (!item) return;
    const { id, code, location, countryId, active, regionId } = item;
    this.props.changeLocationStore.change({ id, code, location, countryId, active: !active, regionId });
    const locationsByRoles = (this.identityService.getUserInfo().locationsByRoles?.['PoC'] as string[]) || [];
    await this.props.changeLocationStore.update();
    this.load();
    if (locationsByRoles.some(id => id === item.id)) {
      alert(
        this.props.t(
          `Updating current user's location (${item.location}) active state. Now you will be redirected to home page due to credentials renew needs`
        )
      );
      await this.identityService.signinSilent(true);
    }
  };

  onDismiss = () => {
    const { locations, changeLocationStore } = this.props;
    locations.clearMessages();
    changeLocationStore.clearMessages();
  };

  isAdmin = () => {
    const currentUserInfo = this.identityService.getUserInfo();
    return IdentityService.isAdmin(currentUserInfo);
  };

  handleOnLocationMaintenance = (selectedItem: LocationDto) => this.setState({ selectedItem, showLocationConfigModal: true });

  closeLocationConfig = () => this.setState({ showLocationConfigModal: false, selectedItem: null });

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t, locations, changeLocationStore } = this.props;
    const { activeFilters, newLocationShown, changeLocationShown, selectedItem, showLocationConfigModal, someFilterOpened } = this.state;
    let errMsgs = (locations.state.result?.messages || [])?.map(o => o.body);
    errMsgs = [...errMsgs, ...(changeLocationStore.state.result?.messages || [])?.map(x => x.body)];

    const tableModel = {
      columns: [
        {
          title: t('Code'),
          tooltipRenderer: true,
          renderer: data => <>{data.code}</>,
          editor: (data, onChange) => (
            <Input
              value={data.code}
              fluid
              onChange={(e, { value }) => {
                data.code = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by location')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<LocationDto>('code'), `contains(${nameof<LocationDto>('code')}, '${value}')`)}
              onClear={() => onClear(nameof<LocationDto>('code'))}
              active={activeFilters.includes(nameof<LocationDto>('code'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<LocationDto>('code'), useProfile: false }
        },
        {
          title: t('Location Description'),
          tooltipRenderer: true,
          renderer: data => <>&nbsp;{data.location}</>,
          editor: (data, onChange) => (
            <Input
              value={data.location}
              fluid
              onChange={(e, { value }) => {
                data.location = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by location')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<LocationDto>('location'), `contains(${nameof<LocationDto>('location')}, '${value}')`)}
              onClear={() => onClear(nameof<LocationDto>('location'))}
              active={activeFilters.includes(nameof<LocationDto>('location'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<LocationDto>('location'), useProfile: false }
        },
        {
          title: t('Country'),
          tooltipRenderer: true,
          renderer: data => (
            <>
              <CountryFlag countryName={data.countryName} countryIsoCode={data.isoCode} />
              &nbsp;{data.countryName}
            </>
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Country')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<LocationDto>('countryName'), `contains(Country/Name, '${value}')`)}
              onClear={() => onClear(nameof<LocationDto>('countryName'))}
              active={activeFilters.includes(nameof<LocationDto>('countryName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: 'Country/Name', useProfile: false }
        },
        {
          title: t('Region'),
          tooltipRenderer: true,
          renderer: data => data.region,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Region')}
              triggerTitle={title}
              onFilter={value => {
                value == null || value === ''
                  ? onFilter(
                      nameof<LocationDto>('region'),
                      `(RegionId ne null and contains(tolower(Region/Name), '${value.toLowerCase()}'))`
                    )
                  : onFilter(nameof<LocationDto>('region'), `RegionId eq null`);
              }}
              onClear={() => onClear(nameof<LocationDto>('region'))}
              active={activeFilters.includes(nameof<LocationDto>('region'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: 'Region/Name', useProfile: false }
        },
        {
          title: t('Active'),
          renderer: data => <Checkbox toggle checked={data.active} onChange={() => this.handleToggleAvailability(data)} />,
          editor: (data, onChange) => (
            <Checkbox
              toggle
              checked={data.active}
              onChange={(e, { checked }) => {
                data.active = !!checked;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <CheckboxFilter
              filterTitle={t('Filter by Active/Inactive')}
              trueLabel={t('Active')}
              falseLabel={t('Inactive')}
              triggerTitle={title}
              onFilter={(value: boolean) => onFilter(nameof<LocationDto>('active'), { active: value })}
              onClear={() => onClear(nameof<LocationDto>('active'))}
              active={activeFilters.includes(nameof<LocationDto>('active'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: nameof<LocationDto>('active'), useProfile: false }
        }
      ],
      data: locations.state
    } as TableModel<LocationDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {errMsgs.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                onDismiss={this.onDismiss}
                error
                header={t('An error ocurred')}
                list={errMsgs}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!newLocationShown && !changeLocationShown && !showLocationConfigModal}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" /> &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: (item: LocationDto) => this.setState({ selectedItem: item }, () => this.onEditItem())
                },
                {
                  content: (
                    <>
                      <Icon name="cogs" /> &nbsp;{t('Location Maintenance')}
                    </>
                  ),
                  isVisible: this.isAdmin,
                  onClick: this.handleOnLocationMaintenance
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              isRowDisableLayout={(item: LocationDto) => !item.active}
              canEdit={false}
              canDelete={false}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add Location')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(newLocationShown && <NewLocationView onClose={this.onNewItemClosed} {...this.props} />) ||
          (changeLocationShown && <ChangeLocationView onClose={this.onEditItemClosed} currentLocation={selectedItem} />)}
        {showLocationConfigModal && (
          <Modal size="small" className="scheduler-poc-config" open closeOnEscape={true} onClose={this.closeLocationConfig}>
            <Modal.Header className="scheduler-poc-config__header">{t('Location Maintenance')}</Modal.Header>
            <Modal.Content className="scheduler-poc-config__content">
              <Tab
                className="scheduler-poc-config__tabs"
                menu={{ secondary: true, pointing: true }}
                panes={[
                  {
                    menuItem: t('Public Holidays'),
                    render: () => <PublicHolidaysTab location={selectedItem} onClose={this.closeLocationConfig} />
                  },
                  {
                    menuItem: t('Working Days'),
                    render: () => <WorkingDaysTab location={selectedItem} onClose={this.closeLocationConfig} />
                  }
                ]}
              />
            </Modal.Content>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(LocationsListPage);
