import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message, Icon, Dropdown, Form, Button, Checkbox, Modal, Dimmer, Loader, Grid } from 'semantic-ui-react';
import { ItemState, OrderDefinition, Query, ItemReference } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { getProperties } from 'utils/object';
import { TableModel, TableView, TableColumn } from 'widgets/collections/table';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces, openInNewWindow, extractFriendlyIdNumber } from 'utils/useful-functions';
import { resolve } from 'inversify.config';
import { IdentityService, Roles } from 'services/identity-service';
import LocationEditor from 'widgets/bussiness/location-editor';
// import ProfileEditor from 'widgets/bussiness/profile-editor';
import { DatesRangeInput } from 'widgets/form/datesRangeInput';
import {
  IwsFormListStore,
  IwsFormListDto,
  ChangeActiveIwsFormIwsStore,
  IwsFormStatusDescp,
  IwsFormStatus
} from 'stores/assessments/forms/iws-forms-store';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { DateTimeService } from 'services/datetime-service';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { ChangeActiveFlagTnaFormDto } from 'stores/assessments/forms/tna-forms-store';
import * as autobind from 'autobind';

import {
  TranslateIwsQuestionTranslateFormStore,
  IwsQuestionFormTranslateLogItemDto,
  TranslateStatus
} from 'stores/assessments/forms/iws-translate-question-form-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';

export interface IwsFormsListProps extends WithTranslation, RouteComponentProps {
  iwsFormListStore?: IwsFormListStore;
  isUserAssessor: boolean;
  changeActiveIwsFormStore: ChangeActiveIwsFormIwsStore;
  translateIwsQuestionStore: TranslateIwsQuestionTranslateFormStore;
}

export interface IwsFormsListState {
  query: Query;
  activeFilters: { [key: string]: any };
  selectedItem: any;
  location: ItemReference;
  creationDateFrom: string;
  creationDateTo: string;
  deadline1From: string;
  deadline1To: string;
  deadline2From: string;
  deadline2To: string;
  deadline3From: string;
  deadline3To: string;
  profile: ProfileItemDto;
  iwsStatus: string;
  isInRoleEmployee: boolean;
  isInRoleAdminOrPoC: boolean;
  isInRoleAdmin: boolean;
  isInRolePoC: boolean;
  isInRoleAssessor: boolean;
  isInRoleAdminOrPocOrAssesor: boolean;
  employee: string;
  showDisabled: boolean;
  parameters: any;
  onConfirmToggleDisable: boolean;
  showInactiveIwsForms: boolean;
  showTranslateIwsQuestionMsg: boolean;
  showTranslateProcessModal: boolean;
  translateProcessModalText: string;
  translateProcessStatus: TranslateStatus;
  someFilterOpened: boolean;
}

@connect(
  ['iwsFormListStore', IwsFormListStore],
  ['changeActiveIwsFormStore', ChangeActiveIwsFormIwsStore],
  ['translateIwsQuestionStore', TranslateIwsQuestionTranslateFormStore]
)
class IwsFormsList extends React.Component<IwsFormsListProps, IwsFormsListState> {
  constructor(props) {
    super(props);
    this.state = {
      activeFilters: [],
      selectedItem: null,

      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: nameof<IwsFormListDto>('createdOn'), useProfile: false }],
        skip: 0,
        take: 10,
        parameters: this.initializeParameters()
      },

      location: null,
      creationDateFrom: null,
      creationDateTo: null,
      deadline1From: null,
      deadline1To: null,
      deadline2From: null,
      deadline2To: null,
      deadline3From: null,
      deadline3To: null,
      profile: null,
      isInRoleEmployee: this.isInRoleEmployee(),
      isInRoleAdminOrPoC: this.isInRoleAdminOrPoC(),
      isInRoleAdmin: this.isInRoleAdmin(),
      isInRolePoC: this.isInRolePoC(),
      iwsStatus: null,
      employee: '',
      showDisabled: false,
      parameters: this.initializeParameters(),
      isInRoleAssessor: this.props.isUserAssessor,
      isInRoleAdminOrPocOrAssesor: this.isInRoleAdminPocOrAssesor(),
      onConfirmToggleDisable: false,
      showInactiveIwsForms: false,
      showTranslateIwsQuestionMsg: false,
      showTranslateProcessModal: false,
      translateProcessModalText: '',
      translateProcessStatus: TranslateStatus.Unknown,
      someFilterOpened: false
    };
  }

  @autobind
  private async showTranslateProcessModal() {
    const filter = [];
    const iwsOrder: OrderDefinition[] = [
      { direction: 'Descending', field: nameof<IwsQuestionFormTranslateLogItemDto>('started'), useProfile: false }
    ];

    var translateIwsQuestionLog = await this.props.translateIwsQuestionStore.getTranslateIwsQuestionLog({
      searchQuery: '',
      skip: 0,
      take: 1,
      orderBy: iwsOrder,
      filter
    });

    var item = translateIwsQuestionLog?.items.firstOrDefault();
    var modalText = '';

    if (item == null) {
      modalText = 'The translate proccess was never launched.\r\n Do you want to start it?';
    } else {
      modalText =
        item.translateStatus === TranslateStatus[TranslateStatus.Completed]
          ? `Translation process finished last ${DateTimeService.toChatString(
              item.finished
            )} with status ${item.translateStatus.toString()}.\r\n Do you want to initialize it again?`
          : 'The translation process is still working.';
    }

    this.setState({
      translateProcessModalText: this.props.t(modalText),
      showTranslateProcessModal: true,
      translateProcessStatus: item == null ? TranslateStatus.Unknown : TranslateStatus[item.translateStatus]
    });
  }

  @autobind
  private hideTranslateProcessModal() {
    this.setState({ showTranslateProcessModal: false, selectedItem: null });
  }

  private initializeParameters = (): any => {
    return { IsActive: 'true' };
  };

  componentDidMount() {
    this.load();
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  private isInRoleEmployee = (): boolean => {
    return this.identityService.isInRole(Roles.Employee);
  };

  private isInRoleAdmin = (): boolean => {
    return IdentityService.isAdminOrPowerInstructor(this.identityService.getUserInfo());
  };

  private isInRolePoC = (): boolean => {
    return this.identityService.isInRole(Roles.PoC);
  };

  private isGlobalPoc(): boolean {
    const userInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(userInfo);
    const isGlobalPoc = isPoc && userInfo.isGlobalPoc;

    return isGlobalPoc;
  }

  private isInRoleAdminOrPoC = (): boolean => {
    return this.isInRoleAdmin() || this.identityService.isInRole(Roles.PoC);
  };

  private isInRoleAdminPocOrAssesor = (): boolean => {
    return this.isInRoleAdmin() || this.identityService.isInRole(Roles.PoC) || this.props.isUserAssessor;
  };

  private onOpenEmployee = (user: IwsFormListDto) => {
    openInNewWindow(`./employee-page/${user.userId}/${user.id}`);
  };

  private canSeeDeactivateOption = (iws: IwsFormListDto): boolean => {
    if (
      IwsFormStatusDescp[iws.status] === IwsFormStatusDescp.FirstAssesment &&
      this.state.isInRoleAdminOrPoC &&
      iws.isActive &&
      !iws.isStarted
    )
      return true;
    else return false;
  };

  private canSeeActivateOption = (iws: IwsFormListDto): boolean => {
    if (
      this.isIwsFormStatusInAllowed(iws.status.toString(), [IwsFormStatus.FirstAssesment, IwsFormStatus.SecondAssesment]) &&
      this.state.isInRoleAdminOrPoC &&
      !iws.isActive &&
      !iws.isStarted
    )
      return true;
    else return false;
  };

  private canEditIwsForm = (iws: IwsFormListDto): boolean => {
    if (
      this.state.isInRoleAdminOrPoC &&
      this.isIwsFormStatusInAllowed(iws.status.toString(), [
        IwsFormStatus.FirstAssesment,
        IwsFormStatus.SecondAssesment,
        IwsFormStatus.Qualifier,
        IwsFormStatus.Done
      ])
    )
      return true;
    else return false;
  };

  private canSeeOpenIws = (iws: IwsFormListDto): boolean => {
    if (this.state.isInRoleAssessor) return true;
    else if (
      this.isIwsFormStatusInAllowed(iws.status.toString(), [
        IwsFormStatus.FirstAssesment,
        IwsFormStatus.SecondAssesment,
        IwsFormStatus.Qualifier,
        IwsFormStatus.Done
      ]) &&
      this.state.isInRoleEmployee
    )
      return true;
    else if (this.state.isInRoleAdminOrPoC) return true;
    else return false;
  };

  private load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const filter = getProperties(activeFilters)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    if (!this.state.isInRoleAssessor || this.state.isInRoleAdmin)
      this.setState({ query, showTranslateIwsQuestionMsg: false }, () => this.props.iwsFormListStore.getAllAsync(this.state.query));
    else {
      this.setState({ query, showTranslateIwsQuestionMsg: false }, () =>
        this.props.iwsFormListStore.getAssessorIwsFormList(this.state.query)
      );
    }
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState(({ query }) => ({ query: { ...query, skip, take } }), this.load);
  };

  private onSaveRow = async (item: IwsFormListDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') await this.props.iwsFormListStore.saveAsync(item, state);

    return { isSuccess: true, items: [], messages: [] };
  };

  private onDelete = async (item: IwsFormListDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') await this.props.iwsFormListStore.deleteAsync(item.id, state);

    return { isSuccess: true, items: [], messages: [] };
  };

  private onNewItem = () => {
    const { history } = this.props;
    history.push({ pathname: '/assessments/iws/form/create' });
  };

  private onEditItem = (iws: IwsFormListDto) => {
    openInNewWindow(`./assessments/iws/iws-edit-form/${iws.id}`);
  };

  private initTranslatingIwsQuestionFormProcess = () => {
    this.setState({
      showTranslateIwsQuestionMsg: true,
      showTranslateProcessModal: false
    });

    this.props.translateIwsQuestionStore.initBackgroundIwsQuestionFormTranslate();
  };

  private getBackgroundTranslateIwsQuestionMessage = () => {
    const { isBusy, result } = this.props.translateIwsQuestionStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    if (!result.isSuccess) {
      header = t('Something went wrong');
      content = result.messages.map(x => x.body).join('. ');
    } else {
      header = t('Background translate process initialized successfully');
      content = t('You will receive an email when the process ends');
    }

    return (
      <Message
        className={!result.isSuccess && 'error-message__style'}
        icon={!result.isSuccess && 'exclamation circle'}
        onDismiss={this.handleTranslateIwsQuestionMsgDismiss}
        header={header}
        content={content}
        error={!result.isSuccess}
        success={result.isSuccess}
      />
    );
  };

  private handleTranslateIwsQuestionMsgDismiss = () => {
    this.setState({ showTranslateIwsQuestionMsg: false });
  };

  private canPocEditThisForm = (selectedItem: IwsFormListDto) => {
    const locationFormToEdit = selectedItem.user.location.id;
    let locations = this.identityService.getCurrentRoleLocations() || [];

    if (this.isInRolePoC && locations.includes(locationFormToEdit)) return true;
    else return false;
  };

  private onEditForm = (selectedItem: IwsFormListDto) => {
    const { history } = this.props;
    const canPocEdit = this.canPocEditThisForm(selectedItem);
    history.location.state = selectedItem;
    if (
      this.isIwsFormStatusInAllowed(selectedItem.status.toString(), [
        IwsFormStatus.FirstAssesment,
        IwsFormStatus.SecondAssesment,
        IwsFormStatus.Qualifier,
        IwsFormStatus.Done
      ]) &&
      (this.isInRoleAdmin || (this.isInRolePoC && canPocEdit))
    )
      history.push({ pathname: '/assessments/iws/form/edit-wizard/' + selectedItem.id, state: selectedItem });
  };

  private handleFilterIws = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) delete activeFilters['IdDescription'];
    else {
      const input = value as string;
      const parts = [`contains(tolower(IwsAssessment/Title), '${input.toLowerCase()}')`];
      const prefix = 'SUC';
      const friendlyId = extractFriendlyIdNumber(input, prefix);
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith(prefix)) parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        else parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
      }

      activeFilters['IdDescription'] = `(${parts.join(' or ')})`;
    }
    this.setState({ activeFilters }, this.load);
  };

  private handleFilterEmployee = (employee: string) => {
    const activeFilters = { ...this.state.activeFilters };
    const parameters = this.state.query.parameters || {};

    if (isNullOrWhiteSpaces(employee)) delete parameters['EmployeeOrSfPosition'];
    else parameters['EmployeeOrSfPosition'] = employee;

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, activeFilters, employee }), this.load);
  };

  private handleFilterProfile = (profile: ProfileItemDto) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!profile) delete activeFilters['ProfileId'];
    else
      activeFilters['ProfileId'] = {
        IwsAssessment: { IwsAssessmentsTemplates: { any: { IwsTemplate: { ProfileItemId: { eq: { type: 'guid', value: profile.id } } } } } }
      };

    this.setState({ activeFilters: activeFilters, profile: profile }, this.load);
  };

  private handleFilterLocation = (location: ItemReference) => {
    let parameters = this.state.parameters;

    if (location) parameters['LocationId'] = location.id;
    else parameters = this.initializeParameters();

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, location, parameters }), this.load);
  };

  private handleFilterIwsStatus = (_, { value }) => {
    const iwsStatus = value;
    const activeFilters = { ...this.state.activeFilters };
    const iwsString = this.returnIwsStatus(iwsStatus);
    if (!iwsStatus) delete activeFilters['iwsStatus'];
    else {
      switch (value) {
        case 10:
          activeFilters['iwsStatus'] = { Status: iwsString, OutOfDate1: false };
          break;
        case 20:
          activeFilters['iwsStatus'] = { Status: iwsString, OutOfDate2: false };
          break;
        case 30:
          activeFilters['iwsStatus'] = { Status: iwsString, OutOfDate3: false };
          break;
        case 40:
          activeFilters['iwsStatus'] = { Status: iwsString };
          break;
        case 50:
          activeFilters['iwsStatus'] = { Status: iwsString, OutOfDate1: true };
          break;
        case 60:
          activeFilters['iwsStatus'] = { Status: iwsString, OutOfDate2: true };
          break;
        case 70:
          activeFilters['iwsStatus'] = { Status: iwsString, OutOfDate3: true };
          break;
        default:
          activeFilters['iwsStatus'] = { Status: iwsString };
      }
    }

    this.setState({ activeFilters, iwsStatus }, this.load);
  };

  private returnIwsStatus = (status: number): string => {
    switch (status) {
      case 10:
        return 'FirstAssesment';
      case 20:
        return 'SecondAssesment';
      case 30:
        return 'Qualifier';
      case 40:
        return 'Done';
      case 50:
        return 'FirstAssesment';
      case 60:
        return 'SecondAssesment';
      case 70:
        return 'Qualifier';
      default:
        return '';
    }
  };

  private handleFilterCreateAt = (from: string, to: string) => {
    const { activeFilters } = this.state;
    const fromIsNull = isNullOrWhiteSpaces(from);
    const toIsNull = isNullOrWhiteSpaces(to);

    if (fromIsNull && toIsNull) {
      delete activeFilters['creationDate'];
    } else {
      activeFilters['creationDate'] = {
        createdOn: {
          ge: { type: 'raw', value: DateTimeService.toString(fromIsNull ? to : from, null, 'startOfDay') },
          le: { type: 'raw', value: DateTimeService.toString(toIsNull ? from : to, null, 'endOfDay') }
        }
      };
    }

    this.setState({ activeFilters, creationDateFrom: from, creationDateTo: to }, this.load);
  };

  private handleFilterDeadline = (from: string, to: string, field: string) => {
    const { activeFilters } = this.state;
    const fromIsNull = isNullOrWhiteSpaces(from);
    const toIsNull = isNullOrWhiteSpaces(to);

    if (fromIsNull && toIsNull) {
      delete activeFilters[field];
    } else {
      activeFilters[field] = {};
      activeFilters[field][field] = {
        ge: { type: 'raw', value: DateTimeService.toString(fromIsNull ? to : from, null, 'startOfDay') },
        le: { type: 'raw', value: DateTimeService.toString(toIsNull ? from : to, null, 'endOfDay') }
      };
    }
    let state = { activeFilters };
    const fieldNameFrom = field + 'From';
    const fieldNameTo = field + 'To';
    state[fieldNameFrom] = from;
    state[fieldNameTo] = to;
    this.setState(state, this.load);
  };

  private handleInactiveUsers = () => {
    const disabled = this.state.showDisabled;
    const activeFilters = { ...this.state.activeFilters };
    const parameters = this.state.query.parameters || {};
    if (disabled) parameters['IsActive'] = `${disabled}`;
    else delete parameters['IsActive'];

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, activeFilters, showDisabled: !disabled }), this.load);
  };

  onToggleAvailabilityItem = async () => {
    this.setState({ onConfirmToggleDisable: false });
    const { id, isActive }: IwsFormListDto = this.state.selectedItem;
    const newItem: ChangeActiveFlagTnaFormDto = { id, isActive: !isActive };
    this.props.changeActiveIwsFormStore.change({ ...newItem });

    const response = await this.props.changeActiveIwsFormStore.update();
    if (response?.isSuccess) this.setState({ selectedItem: null }, this.load);
  };

  closeConfirmationBox = () => {
    this.setState({ ...this.state, selectedItem: null, onConfirmToggleDisable: false });
  };

  private isIwsFormStatusInAllowed = (value: string, allowed: IwsFormStatus[]): boolean => {
    let inAny = allowed.some(x => IwsFormStatus[x] === value);
    return inAny;
  };

  private getColumnsModel = (): TableColumn<IwsFormListDto>[] => {
    const { t } = this.props;
    const isEmployee = this.isInRoleEmployee();
    const isAssessor = this.props.isUserAssessor;

    const columns: TableColumn<IwsFormListDto>[] = [
      {
        title: t('SuC ID'),
        tooltipRenderer: true,
        renderer: data => data.friendlyId,
        selectableHeader: true,
        sortDefinition: {
          field: nameof<IwsFormListDto>('friendlyId'),
          useProfile: false
        }
      }
    ];
    if (isEmployee && !isAssessor)
      columns.push({ title: t('SuC Title'), tooltipRenderer: true, renderer: data => data.title, selectableHeader: true });

    if (!isEmployee || isAssessor)
      columns.push(
        {
          title: t('Employee'),
          tooltipRenderer: false,
          renderer: ({ user }) => `${user?.lastName || ''}, ${user?.firstName || ''}`,
          selectableHeader: true
        },
        { title: t('SF Position'), tooltipRenderer: true, renderer: data => data.user?.sfPosition || '', selectableHeader: true },

        { title: t('Location'), tooltipRenderer: true, renderer: data => data.user?.location?.location || '', selectableHeader: true }

        // {
        //   title: t('Profiles'),
        //   tooltipRenderer: false,
        //   renderer: ({ profiles }) =>
        //     (profiles || []).map((x, i) => {
        //       return (
        //         <span key={i} className="assessment-forms-grey-tag">
        //           {x.name}
        //         </span>
        //       );
        //     })
        // }
      );

    columns.push(
      {
        title: t('Creation Date'),
        tooltipRenderer: false,
        renderer: ({ createAt }) => <span>{DateTimeService.toDateInputString(createAt)}</span>,
        selectableHeader: true,
        sortDefinition: { field: nameof<IwsFormListDto>('createdOn'), useProfile: false, active: 'Descending' }
      },
      {
        title: t('Deadline 1'),
        tooltipRenderer: false,
        renderer: data => <span>{DateTimeService.toDateInputString(data.deadline1)}</span>,
        selectableHeader: true,
        sortDefinition: { field: nameof<IwsFormListDto>('deadline1'), useProfile: false }
      },
      {
        title: t('Deadline 2'),
        tooltipRenderer: false,
        renderer: data => <span>{DateTimeService.toDateInputString(data.deadline2)}</span>,
        selectableHeader: true,
        sortDefinition: { field: nameof<IwsFormListDto>('deadline2'), useProfile: false }
      },
      {
        title: t('Deadline 3'),
        tooltipRenderer: false,
        renderer: data => <span>{DateTimeService.toDateInputString(data.deadline3)}</span>,
        selectableHeader: true,
        sortDefinition: { field: nameof<IwsFormListDto>('deadline3'), useProfile: false }
      },
      {
        title: t('Status'),
        tooltipRenderer: false,
        renderer: ({ extendedStatus }) =>
          (extendedStatus || []).map(eStatus => {
            let className = '';
            if (eStatus.toLowerCase().includes('out of date')) className = 'assessment-forms-out-of-date-tag';
            else if (eStatus.toLowerCase().includes('done')) className = 'assessment-forms-done-tag';
            else if (eStatus.toLowerCase().includes('qualifier')) className = 'assessment-forms-assessors-tag';
            else className = 'assessment-forms-employee-tag';
            return (
              <span key={eStatus} className={`assessment-forms__table__tag ${className}`}>
                {eStatus}
              </span>
            );
          }),
        selectableHeader: true
      }
    );

    return columns;
  };

  private getExtraActions = () => {
    const { t } = this.props as any;

    let buttonMenuOptions = [];
    if (this.state.isInRoleAdminOrPoC || this.state.isInRoleAssessor) {
      buttonMenuOptions = [
        {
          content: (
            <>
              <Icon name="file alternate" />
              {<span className="text__bold">{t('Open IWS SuC')}</span>}
            </>
          ),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onEditItem(item as IwsFormListDto));
          },
          isVisible: item => this.canSeeOpenIws(item as IwsFormListDto)
        },
        {
          content: (
            <>
              <Icon name="eye" />
              {<span className="text__bold">{t('Open Employee Card')}</span>}
            </>
          ),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onOpenEmployee(item as IwsFormListDto));
          }
        },
        {
          content: (
            <>
              <Icon name="toggle off" color="red" />
              <span className="text__bold text__red">{t('Deactivate')}</span>
            </>
          ),
          isVisible: (item: IwsFormListDto) => this.canSeeDeactivateOption(item),
          onClick: (item: IwsFormListDto) => {
            this.setState({ selectedItem: item, onConfirmToggleDisable: true });
          }
        },
        {
          content: (
            <>
              <Icon name="pencil" />
              {<span className="text__bold">{t('Edit IWS SuC')}</span>}
            </>
          ),
          isVisible: item => this.canEditIwsForm(item as IwsFormListDto),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onEditForm(item as IwsFormListDto));
          }
        },
        {
          content: (
            <>
              <Icon name="toggle on" />
              <span className="text__bold">{t('Activate')}</span>
            </>
          ),
          isVisible: (item: IwsFormListDto) => this.canSeeActivateOption(item),
          onClick: (item: IwsFormListDto) => {
            this.setState({ selectedItem: item, onConfirmToggleDisable: true });
          }
        }
      ];
    } else {
      buttonMenuOptions = [
        {
          content: (
            <>
              <Icon name="file alternate" />
              {<span className="text__bold">{t('Open IWS SuC')}</span>}
            </>
          ),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onEditItem(item as IwsFormListDto));
          },
          isVisible: item => this.canSeeOpenIws(item as IwsFormListDto)
        },
        {
          content: (
            <>
              <Icon name="toggle off" color="red" />
              <span className="text__bold text__red">{t('Deactivate')}</span>
            </>
          ),
          isVisible: (item: IwsFormListDto) => this.canSeeDeactivateOption(item),
          onClick: (item: IwsFormListDto) => {
            this.setState({ selectedItem: item, onConfirmToggleDisable: true });
          }
        },
        {
          content: (
            <>
              <Icon name="toggle on" />
              <span className="text__bold">{t('Activate')}</span>
            </>
          ),
          isVisible: (item: IwsFormListDto) => this.canSeeActivateOption(item),
          onClick: (item: IwsFormListDto) => {
            this.setState({ selectedItem: item, onConfirmToggleDisable: true });
          }
        }
      ];
    }

    return buttonMenuOptions;
  };

  private canCreate = () => {
    const { isInRoleAdminOrPocOrAssesor, isInRoleAdmin, isInRolePoC, isInRoleAssessor } = this.state;
    return isInRoleAdminOrPocOrAssesor && (isInRoleAdmin || (isInRolePoC && !isInRoleAssessor));
  };

  private canTranslate = () => {
    return this.state.translateProcessStatus === TranslateStatus.Completed || this.state.translateProcessStatus === TranslateStatus.Unknown;
  };

  private iwsFormStatusOptions = [
    { key: 1, text: '1st Assessment', value: 10 },
    { key: 2, text: '2nd Assessment', value: 20 },
    { key: 3, text: 'Qualifier', value: 30 },
    { key: 5, text: 'Done', value: 40 },
    { key: 6, text: 'Out of Date 1st Assessment', value: 50 },
    { key: 7, text: 'Out of Date 2nd Assessment', value: 60 },
    { key: 8, text: 'Out of Date Qualifier', value: 70 }
  ];

  handleOnEnterKeydown = item => {
    if (this.canSeeOpenIws(item as IwsFormListDto)) this.setState({ selectedItem: item }, () => this.onEditItem(item as IwsFormListDto));
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t, iwsFormListStore, changeActiveIwsFormStore } = this.props;
    //const { isInRoleAdminOrPocOrAssesor, employee, profile, location, iwsStatus, creationDateFrom, creationDateTo } = this.state;
    const { isInRoleAdminOrPocOrAssesor, employee, location, iwsStatus, creationDateFrom, creationDateTo, someFilterOpened } = this.state;
    const { isInRoleAdminOrPoC, query, isInRoleEmployee, isInRoleAssessor, onConfirmToggleDisable, selectedItem } = this.state;
    const { deadline1From, deadline1To, deadline2From, deadline2To, deadline3From, deadline3To } = this.state;
    const { result } = iwsFormListStore.state;
    const pocLocations =
      IdentityService.isPoc(this.identityService.getUserInfo()) && !this.isGlobalPoc()
        ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
        : [];

    const tableModel = {
      columns: this.getColumnsModel(),
      data: iwsFormListStore.state
    } as TableModel<IwsFormListDto>;

    return (
      <div className="question-bank__wrapper">
        {this.props.translateIwsQuestionStore.state.isBusy && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Initializing IWS Question Translate process')}</Loader>
          </Dimmer>
        )}

        <Grid className="event-types-list-grid">
          {result && !result.isSuccess && (result.messages || []).length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}

          {isInRoleAdminOrPocOrAssesor && (
            <div id="assessment-forms-list-filters__admin-or-poc-container">
              <div
                id="assessment-forms-list-filters__admin-or-poc-container__lef-container"
                className="specific-margin-bottom-equal-filters"
              >
                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search in Title or ID')}
                    onChange={this.handleFilterIws}
                    key={'searchIws'}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search Employee')}
                    onChange={(_, employee) => this.handleFilterEmployee(employee.value)}
                    value={employee || ''}
                    key={'searchEmployee'}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                {/* <Form.Field>
                <ProfileEditor
                  placeholder={t('Profile')}
                  nullable
                  assessment={'IWS'}
                  value={profile?.id || ''}
                  onChange={this.handleFilterProfile}
                  clearable
                />
              </Form.Field> */}

                <Form.Field>
                  <LocationEditor
                    loadDataOnOpen={true}
                    placeholder={t('Location')}
                    nullable
                    value={location}
                    onChange={this.handleFilterLocation}
                    clearable={true}
                    locationsReceived={pocLocations}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <Dropdown
                    className="custom-editor width-14em"
                    fluid
                    selection
                    options={this.iwsFormStatusOptions}
                    value={iwsStatus || null}
                    closeOnBlur={true}
                    closeOnEscape={true}
                    selectOnBlur={false}
                    selectOnNavigation={false}
                    placeholder={t('SuC Status')}
                    onChange={this.handleFilterIwsStatus}
                    clearable
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>
                <DatesRangeInput
                  dontUseRedClearIconColor={true}
                  iconPosition="right"
                  fromValue={creationDateFrom}
                  toValue={creationDateTo}
                  onChange={this.handleFilterCreateAt}
                  placeholder={t('Creation Date')}
                  clearable={true}
                  allowSameEndDate
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
                <DatesRangeInput
                  dontUseRedClearIconColor={true}
                  iconPosition="right"
                  fromValue={deadline1From}
                  toValue={deadline1To}
                  onChange={(start, end) => this.handleFilterDeadline(start, end, 'deadline1')}
                  placeholder={t('Deadline 1')}
                  clearable={true}
                  allowSameEndDate
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />

                <DatesRangeInput
                  dontUseRedClearIconColor={true}
                  iconPosition="right"
                  fromValue={deadline2From}
                  toValue={deadline2To}
                  onChange={(start, end) => this.handleFilterDeadline(start, end, 'deadline2')}
                  placeholder={t('Deadline 2')}
                  clearable={true}
                  allowSameEndDate
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />

                <DatesRangeInput
                  dontUseRedClearIconColor={true}
                  iconPosition="right"
                  fromValue={deadline3From}
                  toValue={deadline3To}
                  onChange={(start, end) => this.handleFilterDeadline(start, end, 'deadline3')}
                  placeholder={t('Deadline 3')}
                  clearable={true}
                  allowSameEndDate
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </div>
              <div id="assessment-forms-list-filters__admin-or-poc-container__right-container">
                {this.canCreate() && (
                  <Button className="inverted-color-btn" onClick={this.onNewItem}>
                    {t('Create New IWS SuC')}&nbsp;
                  </Button>
                )}
                {isInRoleAdminOrPoC && (
                  <Checkbox
                    className={`${(query.orderBy || []).length !== 0 ? 'orderby' : ''} include-users`}
                    label={t('Include Inactive Users')}
                    onClick={(_e, { checked }) => {
                      this.handleInactiveUsers();
                    }}
                  />
                )}
              </div>
            </div>
          )}

          {isInRoleEmployee && !isInRoleAssessor && (
            <div id="assessment-forms-list-filters__employee-but-no-assessor-container">
              <Form.Field>
                <Input icon="search" placeholder={t('Search SuC')} onChange={this.handleFilterIws} key={'searchIws'} />
              </Form.Field>
              <Form.Field>
                <Dropdown
                  className="custom-editor width-14em"
                  fluid
                  selection
                  options={this.iwsFormStatusOptions}
                  value={iwsStatus || null}
                  closeOnBlur={true}
                  closeOnEscape={true}
                  selectOnBlur={false}
                  selectOnNavigation={false}
                  placeholder={t('SuC Status')}
                  onChange={this.handleFilterIwsStatus}
                  clearable
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </Form.Field>
              <DatesRangeInput
                dontUseRedClearIconColor={true}
                iconPosition="right"
                fromValue={creationDateFrom}
                toValue={creationDateTo}
                onChange={this.handleFilterCreateAt}
                placeholder={t('Creation Date')}
                clearable={true}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
              <DatesRangeInput
                dontUseRedClearIconColor={true}
                iconPosition="right"
                fromValue={deadline1From}
                toValue={deadline1To}
                onChange={(start, end) => this.handleFilterDeadline(start, end, 'deadline1')}
                placeholder={t('Deadline 1')}
                clearable={true}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
              <DatesRangeInput
                dontUseRedClearIconColor={true}
                iconPosition="right"
                fromValue={deadline2From}
                toValue={deadline2To}
                onChange={(start, end) => this.handleFilterDeadline(start, end, 'deadline2')}
                placeholder={t('Deadline 2')}
                clearable={true}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
              <DatesRangeInput
                dontUseRedClearIconColor={true}
                iconPosition="right"
                fromValue={deadline3From}
                toValue={deadline3To}
                onChange={(start, end) => this.handleFilterDeadline(start, end, 'deadline3')}
                placeholder={t('Deadline 3')}
                clearable={true}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
            </div>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.showTranslateProcessModal && !onConfirmToggleDisable}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////

              model={tableModel}
              extraActions={this.getExtraActions()}
              onOrderByChanged={this.handleOrderBy}
              canCreateNew={false}
              onRefresh={this.load}
              canEdit={false}
              loading={changeActiveIwsFormStore.state.isBusy}
              canDelete={false}
              // isRowDisableLayout={item => !(item as any).isActive}
              isRowDisableLayout={item => !(item as any).user.enabled || !(item as any).isActive}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              createNewButtonTitle={t('Create New IWS SuC')}
              areActionsDisabled={item => !this.canSeeOpenIws(item as IwsFormListDto) && !(isInRoleAdminOrPoC || isInRoleAssessor)}
              extraMenu={
                isInRoleAdminOrPoC && [
                  {
                    className: 'menu-item-download',
                    content: (
                      <Button
                        className="inverted-color-btn"
                        onClick={this.showTranslateProcessModal}
                        data-tooltip={t('Translate IWS Question Form')}
                      >
                        <FontAwesomeIcon className="solid" icon={faLanguage} size="lg" />
                      </Button>
                    )
                  }
                ]
              }
            />
          </Grid.Row>
        </Grid>

        {isInRoleAdminOrPoC && (
          <Modal
            open={this.state.showTranslateProcessModal}
            size="mini"
            className="have-warnings-popup"
            closeOnEscape={true}
            onClose={() => this.hideTranslateProcessModal()}
          >
            <Modal.Content className="have-warnings-popup__content">
              <p>{this.state.translateProcessModalText}</p>
            </Modal.Content>

            {this.canTranslate() && (
              <div className="have-warnings-popup__buttons-container">
                <Button className="have-warnings-popup__btn" content={t('No')} onClick={() => this.hideTranslateProcessModal()} />
                <Button
                  className="have-warnings-popup__btn have-warnings-popup__pending-btn"
                  content={t('Yes')}
                  onClick={() => this.initTranslatingIwsQuestionFormProcess()}
                />
              </div>
            )}

            {!this.canTranslate() && (
              <div className="have-warnings-popup__buttons-container">
                <Button className="have-warnings-popup__btn" content={t('Cancel')} onClick={() => this.hideTranslateProcessModal()} />
              </div>
            )}
          </Modal>
        )}

        {onConfirmToggleDisable && (
          <Modal
            open={onConfirmToggleDisable}
            size="tiny"
            className="change-status-popup"
            closeOnEscape={true}
            onClose={this.closeConfirmationBox}
          >
            <Modal.Content className="change-status-popup__content">
              <p>{t(`You are going to change status to ${selectedItem.isActive ? 'disabled' : 'active'}`)}</p>
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.closeConfirmationBox} />
              <Button
                className="change-status-popup__btn change-status-popup__pending-btn"
                content={t('Yes')}
                onClick={this.onToggleAvailabilityItem}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation()(IwsFormsList);
