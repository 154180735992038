import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';

import i18n from 'i18n';
import CountriesListPage from './countries/countries-list';
import LanguagesListPage from './languages/languages-list';
import LocationsListPage from './locations/locations-list';
import RegionsListPage from './regions/regions-list';

export interface LocationsConfigurationHomeProps extends WithTranslation, RouteComponentProps {}

export interface LocationsConfigurationHomeState {
  activeTab: 'countries' | 'locations';
}

class LocationsConfigurationHomePage extends React.Component<LocationsConfigurationHomeProps, LocationsConfigurationHomeState> {
  constructor(props: LocationsConfigurationHomeProps) {
    super(props);
    this.state = {
      activeTab: 'countries'
    };
  }
  private panes = [
    {
      menuItem: i18n.t('Countries'),
      render: () => (
        <Tab.Pane attached={false}>
          <CountriesListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Regions'),
      render: () => (
        <Tab.Pane attached={false}>
          <RegionsListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: i18n.t('Locations'),
      render: () => (
        <Tab.Pane attached={false}>
          <LocationsListPage />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Languages',
      render: () => (
        <Tab.Pane attached={false}>
          <LanguagesListPage />
        </Tab.Pane>
      )
    }
  ];

  render() {
    const { t } = this.props;
    return (
      <>
        <h3>{t('Locations')}</h3>
        <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={this.panes} />
      </>
    );
  }
}

export default withTranslation()(LocationsConfigurationHomePage);
