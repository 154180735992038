import * as autobind from 'autobind';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Checkbox, Message, List, Icon, Modal, Grid } from 'semantic-ui-react';
import { CheckboxFilter } from 'widgets/collections/table-filters/checkbox-filter';

import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { UserDto, UserStore, DropDownUserStore, ChangeUserStore, ChangeUserDto } from 'stores/users/users-store';
import { DropDownRolesStore, RoleDto } from 'stores/roles/roles-store';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import NewUserView from './new-user';
import ChangeUserView from './edit-user';
import { LocationDto, DropDownLocationsStore } from 'stores/configuration/locations/locations-store';
import { CountryFlag } from 'widgets/bussiness/country-flag';
import { DataStoreFilter } from 'widgets/collections/table-filters/data-store-filter';
import { isNullOrWhiteSpaces, openInNewWindow, isNullOrEmpty } from 'utils/useful-functions';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { GetRoleName } from 'utils/userinfo-functions';
import { DateTimeService } from 'services/datetime-service';

export interface UsersListProps extends WithTranslation, RouteComponentProps {
  users: UserStore;
  usersdropdown: DropDownUserStore;
  dropdownlocations: DropDownLocationsStore;
  dropdownroles: DropDownRolesStore;
  changeUserStore: ChangeUserStore;
}

export interface UsersListState {
  query: Query;
  newUserShown: boolean;
  changeUserShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  disableUserModalShown: boolean;
  someFilterOpened: boolean;
}

@connect(
  ['users', UserStore],
  ['usersdropdown', DropDownUserStore],
  ['dropdownlocations', DropDownLocationsStore],
  ['dropdownroles', DropDownRolesStore],
  ['changeUserStore', ChangeUserStore]
)
class UsersListPage extends Component<UsersListProps, UsersListState> {
  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  @autobind
  private async handleOnRowDoubleClick(item: UserDto) {
    item.enabled && this.setState({ selectedItem: item }, () => this.onOpenEmployee(item as UserDto));
  }

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newUserShown: false,
      changeUserShown: false,
      selectedItem: null,
      activeFilters: [],
      disableUserModalShown: false,
      someFilterOpened: false
    };

    this.load();
  }

  @autobind
  private load() {
    if (this.identityService.isInRole(Roles.PoC)) {
      const currentUserInfo = this.identityService.getUserInfo();
      const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];
      this.props.users.getUsersByRoleAndLocationsAndOData(this.state.query, pocLocations, 'Employee');
    } else this.props.users.getAllAsync(this.state.query);
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  @autobind
  private setFilterLocationPoc() {
    const currentUserInfo = this.identityService.getUserInfo();
    if (IdentityService.isPoc(currentUserInfo) && this.isFilterEmpty()) {
      const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];
      const filter = pocLocations.map(l => `locationId eq ${l}`).join(' or ');
      Object.assign(this.state.query, { filter, skip: 0 });
    }
  }

  @autobind
  private isFilterEmpty() {
    if (!this.state.query.filter) return true;

    let filter = this.state.query.filter as any[];

    if (typeof filter === 'string') {
      return false;
    } else {
      if ((filter || []).any(x => !isNullOrEmpty(x))) return false;
      else return true;
    }
  }

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    const newChanges: OrderDefinition = { direction: 'Descending', field: 'modifiedOn', useProfile: false };
    this.setState(({ query }) => ({ query: { ...query, orderBy: [...orderBy, newChanges] } }), this.load);
  };

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: UserDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.users.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: UserDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.users.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.filter(x => x.id !== 'roles').map(f => f.filter);
    const activeFilters = filters.map(f => f.id);
    let parameters = {};
    if (filter.length !== filters.length) {
      parameters = filters.first(x => x.id === 'roles').filter;
    }

    const query = Object.assign(this.state.query, { filter, skip: 0, parameters });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newUserShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newUserShown: false });
    if (isSuccess) this.load();
  }

  private uploadComplete(data: any) {
    this.load();
  }

  @autobind
  private mapLanguages(languages: any[]) {
    languages.forEach(item => {
      if (!item.languageId) item.languageId = item.id;
      if (!item.id) item.id = item.languageId;
    });
    return languages;
  }

  @autobind
  private handleOnEnterKeydown(item: UserDto) {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  }

  viewModelToChangeDto = (item: UserDto): ChangeUserDto => {
    const { id, email, employeeId, enabled, firstName, lineManager, personnelAreaId, personnelArea } = item;
    const { positionCodeId, positionCode, sfPosition, updateFlag, lastName, region, portrait, location, userName, isGlobalPoc } = item;
    const { hireDate, leavingDate, acronym, origin, languages, roles, hasProfileAssigned, instructorRoleId } = item;
    return {
      id,
      email,
      employeeId,
      enabled: !enabled,
      firstName,
      lineManager,
      personnelAreaId,
      personnelArea,
      positionCodeId,
      positionCode: positionCode?.name || '',
      sfPosition,
      updateFlag,
      lastName,
      region,
      portrait,
      locationId: location.id,
      location: location.location,
      userName,
      hireDate,
      leavingDate: enabled && !leavingDate ? DateTimeService.now().toISOString() : leavingDate,
      acronym,
      origin,
      languages,
      roles: roles.map(({ location, region, role }) => ({
        locationId: location?.id || null,
        regionId: region?.id || null,
        roleName: role.name
      })),
      hasProfileAssigned: hasProfileAssigned,
      instructorRoleId: instructorRoleId,
      isGlobalPoc
    };
  };

  handleToggleAvailability = async (item: UserDto) => {
    if (!item) return;
    const userDto = this.viewModelToChangeDto(item);
    !userDto.enabled && this.setState({ disableUserModalShown: true });
    this.props.changeUserStore.change(userDto);
    await this.props.changeUserStore.update();
    this.load();
  };

  isSapUser(user: UserDto) {
    return !!user?.employeeId;
  }

  canEditSapUser(user: UserDto): boolean {
    return this.isSapUser(user) && user?.updateFlag && this.identityService.isInRole(Roles.Admin);
  }

  canEditActive(user: UserDto): boolean {
    if (this.identityService.isInRole(Roles.PoC)) return true;
    else return !this.isSapUser(user) || this.canEditSapUser(user);
  }

  onDismiss = () => {
    const { users, changeUserStore } = this.props;
    users.clearMessages();
    changeUserStore.clearMessages();
  };

  onDisableUserModalClosed = () => {
    this.setState({ disableUserModalShown: false });
  };

  public render() {
    const { t, users, changeUserStore } = this.props;
    const { activeFilters, someFilterOpened } = this.state;
    const currentUserInfo = this.identityService.getUserInfo();
    const areYouAdmin = IdentityService.isAdmin(currentUserInfo);
    const areYouPoc = IdentityService.isPoc(currentUserInfo);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];

    let errMsgs = (users.state.result?.messages || [])?.map(o => o.body);
    errMsgs = [...errMsgs, ...(changeUserStore.state.result?.messages || [])?.map(x => x.body)];

    const tableModel = {
      columns: [
        {
          title: t('User ID'),
          tooltipRenderer: false,
          renderer: data => (
            <TableTooltipCell textToShow={data.friendlyId} newClassFromComponent={data.enabled ? '' : 'inactive-text'}></TableTooltipCell>
          ),
          selectableHeader: true,
          sortDefinition: { field: nameof<UserDto>('friendlyId'), useProfile: false }
        },
        {
          title: t('Last Name'),
          tooltipRenderer: false,
          renderer: data => (
            <TableTooltipCell textToShow={data.lastName} newClassFromComponent={data.enabled ? '' : 'inactive-text'}></TableTooltipCell>
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Last Name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<UserDto>('lastName'), `startswith(tolower(surname), '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<UserDto>('lastName'))}
              active={activeFilters.includes(nameof<UserDto>('lastName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: { field: 'surname', useProfile: false }
        },
        {
          title: t('First Name'),
          tooltipRenderer: false,
          renderer: data => (
            <TableTooltipCell textToShow={data.firstName} newClassFromComponent={data.enabled ? '' : 'inactive-text'}></TableTooltipCell>
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by First Name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<UserDto>('firstName'), `startswith(tolower(name), '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<UserDto>('firstName'))}
              active={activeFilters.includes(nameof<UserDto>('firstName'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('User Name'),
          tooltipRenderer: false,
          renderer: data => (
            <TableTooltipCell textToShow={data.userName} newClassFromComponent={data.enabled ? '' : 'inactive-text'}></TableTooltipCell>
          ),
          selectableHeader: false,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by User Name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<UserDto>('userName'), `startswith(tolower(username), '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<UserDto>('userName'))}
              active={activeFilters.includes(nameof<UserDto>('userName'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Email'),
          tooltipRenderer: false,
          renderer: data => (
            <TableTooltipCell textToShow={data.email} newClassFromComponent={data.enabled ? '' : 'inactive-text'}></TableTooltipCell>
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Email')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<UserDto>('email'), `contains(tolower(email), '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<UserDto>('email'))}
              active={activeFilters.includes(nameof<UserDto>('email'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Roles'),
          tooltipRenderer: false,
          renderer: data => this.getRolesInLocationOrRegionsAsParagraphs(data),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<RoleDto>
              filterTitle={t('Filter by Role')}
              triggerTitle={title}
              onFilter={(value: string) => onFilter(nameof<UserDto>('roles'), { role: value })}
              onClear={() => onClear(nameof<UserDto>('roles'))}
              active={activeFilters.includes(nameof<UserDto>('roles'))}
              getItems={q => this.props.dropdownroles.getAllAsync(q)}
              parameters="id,name"
              orderBy={[{ direction: 'Ascending', field: nameof<RoleDto>('name'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(name)': { startswith: search.toLowerCase() } })}
              valueSelector={(u: RoleDto) => u.name}
              titleSelector={(u: RoleDto) => GetRoleName(u.name)}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Country'),
          tooltipRenderer: false,
          renderer: data => (
            <TableTooltipCell
              textToShow={this.getCountryWithFlag(data.location)}
              newClassFromComponent={data.enabled ? '' : 'inactive-text'}
            ></TableTooltipCell>
          ),
          selectableHeader: true
        },
        {
          title: t('Location'),
          tooltipRenderer: false,
          renderer: data => (
            <TableTooltipCell
              textToShow={data.location ? data.location.location : ''}
              newClassFromComponent={data.enabled ? '' : 'inactive-text'}
            ></TableTooltipCell>
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<LocationDto>
              filterTitle={t('Filter by Location')}
              triggerTitle={title}
              onFilter={(value: string) => onFilter(nameof<UserDto>('location'), { locationId: { eq: { type: 'guid', value } } })}
              onClear={() => onClear(nameof<LocationDto>('location'))}
              active={activeFilters.includes(nameof<LocationDto>('location'))}
              getItems={q => this.props.dropdownlocations.getAllAsync(q)}
              parameters="id,location"
              orderBy={[{ direction: 'Ascending', field: nameof<LocationDto>('location'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(location)': { startswith: search.toLowerCase() } })}
              valueSelector={(l: LocationDto) => l.id}
              titleSelector={(l: LocationDto) => l.location}
              preFilteredIds={areYouPoc ? pocLocations : null}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Active'),
          renderer: data => (
            <Checkbox
              disabled={!this.canEditActive(data)}
              toggle
              checked={data.enabled}
              onChange={() => this.handleToggleAvailability(data)}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <CheckboxFilter
              filterTitle={t('Filter by Active/Inactive')}
              trueLabel={t('Active')}
              falseLabel={t('Inactive')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<UserDto>('enabled'), { enabled: value })}
              onClear={() => onClear(nameof<UserDto>('enabled'))}
              active={activeFilters.includes(nameof<UserDto>('enabled'))}
            />
          )
        }
      ],
      data: this.props.users.state
    } as TableModel<UserDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {errMsgs.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                onDismiss={this.onDismiss}
                header={t('An error ocurred')}
                list={errMsgs}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newUserShown && !this.state.changeUserShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              /////////////////For build table keyboard navegation/////////////////
              isRowDisableLayout={(item: UserDto) => !item.enabled}
              model={tableModel}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={areYouAdmin}
              createNewButtonTitle={t('Add User')}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="eye" />
                      &nbsp;{t('Open Employee Card')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onOpenEmployee(item as UserDto));
                  }
                }
              ]}
              bulkInsertUrl={areYouAdmin ? 'identity/api/v1/bulk-insert' : null}
              bulkTemplateName={areYouAdmin ? 'users.xlsx' : null}
              bulkTemplateUrl={areYouAdmin ? 'identity/api/template' : null}
              exportUrl={areYouAdmin ? 'identity/api/export' : null}
              uploadComplete={data => this.uploadComplete(data)}
              loading={this.props.changeUserStore.state.isBusy}
            ></TableView>
          </Grid.Row>
        </Grid>

        {(this.state.newUserShown && <NewUserView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeUserShown && (
            <ChangeUserView
              onClose={this.onEditItemClosed}
              currentUser={this.state.selectedItem}
              onChange={languages => {
                languages = this.mapLanguages(languages);
                this.setState({ selectedItem: Object.assign(this.state.selectedItem, { languages }) });
              }}
            />
          )) ||
          (this.state.disableUserModalShown && (
            <Modal className="modal" open closeOnEscape={true} onClose={this.onDisableUserModalClosed}>
              <Modal.Header>{t('User disable warning info.')}</Modal.Header>
              <Modal.Content>
                {t(
                  'By disabling a user here, the effective leaving date wil be set to today automatically, it can be changed in the edit form.'
                )}
              </Modal.Content>
            </Modal>
          ))}
      </>
    );
  }
  @autobind
  onOpenEmployee(user: UserDto): void {
    openInNewWindow(`./employee-page/${user.id}`);
  }

  @autobind
  onEditItem(): void {
    this.setState({ changeUserShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeUserShown: false });
    if (isSuccess) this.load();
  }

  private getRolesInLocationOrRegionsAsParagraphs = (data: UserDto) =>
    (data?.roles || []).map(({ location, region, role }) =>
      location || region ? (
        location?.active ? (
          <List.Item key={`${data.id}-${role.id}-${location.id}`}>
            <TableTooltipCell textToShow={`${GetRoleName(role.name)} - ${location.location}`} />
          </List.Item>
        ) : region ? (
          <List.Item key={`${data.id}-${role.id}-${region.id}`}>
            <TableTooltipCell textToShow={`${GetRoleName(role.name)} - ${region.name}`} />
          </List.Item>
        ) : null
      ) : (
        <List.Item key={`${data.id}-${role.id}`}>
          <TableTooltipCell textToShow={GetRoleName(role.name)} />
        </List.Item>
      )
    );

  private getCountryWithFlag = (location: LocationDto) =>
    location ? (
      <span>
        <CountryFlag countryName={location.countryName} countryIsoCode={location.isoCode} />
        &nbsp;{location.countryName}
      </span>
    ) : null;
}

export default withTranslation()(UsersListPage);
