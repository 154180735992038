import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Popup, Button, Menu, Dimmer, Loader, Modal, Grid } from 'semantic-ui-react';
import { SsaQuestionBankDto, SsaQuestionBanksStore } from 'stores/assessments/questionBank/ssa-questionBank-store';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult, FileInfo } from 'stores/types';
import { getProperties, clone } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber } from 'utils/useful-functions';
import MultiSelectorTNASSATagsFilters, { TNATagsFiltersValues } from 'widgets/bussiness/multiple-selector/multiple-tna-ssa-tags-editor';
import { SsaSkillsStore } from 'stores/assessments/skills/ssa-skills-store';
import NewSsaQuestionBankView from './new-ssa-question-bank';
import ChangeSsaQuestionBankView from './edit-ssa-question-bank';
import MultiLanguageEditor from 'widgets/bussiness/multiple-selector/multi-language-editor';
import { UploadSsaQuestionBankStore, DownloadSsaQuestionBankStore } from 'stores/assessments/questionBank/ssa-questionBank-upload-store';
import { faFileUpload, faFileExcel, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

export interface SsaQuestionBankListProps extends WithTranslation, RouteComponentProps {
  ssaQuestionBankStore: SsaQuestionBanksStore;
  ssaSkillsStore: SsaSkillsStore;
  uploadSsaStore: UploadSsaQuestionBankStore;
  downloadSsaStore: DownloadSsaQuestionBankStore;
}

export interface SsaQuestionBankListState {
  query: Query;
  newQuestionBankShown: boolean;
  changeQuestionBankShown: boolean;
  activeFilters: { [key: string]: any };
  selectedItem: any;
  isclone: boolean;
  cloneData: SsaQuestionBankDto;
  languagesIdentifiers: string[];
  languageIdentifier: string;
  uploadMenuOpen: boolean;
  showUploadMsg: boolean;
  showDownloadMsg: boolean;
  uploadLoadingMessage: string;
  showDeleteConfirm: boolean;
  deleteModalText: string;
  someFilterOpened: boolean;
}

@connect(
  ['ssaQuestionBankStore', SsaQuestionBanksStore],
  ['ssaSkillsStore', SsaSkillsStore],
  ['uploadSsaStore', UploadSsaQuestionBankStore],
  ['downloadSsaStore', DownloadSsaQuestionBankStore]
)
class SsaQuestionBankList extends React.Component<SsaQuestionBankListProps, SsaQuestionBankListState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newQuestionBankShown: false,
      changeQuestionBankShown: false,
      activeFilters: [],
      selectedItem: null,
      cloneData: null,
      isclone: false,
      languagesIdentifiers: [],
      languageIdentifier: '',
      uploadMenuOpen: false,
      showUploadMsg: false,
      showDownloadMsg: false,
      uploadLoadingMessage: '',
      showDeleteConfirm: false,
      deleteModalText: '',
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const filter = [].concat(
      ...getProperties(activeFilters)
        .filter(({ value }) => !!value)
        .map(({ value }) => value)
    );
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    this.setState({ query, showUploadMsg: false, showDownloadMsg: false, uploadMenuOpen: false }, () =>
      this.props.ssaQuestionBankStore.getAllAsync(this.state.query)
    );
  };

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: SsaQuestionBankDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.ssaQuestionBankStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: SsaQuestionBankDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.ssaQuestionBankStore.deleteAsync(item.id, state);
      this.hideConfirmModal();
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async showDeleteModal() {
    if (this.state.selectedItem.state !== 'New') {
      var count = await this.props.ssaQuestionBankStore.getUsedTnaQuestionCountById(this.state.selectedItem.id);
      var modalText =
        count > 0
          ? `This question is used in ${count} template${count === 1 ? '' : 's'} and the template${
              count === 1 ? '' : 's'
            } will become inactive.\r\n Are you sure that you want to delete the question?`
          : 'Are you sure that you want to delete the question?';

      this.setState({
        deleteModalText: this.props.t(modalText)
      });
      this.setState({ showDeleteConfirm: true });
    }
  }

  @autobind
  private hideConfirmModal() {
    this.setState({ showDeleteConfirm: false, selectedItem: null });
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newQuestionBankShown: true });
  }

  @autobind
  private onCloneItem() {
    this.setState({ newQuestionBankShown: true, isclone: true, cloneData: clone(this.state.selectedItem) });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newQuestionBankShown: false, isclone: false, cloneData: null });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeQuestionBankShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeQuestionBankShown: false, selectedItem: null });
    if (isSuccess) this.load();
  }

  private uploadComplete = (data: any) => {
    this.load();
  };

  private onFilterQuestionOrId = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) {
      delete activeFilters['IdDescription'];
    } else {
      const input = value as string;
      const parts = [`contains(tolower(question/text), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'QS');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('QS')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      activeFilters['IdDescription'] = `(${parts.join(' or ')})`;
    }

    this.setState(
      {
        activeFilters
      },
      this.load
    );
  };

  @autobind private multiChangeLanguagesFilters(languagesIds: string[]) {
    const activeFilters = { ...this.state.activeFilters };

    if (languagesIds != null && languagesIds.length > 0) {
      activeFilters['languages'] = {
        or: [
          { QuestionTranslations: { any: { LanguageId: { in: { type: 'guid', value: languagesIds } } } } },
          { Question: { LanguageId: { in: { type: 'guid', value: languagesIds } } } }
        ]
      };
    } else delete activeFilters['languages'];
    this.setState({ activeFilters }, () => this.load());
  }

  handleMultiTagSelector = (filters: TNATagsFiltersValues) => {
    const activeFilters = { ...this.state.activeFilters };

    if (filters.professions.length > 0)
      activeFilters['professions'] = { Professions: { any: { id: { in: { value: filters.professions, type: 'guid' } } } } };
    else delete activeFilters['professions'];

    if (filters.categories.length > 0)
      activeFilters['categories'] = { TestCategoryId: { in: { type: 'guid', value: filters.categories } } };
    else delete activeFilters['categories'];

    if (filters.ssaSkills.length > 0) activeFilters['ssaSkillId'] = { SsaSkillId: { in: { type: 'guid', value: filters.ssaSkills } } };
    else delete activeFilters['ssaSkillId'];

    this.setState({ activeFilters }, () => this.load());
  };

  private onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file: File;
    if (event && event.target && event.target.files && event.target.files.length !== 0 && event.target.files[0])
      file = event.target.files[0];
    else return;
    const { t } = this.props;
    this.setState({ showUploadMsg: true, uploadMenuOpen: false, uploadLoadingMessage: t('Queuing file for import background process') });
    const usr = this.identityService.getUserInfo();
    const fileInfo: FileInfo = {
      fileName: file.name,
      fileSize: file.size,
      content: file,
      userId: usr.userId,
      userName: usr.userName
    };
    this.props.uploadSsaStore
      .uploadFile(fileInfo)

      .then(res => {
        res.data.isSuccess &&
          ToastComponent({ text: this.props.t('File has been uploaded. Merging the list is in progress'), type: 'success-toast' });
        !res.data.isSuccess && ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      });
  };

  private handleUploadMsgDismiss = () => {
    this.setState({ showUploadMsg: false });
  };
  private handleDownloadMsgDismiss = () => {
    this.setState({ showDownloadMsg: false });
  };
  private getBackgroundUploadMessage = () => {
    const { isBusy, result } = this.props.uploadSsaStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    let error = false;
    if (result.error || (result.response && !result.response.isSuccessful)) {
      header = t('Something went wrong with the file');
      content = result.error?.message;
      error = true;
    } else if (result.error == null && result.response && result.response.isSuccessful) {
      header = t('File uploaded successfully');
      content = t('You will receive an email when the process ends');
      error = false;
    } else return null;

    return (
      <Message
        className={error && 'error-message__style'}
        icon={error && 'exclamation circle'}
        onDismiss={this.handleUploadMsgDismiss}
        header={header}
        content={content}
        error={error}
        success={!error}
      />
    );
  };

  private getBackgroundDownloadMessage = () => {
    const { isBusy, result } = this.props.downloadSsaStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    if (!result.isSuccess) {
      header = t('Something went wrong');
      content = result.messages.map(x => x.body).join('. ');
    } else {
      header = t('Background process initialized successfully');
      content = t('You will receive an email when the process ends');
    }

    return (
      <Message
        className={!result.isSuccess && 'error-message__style'}
        icon={!result.isSuccess && 'exclamation circle'}
        onDismiss={this.handleDownloadMsgDismiss}
        header={header}
        content={content}
        error={!result.isSuccess}
        success={result.isSuccess}
      />
    );
  };

  private initExportQuestionBankProcess = () => {
    this.setState({ showDownloadMsg: true });
    this.props.downloadSsaStore.initBackgroundDownload();
  };

  private downloadTemplate = () => {
    const { t } = this.props;
    this.props.uploadSsaStore
      .downloadTemplate()

      .then(res => {
        ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
      });

    this.setState({ uploadMenuOpen: false, showUploadMsg: true, uploadLoadingMessage: t('Downloading the file') });
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t } = this.props as any;
    const { someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Question ID'),
          tooltipRenderer: true,
          renderer: data => data.friendlyId,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<SsaQuestionBankDto>('friendlyId'),
            useProfile: false
          }
        },
        {
          title: t('Questions'),
          tooltipRenderer: false,
          renderer: data => <div>{data.question.text}</div>,
          selectableHeader: true
        },
        {
          title: t('Skills'),

          tooltipRenderer: true,
          renderer: data => (data.ssaSkill ? data.ssaSkill.name : ''),
          selectableHeader: true
        },
        {
          title: t('Category'),
          tooltipRenderer: false,
          renderer: data => <span className="question-bank__cell__tag">{data.testCategory.name}</span>,
          sortDefinition: {
            field: 'testCategory/name',
            useProfile: false
          }
        },
        {
          title: t('Roles'),
          tooltipRenderer: false,
          renderer: data => {
            const allProfessions = data.professions.map(profession => (
              <span className={data.professions && profession ? 'question-bank__cell__tag' : ''}>{profession.profession}</span>
            ));

            return <div className={data.professions && data.professions.length > 1 ? 'table-cell__more-width' : ''}>{allProfessions}</div>;
          }
        },
        {
          title: t('Language'),
          tooltipRenderer: false,
          renderer: data => {
            const languagesList = [data.question.language];
            data.questionTranslations.forEach(questionTranslation => languagesList.push(questionTranslation.language));
            const allLanguages = languagesList.map(item => <span className={'question-bank__cell__tag'}>{item?.languageCode}</span>);
            return (
              <div className={allLanguages && allLanguages.length > 1 ? 'table-cell__more-width question-bank__language__div' : ''}>
                {allLanguages}
              </div>
            );
          },

          selectableHeader: true
        }
      ],
      data: this.props.ssaQuestionBankStore.state
    } as TableModel<SsaQuestionBankDto>;

    return (
      <div className="question-bank__wrapper">
        {this.props.uploadSsaStore.state.isBusy && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{this.state.uploadLoadingMessage}</Loader>
          </Dimmer>
        )}
        {this.state.showUploadMsg && this.getBackgroundUploadMessage()}

        {this.props.downloadSsaStore.state.isBusy && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Initializing download background process')}</Loader>
          </Dimmer>
        )}
        {this.state.showDownloadMsg && this.getBackgroundDownloadMessage()}

        <Grid className="event-types-list-grid">
          {this.props.ssaQuestionBankStore.state.result && !this.props.ssaQuestionBankStore.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.ssaQuestionBankStore.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <div className="tna__inputs-wrapper table__filters-share-main-actions">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Question or ID')}
              onChange={this.onFilterQuestionOrId}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <MultiLanguageEditor
              value={this.state.languagesIdentifiers}
              onChange={language => this.multiChangeLanguagesFilters(language)}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <MultiSelectorTNASSATagsFilters
              placeholder={t('Filter tags')}
              className="question-bank__multiple-dropdown"
              onChange={this.handleMultiTagSelector}
              mode="SSA"
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
          </div>
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.showDeleteConfirm && !this.state.newQuestionBankShown && !this.state.changeQuestionBankShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraMenu={[
                {
                  className: 'menu-item-download',
                  content: (
                    <Popup
                      on="click"
                      position="bottom center"
                      open={this.state.uploadMenuOpen}
                      onOpen={() => this.setState({ uploadMenuOpen: true })}
                      onClose={() => this.setState({ uploadMenuOpen: false })}
                      trigger={
                        <Button
                          icon
                          size="mini"
                          className="custom-table-upload-btn custom-table-upload-btn__up-down transparent-btn"
                          onClick={() => this.setState({ uploadMenuOpen: true })}
                          data-tooltip={t('Import')}
                        >
                          <FontAwesomeIcon className="solid" icon={faFileUpload} size="lg" />
                        </Button>
                      }
                    >
                      <Menu vertical secondary>
                        <Menu.Item key="upload" position="left" as="label" htmlFor="file" className="turquose-onhover">
                          <FontAwesomeIcon className="solid float-right" icon={faFileUpload} size="lg" />
                          <input type="file" id="file" style={{ display: 'none' }} onChange={this.onUpload} />
                          &nbsp;{t('Upload File')}
                        </Menu.Item>

                        <Menu.Item className="turquose-onhover" key="template" position="left" onClick={this.downloadTemplate}>
                          <FontAwesomeIcon className="solid float-right" icon={faFileExcel} size="lg" />
                          &nbsp;{t('Download Template')}
                        </Menu.Item>
                      </Menu>
                    </Popup>
                  )
                },
                {
                  className: 'menu-item-download',
                  content: (
                    <Button
                      icon
                      size="medium"
                      onClick={this.initExportQuestionBankProcess}
                      className="custom-table-upload-btn black-btn"
                      data-tooltip={t('Export')}
                    >
                      <FontAwesomeIcon className="solid" icon={faFileDownload} size="lg" />
                    </Button>
                  )
                }
              ]}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil" />
                      {<span className="text__bold">{t('Edit')}</span>}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="clone" />
                      {<span className="text__bold">{t('Clone')}</span>}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onCloneItem());
                  }
                },
                {
                  content: (
                    <>
                      <Icon color="red" name="trash alternate" />
                      {<span className="text__red text__bold">{t('Delete')}</span>}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, this.showDeleteModal);
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              canCreateNew={true}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              createNewButtonTitle={t('New SSA Question')}
            ></TableView>
          </Grid.Row>
        </Grid>

        <Modal
          open={this.state.showDeleteConfirm}
          size="mini"
          className="have-warnings-popup"
          closeOnEscape={true}
          onClose={() => this.hideConfirmModal()}
        >
          <Modal.Content className="have-warnings-popup__content">
            <p>{this.state.deleteModalText}</p>
          </Modal.Content>
          <div className="have-warnings-popup__buttons-container">
            <Button className="have-warnings-popup__btn" content={t('No')} onClick={() => this.hideConfirmModal()} />
            <Button
              className="have-warnings-popup__btn have-warnings-popup__pending-btn"
              content={t('Yes')}
              onClick={() => this.onDelete(this.state.selectedItem, this.state.selectedItem.state)}
            />
          </div>
        </Modal>
        {this.state.newQuestionBankShown && (
          <NewSsaQuestionBankView
            cloneData={this.state.cloneData}
            isClone={this.state.isclone}
            onClose={this.onNewItemClosed}
            {...this.props}
          />
        )}

        {this.state.changeQuestionBankShown && (
          <ChangeSsaQuestionBankView onClose={this.onEditItemClosed} currentData={this.state.selectedItem} />
        )}
      </div>
    );
  }
}

export default withTranslation()(SsaQuestionBankList);
