import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Input, Icon, Button } from 'semantic-ui-react';
import PeriodInput from 'widgets/form/period-input';
import { DateTimePeriod, EventSupportPositionsDto, EventPositionDto } from 'stores/events/events-store';
import { UserDto } from 'stores/users/users-store';
import ChooseStudentsForm from 'widgets/form/choose-students-form';
import { DatePeriod } from 'site/pages/landing-pages/shared-scheduler-components/events/scheduler-period-service';
import { ReducerType, useStateReducer } from 'utils/react-custom-hooks';
import { IdentityService } from 'services/identity-service';
import { container } from 'inversify.config';

interface InfoSupportDetailedProps extends WithTranslation, EventSupportPositionsDto {
  readonly: boolean;
  onChange: (values: EventSupportPositionsDto) => void;
  onNewPosition: (newUser: UserDto, dates: DatePeriod) => void;
  isHideEditButton: boolean;
}

interface InfoSupportDetailedState {
  showEmployeeSelectionModal: boolean;
  newPosition: boolean;
  userChangeIndex: number;
}

const getDefaultValues = (): InfoSupportDetailedState => ({ showEmployeeSelectionModal: false, newPosition: false, userChangeIndex: -1 });

const InfoSupportDetailed: React.FC<InfoSupportDetailedProps> = props => {
  const identityService: IdentityService = container.get(IdentityService);
  const isAdminPocOrPlanner = (): boolean => {
    return IdentityService.isAdminPocOrPlanner(identityService.getUserInfo());
  };
  const isInstructor = (): boolean => {
    return IdentityService.isInstructor(identityService.getUserInfo());
  };
  const { positionCodeId, positionDescription, requestEndDate, requestStartDate, t, onChange, onNewPosition, ...rest } = props;
  const { positionCodeName, comment, positionsRequested, eventPositions, id, readonly, isHideEditButton } = rest;
  const [{ showEmployeeSelectionModal, newPosition, userChangeIndex }, setState] = React.useReducer<
    ReducerType<InfoSupportDetailedState>,
    InfoSupportDetailedState
  >(useStateReducer, getDefaultValues(), getDefaultValues);

  const initialItem = {
    id,
    positionCodeId,
    positionCodeName,
    positionDescription,
    comment,
    positionsRequested,
    eventPositions,
    requestEndDate,
    requestStartDate
  };
  const openModal = (isNewPosition: boolean = false) => {
    const changes: Partial<InfoSupportDetailedState> = { showEmployeeSelectionModal: true };
    if (isNewPosition) changes.newPosition = isNewPosition;
    setState(changes);
  };

  const closeModal = () => setState({ showEmployeeSelectionModal: false });

  const onEmployeesSelected = (employees: UserDto[]) => {
    const employee = employees.firstOrDefault();
    if (employee == null) return;
    if (newPosition) onNewPosition(employee, { from: requestStartDate, to: requestEndDate });
    else {
      const eventPositions = props.eventPositions.map((eventPosition, i) =>
        userChangeIndex === i
          ? {
              ...eventPosition,
              userLocationId: employee.roles.find(({ role }) => role.name === 'Employee').location.id,
              userId: employee.id,
              user: employee
            }
          : eventPosition
      );
      onChange({ ...initialItem, eventPositions });
    }

    setState({ newPosition: false, userChangeIndex: -1 });
  };

  const changeEmployeeHandler = (idx: number) => setState({ userChangeIndex: idx, showEmployeeSelectionModal: true });

  const formatInput = (e: any) => {
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-';
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const datesChangeHandler = ({ from, to }: DateTimePeriod, idx: number, travelDays: boolean = false) => {
    let eventPositions: EventPositionDto[];
    const fromDate = travelDays ? 'travelDateFrom' : 'startDate';
    const toDate = travelDays ? 'travelDateTo' : 'endDate';

    if (from !== props.eventPositions[idx][fromDate])
      eventPositions = props.eventPositions.map((supportPos, i) => (i === idx ? { ...supportPos, [fromDate]: from } : supportPos));
    else if (to !== props.eventPositions[idx][toDate])
      eventPositions = props.eventPositions.map((supportPos, i) => (i === idx ? { ...supportPos, [toDate]: to } : supportPos));

    eventPositions && onChange({ ...initialItem, eventPositions });
  };

  const inputChangeHandler = (property: string, value: any, idx: number) => {
    let eventPositions;
    eventPositions = props.eventPositions.map((supportPos, i) => (i === idx ? { ...supportPos, [property]: value } : supportPos));
    eventPositions && onChange({ ...initialItem, eventPositions });
  };

  let emptyPositions = [];
  if ((eventPositions || []).length < positionsRequested) {
    for (let index = 0; index < positionsRequested - eventPositions.length; index++) {
      emptyPositions.push(
        <div className="events-support-details__info-position">
          <label>Position</label>
          <Input disabled value={positionDescription} />
          <Button disabled={readonly} onClick={() => openModal(true)} icon>
            <Icon name="user plus" />
          </Button>
        </div>
      );
    }
  }

  return (
    <div className="events-support-details__main-detail">
      {(eventPositions || []).map((ep, idx) => {
        const { actualCost, actualHours, actualDays, endDate, startDate, travelDateFrom, travelDateTo, user, userId } = ep;
        const employeeRole = user.roles.find(x => x.role.name === 'Employee');
        const employeeLocation = employeeRole?.location?.location || t('|LOCATION|');
        return (
          <div key={'event-position' + id + comment + userId + idx} className="events-support-details__detail">
            <div className="events-support-details__detail-position">
              <div className="info-support-details__comment">
                <Input disabled value={comment} placeholder={t('Free text from Request')} />
              </div>
              <div className="info-support-details__employee-info">
                <div className="info-support-details__employee-detailed hzt-section">
                  <span>{t('Position')}</span>
                  <Input disabled value={positionDescription} />
                </div>
                <div className="info-support-details__employee-detailed hzt-section">
                  <span>{t('Employee')}</span>
                  <Input disabled value={t(user.lastName + ', ' + user.firstName)} />
                </div>
                <div className="info-support-details__employee-detailed hzt-section">
                  <span>{t('SAP ID')}</span>
                  <Input disabled value={user.employeeId} />
                </div>
                <div className="info-support-details__employee-detailed hzt-section">
                  <span>{t('Position Code')}</span>
                  <Input disabled value={user.positionCode?.code || ''} />
                </div>
                <div className="info-support-details__employee-detailed hzt-section">
                  <span>{t('Location')}</span>
                  <Input disabled value={employeeLocation} />
                </div>
                <div className="info-support-details__employee-detailed hzt-section">
                  <span>{t('Line Manager')}</span>
                  <Input disabled value={user.lineManager} />
                </div>
              </div>
              <div className="info-support-details__employee-info">
                <div className="info-support-details__employee-detailed hzt-section">
                  <span>{t('Dates')}</span>
                  <PeriodInput
                    id={'info-support-details-dates-' + id + comment + userId + idx}
                    readOnly={readonly}
                    onChange={period => datesChangeHandler(period, idx)}
                    value={{ from: startDate, to: endDate }}
                  />
                </div>
                <div className="info-support-details__employee-detailed hzt-section">
                  <span>{t('Actual Days')}</span>
                  <Input
                    labelPosition="right"
                    type="number"
                    label={{ basic: true, content: 'w/d' }}
                    disabled={readonly}
                    onChange={(_, { value }) => inputChangeHandler('actualDays', value, idx)}
                    value={actualDays}
                    onKeyDown={formatInput}
                  />
                </div>
                <div className="info-support-details__employee-detailed hzt-section">
                  <span>{t('Actual Hours')}</span>
                  <Input
                    labelPosition="right"
                    type="number"
                    label={{ basic: true, content: 'h' }}
                    disabled={readonly}
                    onChange={(_, { value }) => inputChangeHandler('actualHours', value, idx)}
                    value={actualHours}
                    onKeyDown={formatInput}
                  />
                </div>
                <div className="info-support-details__employee-detailed hzt-section">
                  <span>{t('Actual Cost')}</span>
                  <Input
                    className="enlarged-input"
                    labelPosition="right"
                    label={{ basic: true, content: '$' }}
                    type="number"
                    disabled={readonly || isInstructor()}
                    onChange={(_, { value }) => inputChangeHandler('actualCost', value, idx)}
                    value={actualCost}
                    onKeyDown={formatInput}
                  />
                </div>
                <div className="info-support-details__employee-detailed hzt-section">
                  <span>{t('Travel Days')}</span>
                  <PeriodInput
                    id={'info-support-details-travel-days-' + id + comment + userId + idx}
                    readOnly={readonly}
                    onChange={period => datesChangeHandler(period, idx, true)}
                    value={{ from: travelDateFrom, to: travelDateTo }}
                  />
                </div>
              </div>
            </div>
            {isAdminPocOrPlanner() && !readonly && !isHideEditButton && <Icon onClick={() => changeEmployeeHandler(idx)} name="edit" />}
          </div>
        );
      })}
      {emptyPositions}
      {showEmployeeSelectionModal && (
        <ChooseStudentsForm
          showSFPositionFields
          showPositionCodeFields
          onAddParticipants={onEmployeesSelected}
          maxSelection={1}
          alreadySelectedUserIds={(eventPositions || []).map(({ userId }) => userId)}
          onCloseUsersModal={closeModal}
        />
      )}
    </div>
  );
};
export default withTranslation()(React.memo(InfoSupportDetailed));
