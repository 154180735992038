import * as autobind from 'autobind';
import * as React from 'react';
import { SketchPicker } from 'react-color';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  Form,
  FormGroup,
  Grid,
  Icon,
  Label,
  Message,
  Modal,
  Popup,
  Menu,
  List,
  Dimmer,
  Loader,
  Tab
} from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import {
  CreateEventTypeDto,
  NewEventTypeStore,
  StatusConfig,
  getStatusDefault,
  EventTypeDto,
  EventTypeCategory,
  rolesWFConst,
  newRolesWFConst,
  rolesRequestConst,
  extendedRolesWFConst,
  roleToTitleDict
} from 'stores/configuration/events-workflow/event-types-store';
import { nameof, getProperties, getKeyValuePairs } from 'utils/object';
import { NotificationSettingPopupItem } from './components/notification-setting-popup-item';
import { RoleSelectorPopupItem } from './components/role-selector-popup-item';
import { difference, firstToLowerCase, firstToUpperCase, customEqualCompare, isNullOrWhiteSpaces } from 'utils/useful-functions';
import i18n from 'i18n';
import { TimerInput } from 'widgets/editors/timer-input';
import IconSelector from 'widgets/editors/icon-selector/icon-selector';
import { getEventOwners } from 'utils/event-type-utils';
import {
  DropDownEventGroupRelationshipsStore,
  EventGroupRelationshipDto
} from 'stores/configuration/events-n-requests/event-group-rel-store';

export enum CreationType {
  Normal,
  Edit,
  Clone
}

interface NewEventTypeViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  creationType?: CreationType;
  newEventType?: NewEventTypeStore;
  initialData?: EventTypeDto;
  dropDownEventTypeGroup?: DropDownEventGroupRelationshipsStore;
}

// tslint:disable-next-line:no-empty-interface
interface NewEventTypeViewState {
  statusActorsOpenPopup: { [key: string]: boolean };
  statusConfigOpenPopup: { [key: string]: { [key: string]: boolean } };
  tabSelected: 0 | 1;
  errorMessagesVisible: boolean;
  eventGroupOptions: any[];
}

@connect(['newEventType', NewEventTypeStore], ['dropDownEventTypeGroup', DropDownEventGroupRelationshipsStore])
class NewEventTypeView extends React.Component<NewEventTypeViewProps, NewEventTypeViewState> {
  workflowRoles: string[] = rolesWFConst;
  newRoles: string[] = newRolesWFConst;
  workflowRolesExtended: string[] = extendedRolesWFConst;

  requestRoles: string[] = rolesRequestConst;
  requestRolesExtended: string[] = this.requestRoles.concat(newRolesWFConst);

  private get newEventTypeStore() {
    return this.props.newEventType;
  }
  private get dropDownEventTypeGroupsStore() {
    return this.props.dropDownEventTypeGroup;
  }

  constructor(props: NewEventTypeViewProps) {
    super(props);
    let isClonedEvent = props.creationType === CreationType.Clone;
    this.newEventTypeStore.createNew({
      id: props.initialData == null || isClonedEvent ? '' : props.initialData.id,
      name: props.initialData == null ? '' : props.initialData.name,
      eventsColor: props.initialData == null ? '#000000' : props.initialData.eventsColor,
      eventsIcon: props.initialData == null ? '' : props.initialData.eventsIcon,
      feedbacks: props.initialData == null ? false : props.initialData.feedbacks,
      preEventTest: props.initialData == null ? false : props.initialData.preEventTest,
      practicalTest: props.initialData == null ? false : props.initialData.practicalTest,
      postEventTest: props.initialData == null ? false : props.initialData.postEventTest,
      participants:
        props.initialData == null || props.initialData.eventTypeCategory === 'Extended' ? false : props.initialData.participants,
      participantsOptional:
        props.initialData == null || props.initialData.eventTypeCategory === 'Extended' ? true : props.initialData.participantsOptional,
      directEventsAllowed: props.initialData == null ? false : props.initialData.directEventsAllowed,
      instructor: props.initialData == null ? 'No' : props.initialData.instructor,

      rolesThatCreateRequests: props.initialData == null ? [] : props.initialData.rolesThatCreateRequests,
      roleThatValidateRequests: props.initialData == null ? null : props.initialData.roleThatValidateRequests,
      rolesThatCreateDirectEvents: props.initialData == null ? [] : props.initialData.rolesThatCreateDirectEvents,
      roleThatOwnEvents: null, //: props.initialData == null ? null : props.initialData.roleThatOwnEvents,

      draft: props.initialData == null ? getStatusDefault() : props.initialData.draft,
      planned: props.initialData == null ? getStatusDefault() : props.initialData.planned,
      inProgress: props.initialData == null ? getStatusDefault() : props.initialData.inProgress,
      completed: props.initialData == null ? getStatusDefault() : props.initialData.completed,
      closed: props.initialData == null ? getStatusDefault(true) : props.initialData.closed,

      requestFieldGroups: props.initialData == null ? ['Basic'] : props.initialData.requestFieldGroups,
      parentId: null,
      category: props.initialData == null ? EventTypeCategory[EventTypeCategory.Custom] : props.initialData.eventTypeCategory,

      rolesThatOwnEventsExtended: props.initialData == null ? [] : getEventOwners(props.initialData),
      rolesThatValidateRequestsExtended: props.initialData == null ? [] : props.initialData.rolesThatValidateRequestsExtended,
      eventGroupRelationId: props.initialData?.eventGroupRelationId || null
    });
    this.state = {
      statusActorsOpenPopup: {},
      statusConfigOpenPopup: {},
      errorMessagesVisible: false,
      tabSelected: 0,
      eventGroupOptions: []
    };
  }

  componentDidMount(): void {
    this.init();
  }

  private async init() {
    let eventTypes = this.getAllEventGroups();
    let eventGroupResponse = await eventTypes;
    const ego = this.mapEventGroups(eventGroupResponse.items);
    this.setState({
      eventGroupOptions: ego
    });
  }

  private getAllEventGroups() {
    return this.dropDownEventTypeGroupsStore.getAllAsync({
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [{ direction: 'Ascending', field: 'eventGroup/Name', useProfile: false }],
      filter: []
    });
  }

  private mapEventGroups(eventTypeGroups: EventGroupRelationshipDto[]): any[] {
    return eventTypeGroups.map(etg => ({
      value: etg.id,
      text: this.eventTypeGroupOptionText(etg),
      id: etg.id
    }));
  }

  private eventTypeGroupOptionText(dto: EventGroupRelationshipDto): string {
    let suffix = ` (Lead: ${dto.leadTypeHoursCodeId ? dto.leadTypeHoursCodeName : '-'}, Apprentice: ${
      dto.apprenticeTypeHoursCodeId ? dto.apprenticeTypeHoursCodeName : '-'
    })`;
    let text = dto.eventGroupName + suffix;
    return text;
  }

  UNSAFE_componentWillReceiveProps(nextProps: NewEventTypeViewProps) {
    const { result } = this.newEventTypeStore.state;
    if (result && result.isSuccess) {
      nextProps.onClose(true);
    }
  }

  @autobind
  private onCreateNewItem() {
    this.newEventTypeStore
      .submit()
      .then(r => {
        if (r != null) {
          this.setState({ errorMessagesVisible: !r.isSuccess });
        }
      })
      .catch(_ => {
        this.setState({ errorMessagesVisible: true });
      });
  }

  private onHideErrorMessages = () => {
    this.setState({ errorMessagesVisible: false });
  };

  @autobind
  private onCancelNewItem() {
    this.newEventTypeStore.clear();
    this.props.onClose(false);
  }

  private handleValues = (...changes: [string, any][]) => {
    if ((changes || []).length === 0) return;
    const { item } = this.newEventTypeStore.state;
    const change = {};
    changes.forEach(([prop, value]) => {
      change[prop] = value;
      if (prop === nameof<CreateEventTypeDto>('rolesThatCreateDirectEvents')) {
        change[nameof<CreateEventTypeDto>('directEventsAllowed')] = (value || []).length !== 0;
      }
    });
    this.newEventTypeStore.change({ ...item, ...change });
  };

  @autobind
  private handleValue(property: string, value: any) {
    const { item } = this.newEventTypeStore.state;
    const change = {};
    change[property] = value;
    if (property === nameof<CreateEventTypeDto>('rolesThatCreateDirectEvents')) {
      change[nameof<CreateEventTypeDto>('directEventsAllowed')] = (value || []).length !== 0;
    }
    this.newEventTypeStore.change({ ...item, ...change });
  }

  private closeStatusActorsPopup = (key: string) => {
    const statusActorsOpenPopup = this.state.statusActorsOpenPopup;
    statusActorsOpenPopup[key] = false;
    this.setState({ statusActorsOpenPopup });
  };
  private toggleStatusActorsPopup = (key: string) => {
    let statusActorsOpenPopup = this.state.statusActorsOpenPopup;

    const next = !statusActorsOpenPopup[key];

    if (next) {
      statusActorsOpenPopup = {};
      statusActorsOpenPopup[key] = next;

      this.setState({
        statusConfigOpenPopup: {},
        statusActorsOpenPopup
      });
    } else {
      statusActorsOpenPopup[key] = next;
      this.setState({ statusActorsOpenPopup });
    }
  };

  private closeStatusConfigPopup = (key1: string, key2: string) => {
    const statusConfigOpenPopup = this.state.statusConfigOpenPopup;
    if (statusConfigOpenPopup[key1] == null) statusConfigOpenPopup[key1] = {};

    statusConfigOpenPopup[key1][key2] = false;
    this.setState({ statusConfigOpenPopup });
  };
  private toggleStatusConfigPopup = (key1: string, key2: string) => {
    let statusConfigOpenPopup = this.state.statusConfigOpenPopup;

    if (statusConfigOpenPopup[key1] == null) statusConfigOpenPopup[key1] = {};

    const next = !statusConfigOpenPopup[key1][key2];

    if (next) {
      statusConfigOpenPopup = {};
      statusConfigOpenPopup[key1] = {};
      statusConfigOpenPopup[key1][key2] = next;

      this.setState({
        statusConfigOpenPopup,
        statusActorsOpenPopup: {}
      });
    } else {
      statusConfigOpenPopup[key1][key2] = next;
      this.setState({ statusConfigOpenPopup });
    }
  };

  private onModalClick = (e: any) => {
    let target = e.target;
    if (
      target.classList.length === 0 &&
      target.localName === 'label' &&
      target.parentElement != null &&
      target.parentElement.className.includes('checkbox')
    ) {
      target = target.parentElement;
    }

    if (
      target.classList.contains('popup') ||
      target.classList.contains('popup-item') ||
      target.classList.contains('popup-icon') ||
      target.classList.contains('popup-chkbx')
    )
      return;
    this.setState({
      statusConfigOpenPopup: {},
      statusActorsOpenPopup: {}
    });
  };

  private pruneStatusConfigObj = (obj: { [key: string]: string[] }) => {
    if (obj === undefined) return undefined;
    if (obj === null) return null;
    const next: { [key: string]: string[] } = {};
    getKeyValuePairs<string[]>(obj).forEach(({ key, value }) => {
      const upperKey = firstToUpperCase(key);
      if (!newRolesWFConst.includes(upperKey)) next[key] = value;
    });

    return next;
  };

  private pruneStatusConfig = (status: StatusConfig) => {
    if (status === undefined) return undefined;
    if (status === null) return null;

    const next: StatusConfig = {} as StatusConfig;

    next.active = status.active;

    if (status.actorRoles != null) next.actorRoles = status.actorRoles.filter(x => !newRolesWFConst.includes(x));

    if (status.rolesRequiredToValidateStatus != null)
      next.rolesRequiredToValidateStatus = status.rolesRequiredToValidateStatus.filter(x => !newRolesWFConst.includes(x));

    next.pastTime = status.pastTime;
    next.rolesNotifiedOnStatusChanged = this.pruneStatusConfigObj(status.rolesNotifiedOnStatusChanged);
    next.rolesNotifiedOnChanges = this.pruneStatusConfigObj(status.rolesNotifiedOnChanges);
    next.rolesNotifiedPastTime = this.pruneStatusConfigObj(status.rolesNotifiedPastTime);
    return next;
  };

  private mergeOwners = (owner: string, extendedOwners: string[], type: EventTypeCategory) => {
    let res = [];
    if (!isNullOrWhiteSpaces(owner)) res.push(owner);
    if ((extendedOwners || []).filter(r => !isNullOrWhiteSpaces(r))) res = [...res, ...extendedOwners];

    if (type !== EventTypeCategory.Extended) return res.filter(x => !newRolesWFConst.includes(x));

    return res;
  };

  private handleTabChange = (_: any, data: any) => {
    const { creationType } = this.props;
    if (creationType !== CreationType.Normal) return;

    const { item } = this.newEventTypeStore.state;

    let type =
      data.activeIndex === 0
        ? EventTypeCategory[EventTypeCategory.Custom]
        : data.activeIndex === 1
        ? EventTypeCategory[EventTypeCategory.Extended]
        : EventTypeCategory[EventTypeCategory.Vacations];
    this.setState({ tabSelected: data.activeIndex });

    let changes: [string, any][] = [[nameof<CreateEventTypeDto>('category'), type]];

    this.newEventTypeStore.state.result = null;

    if (EventTypeCategory[type] === EventTypeCategory.Custom) {
      changes = [
        ...changes,
        [nameof<CreateEventTypeDto>('draft'), this.pruneStatusConfig(item.draft)],
        [nameof<CreateEventTypeDto>('planned'), this.pruneStatusConfig(item.planned)],
        [nameof<CreateEventTypeDto>('inProgress'), this.pruneStatusConfig(item.inProgress)],
        [nameof<CreateEventTypeDto>('completed'), this.pruneStatusConfig(item.completed)],
        [nameof<CreateEventTypeDto>('closed'), this.pruneStatusConfig(item.closed)],
        [
          nameof<CreateEventTypeDto>('rolesThatCreateRequests'),
          (item.rolesThatCreateRequests || []).filter(x => !newRolesWFConst.includes(x))
        ],
        [nameof<CreateEventTypeDto>('rolesThatOwnEventsExtended'), []],
        [nameof<CreateEventTypeDto>('rolesThatValidateRequestsExtended'), []],
        [
          nameof<CreateEventTypeDto>('rolesThatOwnEventsExtended'),
          this.mergeOwners(item.roleThatOwnEvents, item.rolesThatOwnEventsExtended, EventTypeCategory.Custom)
        ],
        [
          nameof<CreateEventTypeDto>('roleThatValidateRequests'),
          (item.rolesThatValidateRequestsExtended || []).firstOrDefault(x => !newRolesWFConst.includes(x))
        ],
        [nameof<CreateEventTypeDto>('requestFieldGroups'), (item.requestFieldGroups || []).filter(x => x !== 'SupportDetails')]
      ];
    } else if (EventTypeCategory[type] === EventTypeCategory.Extended) {
      changes = [
        ...changes,
        [nameof<CreateEventTypeDto>('rolesThatCreateDirectEvents'), []],
        [nameof<CreateEventTypeDto>('directEventsAllowed'), false],
        [
          nameof<CreateEventTypeDto>('rolesThatCreateRequests'),
          ['FactoryLead', ...(item.rolesThatCreateRequests || [])].filter((element, index) => {
            return ['FactoryLead', ...(item.rolesThatCreateRequests || [])].indexOf(element) === index;
          })
        ],
        [nameof<CreateEventTypeDto>('roleThatOwnEvents'), null],
        [nameof<CreateEventTypeDto>('roleThatValidateRequests'), null],
        [
          nameof<CreateEventTypeDto>('rolesThatOwnEventsExtended'),
          this.mergeOwners(
            item.roleThatOwnEvents,
            ['PoC', ...(item.rolesThatOwnEventsExtended || [])].filter((element, index) => {
              return ['PoC', ...(item.rolesThatOwnEventsExtended || [])].indexOf(element) === index;
            }),
            EventTypeCategory.Extended
          )
        ],
        [
          nameof<CreateEventTypeDto>('rolesThatValidateRequestsExtended'),
          item.roleThatValidateRequests == null ? [] : [item.roleThatValidateRequests]
        ]
      ];
      if (this.newEventTypeStore.state.item.instructor === 'EventOnly') {
        changes.push([nameof<CreateEventTypeDto>('instructor'), 'No']);
      }
      item.participants = false;
      item.participantsOptional = true;

      const items = item.requestFieldGroups.filter(x => x !== 'EventDetails');
      this.handleValue(nameof<CreateEventTypeDto>('requestFieldGroups'), items);
    }
    this.handleValues(...changes);
  };

  private getGeneralFlowPane = () => {
    const { t } = this.props;
    const { result, item } = this.newEventTypeStore.state;
    const { eventGroupOptions } = this.state;
    return {
      menuItem: i18n.t('General Workflow'),
      render: () => (
        <Tab.Pane attached={false} className="">
          <Grid className="field-group-grid" columns={2}>
            <Grid.Column className="field-title required field" verticalAlign="middle" textAlign="right" computer={5} widescreen={4}>
              {t('EVENT TYPE TITLE')}
            </Grid.Column>
            <Grid.Column computer={11} widescreen={12}>
              <FormGroup className="display-flex-wrapper">
                <Form.Field
                  widescreen={12}
                  computer={10}
                  width={11}
                  error={
                    result && !result.isSuccess && (result.messages || []).any(x => x.propertyName === nameof<CreateEventTypeDto>('name'))
                  }
                >
                  <TimerInput
                    ms={1000}
                    value={item.name}
                    onChange={(_, { value }) => this.handleValue(nameof<CreateEventTypeDto>('name'), value)}
                  />
                </Form.Field>

                <Form.Field className="new-event-type__color-picker-wrap" computer={3} widescreen={2} width={3}>
                  <Popup
                    on="click"
                    basic
                    position="bottom center"
                    className="color-picker-popup"
                    trigger={
                      <div className="ui selection dropdown color-picker-item">
                        <Label circular size="tiny" style={{ backgroundColor: item.eventsColor }}></Label>
                        <i className="dropdown icon"></i>
                      </div>
                    }
                  >
                    <SketchPicker
                      color={item.eventsColor}
                      onChangeComplete={(color: any) => this.handleValue(nameof<CreateEventTypeDto>('eventsColor'), color.hex)}
                      presetColors={[
                        '#407EC9',
                        '#8CB2DF',
                        '#8031A7',
                        '#B777D7',
                        '#EAAA00',
                        '#FFD259',
                        '#E03C31',
                        '#EC8A83',
                        '#00B2A9',
                        '#BDFFFC',
                        '#E782A9',
                        '#F1B4CB'
                      ]}
                    />
                  </Popup>
                </Form.Field>
                <Form.Field className="icon-selector-wrapper" computer={3} widescreen={2} width={2}>
                  <IconSelector
                    nullable
                    clearable
                    rowsPerPage={6}
                    columnsPerPage={6}
                    onChange={value => this.handleValue(nameof<CreateEventTypeDto>('eventsIcon'), value)}
                    value={item.eventsIcon as SemanticICONS}
                  />
                </Form.Field>
              </FormGroup>
            </Grid.Column>
          </Grid>
          <Grid className="field-group-grid" columns={2}>
            <Grid.Row className="group-title">{t('Request & Creation')}</Grid.Row>
            <Grid.Row>
              <Grid.Column stretched widescreen={4} computer={6} textAlign="right">
                <Grid.Row verticalAlign="middle" className="field-title">
                  {t('Request Creator')}
                </Grid.Row>
                <Grid.Row
                  verticalAlign="middle"
                  className={item.rolesThatCreateRequests.length === 0 ? 'field-title ' : 'field-title required field'}
                >
                  {t('Request Validator')}
                </Grid.Row>
                <Grid.Row verticalAlign="middle" className="field-title required field">
                  {t('Event Owner')}
                </Grid.Row>
                <Grid.Row verticalAlign="middle" className="field-title">
                  {t('Direct Event Creator')}
                </Grid.Row>
              </Grid.Column>
              <Grid.Column className="field-group" widescreen={9} computer={8}>
                <Grid.Row>
                  <Dropdown
                    search
                    selection
                    multiple
                    closeOnChange
                    closeOnBlur
                    closeOnEscape
                    selectOnNavigation
                    error={
                      result &&
                      !result.isSuccess &&
                      (result.messages || []).any(x => x.propertyName === nameof<CreateEventTypeDto>('rolesThatCreateRequests'))
                    }
                    options={this.requestRoles.map(x => ({
                      key: `rolesThatCreateRequests-${x}`,
                      text: roleToTitleDict[x],
                      value: x,
                      content: roleToTitleDict[x]
                    }))}
                    value={item.rolesThatCreateRequests}
                    onChange={(_, { value }) => this.handleValue(nameof<CreateEventTypeDto>('rolesThatCreateRequests'), value)}
                  />
                </Grid.Row>
                <Grid.Row>
                  <Dropdown
                    search
                    selection
                    closeOnChange
                    closeOnBlur
                    closeOnEscape
                    clearable
                    selectOnNavigation
                    error={
                      result &&
                      !result.isSuccess &&
                      (result.messages || []).any(x => x.propertyName === nameof<CreateEventTypeDto>('roleThatValidateRequests'))
                    }
                    options={['Planner', 'PoC', 'PlannerMTC'].map(x => ({
                      key: `roleThatValidateRequests-${x}`,
                      text: roleToTitleDict[x],
                      value: x,
                      content: roleToTitleDict[x]
                    }))}
                    value={item.roleThatValidateRequests}
                    onChange={(_, { value }) => this.handleValue(nameof<CreateEventTypeDto>('roleThatValidateRequests'), value)}
                  />
                </Grid.Row>
                <Grid.Row>
                  <Dropdown
                    className="full-width"
                    search
                    selection
                    multiple
                    closeOnChange
                    closeOnBlur
                    clearable
                    closeOnEscape
                    selectOnNavigation
                    error={
                      result &&
                      !result.isSuccess &&
                      (result.messages || []).any(x => x.propertyName === nameof<CreateEventTypeDto>('rolesThatOwnEventsExtended'))
                    }
                    options={this.requestRoles.map(x => ({
                      key: `roleThatOwnEvents-${x}`,
                      text: roleToTitleDict[x],
                      value: x,
                      content: roleToTitleDict[x]
                    }))}
                    value={item.rolesThatOwnEventsExtended}
                    onChange={(_, { value }) => this.handleValue(nameof<CreateEventTypeDto>('rolesThatOwnEventsExtended'), value)}
                  />
                </Grid.Row>
                <Grid.Row>
                  <Dropdown
                    search
                    selection
                    multiple
                    closeOnChange
                    closeOnBlur
                    closeOnEscape
                    selectOnNavigation
                    error={
                      result &&
                      !result.isSuccess &&
                      (result.messages || []).any(x => x.propertyName === nameof<CreateEventTypeDto>('rolesThatCreateDirectEvents'))
                    }
                    options={this.requestRoles.map(x => ({
                      key: `rolesThatCreateDirectEvents-${x}`,
                      text: roleToTitleDict[x],
                      value: x,
                      content: roleToTitleDict[x]
                    }))}
                    value={item.rolesThatCreateDirectEvents}
                    onChange={(_, { value }) => this.handleValue(nameof<CreateEventTypeDto>('rolesThatCreateDirectEvents'), value)}
                  />
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className="field-group-grid" columns={2}>
            <Grid.Row className="group-title">{t('Settings')}</Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column width={8}>
                <Grid>
                  <Grid.Row columns="equal">
                    <Grid.Column stretched computer={7} textAlign="right">
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Feedback')}
                      </Grid.Row>
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Practical Test')}
                      </Grid.Row>
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Students')}
                      </Grid.Row>
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Instructor')}
                      </Grid.Row>
                    </Grid.Column>
                    <Grid.Column className="field-group" width={9}>
                      <Grid.Row>
                        <Button.Group className="filter-button-group">
                          <Button
                            basic
                            className={item.feedbacks ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('feedbacks'), true)}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            basic
                            className={!item.feedbacks ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('feedbacks'), false)}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>

                      <Grid.Row>
                        <Button.Group className="filter-button-group">
                          <Button
                            basic
                            className={item.practicalTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('practicalTest'), true)}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            basic
                            className={!item.practicalTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('practicalTest'), false)}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>

                      <Grid.Row>
                        <Button.Group className="filter-button-group">
                          <Button
                            basic
                            className={item.participants ? 'active gray-border' : 'gray-border'}
                            onClick={() => {
                              this.handleValue(nameof<CreateEventTypeDto>('participants'), true);
                              this.handleValue(nameof<CreateEventTypeDto>('participantsOptional'), false);
                            }}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            basic
                            className={!item.participants ? 'active gray-border' : 'gray-border'}
                            onClick={() => {
                              this.handleValue(nameof<CreateEventTypeDto>('participants'), false);
                              this.handleValue(nameof<CreateEventTypeDto>('participantsOptional'), true);
                            }}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>
                      <Grid.Row>
                        <Button.Group className="filter-button-group three-btns">
                          <Button
                            basic
                            className={item.instructor === 'Yes' ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('instructor'), 'Yes')}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            basic
                            className={item.instructor === 'EventOnly' ? 'active middle gray-border' : 'middle gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('instructor'), 'EventOnly')}
                          >
                            {t('Event Only')}
                          </Button>
                          <Button
                            basic
                            className={item.instructor === 'No' ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('instructor'), 'No')}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={8} className="fit-content-column">
                <Grid>
                  <Grid.Row columns="equal">
                    <Grid.Column stretched computer={7} textAlign="right">
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Pre-event Test')}
                      </Grid.Row>

                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Post-event Test')}
                      </Grid.Row>
                      <Grid.Row>
                        <Checkbox
                          disabled={!item.participants}
                          checked={!item.participantsOptional}
                          onChange={(_, { checked }) => this.handleValue(nameof<CreateEventTypeDto>('participantsOptional'), !checked)}
                        ></Checkbox>
                      </Grid.Row>
                    </Grid.Column>
                    <Grid.Column className="field-group" width={9}>
                      <Grid.Row>
                        <Button.Group className="filter-button-group">
                          <Button
                            basic
                            className={item.preEventTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('preEventTest'), true)}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            basic
                            className={!item.preEventTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('preEventTest'), false)}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>
                      <Grid.Row>
                        <Button.Group className="filter-button-group">
                          <Button
                            basic
                            className={item.postEventTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('postEventTest'), true)}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            basic
                            className={!item.postEventTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('postEventTest'), false)}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Students Mandatory')}
                      </Grid.Row>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className="field-group-grid" columns={2}>
            <Grid.Row className="group-title">{t('Request Fields Needed')}</Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="right" widescreen={5} tablet={8} mobile={8} computer={6}>
                <Grid.Row>
                  <Popup
                    on="hover"
                    position="bottom center"
                    inverted
                    size="large"
                    className="field-group-description-popup"
                    trigger={
                      <label className="field-title inverted-chckbx">
                        <Checkbox
                          checked={item.requestFieldGroups.includes('RequestDetails')}
                          onChange={(_, { checked }) => {
                            const items = checked
                              ? [...item.requestFieldGroups, 'RequestDetails']
                              : item.requestFieldGroups.filter(x => x !== 'RequestDetails');
                            this.handleValue(nameof<CreateEventTypeDto>('requestFieldGroups'), items);
                          }}
                        />{' '}
                        <span className="clickable-icon">{t('Request Details')}&nbsp;</span>
                      </label>
                    }
                  >
                    <div>
                      {t('Request Location')} <br />
                      {t('Event Location')} <br />
                      {t('Priority')} <br />
                      {t('Roles')} <br />
                      {t('Category')} <br />
                      {t('Machine Related')} <br />
                    </div>
                  </Popup>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column textAlign="right" widescreen={5} tablet={8} mobile={8} computer={6}>
                <Grid.Row>
                  <Popup
                    on="hover"
                    position="bottom center"
                    inverted
                    size="large"
                    className="field-group-description-popup"
                    trigger={
                      <label className="field-title inverted-chckbx">
                        {' '}
                        <Checkbox
                          checked={item.requestFieldGroups.includes('EventDetails')}
                          onChange={(_, { checked }) => {
                            const items = checked
                              ? [...item.requestFieldGroups, 'EventDetails']
                              : item.requestFieldGroups.filter(x => x !== 'EventDetails');
                            this.handleValue(nameof<CreateEventTypeDto>('requestFieldGroups'), items);
                          }}
                        />{' '}
                        <span className="clickable-icon">{t('Event Details')}&nbsp;</span>
                      </label>
                    }
                  >
                    <div>
                      {t('Training Level')} <br />
                      {t('Customization')} <br />
                      {t('Delivery Method')} <br />
                      {t('Language')} <br />
                    </div>
                  </Popup>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className="field-group-grid" columns={2}>
            <Grid.Row className="group-title">{t('Event Group')}</Grid.Row>
            <Grid.Row>
              <Grid.Column className="field-title required field" verticalAlign="middle" textAlign="right" computer={5} widescreen={4}>
                {t('Event Group')}
              </Grid.Column>
              <Grid.Column className="field-group " widescreen={9} computer={8}>
                <Dropdown
                  id="event-type-group"
                  className="full-width"
                  search
                  selection
                  closeOnChange
                  closeOnBlur
                  closeOnEscape
                  selectOnNavigation
                  error={
                    result &&
                    !result.isSuccess &&
                    (result.messages || []).any(x => x.propertyName === nameof<CreateEventTypeDto>('eventGroupRelationId'))
                  }
                  options={eventGroupOptions || []}
                  value={item.eventGroupRelationId}
                  onChange={(_, { value }) => {
                    let v = value as string[];
                    this.handleValue(nameof<CreateEventTypeDto>('eventGroupRelationId'), v);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      )
    };
  };

  private getExtendedFlowPane = () => {
    const { t } = this.props;
    const { result, item } = this.newEventTypeStore.state;
    const { eventGroupOptions } = this.state;

    return {
      menuItem: i18n.t('Extended Workflow'),
      render: () => (
        <Tab.Pane attached={false} className="">
          <Grid className="field-group-grid" columns={2}>
            <Grid.Column className="field-title required field" verticalAlign="middle" textAlign="right" computer={5} widescreen={4}>
              {t('EVENT TYPE TITLE')}
            </Grid.Column>
            <Grid.Column computer={11} widescreen={12}>
              <FormGroup className="display-flex-wrapper">
                <Form.Field
                  widescreen={12}
                  computer={10}
                  width={11}
                  error={
                    result && !result.isSuccess && (result.messages || []).any(x => x.propertyName === nameof<CreateEventTypeDto>('name'))
                  }
                >
                  <TimerInput
                    ms={1000}
                    value={item.name}
                    onChange={(_, { value }) => this.handleValue(nameof<CreateEventTypeDto>('name'), value)}
                  />
                </Form.Field>

                <Form.Field className="new-event-type__color-picker-wrap" computer={3} widescreen={2} width={3}>
                  <Popup
                    on="click"
                    basic
                    position="bottom center"
                    className="color-picker-popup"
                    trigger={
                      <div className="ui selection dropdown color-picker-item">
                        <Label circular size="tiny" style={{ backgroundColor: item.eventsColor }}></Label>
                        <i className="dropdown icon"></i>
                      </div>
                    }
                  >
                    <SketchPicker
                      color={item.eventsColor}
                      onChangeComplete={(color: any) => this.handleValue(nameof<CreateEventTypeDto>('eventsColor'), color.hex)}
                      presetColors={[
                        '#407EC9',
                        '#8CB2DF',
                        '#8031A7',
                        '#B777D7',
                        '#EAAA00',
                        '#FFD259',
                        '#E03C31',
                        '#EC8A83',
                        '#00B2A9',
                        '#BDFFFC',
                        '#E782A9',
                        '#F1B4CB'
                      ]}
                    />
                  </Popup>
                </Form.Field>
                <Form.Field className="icon-selector-wrapper" computer={3} widescreen={2} width={2}>
                  <IconSelector
                    nullable
                    clearable
                    rowsPerPage={6}
                    columnsPerPage={6}
                    onChange={value => this.handleValue(nameof<CreateEventTypeDto>('eventsIcon'), value)}
                    value={item.eventsIcon as SemanticICONS}
                  />
                </Form.Field>
              </FormGroup>
            </Grid.Column>
          </Grid>
          <Grid className="field-group-grid" columns={2}>
            <Grid.Row className="group-title">{t('Request & Creation')}</Grid.Row>
            <Grid.Row verticalAlign="middle" className="no-padding first">
              <Grid.Column stretched widescreen={4} computer={6} textAlign="right" className="field-title">
                {t('Request Creator')}
              </Grid.Column>
              <Grid.Column className="field-group" widescreen={9} computer={8}>
                <Dropdown
                  id="event-type-request-creators"
                  className="full-width"
                  search
                  selection
                  multiple
                  closeOnChange
                  closeOnBlur
                  closeOnEscape
                  selectOnNavigation
                  error={
                    result &&
                    !result.isSuccess &&
                    (result.messages || []).any(x => x.propertyName === nameof<CreateEventTypeDto>('rolesThatCreateRequests'))
                  }
                  options={this.requestRolesExtended.map(x => ({
                    key: `rolesThatCreateRequests-${x}`,
                    text: roleToTitleDict[x],
                    value: x,
                    content: roleToTitleDict[x]
                  }))}
                  value={item.rolesThatCreateRequests}
                  onChange={(_, { value }) => {
                    let v = value as string[];
                    if (!(v || []).includes('FactoryLead')) v = ['FactoryLead', ...v];
                    this.handleValue(nameof<CreateEventTypeDto>('rolesThatCreateRequests'), v);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row verticalAlign="middle" className="no-padding">
              <Grid.Column
                stretched
                widescreen={4}
                computer={6}
                textAlign="right"
                className={item.rolesThatCreateRequests.length === 0 ? 'field-title field' : ' field-title required field'}
              >
                {t('Request Validator')}
              </Grid.Column>
              <Grid.Column className="field-group" widescreen={9} computer={8}>
                <Dropdown
                  className="full-width"
                  search
                  selection
                  multiple
                  closeOnChange
                  closeOnBlur
                  closeOnEscape
                  clearable
                  selectOnNavigation
                  error={
                    result &&
                    !result.isSuccess &&
                    (result.messages || []).any(x => x.propertyName === nameof<CreateEventTypeDto>('rolesThatValidateRequestsExtended'))
                  }
                  options={['Planner', 'PoC', 'PlannerMTC'].concat(this.newRoles).map(x => ({
                    key: `rolesThatValidateRequestsExtended-${x}`,
                    text: roleToTitleDict[x],
                    value: x,
                    content: roleToTitleDict[x]
                  }))}
                  value={item.rolesThatValidateRequestsExtended}
                  onChange={(_, { value }) => this.handleValue(nameof<CreateEventTypeDto>('rolesThatValidateRequestsExtended'), value)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row verticalAlign="middle" className="no-padding">
              <Grid.Column stretched widescreen={4} computer={6} textAlign="right" className="required field-title field">
                {t('Event Owner')}
              </Grid.Column>
              <Grid.Column className="field-group" widescreen={9} computer={8}>
                <Dropdown
                  className="full-width"
                  search
                  selection
                  multiple
                  closeOnChange
                  closeOnBlur
                  clearable
                  closeOnEscape
                  selectOnNavigation
                  error={
                    result &&
                    !result.isSuccess &&
                    (result.messages || []).any(x => x.propertyName === nameof<CreateEventTypeDto>('rolesThatOwnEventsExtended'))
                  }
                  options={this.requestRolesExtended.map(x => ({
                    key: `rolesThatOwnEventsExtended-${x}`,
                    text: roleToTitleDict[x],
                    value: x,
                    content: roleToTitleDict[x]
                  }))}
                  value={item.rolesThatOwnEventsExtended}
                  onChange={(_, { value }) => this.handleValue(nameof<CreateEventTypeDto>('rolesThatOwnEventsExtended'), value)}
                  // onChange={(_, { value }) => {
                  //   let v = value as string[];
                  //   if (!(v || []).includes('PoC')) v = ['PoC', ...v];
                  //   this.handleValue(nameof<CreateEventTypeDto>('rolesThatOwnEventsExtended'), v);
                  // }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className="field-group-grid" columns={2}>
            <Grid.Row className="group-title">{t('Settings')}</Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column width={8}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column stretched computer={7} textAlign="right">
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Feedback')}
                      </Grid.Row>
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Practical Test')}
                      </Grid.Row>
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Students')}
                      </Grid.Row>
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Instructor')}
                      </Grid.Row>
                    </Grid.Column>
                    <Grid.Column className="field-group" width={9}>
                      <Grid.Row>
                        <Button.Group className="filter-button-group">
                          <Button
                            basic
                            className={item.feedbacks ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('feedbacks'), true)}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            basic
                            className={!item.feedbacks ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('feedbacks'), false)}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>

                      <Grid.Row>
                        <Button.Group className="filter-button-group">
                          <Button
                            basic
                            className={item.practicalTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('practicalTest'), true)}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            basic
                            className={!item.practicalTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('practicalTest'), false)}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>

                      <Grid.Row>
                        <Button.Group className="filter-button-group">
                          <Button
                            disabled
                            basic
                            className={item.participants ? 'active gray-border' : 'gray-border'}
                            onClick={() => {
                              this.handleValue(nameof<CreateEventTypeDto>('participants'), true);
                              this.handleValue(nameof<CreateEventTypeDto>('participantsOptional'), false);
                            }}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            disabled
                            basic
                            className={!item.participants ? 'active gray-border' : 'gray-border'}
                            onClick={() => {
                              this.handleValue(nameof<CreateEventTypeDto>('participants'), false);
                              this.handleValue(nameof<CreateEventTypeDto>('participantsOptional'), true);
                            }}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>
                      <Grid.Row>
                        <Button.Group className="filter-button-group">
                          <Button
                            basic
                            className={item.instructor === 'Yes' ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('instructor'), 'Yes')}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            basic
                            className={item.instructor !== 'Yes' ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('instructor'), 'No')}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={8}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column stretched computer={7} textAlign="right">
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Pre-event Test')}
                      </Grid.Row>

                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Post-event Test')}
                      </Grid.Row>
                      <Grid.Row verticalAlign="middle">
                        <Checkbox
                          disabled
                          checked={!item.participantsOptional}
                          onChange={(_, { checked }) => this.handleValue(nameof<CreateEventTypeDto>('participantsOptional'), !checked)}
                        ></Checkbox>
                      </Grid.Row>
                    </Grid.Column>
                    <Grid.Column className="field-group" width={9}>
                      <Grid.Row>
                        <Button.Group className="filter-button-group">
                          <Button
                            basic
                            className={item.preEventTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('preEventTest'), true)}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            basic
                            className={!item.preEventTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('preEventTest'), false)}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>
                      <Grid.Row>
                        <Button.Group className="filter-button-group">
                          <Button
                            basic
                            className={item.postEventTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('postEventTest'), true)}
                          >
                            {t('Yes')}
                          </Button>
                          <Button
                            basic
                            className={!item.postEventTest ? 'active gray-border' : 'gray-border'}
                            onClick={() => this.handleValue(nameof<CreateEventTypeDto>('postEventTest'), false)}
                          >
                            {t('No')}
                          </Button>
                        </Button.Group>
                      </Grid.Row>
                      <Grid.Row verticalAlign="middle" className="field-title">
                        {t('Students Mandatory')}
                      </Grid.Row>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className="field-group-grid" columns={2}>
            <Grid.Row className="group-title">{t('Request Fields Needed')}</Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="right" widescreen={5} tablet={8} mobile={8} computer={5}>
                <Grid.Row>
                  <Popup
                    on="hover"
                    position="bottom center"
                    inverted
                    size="large"
                    className="field-group-description-popup"
                    trigger={
                      <label className="field-title inverted-chckbx">
                        <Checkbox
                          checked={item.requestFieldGroups.includes('RequestDetails')}
                          onChange={(_, { checked }) => {
                            const items = checked
                              ? [...item.requestFieldGroups, 'RequestDetails']
                              : item.requestFieldGroups.filter(x => x !== 'RequestDetails');
                            this.handleValue(nameof<CreateEventTypeDto>('requestFieldGroups'), items);
                          }}
                        />{' '}
                        <span className="clickable-icon">{t('Request Details')}&nbsp;</span>
                      </label>
                    }
                  >
                    <div>
                      {t('Request Location')} <br />
                      {t('Event Location')} <br />
                      {t('Priority')} <br />
                      {t('Roles')} <br />
                      {t('Category')} <br />
                      {t('Machine Related')} <br />
                    </div>
                  </Popup>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column textAlign="right" widescreen={5} tablet={8} mobile={8} computer={5}>
                <Grid.Row>
                  <Popup
                    on="hover"
                    position="bottom center"
                    inverted
                    size="large"
                    className="field-group-description-popup"
                    trigger={
                      <label className="field-title inverted-chckbx">
                        {' '}
                        <Checkbox
                          disabled
                          checked={item.requestFieldGroups.includes('EventDetails')}
                          onChange={(_, { checked }) => {
                            const items = checked
                              ? [...item.requestFieldGroups, 'EventDetails']
                              : item.requestFieldGroups.filter(x => x !== 'EventDetails');
                            this.handleValue(nameof<CreateEventTypeDto>('requestFieldGroups'), items);
                          }}
                        />{' '}
                        <span className="clickable-icon">{t('Event Details')}&nbsp;</span>
                      </label>
                    }
                  >
                    <div>
                      {t('Training Level')} <br />
                      {t('Customization')} <br />
                      {t('Delivery Method')} <br />
                      {t('Language')} <br />
                    </div>
                  </Popup>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column textAlign="right" widescreen={5} tablet={8} mobile={8} computer={5}>
                <Grid.Row>
                  <Popup
                    on="hover"
                    position="bottom center"
                    inverted
                    size="large"
                    className="field-group-description-popup"
                    trigger={
                      <label className="field-title inverted-chckbx">
                        {' '}
                        <Checkbox
                          checked={item.requestFieldGroups.includes('SupportDetails')}
                          onChange={(_, { checked }) => {
                            const items = checked
                              ? [...item.requestFieldGroups, 'SupportDetails']
                              : item.requestFieldGroups.filter(x => x !== 'SupportDetails');
                            this.handleValue(nameof<CreateEventTypeDto>('requestFieldGroups'), items);
                          }}
                        />{' '}
                        <span className="clickable-icon">{t('Support Details')}&nbsp;</span>
                      </label>
                    }
                  >
                    <div>
                      {t('Position')} <br />
                      {t('Requested HC')} <br />
                      {t('Dates')} <br />
                      {t('Duration')} <br />
                      {t('Supplying Location')} <br />
                      {t('Estimated Cost')} <br />
                    </div>
                  </Popup>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid className="field-group-grid" columns={2}>
            <Grid.Row className="group-title">{t('Event Group')}</Grid.Row>
            <Grid.Row>
              <Grid.Column className="field-title required field" verticalAlign="middle" textAlign="right" computer={5} widescreen={4}>
                {t('Event Group')}
              </Grid.Column>
              <Grid.Column className="field-group required" widescreen={9} computer={8}>
                <Dropdown
                  id="event-type-group"
                  className="full-width"
                  search
                  selection
                  closeOnChange
                  closeOnBlur
                  closeOnEscape
                  selectOnNavigation
                  error={
                    result &&
                    !result.isSuccess &&
                    (result.messages || []).any(x => x.propertyName === nameof<CreateEventTypeDto>('eventGroupRelationId'))
                  }
                  options={eventGroupOptions || []}
                  value={item.eventGroupRelationId}
                  onChange={(_, { value }) => {
                    let v = value as string[];
                    this.handleValue(nameof<CreateEventTypeDto>('eventGroupRelationId'), v);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      )
    };
  };

  private getPanes = () => {
    const { creationType } = this.props;
    const { item } = this.newEventTypeStore.state;

    if (creationType == null) return [];
    else if (creationType === CreationType.Normal) return [this.getGeneralFlowPane(), this.getExtendedFlowPane()];
    else if (EventTypeCategory[item.category] === EventTypeCategory.Extended) return [this.getExtendedFlowPane()];
    else return [this.getGeneralFlowPane()];
  };
  private excludedActorRoles: string[] = ['GlobalEngineering', 'GlobalManufacturing', 'RegionalManufacturingVP'];
  private removeExtendedRoles = (roles: string[]) => {
    return difference(roles, this.excludedActorRoles, customEqualCompare);
  };

  public render() {
    const { t } = this.props;
    const { statusActorsOpenPopup, statusConfigOpenPopup, tabSelected, errorMessagesVisible } = this.state;
    const { result, item } = this.newEventTypeStore.state;
    let rolesList = EventTypeCategory[item.category] !== EventTypeCategory.Extended ? this.workflowRoles : this.workflowRolesExtended;

    return (
      <Modal
        onClick={this.onModalClick}
        id="new-event-modal"
        open
        closeOnEscape={true}
        onClose={this.onCancelNewItem}
        closeOnDimmerClick={false}
      >
        <Dimmer active={this.newEventTypeStore.state.isBusy} inverted style={{ position: 'fixed' }}>
          <Loader indeterminate inverted></Loader>
        </Dimmer>
        {result && !result.isSuccess && (
          <Message
            className="no-white-error"
            hidden={!errorMessagesVisible}
            onDismiss={this.onHideErrorMessages}
            error
            header={t('An error ocurred')}
            list={result.messages.map(o => o.body)}
          />
        )}
        <Modal.Content image>
          <Container>
            {item && (
              <Form id="new-event-form">
                <Grid id="new-event-form-grid" columns={2}>
                  <Grid.Column tablet={8} mobile={8} computer={8} widescreen={7} id="left-column">
                    <Tab
                      className="main-tab"
                      menu={{ secondary: true, pointing: true }}
                      activeIndex={tabSelected}
                      panes={this.getPanes()}
                      onTabChange={this.handleTabChange}
                    />
                  </Grid.Column>
                  <Grid.Column tablet={8} mobile={8} computer={8} widescreen={9} id="right-column">
                    <Grid columns={2} className="field-group-grid">
                      <Grid.Row className="green-group-title">{t('WORKFLOW STATUS')}</Grid.Row>
                    </Grid>
                    {[
                      { key: 'draft', title: t('DRAFT') },
                      { key: 'planned', title: t('PLANNED') },
                      { key: 'inProgress', title: t('IN PROGRESS') },
                      { key: 'completed', title: t('COMPLETED') },
                      { key: 'closed', title: t('CLOSED') }
                    ].map(({ key, title }) => (
                      <Grid
                        key={`grid-status-${key}`}
                        columns={2}
                        className={`field-group-grid ${item[key].active ? 'active' : 'inactive'} ${
                          result && !result.isSuccess && (result.messages || []).any(x => x.propertyName === key) ? 'error' : ''
                        }`}
                      >
                        <Grid.Row className="status-row-1">
                          <Grid.Column widescreen={15} computer={14} mobile={14} tablet={14}>
                            <div className="status-row-1-p">
                              <span className="status-name">{t(title)}</span>
                              {item[key].actorRoles.map((r: string) => (
                                <Label key={`${key}-${r}`} circular className="status-label">
                                  {t(roleToTitleDict[r])}{' '}
                                  <Icon
                                    name="times circle"
                                    className="clickable-icon"
                                    onClick={() => {
                                      let status = item[key];
                                      const nextActorRoles = item[key].actorRoles.filter((x: string) => x !== r);
                                      if (nextActorRoles.length === 0) {
                                        status = getStatusDefault(key === 'closed');
                                      } else {
                                        const dif = difference(item[key].actorRoles, nextActorRoles, (x, y) => x === y);
                                        dif.forEach(x => {
                                          let lowerCaseKey = firstToLowerCase(x.toString());
                                          delete status.rolesNotifiedOnStatusChanged[lowerCaseKey];
                                          if (status.rolesNotifiedOnChanges != null) delete status.rolesNotifiedOnChanges[lowerCaseKey];
                                        });
                                        if (status.rolesRequiredToValidateStatus != null)
                                          status.rolesRequiredToValidateStatus = difference(
                                            status.rolesRequiredToValidateStatus,
                                            dif,
                                            (x, y) => x === y
                                          );
                                        if (status.rolesNotifiedPastTime != null && status.length > 0)
                                          status.rolesNotifiedPastTime = difference(status.rolesNotifiedPastTime, dif, (x, y) => x === y);
                                      }

                                      status.actorRoles = nextActorRoles;
                                      this.handleValue(key, status);
                                    }}
                                  />
                                </Label>
                              ))}
                              <Popup
                                on="click"
                                position="bottom left"
                                open={!!statusActorsOpenPopup[key]}
                                className="status-actors-popup"
                                trigger={
                                  <Icon
                                    name="add user"
                                    className="clickable-icon add-status-role popup-icon"
                                    onClick={item[key].active ? () => this.toggleStatusActorsPopup(key) : undefined}
                                  />
                                }
                              >
                                <Menu
                                  size="mini"
                                  vertical
                                  secondary
                                  className="new-event-type__custom-menu"
                                  items={rolesList.map(r => (
                                    <Menu.Item
                                      key={`add-status-role-menu-item-${r}`}
                                      className="popup-item"
                                      onClick={() => {
                                        const status = item[key];
                                        status.actorRoles = [...new Set([...item[key].actorRoles, r])];
                                        this.closeStatusActorsPopup(key);
                                        this.handleValue(key, status);
                                      }}
                                    >
                                      {t(roleToTitleDict[r])}
                                    </Menu.Item>
                                  ))}
                                />
                              </Popup>
                            </div>
                          </Grid.Column>
                          <Grid.Column widescreen={1} computer={2} mobile={2} tablet={2}>
                            {key !== 'closed' && (
                              <Checkbox
                                toggle
                                checked={item[key].active}
                                onClick={(_, { checked }) => {
                                  const status = getStatusDefault(key === 'closed');
                                  status.active = checked;
                                  this.handleValue(key, status);
                                }}
                              />
                            )}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="status-row-2">
                          <Grid.Column widescreen={4} tablet={6} mobile={6} computer={6}>
                            <div className="status-row-2-p">
                              <span className="status-row-2-ptitle">{t('Notifications')}</span>
                              <Popup
                                on="click"
                                position="bottom left"
                                open={!!(statusConfigOpenPopup[key] && statusConfigOpenPopup[key]['notifications'])}
                                className="status-config-popup"
                                trigger={
                                  <Icon
                                    name="user"
                                    bordered
                                    inverted
                                    className={`clickable-icon add-config-role popup-icon ${
                                      getProperties(item[key].rolesNotifiedOnStatusChanged).any(x => (x.value || []).length !== 0)
                                        ? 'active'
                                        : ''
                                    }`}
                                    onClick={() =>
                                      item[key].active && (item[key].actorRoles || []).length !== 0
                                        ? this.toggleStatusConfigPopup(key, 'notifications')
                                        : undefined
                                    }
                                  />
                                }
                              >
                                <List
                                  className={EventTypeCategory[item.category] === EventTypeCategory.Extended ? 'big-popup-list' : ''}
                                  divided
                                  relaxed
                                  items={(item[key].actorRoles || [])
                                    .map((x: string) => [roleToTitleDict[x], firstToLowerCase(x)])
                                    .map(([itemTitle, r]) => (
                                      <NotificationSettingPopupItem
                                        key={`notifications-config-role-${r}`}
                                        title={itemTitle}
                                        active={
                                          !!(
                                            item[key].rolesNotifiedOnStatusChanged[r] &&
                                            item[key].rolesNotifiedOnStatusChanged[r].length !== 0
                                          )
                                        }
                                        notificationTypes={item[key].rolesNotifiedOnStatusChanged[r] || []}
                                        onChange={notificationTypes => {
                                          const statusConfig = item[key] as StatusConfig;
                                          statusConfig.rolesNotifiedOnStatusChanged[r] = notificationTypes;
                                          this.handleValue(key, statusConfig);
                                        }}
                                      />
                                    ))}
                                />
                              </Popup>
                            </div>
                          </Grid.Column>

                          {key !== 'closed' && (
                            <Grid.Column widescreen={4} tablet={5} mobile={5} computer={5}>
                              <p className="status-row-2-p">
                                <span className="status-row-2-ptitle">{t('Changes')}</span>
                                <Popup
                                  on="click"
                                  position="bottom left"
                                  open={!!(statusConfigOpenPopup[key] && statusConfigOpenPopup[key]['changes'])}
                                  className="status-config-popup"
                                  trigger={
                                    <Icon
                                      name="user"
                                      bordered
                                      inverted
                                      className={`clickable-icon add-config-role popup-icon ${
                                        getProperties(item[key].rolesNotifiedOnChanges).any(x => (x.value || []).length !== 0)
                                          ? 'active'
                                          : ''
                                      }`}
                                      onClick={() =>
                                        item[key].active && (item[key].actorRoles || []).length !== 0
                                          ? this.toggleStatusConfigPopup(key, 'changes')
                                          : undefined
                                      }
                                    />
                                  }
                                >
                                  <List
                                    divided
                                    relaxed
                                    className={EventTypeCategory[item.category] === EventTypeCategory.Extended ? 'big-popup-list' : ''}
                                    items={(item[key].actorRoles || [])
                                      .map((x: string) => [roleToTitleDict[x], firstToLowerCase(x)])
                                      .map(([itemTitle, r]) => (
                                        <NotificationSettingPopupItem
                                          key={`changes-config-role-${r}`}
                                          title={itemTitle}
                                          active={
                                            !!(item[key].rolesNotifiedOnChanges[r] && item[key].rolesNotifiedOnChanges[r].length !== 0)
                                          }
                                          notificationTypes={item[key].rolesNotifiedOnChanges[r] || []}
                                          onChange={notificationTypes => {
                                            const statusConfig = item[key] as StatusConfig;
                                            statusConfig.rolesNotifiedOnChanges[r] = notificationTypes;
                                            this.handleValue(key, statusConfig);
                                          }}
                                        />
                                      ))}
                                  />
                                </Popup>
                              </p>
                            </Grid.Column>
                          )}
                          {key !== 'closed' && (
                            <Grid.Column widescreen={4} tablet={5} mobile={5} computer={5}>
                              {(item[key].rolesRequiredToValidateStatus || []).length !== 0 && (
                                <p className="status-row-2-p">
                                  <span className="status-row-2-ptitle">{t('Validations')}</span>
                                  <Popup
                                    on="click"
                                    position="bottom left"
                                    open={!!(statusConfigOpenPopup[key] && statusConfigOpenPopup[key]['validations'])}
                                    className="status-config-popup"
                                    trigger={
                                      <Icon
                                        name="user"
                                        bordered
                                        inverted
                                        className={`clickable-icon add-config-role popup-icon ${
                                          (item[key].rolesRequiredToValidateStatus || []).length !== 0 ? 'active' : ''
                                        }`}
                                        onClick={() =>
                                          item[key].active && this.removeExtendedRoles(item[key].actorRoles || []).length !== 0
                                            ? this.toggleStatusConfigPopup(key, 'validations')
                                            : undefined
                                        }
                                      />
                                    }
                                  >
                                    <List
                                      divided
                                      relaxed
                                      items={this.removeExtendedRoles(item[key].actorRoles || [])
                                        .map(x => [roleToTitleDict[x], x])
                                        .map(([itemTitle, r]) => (
                                          <RoleSelectorPopupItem
                                            key={`validations-config-role-${r}`}
                                            title={itemTitle}
                                            active={(item[key].rolesRequiredToValidateStatus || []).includes(r)}
                                            onChange={active => {
                                              const statusConfig = item[key] as StatusConfig;
                                              if (active) {
                                                statusConfig.rolesRequiredToValidateStatus = [
                                                  ...new Set([...statusConfig.rolesRequiredToValidateStatus, r])
                                                ];
                                              } else {
                                                statusConfig.rolesRequiredToValidateStatus = (
                                                  statusConfig.rolesRequiredToValidateStatus || []
                                                ).filter(x => x !== r);
                                              }
                                              this.handleValue(key, statusConfig);
                                            }}
                                          />
                                        ))}
                                    />
                                  </Popup>
                                </p>
                              )}
                            </Grid.Column>
                          )}
                          {key === 'closed' && (
                            <Grid.Column widescreen={4} tablet={7} mobile={7} computer={7}>
                              <p className="status-row-2-p">
                                <span className="status-row-2-ptitle">{t('6 months reminder')}</span>
                                <Popup
                                  on="click"
                                  position="bottom left"
                                  open={!!(statusConfigOpenPopup[key] && statusConfigOpenPopup[key]['pastTime'])}
                                  className="status-config-popup"
                                  trigger={
                                    <Icon
                                      name="user"
                                      bordered
                                      inverted
                                      className={`clickable-icon add-config-role popup-icon ${
                                        getProperties(item[key].rolesNotifiedPastTime).any(x => (x.value || []).length !== 0)
                                          ? 'active'
                                          : ''
                                      }`}
                                      onClick={() =>
                                        item[key].active && this.removeExtendedRoles(item[key].actorRoles || []).length !== 0
                                          ? this.toggleStatusConfigPopup(key, 'pastTime')
                                          : undefined
                                      }
                                    />
                                  }
                                >
                                  <List
                                    divided
                                    relaxed
                                    className={EventTypeCategory[item.category] === EventTypeCategory.Extended ? 'big-popup-list' : ''}
                                    items={this.removeExtendedRoles(item[key].actorRoles || [])
                                      .map(x => [roleToTitleDict[x], firstToLowerCase(x)])
                                      .map(([itemTitle, r]) => (
                                        <NotificationSettingPopupItem
                                          key={`pastTime-config-role-${r}`}
                                          title={itemTitle}
                                          active={!!(item[key].rolesNotifiedPastTime[r] && item[key].rolesNotifiedPastTime[r].length !== 0)}
                                          notificationTypes={item[key].rolesNotifiedPastTime[r] || []}
                                          onChange={notificationTypes => {
                                            const statusConfig = item[key];
                                            statusConfig.rolesNotifiedPastTime[r] = notificationTypes;
                                            this.handleValue(key, statusConfig);
                                          }}
                                        />
                                      ))}
                                  />
                                </Popup>
                              </p>
                            </Grid.Column>
                          )}
                        </Grid.Row>
                      </Grid>
                    ))}
                    <Modal.Actions className="event-types-modal-actions">
                      <Button onClick={this.onCancelNewItem} basic>
                        {t('Cancel')}
                      </Button>
                      <Button onClick={this.onCreateNewItem} positive>
                        {t('Save')}
                      </Button>
                    </Modal.Actions>
                  </Grid.Column>
                </Grid>
              </Form>
            )}
          </Container>
        </Modal.Content>
      </Modal>
    );
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(NewEventTypeView);
