import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Button, Modal, Dimmer, Loader, Grid } from 'semantic-ui-react';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { getProperties } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { nameof } from 'utils/object';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import { ProfileItemDto } from 'stores/profile/profile-store';
import './tna-ssa-templates.less';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import { ItemReference } from 'stores/dataStore';
import {
  TnaTemplatesStore,
  TnaTemplateDto,
  NewTnaTemplateStore,
  ChangeTnaTemplateStore,
  ChangeTnaTemplateDto,
  CreateTnaTemplateDto,
  CreateTnaSkillTemplateSectionDto,
  TnaMachineUnitTemplateSectionDto,
  CreateTnaMachineUnitTemplateSectionDto,
  TnaSkillTemplateSectionDto
} from 'stores/assessments/templates/tna-templates-store';
import { isNullOrUndefined } from 'util';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { customEqualCompare, extractFriendlyIdNumber, isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface TnaTemplateListProps extends WithTranslation, RouteComponentProps {
  tnaTemplateStore: TnaTemplatesStore;
  newTnaTemplateStore: NewTnaTemplateStore;
  changeTnaTemplateStore: ChangeTnaTemplateStore;
}

interface TnaTemplateFilters {
  isActive: boolean;
  profileItemId: string;
  templateNameOrID: string;
  machineModelId: string;
  oemId: string;
  equipmentTypeId: string;
  clusterId: string;
}

export interface TnaTemplateListState {
  query: Query;
  activeFilters: { [key: string]: any };
  selectedItem: TnaTemplateDto;
  isclone: boolean;
  cloneData: TnaTemplateDto;
  showInactiveTemplates: boolean;
  uploadLoadingMessage: string;
  filters: TnaTemplateFilters;
  onConfirmToggleDisable: boolean;
  cloneModalToShown: boolean;
  showOnlyInactiveRows?: boolean;
  loading: boolean;
  someFilterOpened: boolean;
}

@connect(
  ['tnaTemplateStore', TnaTemplatesStore],
  ['newTnaTemplateStore', NewTnaTemplateStore],
  ['changeTnaTemplateStore', ChangeTnaTemplateStore]
)
class TnaTemplateList extends React.Component<TnaTemplateListProps, TnaTemplateListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem(item));
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      cloneData: null,
      isclone: false,
      cloneModalToShown: false,
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }],
        filter: [{ isActive: true }],
        skip: 0,
        take: 10
      },
      activeFilters: [{ isActive: true }],
      showInactiveTemplates: false,
      uploadLoadingMessage: '',
      onConfirmToggleDisable: false,
      showOnlyInactiveRows: false,
      filters: {
        isActive: true,
        templateNameOrID: null,
        profileItemId: null,
        machineModelId: null,
        oemId: null,
        equipmentTypeId: null,
        clusterId: null
      },
      loading: false,
      someFilterOpened: false
    };
  }

  private get newTnaTemplateStore() {
    return this.props.newTnaTemplateStore;
  }

  private get changeTnaTemplateStore() {
    return this.props.changeTnaTemplateStore;
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const filter = getProperties(activeFilters)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    this.setState({ query }, () => this.props.tnaTemplateStore.getAllAsync(this.state.query));
  };

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: TnaTemplateDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.tnaTemplateStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: TnaTemplateDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.tnaTemplateStore.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private onNewTemplate() {
    const { history } = this.props;
    history.push({ pathname: '/assessments/tna/template/' });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem(selectedItem: TnaTemplateDto) {
    const { history } = this.props;
    history.location.state = selectedItem;
    history.push({ pathname: '/assessments/tna/template/' + selectedItem.id, state: selectedItem });
  }

  private onFilterTemplateOrId = (_, { value }) => {
    const filters = { ...this.state.filters };
    if (!value) {
      delete filters['templateNameOrID'];
    } else {
      const input = value as string;
      const parts = [`contains(tolower(title), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'TT');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('TT')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      filters['templateNameOrID'] = `(${parts.join(' or ')})`;
    }

    this.setState(
      {
        filters
      },
      () => this.refreshTable(filters)
    );
  };

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters;

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  private handleFilterByProfile(profile: ProfileItemDto) {
    let filters = { ...this.state.filters };

    filters.profileItemId = profile ? profile.id : null;
    this.refreshTable(filters);
  }

  private refreshTable(filters: TnaTemplateFilters) {
    const tableFilter = this.buildODataFilter(filters);

    this.setState({ filters });
    this.handleFilterChange(tableFilter);
  }

  private buildODataFilter(filters: TnaTemplateFilters) {
    let oDataFilters = [];
    const { isActive, templateNameOrID, profileItemId, machineModelId } = filters;
    if (isActive) {
      oDataFilters.push({ isActive: true });
    }

    if (!isNullOrWhiteSpaces(templateNameOrID)) {
      oDataFilters.push(templateNameOrID);
    }

    if (profileItemId) {
      oDataFilters.push({ ProfileItemId: { eq: { type: 'guid', value: profileItemId } } });
    }

    if (machineModelId) {
      oDataFilters.push({ MachineModels: { any: { id: { eq: { value: machineModelId, type: 'guid' } } } } });
    }

    return oDataFilters;
  }

  onMachineModelFilterChange = (item: ItemReference) => {
    const filters = { ...this.state.filters };
    const prev = filters.machineModelId;
    const next = item ? item.id : null;

    if (!customEqualCompare(prev, next)) {
      filters.machineModelId = next;
      this.refreshTable(filters);
    }
  };

  onHandleChangeFilter = (property: string, value: string | boolean) => {
    let filters = { ...this.state.filters };
    if (value == null) {
      delete filters[property];
    } else {
      filters[property] = value;
    }

    this.setState({
      filters
    });
  };

  onToggleAvailabilityItem = () => {
    this.setState({ onConfirmToggleDisable: false, loading: true });
    const item: TnaTemplateDto = { ...this.state.selectedItem };
    const newItem: ChangeTnaTemplateDto = {
      id: item.id,
      title: item.title,
      header: item.header,
      isActive: !item.isActive,
      machineModels: item.machineModels.map(machineModel => machineModel.id),
      nonMachineRelatedSkillSections: this.toTnaSkillTemplateMachineRelatedSections(item.nonMachineRelatedSkillSections),
      machineRelatedSections: this.toTnaMachineUnitTemplateRelatedSection(item.machineRelatedSections)
    };

    this.changeTnaTemplateStore.change({ ...newItem });

    this.changeTnaTemplateStore.update().then(response => {
      if (response && response.isSuccess) {
        this.setState({ selectedItem: null, loading: false }, this.load);
      } else {
        this.setState({ loading: false });
      }
    });
  };

  toTnaSkillTemplateMachineRelatedSections(skills: TnaSkillTemplateSectionDto[]): CreateTnaSkillTemplateSectionDto[] {
    return (skills || []).map(skill => ({
      skillId: skill.skillId,
      questions: skill.questions.map(({ id }) => id)
    }));
  }

  toTnaMachineUnitTemplateRelatedSection(
    machineRelatedSections: TnaMachineUnitTemplateSectionDto[]
  ): CreateTnaMachineUnitTemplateSectionDto[] {
    return (machineRelatedSections || []).map(section => ({
      machineUnitId: section.machineUnitId,
      skillSections: this.toCreateTnaSkillTemplateSections(section.skillSections || [])
    }));
  }

  toCreateTnaSkillTemplateSections(skillSections: TnaSkillTemplateSectionDto[]): CreateTnaSkillTemplateSectionDto[] {
    return (skillSections || []).map(section => ({
      skillId: section.skillId,
      questions: section.questions.map(question => question.id)
    }));
  }

  isOperationResultSucess = () => {
    return this.props.changeTnaTemplateStore.state.result && this.props.changeTnaTemplateStore.state.result.isSuccess;
  };

  isOperationCloneSucess = () => {
    return this.props.newTnaTemplateStore.state.result && this.props.newTnaTemplateStore.state.result.isSuccess;
  };

  closeConfirmationBox = () => {
    this.setState({ ...this.state, selectedItem: null, onConfirmToggleDisable: false, cloneModalToShown: false });
  };

  onCloneItem = () => {
    const item = { ...this.state.selectedItem };
    const newItem: CreateTnaTemplateDto = {
      profileItemId: item.profileItemId,
      title: item.title,
      header: item.header,
      isActive: item.isActive,
      machineModels: item.machineModels.map(machineModel => machineModel.id),
      nonMachineRelatedSkillSections: this.toTnaSkillTemplateMachineRelatedSections(item.nonMachineRelatedSkillSections || []),
      machineRelatedSections: this.toTnaMachineUnitTemplateRelatedSection(item.machineRelatedSections || [])
    };

    this.newTnaTemplateStore.createNew({ ...newItem });
    this.setState({ cloneModalToShown: false, loading: true });

    this.newTnaTemplateStore.submit().then(res => {
      this.setState({ loading: false });
      if (res == null || !res.isSuccess) console.log('Error cloning TNA Template');
      this.load();
    });
  };
  private onShowInactiveTemplates = () => {
    this.setState({ loading: true });
    let filters = { ...this.state.filters };

    this.setState({ showInactiveTemplates: !this.state.showInactiveTemplates }, () => {
      if (this.state.showInactiveTemplates) delete filters['isActive'];
      else filters['isActive'] = true;
      this.setState({ loading: false });
      this.refreshTable(filters);
    });
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t } = this.props as any;
    const { filters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Template ID'),
          tooltipRenderer: true,
          renderer: data => data.friendlyId,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<TnaTemplateDto>('friendlyId'),
            useProfile: false
          }
        },
        {
          title: t('Template'),
          tooltipRenderer: false,
          renderer: data => <div>{data.title}</div>,
          selectableHeader: true
        },
        {
          title: t('Profile'),
          tooltipRenderer: false,
          renderer: data => (
            <span style={{ backgroundColor: '#EEEEEE', fontWeight: 'normal' }} className="question-bank__cell__tag">
              {data.profileName ? data.profileName : ''}
            </span>
          )
        },
        {
          title: t('Machine Model'),
          tooltipRenderer: false,
          renderer: data => {
            const allMachineModels = (data.machineModels || [])
              .filter(m => m != null)
              .map(machineModel => (
                <span
                  key={machineModel.id}
                  style={{ backgroundColor: '#EEEEEE', fontWeight: 'normal' }}
                  className={'question-bank__cell__tag'}
                >{`${machineModel.equipmentTypeName} | ${machineModel.name}`}</span>
              ));

            return (
              <div className={data.machineModels && data.machineModels.length > 1 ? 'table-cell__more-width' : ''}>{allMachineModels}</div>
            );
          }
        }
      ],
      data: this.props.tnaTemplateStore.state
    } as TableModel<TnaTemplateDto>;

    return (
      <div className="tna-ssa-template__wrapper tna-template__wrapper">
        <Dimmer active={this.state.loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <Grid className="event-types-list-grid">
          {this.props.tnaTemplateStore.state.result && !this.props.tnaTemplateStore.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.tnaTemplateStore.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}

          <div className="tna__inputs-wrapper table__filters-share-main-actions">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Template or ID')}
              onChange={this.onFilterTemplateOrId}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <ProfileEditor
              value={null}
              nullable
              className="custom-editor"
              clearable
              onChange={profile => this.handleFilterByProfile(profile)}
              placeholder={t('Profile')}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <MRClusterEditor
              clearable
              className="custom-editor"
              nullable
              value={filters.clusterId}
              onChange={data => this.onHandleChangeFilter(nameof<TnaTemplateFilters>('clusterId'), data ? data.id : null)}
              placeholder={t('Cluster')}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <EquipmentTypeEditor
              readOnly={isNullOrUndefined(filters.clusterId)}
              clearable
              className="custom-editor"
              nullable
              clusterId={filters.clusterId ? filters.clusterId : undefined}
              value={filters.equipmentTypeId}
              onChange={data => this.onHandleChangeFilter(nameof<TnaTemplateFilters>('equipmentTypeId'), data ? data.id : null)}
              placeholder={t('Equipment Type')}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <OemEditor
              readonly={isNullOrUndefined(filters.equipmentTypeId) || isNullOrUndefined(filters.clusterId)}
              clearable
              className="custom-editor"
              nullable
              value={filters.oemId}
              equipmentId={filters.equipmentTypeId ? filters.equipmentTypeId : undefined}
              onChange={data => this.onHandleChangeFilter(nameof<TnaTemplateFilters>('oemId'), data ? data.id : null)}
              placeholder={t('OEM')}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <MachineModelEditor
              readonly={
                isNullOrUndefined(filters.oemId) || isNullOrUndefined(filters.clusterId) || isNullOrUndefined(filters.equipmentTypeId)
              }
              clearable
              className="custom-editor"
              nullable
              value={filters.machineModelId}
              oemId={filters.oemId ? filters.oemId : undefined}
              equipmentId={filters.equipmentTypeId ? filters.equipmentTypeId : undefined}
              onChange={this.onMachineModelFilterChange}
              placeholder={t('Machine Model')}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
          </div>
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.onConfirmToggleDisable && !this.state.cloneModalToShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              isRowDisableLayout={item => !(item as any).isActive}
              onRefresh={this.load}
              extraMenu={[
                {
                  index: 0,
                  className: '',
                  content: (
                    <Button onClick={this.onShowInactiveTemplates} className="table__custom-white-outline-button">
                      {this.state.showInactiveTemplates ? t('Hide Inactive templates') : t('Show Inactive templates')}
                    </Button>
                  )
                }
              ]}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil" />
                      {<span className="text__bold">{t('Edit')}</span>}
                    </>
                  ),
                  onClick: (item: TnaTemplateDto) => {
                    this.setState({ selectedItem: item }, () => this.onEditItem(item));
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="clone" />
                      {<span className="text__bold">{t('Clone')}</span>}
                    </>
                  ),
                  onClick: (item: TnaTemplateDto) => {
                    this.setState({ selectedItem: item, cloneModalToShown: true });
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="toggle off" color="red" />
                      <span className="text__bold text__red">{t('Deactivate')}</span>
                    </>
                  ),
                  isVisible: (item: TnaTemplateDto) => item.isActive,
                  onClick: (item: TnaTemplateDto) => {
                    this.setState({ selectedItem: item, onConfirmToggleDisable: true });
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="toggle on" />
                      <span className="text__bold">{t('Activate')}</span>
                    </>
                  ),
                  isVisible: (item: TnaTemplateDto) => !item.isActive,
                  onClick: (item: TnaTemplateDto) => {
                    this.setState({ selectedItem: item, onConfirmToggleDisable: true });
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewTemplate}
              canCreateNew={true}
              canEdit={false}
              canDelete={true}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              createNewButtonTitle={t('New TNA Template')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {this.state.onConfirmToggleDisable && (
          <Modal
            closeOnEscape={true}
            onClose={this.closeConfirmationBox}
            open={this.state.onConfirmToggleDisable}
            size="tiny"
            className="change-status-popup"
          >
            <Modal.Content className="change-status-popup__content">
              <p>{t(`You are going to change status to ${this.state.selectedItem.isActive ? 'disabled' : 'active'}`)}</p>
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.closeConfirmationBox} />
              <Button
                className="change-status-popup__btn change-status-popup__pending-btn"
                content={t('Yes')}
                onClick={this.onToggleAvailabilityItem}
              />
            </div>
          </Modal>
        )}
        {this.state.cloneModalToShown && (
          <Modal
            closeOnEscape={true}
            onClose={this.closeConfirmationBox}
            open={this.state.cloneModalToShown}
            size="tiny"
            className="change-status-popup"
          >
            <Modal.Content className="change-status-popup__content">
              <p>{t(`You are going to clone this template with id ${this.state.selectedItem.friendlyId}`)}</p>
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.closeConfirmationBox} />
              <Button className="change-status-popup__btn change-status-popup__pending-btn" content={t('Yes')} onClick={this.onCloneItem} />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation()(TnaTemplateList);
