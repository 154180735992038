import * as autobind from 'autobind';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Button, Container, Form, Message, Modal, Dimmer, Loader, Dropdown, Grid } from 'semantic-ui-react';
import LocationEditor from 'widgets/bussiness/location-editor';
import '../../../../assets/less/edit-user-modal.less';
import { LanguageDto, DropdownLanguagesStore } from 'stores/configuration/locations/languages-store';
import { ItemReference } from 'stores/dataStore';
import { nameof } from 'utils/object';
import { ChangeInstructorStore, InstructorDto } from 'stores/instructors/instructors-store';
import PillarsEditor from 'widgets/bussiness/pillars-editor';
import InstructorRoleEditor from 'widgets/bussiness/instructor-role-editor';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';

interface ChangeInstructorAssignmentViewProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  changeInstructor?: ChangeInstructorStore;
  currentInstructor: InstructorDto;
  languageStore?: DropdownLanguagesStore;
}

interface ChangeInstructorAssignmentViewState {
  selectedLanguages: LanguageDto[];
  languageOptions: { text: string; value: string }[];
  availableLanguages: { [id: string]: LanguageDto };

  instructor: ItemReference;
  location: ItemReference;
  language: ItemReference;
  pillar: ItemReference;
  instructorRole: ItemReference;
}

@connect(['changeInstructor', ChangeInstructorStore], ['languageStore', DropdownLanguagesStore])
class ChangeInstructorAssignmentView extends React.Component<ChangeInstructorAssignmentViewProps, ChangeInstructorAssignmentViewState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private get ChangeInstructorStore() {
    return this.props.changeInstructor;
  }

  constructor(props: ChangeInstructorAssignmentViewProps) {
    super(props);

    this.ChangeInstructorStore.state.result = null;

    this.state = {
      selectedLanguages: this.props.currentInstructor.languages,
      availableLanguages: {},
      languageOptions: [],
      location: this.fillLocation(),
      instructor: null,
      language: null,
      pillar: this.fillPillar(),
      instructorRole: this.fillInstructorRole()
    };

    this.ChangeInstructorStore.createNew({
      id: this.props.currentInstructor.userId,
      userId: this.props.currentInstructor.userId,
      name: this.props.currentInstructor.name,
      surname: this.props.currentInstructor.surname,
      locationId: this.state.location == null ? this.props.currentInstructor.locationId : this.state.location.id,
      languages: this.props.currentInstructor.languages,
      pillarId: this.state.pillar == null ? this.props.currentInstructor.pillarId : this.state.pillar.id,
      instructorRoleId: this.state.instructorRole == null ? this.props.currentInstructor.instructorRoleId : this.state.instructorRole.id
    });
    this.initLanguages();
  }

  @autobind
  private fillInstructorRole() {
    if (
      this.props.currentInstructor.instructorRole != null &&
      this.props.currentInstructor.instructorRole.id != null &&
      this.props.currentInstructor.instructorRole.name != null
    ) {
      let location: ItemReference = {
        id: this.props.currentInstructor.instructorRole.id,
        title: this.props.currentInstructor.instructorRole.name
      };
      return location;
    }
    return null;
  }

  @autobind
  private fillLocation() {
    if (
      this.props.currentInstructor.location != null &&
      this.props.currentInstructor.location.id != null &&
      this.props.currentInstructor.location.location != null
    ) {
      let location: ItemReference = { id: this.props.currentInstructor.location.id, title: this.props.currentInstructor.location.location };
      return location;
    }
    return null;
  }

  @autobind
  private fillPillar() {
    if (
      this.props.currentInstructor.pillar != null &&
      this.props.currentInstructor.pillar.id != null &&
      this.props.currentInstructor.pillar.name != null
    ) {
      let location: ItemReference = { id: this.props.currentInstructor.pillar.id, title: this.props.currentInstructor.pillar.name };
      return location;
    }
    return null;
  }

  @autobind
  private onChangeItem() {
    this.ChangeInstructorStore.update().then(x => {
      if (this.ChangeInstructorStore.state.result && this.ChangeInstructorStore.state.result.isSuccess) {
        this.props.onClose(true);
      }
    });
  }

  @autobind
  private onCancelChangeItem() {
    this.ChangeInstructorStore.clear();
    this.props.onClose(false);
  }

  @autobind
  private handleValue(property: string, value: any) {
    const change = {};
    change[property] = value;
    this.ChangeInstructorStore.change({ ...this.ChangeInstructorStore.state.item, ...change });
  }

  private selectedLanguages() {
    if (this.state.selectedLanguages) return this.state.selectedLanguages.map(x => (x.languageId ? x.languageId : x.id));
    return null;
  }

  @autobind
  private handleLanguageValue(data: any) {
    let languages = data.multiple
      ? data.value.map(x => this.state.availableLanguages[x])
      : data.value === ''
      ? null
      : [this.state.availableLanguages[data.value]];

    const newSelectedLanguages = this.mapLanguages(languages);

    this.setState({
      selectedLanguages: newSelectedLanguages
    });

    const item = this.ChangeInstructorStore.state.item;
    item.languages = newSelectedLanguages;
    this.ChangeInstructorStore.change(item);
  }

  private mapLanguages(languages) {
    languages.forEach(item => {
      if (!item.languageId) item.languageId = item.id;
      if (!item.id) item.id = item.languageId;
    });
    return languages;
  }

  private async initLanguages() {
    return await this.languageStore
      .getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [
          {
            direction: 'Ascending',
            field: nameof<LanguageDto>('language'),
            useProfile: false
          }
        ]
      })
      .then(languages => {
        const dict = {};
        const options = [];

        languages.items.forEach(language => {
          language.languageId = language.id;
          dict[language.id] = language;
          options.push({ text: language.language, value: language.id });
        });

        this.setState({
          availableLanguages: dict,
          languageOptions: options,
          selectedLanguages: this.props.changeInstructor.state.item.languages
        });
      });
  }

  @autobind
  private get languageStore() {
    return this.props.languageStore;
  }

  public render() {
    const { t } = this.props as any;
    const currentUserInfo = this.identityService.getUserInfo();
    const areYouPowerInstructor = IdentityService.isPowerInstructor(currentUserInfo);

    return (
      <Modal className="edit-instructor__modal" open closeOnEscape={true} onClose={this.onCancelChangeItem} closeOnDimmerClick={false}>
        <Dimmer active={this.ChangeInstructorStore.state.isBusy} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('')}</Loader>
        </Dimmer>
        <Modal.Header>{t('Edit Instructor Assignment')}</Modal.Header>
        <Modal.Content image>
          <Container className="edit-user-modal__wrapper">
            {this.ChangeInstructorStore.state.result && !this.ChangeInstructorStore.state.result.isSuccess && (
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.ChangeInstructorStore.state.result.messages.map(o => o.body)}
              />
            )}

            <Form>
              <Grid columns={4}>
                <Grid.Row>
                  <Grid.Column width={2} textAlign="right" verticalAlign="middle">
                    <div className={`field inline`}>
                      <label>{t('Instructor')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    <span>{this.props.currentInstructor.name + ' ' + this.props.currentInstructor.surname}</span>
                  </Grid.Column>
                  <Grid.Column width={3} textAlign="right" verticalAlign="middle">
                    <div className={`required field`}>
                      <label>{t("Instructor's Role")}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    <InstructorRoleEditor
                      nullable
                      value={this.state.instructorRole}
                      onChange={c => {
                        this.handleValue('instructorRoleId', c == null ? null : c.id);
                        this.setState({ instructorRole: c });
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={2} textAlign="right" verticalAlign="middle">
                    <div className={`required field`}>
                      <label>{t('Location')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    <LocationEditor
                      nullable
                      value={this.state.location}
                      onChange={c => {
                        this.handleValue('locationId', c == null ? null : c.id);
                        this.setState({ location: c });
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={3} textAlign="right" verticalAlign="middle">
                    <div className={`required field`}>
                      <label>{t('Languages')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    <Dropdown
                      search
                      inline
                      selection
                      clearable
                      options={this.state.languageOptions}
                      multiple={true}
                      value={this.selectedLanguages()}
                      className="planit-user-dropdown min-width-100pc"
                      onChange={(e, data) => this.handleLanguageValue(data)}
                      placeholder={t('Select languages')}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={2} textAlign="right" verticalAlign="middle">
                    <div className={`required field`}>
                      <label>{t('Pillars')}</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    <PillarsEditor
                      nullable
                      readonly={areYouPowerInstructor}
                      value={this.state.pillar}
                      onChange={c => {
                        this.handleValue('pillarId', c == null ? null : c.id);
                        this.setState({ pillar: c });
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={3} textAlign="right" verticalAlign="middle"></Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle"></Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCancelChangeItem} basic>
            {t('Cancel')}
          </Button>
          <Button onClick={this.onChangeItem} positive>
            {t('Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(ChangeInstructorAssignmentView);
