import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Button, Modal, Dimmer, Loader, Grid } from 'semantic-ui-react';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { getProperties } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { nameof } from 'utils/object';
import { ProfileItemDto } from 'stores/profile/profile-store';
import '../../tna/templates/tna-ssa-templates.less';
import {
  IwsTemplatesStore,
  IwsTemplateDto,
  NewIwsTemplateStore,
  ChangeIwsTemplateStore,
  ChangeIwsTemplateDto,
  CreateIwsTemplateDto,
  CreateIwsSkillTemplateSectionDto,
  IwsSkillTemplateSectionDto,
  ChangeIwsSkillTemplateSectionDto
} from 'stores/assessments/templates/iws-templates-store';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { extractFriendlyIdNumber, isNullOrWhiteSpaces } from 'utils/useful-functions';
import SkillsEditor, { SkillType } from 'widgets/bussiness/skills-editor';

export interface IwsTemplateListProps extends WithTranslation, RouteComponentProps {
  iwsTemplateStore: IwsTemplatesStore;
  newIwsTemplateStore: NewIwsTemplateStore;
  changeIwsTemplateStore: ChangeIwsTemplateStore;
}

interface IwsTemplateFilters {
  isActive: boolean;
  profileItemId: string;
  templateNameOrID: string;
  skillId: string;
}

export interface IwsTemplateListState {
  query: Query;
  activeFilters: { [key: string]: any };
  selectedItem: IwsTemplateDto;
  isclone: boolean;
  cloneData: IwsTemplateDto;
  showInactiveTemplates: boolean;
  uploadLoadingMessage: string;
  filters: IwsTemplateFilters;
  onConfirmToggleDisable: boolean;
  cloneModalToShown: boolean;
  showOnlyInactiveRows?: boolean;
  loading: boolean;
  someFilterOpened: boolean;
}

@connect(
  ['iwsTemplateStore', IwsTemplatesStore],
  ['newIwsTemplateStore', NewIwsTemplateStore],
  ['changeIwsTemplateStore', ChangeIwsTemplateStore]
)
class IwsTemplateList extends React.Component<IwsTemplateListProps, IwsTemplateListState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      cloneData: null,
      isclone: false,
      cloneModalToShown: false,
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }],
        filter: [{ isActive: true }],
        skip: 0,
        take: 10
      },
      activeFilters: [{ isActive: true }],
      showInactiveTemplates: false,
      uploadLoadingMessage: '',
      onConfirmToggleDisable: false,
      showOnlyInactiveRows: false,
      filters: {
        isActive: true,
        templateNameOrID: null,
        profileItemId: null,
        skillId: null
      },
      loading: false,
      someFilterOpened: false
    };
  }

  private get newIwsTemplateStore() {
    return this.props.newIwsTemplateStore;
  }

  private get changeIwsTemplateStore() {
    return this.props.changeIwsTemplateStore;
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const filter = getProperties(activeFilters)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    this.setState({ query }, () => this.props.iwsTemplateStore.getAllAsync(this.state.query));
  };

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: IwsTemplateDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.iwsTemplateStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: IwsTemplateDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.iwsTemplateStore.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private onNewTemplate() {
    const { history } = this.props;
    history.push({ pathname: '/assessments/iws/template/' });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem(selectedItem: IwsTemplateDto) {
    const { history } = this.props;
    history.location.state = selectedItem;
    history.push({ pathname: '/assessments/iws/template/' + selectedItem.id, state: selectedItem });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    if (isSuccess) this.load();
  }

  private uploadComplete = (data: any) => {
    this.load();
  };

  private onFilterTemplateOrId = (_, { value }) => {
    const filters = { ...this.state.filters };
    const iwsPrefix = 'TSC';
    if (!value) {
      delete filters['templateNameOrID'];
    } else {
      const input = value as string;
      const parts = [`contains(tolower(title), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, iwsPrefix);
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith(iwsPrefix)) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      filters['templateNameOrID'] = `(${parts.join(' or ')})`;
    }

    this.setState({ filters }, () => this.refreshTable(filters));
  };

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters;

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  private handleFilterByProfile(profile: ProfileItemDto) {
    let filters = { ...this.state.filters };

    filters.profileItemId = profile ? profile.id : null;
    this.refreshTable(filters);
  }

  private handleFilterBySkill(value: any) {
    let filters = { ...this.state.filters };

    filters.skillId = value ? value.id : null;
    this.refreshTable(filters);
  }
  private refreshTable(filters: IwsTemplateFilters) {
    const tableFilter = this.buildODataFilter(filters);

    this.setState({ filters });
    this.handleFilterChange(tableFilter);
  }

  private buildODataFilter(filters: IwsTemplateFilters) {
    let oDataFilters = [];
    const { isActive, templateNameOrID, profileItemId, skillId } = filters;
    if (isActive) {
      oDataFilters.push({ isActive: true });
    }

    if (!isNullOrWhiteSpaces(templateNameOrID)) {
      oDataFilters.push(templateNameOrID);
    }

    if (profileItemId) {
      oDataFilters.push({ ProfileItemId: { eq: { type: 'guid', value: profileItemId } } });
    }

    if (skillId) {
      oDataFilters.push({ 'SkillSection/SkillId': { eq: { type: 'guid', value: skillId } } });
    }

    return oDataFilters;
  }

  onHandleChangeFilter = (property: string, value: string | boolean) => {
    let filters = { ...this.state.filters };
    if (value == null) {
      delete filters[property];
    } else {
      filters[property] = value;
    }

    this.setState({
      filters
    });
  };

  onToggleAvailabilityItem = () => {
    this.setState({ onConfirmToggleDisable: false, loading: true });
    const item: IwsTemplateDto = { ...this.state.selectedItem };
    const newItem: ChangeIwsTemplateDto = {
      id: item.id,
      title: item.title,
      header: item.header,
      isActive: !item.isActive,
      skillSection: this.toChangeIwsSkillTemplateSection(item.skillSection)
    };

    this.changeIwsTemplateStore.change(newItem);
    this.changeIwsTemplateStore.update().then(response => {
      if (response && response.isSuccess) {
        this.setState({ selectedItem: null, loading: false }, this.load);
      } else {
        this.setState({ loading: false });
      }
    });
  };

  // toIwsskillTemplateSectionCategories(categories: IwsSkillTemplateSectionCategoryDto[]): CreateIwsSkillTemplateSectionCategoryDto[] {
  //   return (categories || []).map(category => ({
  //     name: category.name,
  //     skillSections: this.toCreateIwsSkillTemplateSections(category.skillSections)
  //   }));
  // }

  // toCreateIwsSkillTemplateSections(skillSections: IwsSkillTemplateSectionDto[]): CreateIwsSkillTemplateSectionDto[] {
  //   return skillSections.map(({ skillId, questions }) => ({
  //     skillId,
  //     questions: questions.map(x => x.id)
  //   }));
  // }

  toChangeIwsSkillTemplateSection(skillSection: IwsSkillTemplateSectionDto): ChangeIwsSkillTemplateSectionDto {
    return {
      skillId: skillSection.skillId,
      questions: skillSection.questions.map(x => x.id)
    };
  }

  toCreateIwsSkillTemplateSection(skillSection: IwsSkillTemplateSectionDto): CreateIwsSkillTemplateSectionDto {
    return {
      skillId: skillSection.skillId,
      questions: skillSection.questions.map(x => x.id)
    };
  }

  isOperationResultSucess = () => {
    return this.props.changeIwsTemplateStore.state.result && this.props.changeIwsTemplateStore.state.result.isSuccess;
  };

  isOperationCloneSucess = () => {
    return this.props.newIwsTemplateStore.state.result && this.props.newIwsTemplateStore.state.result.isSuccess;
  };

  closeConfirmationBox = () => {
    this.setState({ ...this.state, selectedItem: null, onConfirmToggleDisable: false, cloneModalToShown: false });
  };

  onCloneItem = () => {
    const item: IwsTemplateDto = { ...this.state.selectedItem };
    const newItem: CreateIwsTemplateDto = {
      profileItemId: item.profileItemId,
      title: item.title,
      header: item.header,
      isActive: item.isActive,
      skillSection: this.toChangeIwsSkillTemplateSection(item.skillSection)
    };

    this.newIwsTemplateStore.createNew({ ...newItem });
    this.setState({ cloneModalToShown: false, loading: true });

    this.newIwsTemplateStore.submit().then(res => {
      this.setState({ loading: false });
      if (res == null || !res.isSuccess) console.log('Error cloning IWS Template');
      this.load();
    });
  };
  private onShowInactiveTemplates = () => {
    this.setState({ loading: true });
    let filters = { ...this.state.filters };

    this.setState({ showInactiveTemplates: !this.state.showInactiveTemplates }, () => {
      if (this.state.showInactiveTemplates) delete filters['isActive'];
      else filters['isActive'] = true;
      this.setState({ loading: false });
      this.refreshTable(filters);
    });
  };

  private getExtraMenu = () => {
    const { t } = this.props as any;
    return [
      {
        index: 0,
        className: '',
        content: (
          <Button onClick={this.onShowInactiveTemplates} className="table__custom-white-outline-button">
            {this.state.showInactiveTemplates ? t('Hide Inactive templates') : t('Show Inactive templates')}
          </Button>
        )
      }
    ];
  };
  private getExtraActions = () => {
    const { t } = this.props as any;

    let buttonMenuOptions = [];
    buttonMenuOptions = [
      {
        content: (
          <>
            <Icon name="pencil" />
            {<span className="text__bold">{t('Edit')}</span>}
          </>
        ),
        onClick: (item: IwsTemplateDto) => {
          this.setState({ selectedItem: item }, () => this.onEditItem(item));
        }
      },
      {
        content: (
          <>
            <Icon name="clone" />
            {<span className="text__bold">{t('Clone')}</span>}
          </>
        ),
        onClick: (item: IwsTemplateDto) => {
          this.setState({ selectedItem: item, cloneModalToShown: true });
        }
      },
      {
        content: (
          <>
            <Icon name="toggle off" color="red" />
            <span className="text__bold text__red">{t('Deactivate')}</span>
          </>
        ),
        isVisible: (item: IwsTemplateDto) => item.isActive,
        onClick: (item: IwsTemplateDto) => {
          this.setState({ selectedItem: item, onConfirmToggleDisable: true });
        }
      },
      {
        content: (
          <>
            <Icon name="toggle on" />
            <span className="text__bold">{t('Activate')}</span>
          </>
        ),
        isVisible: (item: IwsTemplateDto) => !item.isActive,
        onClick: (item: IwsTemplateDto) => {
          this.setState({ selectedItem: item, onConfirmToggleDisable: true });
        }
      }
    ];

    return buttonMenuOptions;
  };

  handleOnEnterKeydown = (item: IwsTemplateDto) => {
    this.setState({ selectedItem: item }, () => this.onEditItem(item));
  };

  handleOnRowDoubleClick = (item: IwsTemplateDto) => {
    this.handleOnEnterKeydown(item);
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t, iwsTemplateStore, changeIwsTemplateStore } = this.props;
    const { someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Template ID'),
          tooltipRenderer: true,
          renderer: data => data.friendlyId,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<IwsTemplateDto>('friendlyId'),
            useProfile: false
          }
        },
        {
          title: t('Template'),
          tooltipRenderer: false,
          renderer: data => <div>{data.title}</div>,
          selectableHeader: true
        },
        {
          title: t('Skill'),
          tooltipRenderer: false,
          renderer: data => (
            <span style={{ backgroundColor: '#EEEEEE', fontWeight: 'normal' }} className="question-bank__cell__tag">
              {data.skillSection ? data.skillSection.skillName : ''}
            </span>
          )
        }
      ],
      data: iwsTemplateStore.state
    } as TableModel<IwsTemplateDto>;

    const messages = [];
    if (iwsTemplateStore.state.result && !iwsTemplateStore.state.result.isSuccess)
      iwsTemplateStore.state.result.messages.forEach(({ body }) => messages.push(body));
    if (changeIwsTemplateStore.state.result && !changeIwsTemplateStore.state.result.isSuccess)
      changeIwsTemplateStore.state.result.messages.forEach(({ body }) => messages.push(body));
    return (
      <div className="tna-ssa-template__wrapper iws-template__wrapper">
        <Dimmer active={this.state.loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>

        <Grid className="event-types-list-grid">
          {messages && messages.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message className="error-message__style" icon="exclamation circle" error header={t('An error ocurred')} list={messages} />
            </Grid.Row>
          )}

          <div className="tna__inputs-wrapper table__filters-share-main-actions">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Template or ID')}
              onChange={this.onFilterTemplateOrId}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <SkillsEditor
              skillType={SkillType.IWS}
              value={null}
              nullable
              className="custom-editor"
              clearable
              onChange={skill => this.handleFilterBySkill(skill)}
              placeholder={t('Skill')}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
          </div>
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.onConfirmToggleDisable && !this.state.cloneModalToShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////

              model={tableModel}
              isRowDisableLayout={item => !(item as any).isActive}
              onRefresh={this.load}
              extraMenu={this.getExtraMenu()}
              extraActions={this.getExtraActions()}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewTemplate}
              canCreateNew={true}
              canEdit={false}
              canDelete={true}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              createNewButtonTitle={t('New Step Up Card Template')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {this.state.onConfirmToggleDisable && (
          <Modal
            open={this.state.onConfirmToggleDisable}
            size="tiny"
            className="change-status-popup"
            closeOnEscape={true}
            onClose={this.closeConfirmationBox}
          >
            <Modal.Content className="change-status-popup__content">
              <p>{t(`You are going to change status to ${this.state.selectedItem.isActive ? 'disabled' : 'active'}`)}</p>
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.closeConfirmationBox} />
              <Button
                className="change-status-popup__btn change-status-popup__pending-btn"
                content={t('Yes')}
                onClick={this.onToggleAvailabilityItem}
              />
            </div>
          </Modal>
        )}

        {this.state.cloneModalToShown && (
          <Modal
            open={this.state.cloneModalToShown}
            size="tiny"
            className="change-status-popup"
            closeOnEscape={true}
            onClose={this.closeConfirmationBox}
          >
            <Modal.Content className="change-status-popup__content">
              <p>{t(`You are going to clone this template with id ${this.state.selectedItem.friendlyId}`)}</p>
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.closeConfirmationBox} />
              <Button className="change-status-popup__btn change-status-popup__pending-btn" content={t('Yes')} onClick={this.onCloneItem} />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation()(IwsTemplateList);
