import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Message, Icon, Dropdown, Form, Button, Checkbox, Modal, Dimmer, Loader, Grid } from 'semantic-ui-react';
import { ItemState, OrderDefinition, Query, ItemReference } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { getProperties } from 'utils/object';
import { TableModel, TableView, TableColumn } from 'widgets/collections/table';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces, openInNewWindow, extractFriendlyIdNumber } from 'utils/useful-functions';

import { resolve } from 'inversify.config';
import { IdentityService, Roles } from 'services/identity-service';
import LocationEditor from 'widgets/bussiness/location-editor';
import ProfileEditor from 'widgets/bussiness/profile-editor';
import { DatesRangeInput } from 'widgets/form/datesRangeInput';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import {
  TnaFormListStore,
  TnaFormListDto,
  ChangeActiveFlagTnaFormDto,
  ChangeActiveTnaFormTnaStore
} from 'stores/assessments/forms/tna-forms-store';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { DateTimeService } from 'services/datetime-service';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';

import {
  TnaQuestionFormTranslateLogItemDto,
  TranslateStatus,
  TranslateTnaQuestionTranslateFormStore
} from 'stores/assessments/forms/tna-translate-question-form-store';

export interface TnaFormsListProps extends WithTranslation, RouteComponentProps {
  tnaFormListStore?: TnaFormListStore;
  isUserAssessor: boolean;
  changeActiveTnaFormStore: ChangeActiveTnaFormTnaStore;
  translateTnaQuestionStore: TranslateTnaQuestionTranslateFormStore;
}

export interface TnaFormsListState {
  query: Query;
  activeFilters: { [key: string]: any };
  selectedItem: any;
  location: ItemReference;
  creationDateFrom: string;
  creationDateTo: string;
  deadlineFrom: string;
  deadlineTo: string;
  profile: ProfileItemDto;
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  tnaStatus: string;
  isInRoleEmployee: boolean;
  isInRoleAdminOrPoC: boolean;
  isInRoleAdmin: boolean;
  isInRolePoC: boolean;
  isInRoleAssessor: boolean;
  isInRoleAdminOrPocOrAssesor: boolean;
  employee: string;
  showDisabled: boolean;
  parameters: any;
  onConfirmToggleDisable: boolean;
  showInactiveTnaForms: boolean;
  showTranslateTnaQuestionMsg: boolean;
  showTranslateProcessModal: boolean;
  translateProcessModalText: string;
  translateProcessStatus: TranslateStatus;
  someFilterOpened: boolean;
}

@connect(
  ['tnaFormListStore', TnaFormListStore],
  ['changeActiveTnaFormStore', ChangeActiveTnaFormTnaStore],
  ['translateTnaQuestionStore', TranslateTnaQuestionTranslateFormStore]
)
class TnaFormsList extends React.Component<TnaFormsListProps, TnaFormsListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem(item as TnaFormListDto));
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      activeFilters: [],
      selectedItem: null,

      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: nameof<TnaFormListDto>('createdOn'), useProfile: false }],
        skip: 0,
        take: 10,
        parameters: this.initializeParameters()
      },

      location: null,
      creationDateFrom: null,
      creationDateTo: null,
      deadlineFrom: null,
      deadlineTo: null,
      profile: null,
      cluster: null,
      equipmentType: null,
      oem: null,
      machineModel: null,
      isInRoleEmployee: this.isInRoleEmployee(),
      isInRoleAdminOrPoC: this.isInRoleAdminOrPoC(),
      isInRoleAdmin: this.isInRoleAdmin(),
      isInRolePoC: this.isInRolePoC(),
      tnaStatus: null,
      employee: '',
      showDisabled: false,
      parameters: this.initializeParameters(),
      isInRoleAssessor: this.props.isUserAssessor,
      isInRoleAdminOrPocOrAssesor: this.isInRoleAdminPocOrAssesor(),
      onConfirmToggleDisable: false,
      showInactiveTnaForms: false,
      showTranslateTnaQuestionMsg: false,
      showTranslateProcessModal: false,
      translateProcessModalText: '',
      translateProcessStatus: TranslateStatus.Unknown,
      someFilterOpened: false
    };
  }

  @autobind
  private async showTranslateProcessModal() {
    const filter = [];
    const tnaOrder: OrderDefinition[] = [
      { direction: 'Descending', field: nameof<TnaQuestionFormTranslateLogItemDto>('started'), useProfile: false }
    ];

    var translateTnaQuestionLog = await this.props.translateTnaQuestionStore.getTranslateTnaQuestionLog({
      searchQuery: '',
      skip: 0,
      take: 1,
      orderBy: tnaOrder,
      filter
    });

    var item = translateTnaQuestionLog?.items.firstOrDefault();
    var modalText = '';

    if (item == null) {
      modalText = 'The translate proccess was never launched.\r\n Do you want to start it?';
    } else {
      modalText =
        item.translateStatus === TranslateStatus[TranslateStatus.Completed]
          ? `Translation process finished last ${DateTimeService.toChatString(
              item.finished
            )} with status ${item.translateStatus.toString()}.\r\n Do you want to initialize it again?`
          : 'The translation process is still working.';
    }

    this.setState({
      translateProcessModalText: this.props.t(modalText),
      showTranslateProcessModal: true,
      translateProcessStatus: item == null ? TranslateStatus.Unknown : TranslateStatus[item.translateStatus]
    });
  }

  @autobind
  private hideTranslateProcessModal() {
    this.setState({ showTranslateProcessModal: false, selectedItem: null });
  }

  private initializeParameters = (): any => {
    return { IsActive: 'true' };
  };

  componentDidMount() {
    this.load();
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  private isInRoleEmployee = (): boolean => {
    return this.identityService.isInRole(Roles.Employee);
  };

  private isInRoleAdmin = (): boolean => {
    return IdentityService.isAdminOrPowerInstructor(this.identityService.getUserInfo());
  };

  private isInRolePoC = (): boolean => {
    return this.identityService.isInRole(Roles.PoC);
  };

  private isGlobalPoc(): boolean {
    const userInfo = this.identityService.getUserInfo();
    const isPoc = IdentityService.isPoc(userInfo);
    const isGlobalPoc = isPoc && userInfo.isGlobalPoc;

    return isGlobalPoc;
  }

  private isInRoleAdminOrPoC = (): boolean => {
    return this.isInRoleAdmin() || this.identityService.isInRole(Roles.PoC);
  };

  private isInRoleAdminPocOrAssesor = (): boolean => {
    return this.isInRoleAdmin() || this.identityService.isInRole(Roles.PoC) || this.props.isUserAssessor;
  };

  private onOpenEmployee = (user: TnaFormListDto) => {
    openInNewWindow(`./employee-page/${user.userId}/${user.id}`);
  };

  private canSeeDeactivateOption = (tna: TnaFormListDto): boolean => {
    if (tna.status.toString() === 'Employee' && this.state.isInRoleAdminOrPoC && tna.isActive && !tna.isStarted) return true;
    else return false;
  };

  private canSeeActivateOption = (tna: TnaFormListDto): boolean => {
    if (tna.status.toString() === 'Employee' && this.state.isInRoleAdminOrPoC && !tna.isActive && !tna.isStarted) return true;
    else return false;
  };

  private canEditTnaForm = (tna: TnaFormListDto): boolean => {
    if (
      this.state.isInRoleAdminOrPoC &&
      (tna.status.toString() === 'Employee' || tna.status.toString() === 'Assessors' || tna.status.toString() === 'Done')
    )
      return true;
    else return false;
  };

  private canSeeOpenTna = (tna: TnaFormListDto): boolean => {
    if (this.state.isInRoleAssessor && tna.status.toString() === 'Assessors') return true;
    else if ((tna.status.toString() === 'Employee' || tna.status.toString() === 'Done') && this.state.isInRoleEmployee) return true;
    else if (this.state.isInRoleAdminOrPoC) return true;
    else return false;
  };

  private load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const filter = getProperties(activeFilters)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    if (!this.state.isInRoleAssessor || this.state.isInRoleAdmin)
      this.setState({ query, showTranslateTnaQuestionMsg: false }, () => this.props.tnaFormListStore.getAllAsync(this.state.query));
    else {
      this.setState({ query, showTranslateTnaQuestionMsg: false }, () =>
        this.props.tnaFormListStore.getAssessorTnaFormList(this.state.query)
      );
    }
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState(({ query }) => ({ query: { ...query, skip, take } }), this.load);
  };

  private onSaveRow = async (item: TnaFormListDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') await this.props.tnaFormListStore.saveAsync(item, state);

    return { isSuccess: true, items: [], messages: [] };
  };

  private onDelete = async (item: TnaFormListDto, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') await this.props.tnaFormListStore.deleteAsync(item.id, state);

    return { isSuccess: true, items: [], messages: [] };
  };

  private onNewItem = () => {
    const { history } = this.props;
    history.push({ pathname: '/assessments/tna/form/create' });
  };

  private onEditItem = (tna: TnaFormListDto) => {
    openInNewWindow(`./assessments/tna/tna-edit-form/${tna.id}`);
  };

  private initTranslatingTnaQuestionFormProcess = () => {
    this.setState({
      showTranslateTnaQuestionMsg: true,
      showTranslateProcessModal: false
    });

    this.props.translateTnaQuestionStore.initBackgroundTnaQuestionFormTranslate();
  };

  private getBackgroundTranslateTnaQuestionMessage = () => {
    const { isBusy, result } = this.props.translateTnaQuestionStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    if (!result.isSuccess) {
      header = t('Something went wrong');
      content = result.messages.map(x => x.body).join('. ');
    } else {
      header = t('Background translate process initialized successfully');
      content = t('You will receive an email when the process ends');
    }

    return (
      <Message
        className={!result.isSuccess && 'error-message__style'}
        icon={!result.isSuccess && 'exclamation circle'}
        onDismiss={this.handleTranslateTnaQuestionMsgDismiss}
        header={header}
        content={content}
        error={!result.isSuccess}
        success={result.isSuccess}
      />
    );
  };

  private handleTranslateTnaQuestionMsgDismiss = () => {
    this.setState({ showTranslateTnaQuestionMsg: false });
  };

  private canPocEditThisForm = (selectedItem: TnaFormListDto) => {
    const locationFormToEdit = selectedItem.user.location.id;
    let locations = this.identityService.getCurrentRoleLocations() || [];

    if (this.isInRolePoC && locations.includes(locationFormToEdit)) return true;
    else return false;
  };

  private onEditForm = (selectedItem: TnaFormListDto) => {
    const { history } = this.props;
    const canPocEdit = this.canPocEditThisForm(selectedItem);
    history.location.state = selectedItem;
    if (
      (selectedItem.status === 'Employee' || selectedItem.status === 'Assessors' || selectedItem.status === 'Done') &&
      (this.isInRoleAdmin || (this.isInRolePoC && canPocEdit))
    )
      history.push({ pathname: '/assessments/tna/form/edit-wizard/' + selectedItem.id, state: selectedItem });
  };

  private handleFilterTna = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) delete activeFilters['IdDescription'];
    else {
      const input = value as string;
      const parts = [`contains(tolower(TnaAssessment/Title), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'TNA');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('TNA')) parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        else parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
      }

      activeFilters['IdDescription'] = `(${parts.join(' or ')})`;
    }
    this.setState({ activeFilters }, this.load);
  };

  private handleFilterEmployee = (employee: string) => {
    const activeFilters = { ...this.state.activeFilters };
    const parameters = this.state.query.parameters || {};

    if (isNullOrWhiteSpaces(employee)) delete parameters['EmployeeOrSfPosition'];
    else parameters['EmployeeOrSfPosition'] = employee;

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, activeFilters, employee }), this.load);
  };

  private handleFilterProfile = (profile: ProfileItemDto) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!profile) delete activeFilters['ProfileId'];
    else activeFilters['ProfileId'] = { TnaAssessment: { TnaTemplate: { ProfileItemId: { eq: { type: 'guid', value: profile.id } } } } };

    this.setState({ activeFilters: activeFilters, profile: profile }, this.load);
  };

  private handleFilterLocation = (location: ItemReference) => {
    let parameters = this.state.parameters;

    if (location) parameters['LocationId'] = location.id;
    else parameters = this.initializeParameters();

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, location, parameters }), this.load);
  };

  private handleFilterTnaStatus = (_, { value }) => {
    const tnaStatus = value;
    const activeFilters = { ...this.state.activeFilters };
    const tnaString = this.returnTnaStatus(tnaStatus);
    if (!tnaStatus) delete activeFilters['tnaStatus'];
    else if (value === 10 || value === 20) activeFilters['tnaStatus'] = { Status: tnaString, OutOfDate: false };
    else if (value === 30) activeFilters['tnaStatus'] = { Status: tnaString };
    else activeFilters['tnaStatus'] = { Status: { ne: 'Done' }, OutOfDate: true };

    this.setState({ activeFilters, tnaStatus }, this.load);
  };

  private returnTnaStatus = (status: number): string => {
    if (status === 10) return 'Employee';
    else if (status === 20) return 'Assessors';
    else if (status === 30) return 'Done';
    else if (status === 40) return 'Out of Date';
    else return '';
  };

  private handleFilterCreateAt = (from: string, to: string) => {
    const { activeFilters } = this.state;
    const fromIsNull = isNullOrWhiteSpaces(from);
    const toIsNull = isNullOrWhiteSpaces(to);

    if (fromIsNull && toIsNull) {
      delete activeFilters['creationDate'];
    } else {
      activeFilters['creationDate'] = {
        createdOn: {
          ge: { type: 'raw', value: DateTimeService.toString(fromIsNull ? to : from, null, 'startOfDay') },
          le: { type: 'raw', value: DateTimeService.toString(toIsNull ? from : to, null, 'endOfDay') }
        }
      };
    }

    this.setState({ activeFilters, creationDateFrom: from, creationDateTo: to }, this.load);
  };

  private handleFilterDeadline = (from: string, to: string) => {
    const { activeFilters } = this.state;
    const fromIsNull = isNullOrWhiteSpaces(from);
    const toIsNull = isNullOrWhiteSpaces(to);

    if (fromIsNull && toIsNull) {
      delete activeFilters['deadline'];
    } else {
      activeFilters['deadline'] = {
        Deadline: {
          ge: { type: 'raw', value: DateTimeService.toString(fromIsNull ? to : from, null, 'startOfDay') },
          le: { type: 'raw', value: DateTimeService.toString(toIsNull ? from : to, null, 'endOfDay') }
        }
      };
    }

    this.setState({ activeFilters, deadlineFrom: from, deadlineTo: to }, this.load);
  };

  private onClusterEditorChange = (cluster: ItemReference) => {
    const parameters = this.state.query.parameters || {};
    if (!cluster?.id) delete parameters['clusterId'];
    else parameters['clusterId'] = cluster.id;
    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, cluster }), this.load);
  };

  onEquipmentTypeFilterChange = (equipmentType: ItemReference) => {
    const parameters = this.state.query.parameters || {};
    if (!equipmentType?.id) delete parameters['equipmentTypeId'];
    else parameters['equipmentTypeId'] = equipmentType.id;
    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, equipmentType }), this.load);
  };

  onOemFilterChange = (oem: ItemReference) => {
    const parameters = this.state.query.parameters || {};
    if (!oem?.id) delete parameters['oemId'];
    else parameters['oemId'] = oem.id;
    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, oem }), this.load);
  };

  onChangeMachineModelFilter = (machineModel: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!machineModel?.id) delete activeFilters['machineModelId'];
    else
      activeFilters['machineModelId'] = {
        MachineModelsSelection: { any: { MachineModelId: { eq: { type: 'guid', value: machineModel.id } } } }
      };

    this.setState({ activeFilters, machineModel }, this.load);
  };

  private handleInactiveUsers = () => {
    const disabled = this.state.showDisabled;
    const activeFilters = { ...this.state.activeFilters };
    const parameters = this.state.query.parameters || {};
    if (disabled) parameters['IsActive'] = `${disabled}`;
    else delete parameters['IsActive'];

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, activeFilters, showDisabled: !disabled }), this.load);
  };

  onToggleAvailabilityItem = async () => {
    this.setState({ onConfirmToggleDisable: false });
    const { id, isActive }: TnaFormListDto = this.state.selectedItem;
    const newItem: ChangeActiveFlagTnaFormDto = { id, isActive: !isActive };
    this.props.changeActiveTnaFormStore.change({ ...newItem });

    const response = await this.props.changeActiveTnaFormStore.update();
    if (response?.isSuccess) this.setState({ selectedItem: null }, this.load);
  };

  closeConfirmationBox = () => {
    this.setState({ ...this.state, selectedItem: null, onConfirmToggleDisable: false });
  };

  private onShowInactiveTnaForms = () => {
    const activeFilters = { ...this.state.activeFilters };

    if (this.state.showInactiveTnaForms) delete activeFilters['isActive'];
    else activeFilters['isActive'] = { IsActive: true };

    this.setState(({ showInactiveTnaForms }) => ({ activeFilters, showInactiveTnaForms: !showInactiveTnaForms }), this.load);
  };

  private getColumnsModel = (): TableColumn<TnaFormListDto>[] => {
    const { t } = this.props;
    const isEmployee = this.isInRoleEmployee();
    const isAssessor = this.props.isUserAssessor;

    const columns: TableColumn<TnaFormListDto>[] = [
      {
        title: t('TNA ID'),
        tooltipRenderer: true,
        renderer: data => data.friendlyId,
        selectableHeader: true,
        sortDefinition: {
          field: nameof<TnaFormListDto>('friendlyId'),
          useProfile: false
        }
      }
    ];
    if (isEmployee && !isAssessor)
      columns.push({ title: t('TNA Title'), tooltipRenderer: true, renderer: data => data.title, selectableHeader: true });

    if (!isEmployee || isAssessor)
      columns.push(
        {
          title: t('Employee'),
          tooltipRenderer: false,
          renderer: ({ user }) => `${user?.lastName || ''}, ${user?.firstName || ''}`,
          selectableHeader: true
        },
        { title: t('SF Position'), tooltipRenderer: true, renderer: data => data.user?.sfPosition || '', selectableHeader: true },

        { title: t('Location'), tooltipRenderer: true, renderer: data => data.user?.location?.location || '', selectableHeader: true },

        {
          title: t('Profile'),
          tooltipRenderer: false,
          renderer: ({ profileName }) => <span className="assessment-forms-grey-tag">{profileName}</span>
        }
      );

    columns.push(
      {
        title: t('Machine Model'),
        tooltipRenderer: false,
        renderer: ({ machineModels }) => {
          const allMachines = (machineModels || []).map(machine => (
            <span
              key={`${machine.id}-${machine.clusterId}-${machine.oemId}-${machine.equipmentTypeId}`}
              className={machineModels && machine ? 'assessment-forms-grey-tag' : ''}
            >
              {machine.equipmentTypeName}&nbsp;|&nbsp;{machine.name}
            </span>
          ));
          return <div className={(machineModels || []).length > 0 ? 'table-cell__more-width' : ''}>{allMachines}</div>;
        }
      },

      {
        title: t('Creation Date'),
        tooltipRenderer: false,
        renderer: ({ createAt }) => <span>{DateTimeService.toDateInputString(createAt)}</span>,
        selectableHeader: true,
        sortDefinition: { field: nameof<TnaFormListDto>('createdOn'), useProfile: false, active: 'Descending' }
      },
      {
        title: t('Deadline'),
        tooltipRenderer: false,
        renderer: data => <span>{DateTimeService.toDateInputString(data.deadline)}</span>,
        selectableHeader: true,
        sortDefinition: { field: nameof<TnaFormListDto>('deadline'), useProfile: false }
      },
      {
        title: t('Status'),
        tooltipRenderer: false,
        renderer: ({ extendedStatus }) =>
          (extendedStatus || []).map(eStatus => {
            let className = '';
            if (eStatus.toLowerCase().includes('out of date')) className = 'assessment-forms-out-of-date-tag';
            else if (eStatus.toLowerCase().includes('done')) className = 'assessment-forms-done-tag';
            else if (eStatus.toLowerCase().includes('assessment')) className = 'assessment-forms-assessors-tag';
            else className = 'assessment-forms-employee-tag';
            return (
              <span key={eStatus} className={`assessment-forms__table__tag ${className}`}>
                {eStatus}
              </span>
            );
          }),
        selectableHeader: true
      }
    );

    return columns;
  };

  private getExtraActions = () => {
    const { t } = this.props as any;

    let buttonMenuOptions = [];
    if (this.state.isInRoleAdminOrPoC || this.state.isInRoleAssessor) {
      buttonMenuOptions = [
        {
          content: (
            <>
              <Icon name="file alternate" />
              {<span className="text__bold">{t('Open TNA Form')}</span>}
            </>
          ),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onEditItem(item as TnaFormListDto));
          },
          isVisible: item => this.canSeeOpenTna(item as TnaFormListDto)
        },
        {
          content: (
            <>
              <Icon name="eye" />
              {<span className="text__bold">{t('Open Employee Card')}</span>}
            </>
          ),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onOpenEmployee(item as TnaFormListDto));
          }
        },
        {
          content: (
            <>
              <Icon name="toggle off" color="red" />
              <span className="text__bold text__red">{t('Deactivate')}</span>
            </>
          ),
          isVisible: (item: TnaFormListDto) => this.canSeeDeactivateOption(item),
          onClick: (item: TnaFormListDto) => {
            this.setState({ selectedItem: item, onConfirmToggleDisable: true });
          }
        },
        {
          content: (
            <>
              <Icon name="pencil" />
              {<span className="text__bold">{t('Edit TNA form')}</span>}
            </>
          ),
          isVisible: item => this.canEditTnaForm(item as TnaFormListDto),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onEditForm(item as TnaFormListDto));
          }
        },
        {
          content: (
            <>
              <Icon name="toggle on" />
              <span className="text__bold">{t('Activate')}</span>
            </>
          ),
          isVisible: (item: TnaFormListDto) => this.canSeeActivateOption(item),
          onClick: (item: TnaFormListDto) => {
            this.setState({ selectedItem: item, onConfirmToggleDisable: true });
          }
        }
      ];
    } else {
      buttonMenuOptions = [
        {
          content: (
            <>
              <Icon name="file alternate" />
              {<span className="text__bold">{t('Open TNA Form')}</span>}
            </>
          ),
          onClick: item => {
            this.setState({ selectedItem: item }, () => this.onEditItem(item as TnaFormListDto));
          },
          isVisible: item => this.canSeeOpenTna(item as TnaFormListDto)
        },
        {
          content: (
            <>
              <Icon name="toggle off" color="red" />
              <span className="text__bold text__red">{t('Deactivate')}</span>
            </>
          ),
          isVisible: (item: TnaFormListDto) => this.canSeeDeactivateOption(item),
          onClick: (item: TnaFormListDto) => {
            this.setState({ selectedItem: item, onConfirmToggleDisable: true });
          }
        },
        {
          content: (
            <>
              <Icon name="toggle on" />
              <span className="text__bold">{t('Activate')}</span>
            </>
          ),
          isVisible: (item: TnaFormListDto) => this.canSeeActivateOption(item),
          onClick: (item: TnaFormListDto) => {
            this.setState({ selectedItem: item, onConfirmToggleDisable: true });
          }
        }
      ];
    }

    return buttonMenuOptions;
  };

  private canCreate = () => {
    const { isInRoleAdminOrPocOrAssesor, isInRoleAdmin, isInRolePoC, isInRoleAssessor } = this.state;
    return isInRoleAdminOrPocOrAssesor && (isInRoleAdmin || (isInRolePoC && !isInRoleAssessor));
  };

  private canTranslate = () => {
    return this.state.translateProcessStatus === TranslateStatus.Completed || this.state.translateProcessStatus === TranslateStatus.Unknown;
  };

  private tnaFormStatusOptions = [
    { key: 1, text: 'Employee', value: 10 },
    { key: 2, text: 'Assessors', value: 20 },
    { key: 3, text: 'Done', value: 30 },
    { key: 4, text: 'Out of Date', value: 40 }
  ];

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t, tnaFormListStore, changeActiveTnaFormStore } = this.props;
    const {
      isInRoleAdminOrPocOrAssesor,
      employee,
      profile,
      location,
      tnaStatus,
      creationDateFrom,
      creationDateTo,
      someFilterOpened
    } = this.state;
    const { isInRoleAdminOrPoC, query, isInRoleEmployee, isInRoleAssessor, onConfirmToggleDisable, selectedItem, cluster } = this.state;
    const { deadlineFrom, deadlineTo, equipmentType, oem, machineModel } = this.state;
    const { result } = tnaFormListStore.state;
    const pocLocations =
      IdentityService.isPoc(this.identityService.getUserInfo()) && !this.isGlobalPoc()
        ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
        : [];

    const tableModel = {
      columns: this.getColumnsModel(),
      data: tnaFormListStore.state
    } as TableModel<TnaFormListDto>;

    return (
      <div className="tna-ssa-template__wrapper tna-template__wrapper">
        {this.props.translateTnaQuestionStore.state.isBusy && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Initializing TNA Question Translate process')}</Loader>
          </Dimmer>
        )}
        <Grid className="event-types-list-grid">
          {result && !result.isSuccess && (result.messages || []).length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}

          {this.state.showTranslateTnaQuestionMsg && this.getBackgroundTranslateTnaQuestionMessage()}

          {isInRoleAdminOrPocOrAssesor && (
            <div id="assessment-forms-list-filters__admin-or-poc-container">
              <div
                id="assessment-forms-list-filters__admin-or-poc-container__lef-container"
                className="specific-margin-bottom-equal-filters"
              >
                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search in Title or ID')}
                    onChange={this.handleFilterTna}
                    key={'searchTna'}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search Employee')}
                    onChange={(_, employee) => this.handleFilterEmployee(employee.value)}
                    value={employee || ''}
                    key={'searchEmployee'}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <ProfileEditor
                    placeholder={t('Profile')}
                    nullable
                    assessment={'TNA'}
                    value={profile?.id || ''}
                    onChange={this.handleFilterProfile}
                    clearable
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <LocationEditor
                    loadDataOnOpen={true}
                    placeholder={t('Location')}
                    nullable
                    value={location}
                    onChange={this.handleFilterLocation}
                    clearable={true}
                    locationsReceived={pocLocations}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <Dropdown
                    className="custom-editor width-14em"
                    fluid
                    selection
                    options={this.tnaFormStatusOptions}
                    value={tnaStatus || null}
                    closeOnBlur={true}
                    closeOnEscape={true}
                    selectOnBlur={false}
                    selectOnNavigation={false}
                    placeholder={t('TNA Status')}
                    onChange={this.handleFilterTnaStatus}
                    clearable
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>
                <DatesRangeInput
                  dontUseRedClearIconColor={true}
                  iconPosition="right"
                  fromValue={creationDateFrom}
                  toValue={creationDateTo}
                  onChange={this.handleFilterCreateAt}
                  placeholder={t('Creation Date')}
                  clearable={true}
                  allowSameEndDate
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
                <DatesRangeInput
                  dontUseRedClearIconColor={true}
                  iconPosition="right"
                  fromValue={deadlineFrom}
                  toValue={deadlineTo}
                  onChange={this.handleFilterDeadline}
                  placeholder={t('Deadline')}
                  clearable={true}
                  allowSameEndDate
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
                <Form.Field>
                  <MRClusterEditor
                    clearable={true}
                    nullable
                    value={cluster}
                    onChange={this.onClusterEditorChange}
                    placeholder={t('Cluster')}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>
                <Form.Field>
                  <EquipmentTypeEditor
                    nullable
                    clearable={true}
                    clusterId={cluster?.id || undefined}
                    value={equipmentType}
                    onChange={this.onEquipmentTypeFilterChange}
                    placeholder={t('Equipment Type')}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <OemEditor
                    nullable
                    clearable={true}
                    value={oem}
                    onChange={this.onOemFilterChange}
                    equipmentId={equipmentType?.id || undefined}
                    placeholder={t('OEM')}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <MachineModelEditor
                    cascade="true"
                    nullable
                    clearable={true}
                    oemId={oem?.id || undefined}
                    equipmentId={equipmentType?.id || undefined}
                    value={machineModel}
                    onChange={this.onChangeMachineModelFilter}
                    placeholder={t('Machine Model')}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>
              </div>
              <div id="assessment-forms-list-filters__admin-or-poc-container__right-container">
                {this.canCreate() && (
                  <Button className="inverted-color-btn" onClick={this.onNewItem}>
                    {t('Create New TNA')}&nbsp;
                  </Button>
                )}

                {isInRoleAdminOrPoC && (
                  <Checkbox
                    className={`${(query.orderBy || []).length !== 0 ? 'orderby' : ''} include-users`}
                    label={t('Include Inactive Users')}
                    onClick={(_e, { checked }) => {
                      this.handleInactiveUsers();
                    }}
                  />
                )}
              </div>
            </div>
          )}

          {isInRoleEmployee && !isInRoleAssessor && (
            <div id="assessment-forms-list-filters__employee-but-no-assessor-container">
              <Form.Field>
                <Input icon="search" placeholder={t('Search TNA')} onChange={this.handleFilterTna} key={'searchTna'} />
              </Form.Field>

              <Form.Field>
                <MRClusterEditor
                  clearable={true}
                  nullable
                  value={cluster}
                  onChange={this.onClusterEditorChange}
                  placeholder={t('Cluster')}
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </Form.Field>
              <Form.Field>
                <EquipmentTypeEditor
                  nullable
                  clearable={true}
                  clusterId={cluster?.id || undefined}
                  value={equipmentType}
                  onChange={this.onEquipmentTypeFilterChange}
                  placeholder={t('Equipment Type')}
                  readOnly={!cluster?.id}
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </Form.Field>

              <Form.Field>
                <OemEditor
                  nullable
                  clearable={true}
                  value={oem}
                  onChange={this.onOemFilterChange}
                  placeholder={t('OEM')}
                  readonly={!equipmentType?.id}
                  equipmentId={equipmentType?.id || undefined}
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </Form.Field>

              <Form.Field>
                <MachineModelEditor
                  cascade="true"
                  nullable
                  clearable={true}
                  oemId={oem?.id || undefined}
                  equipmentId={equipmentType?.id || undefined}
                  value={machineModel}
                  onChange={this.onChangeMachineModelFilter}
                  placeholder={t('Machine Model')}
                  readonly={!oem?.id}
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </Form.Field>

              <Form.Field>
                <Dropdown
                  className="custom-editor width-14em"
                  fluid
                  selection
                  options={this.tnaFormStatusOptions}
                  value={tnaStatus || null}
                  closeOnBlur={true}
                  closeOnEscape={true}
                  selectOnBlur={false}
                  selectOnNavigation={false}
                  placeholder={t('TNA Status')}
                  onChange={this.handleFilterTnaStatus}
                  clearable
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </Form.Field>
              <DatesRangeInput
                dontUseRedClearIconColor={true}
                iconPosition="right"
                fromValue={creationDateFrom}
                toValue={creationDateTo}
                onChange={this.handleFilterCreateAt}
                placeholder={t('Creation Date')}
                clearable={true}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
              <DatesRangeInput
                dontUseRedClearIconColor={true}
                iconPosition="right"
                fromValue={deadlineFrom}
                toValue={deadlineTo}
                onChange={this.handleFilterDeadline}
                placeholder={t('Deadline')}
                clearable={true}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
            </div>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!changeActiveTnaFormStore.state.isBusy && !this.state.showTranslateProcessModal && !onConfirmToggleDisable}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////

              model={tableModel}
              extraActions={this.getExtraActions()}
              onOrderByChanged={this.handleOrderBy}
              canCreateNew={false}
              onRefresh={this.load}
              canEdit={false}
              loading={changeActiveTnaFormStore.state.isBusy}
              canDelete={false}
              // isRowDisableLayout={item => !(item as any).isActive}
              isRowDisableLayout={item => !(item as any).user.enabled || !(item as any).isActive}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              createNewButtonTitle={t('Create New TNA')}
              areActionsDisabled={item => !this.canSeeOpenTna(item as TnaFormListDto) && !(isInRoleAdminOrPoC || isInRoleAssessor)}
              extraMenu={
                isInRoleAdminOrPoC && [
                  {
                    className: 'menu-item-download',
                    content: (
                      <Button
                        className="inverted-color-btn"
                        onClick={this.showTranslateProcessModal}
                        data-tooltip={t('Translate TNA Question Form')}
                      >
                        <FontAwesomeIcon className="solid" icon={faLanguage} size="lg" />
                      </Button>
                    )
                  }
                ]
              }
            />
          </Grid.Row>
        </Grid>

        {isInRoleAdminOrPoC && (
          <Modal
            closeOnEscape={true}
            onClose={() => this.hideTranslateProcessModal()}
            open={this.state.showTranslateProcessModal}
            size="mini"
            className="have-warnings-popup"
          >
            <Modal.Content className="have-warnings-popup__content">
              <p>{this.state.translateProcessModalText}</p>
            </Modal.Content>

            {this.canTranslate() && (
              <div className="have-warnings-popup__buttons-container">
                <Button className="have-warnings-popup__btn" content={t('No')} onClick={() => this.hideTranslateProcessModal()} />
                <Button
                  className="have-warnings-popup__btn have-warnings-popup__pending-btn"
                  content={t('Yes')}
                  onClick={() => this.initTranslatingTnaQuestionFormProcess()}
                />
              </div>
            )}

            {!this.canTranslate() && (
              <div className="have-warnings-popup__buttons-container">
                <Button className="have-warnings-popup__btn" content={t('Cancel')} onClick={() => this.hideTranslateProcessModal()} />
              </div>
            )}
          </Modal>
        )}

        {onConfirmToggleDisable && (
          <Modal
            closeOnEscape={true}
            onClose={this.closeConfirmationBox}
            open={onConfirmToggleDisable}
            size="tiny"
            className="change-status-popup"
          >
            <Modal.Content className="change-status-popup__content">
              <p>{t(`You are going to change status to ${selectedItem.isActive ? 'disabled' : 'active'}`)}</p>
              <p>{t('Are you sure you want to continue?')}</p>
            </Modal.Content>
            <div className="change-status-popup__buttons-container">
              <Button className="change-status-popup__btn" content={t('No')} onClick={this.closeConfirmationBox} />
              <Button
                className="change-status-popup__btn change-status-popup__pending-btn"
                content={t('Yes')}
                onClick={this.onToggleAvailabilityItem}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default withTranslation()(TnaFormsList);
