import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Icon, Message, Grid } from 'semantic-ui-react';
import { EventGroupRelationshipDto, EventGroupRelationshipsStore } from 'stores/configuration/events-n-requests/event-group-rel-store';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';

import NewEventGroupRelationshipView from './new-event-relation';
import ChangeEventGroupRelationshipView from './edit-event-relation';
import { EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';

export interface EventGroupRelationshipsListProps extends WithTranslation, RouteComponentProps {
  eventTypeGroups: EventGroupRelationshipsStore;
}

export interface EventGroupRelationshipsListState {
  query: Query;
  newEventGroupRelationshipShown: boolean;
  changeEventGroupRelationshipShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['eventTypeGroups', EventGroupRelationshipsStore])
class EventGroupRelationshipsListPage extends React.Component<EventGroupRelationshipsListProps, EventGroupRelationshipsListState> {
  handleOnEnterKeydown = () => {
    this.onEditItem();
  };

  handleOnRowDoubleClick = () => {
    this.onEditItem();
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newEventGroupRelationshipShown: false,
      changeEventGroupRelationshipShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.eventTypeGroups.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: EventGroupRelationshipDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.eventTypeGroups.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: EventGroupRelationshipDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.eventTypeGroups.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newEventGroupRelationshipShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newEventGroupRelationshipShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeEventGroupRelationshipShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeEventGroupRelationshipShown: false });
    if (isSuccess) this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Event Group Name'),
          //newClassFromComponent: data => 'table__more-text-long',
          tooltipRenderer: false,
          renderer: data => data.eventGroupName,
          editor: (data, onChange) => (
            <Input
              value={data.eventGroupName}
              fluid
              onChange={(e, { value }) => {
                data.eventGroupName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by name')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<EventGroupRelationshipDto>('eventGroupName'), `contains(${nameof<EventTypeDto>('name')}, '${value}')`)
              }
              onClear={() => onClear(nameof<EventGroupRelationshipDto>('eventGroupName'))}
              active={activeFilters.includes(nameof<EventGroupRelationshipDto>('eventGroupName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'eventGroup/Name',
            useProfile: false,
            active: 'Ascending'
          }
        },
        {
          title: t('Lead Type Hours Code'),
          tooltipRenderer: false,
          renderer: data => data.leadTypeHoursCodeName || '-'
        },
        {
          title: t('Apprentice Type Hours Code'),
          tooltipRenderer: false,
          renderer: data => data.apprenticeTypeHoursCodeName || '-'
        }
      ],
      data: this.props.eventTypeGroups.state
    } as TableModel<EventGroupRelationshipDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.eventTypeGroups.state.result && !this.props.eventTypeGroups.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.eventTypeGroups.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newEventGroupRelationshipShown && !this.state.changeEventGroupRelationshipShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add Event Group Relation')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newEventGroupRelationshipShown && <NewEventGroupRelationshipView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeEventGroupRelationshipShown && (
            <ChangeEventGroupRelationshipView onClose={this.onEditItemClosed} currentEventGroupRelationship={this.state.selectedItem} />
          ))}
      </>
    );
  }
}
export default withTranslation()(EventGroupRelationshipsListPage);
