import React, { Component, RefObject } from 'react';
import './scheduler-instructor-style.less';

import BryntumScheduler from 'widgets/scheduler/BryntumScheduler';
import { WidgetHelper } from '@planit/bryntum-scheduler';
import Drag from '../util/Drag';
import { UserStore, UserDto } from 'stores/users/users-store';
import { EventsSchedulerStore, EventDto, EventInstructorDto, ChangeEventsStore } from 'stores/events/events-store';
import { connect } from 'redux-scaffolding-ts';
import schedulerConfig from './scheduler-instructors-config';
import SchedulerRequestList from '../shared-scheduler-components/request-component/scheduler-request-list';
import { InvisibleFiltersValue } from '../shared-scheduler-components/scheduler-header/filters/invisible-filters';
import { AlwaysVisibleFiltersValue } from '../shared-scheduler-components/scheduler-header/filters/always-visible-filters';
import { SchedulerEventFilterService } from '../shared-scheduler-components/scheduler-header/filters/scheduler-event-filter-service';
import { resolve } from 'inversify.config';
import SchedulerHeaderComponent, { FilterValues } from '../shared-scheduler-components/scheduler-header/scheduler-header.component';
import { SchedulerPeriodService, DatePeriod } from '../shared-scheduler-components/events/scheduler-period-service';
import InstructorRowFilter, { InstructorRowFilterValues } from './instructor-row-filter/instructor-row-filter';
import { InstructorRowFilterService } from './instructor-row-filter/instructor-row-filter-service';
import { DropDownEventTypesStore, EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import { DropDownMachineModelsStore } from 'stores/configuration/machinery/machine-models-store';
import { DropDownNMFunctionalSubareasStore } from 'stores/configuration/events-n-requests/non-machine-related/functional-subareas-store';
import { DropDownProfessionsStore } from 'stores/configuration/profiles/profession-roles-store';
import { DropDownTrainingLevelsStore } from 'stores/configuration/events-n-requests/training-levels-store';
import ReactDOM from 'react-dom';
import { InstructorExpertiseStore } from 'stores/skills/instructor-expertise-store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DropDownEquipmentTypesStore } from 'stores/configuration/machinery/equipment-types-store';
import EventForm from '../../events/event-form/event-form';
import { PublicHolidayConfigurationStore, PublicHolidayDto } from 'stores/public-holidays/public-holidays-store';
import { WorkingDaysStore, WorkingDayDto, CreateWorkingDaysDto } from 'stores/working-days/working-days-store';
import {
  //getAllLocationWorkingDays,
  getAllLocationPublicHolidays,
  //getResourceTR,
  manageEventDates,
  getInstructorDefaultRequestFilterValue,
  buildRequestFilter,
  manageInstructorDates
} from '../shared-scheduler-components/scheduler-methods';
import { RequestDto, ChangeRequestStore, RequestsStore } from 'stores/requests/requests-store';
import { OrderDefinition, Query, QueryResult } from 'stores/dataStore';
import { nameof } from 'utils/object';
import { RequestPopupFilterValues } from '../shared-scheduler-components/request-component/request-popup-filters';
import { Roles, IdentityService } from 'services/identity-service';
import { ToastComponent } from '../util/toast-component';
import { DateTimeService } from 'services/datetime-service';
import { buildEventViewModel } from 'stores/events/event-form-store';
import { isCurrentUserEventOwner } from 'utils/event-utils';
import { isExtendedWorkflow } from 'utils/event-type-utils';
import { EventFormActions } from 'site/pages/events/event-form/buttons/action-buttons';
import { Dimmer, Loader } from 'semantic-ui-react';
import { PublicHolidayConf } from '../shared-scheduler-components/scheduler-poc-config/config-tabs/public-holidays-tab';
import { SchedulerResourceFilterService } from '../shared-scheduler-components/scheduler-header/filters/scheduler-resource-filter-service';
import { PlanitPlannerTypes } from 'stores/configuration/profiles/pillars-store';
import { RequestFormActions } from 'site/pages/requests/request-form/request-form';
import { SelectionItem } from 'widgets/form/selectionInput';

interface EventPerInstructor {
  event: EventDto;
  instructor: EventInstructorDto;
}

export interface SchedulerInstructorPageProps extends WithTranslation {
  users?: UserStore;
  events?: EventsSchedulerStore;
  eventTypesStore?: DropDownEventTypesStore;
  machineModelsStore?: DropDownMachineModelsStore;
  functionalSubareasStore?: DropDownNMFunctionalSubareasStore;
  professionsStore?: DropDownProfessionsStore;
  traininglevelsStore?: DropDownTrainingLevelsStore;
  instructorExpertiseStore?: InstructorExpertiseStore;
  equipmentTypeStore?: DropDownEquipmentTypesStore;
  publicHolidayConfiguration?: PublicHolidayConfigurationStore;
  workingDays?: WorkingDaysStore;
  changeRequestsStore?: ChangeRequestStore;
  changeEventsStore?: ChangeEventsStore;
  requestsStore?: RequestsStore;
}
export interface SchedulerInstructorPageState {
  barMargin: number;
  selectedEvent: EventDto;
  eventsVersion: number;
  resourcesVersion: number;
  events: any;
  resources: any;
  filterValues: FilterValues;
  showRequestColumn: boolean;
  resourceTimeRanges: any;
  requestsRef: RefObject<any>;
  filtersDisabled: boolean;
  showAdditionalFilters: boolean;
  requests: RequestDto[];
  currentRequestsQuery: Query;
  hasMoreRequest: boolean;
  eventPreview: EventDto;
  mainRequest: RequestDto;
  mergeRequestIds: string[];
  maskRequestTable: any;
  locations?: any;
  eventEdition: boolean;
  loading: boolean;
  instructorRowFilterValues: InstructorRowFilterValues;
  instructors: QueryResult<UserDto>;
}

@connect(
  ['users', UserStore],
  ['events', EventsSchedulerStore],
  ['eventTypesStore', DropDownEventTypesStore],
  ['machineModelsStore', DropDownMachineModelsStore],
  ['functionalSubareasStore', DropDownNMFunctionalSubareasStore],
  ['professionsStore', DropDownProfessionsStore],
  ['traininglevelsStore', DropDownTrainingLevelsStore],
  ['instructorExpertiseStore', InstructorExpertiseStore],
  ['equipmentTypeStore', DropDownEquipmentTypesStore],
  ['publicHolidayConfiguration', PublicHolidayConfigurationStore],
  ['workingDays', WorkingDaysStore],
  ['changeRequestsStore', ChangeRequestStore],
  ['changeEventsStore', ChangeEventsStore],
  ['requestsStore', RequestsStore]
)
class SchedulerInstructorPage extends Component<SchedulerInstructorPageProps, SchedulerInstructorPageState> {
  @resolve(SchedulerEventFilterService)
  private schedulerEventFilterService: SchedulerEventFilterService;

  @resolve(SchedulerResourceFilterService)
  private schedulerResourceFilterService: SchedulerResourceFilterService;

  @resolve(IdentityService)
  private identityService: IdentityService;

  @resolve(SchedulerPeriodService)
  private schedulerPeriodService: SchedulerPeriodService;

  @resolve(InstructorRowFilterService)
  private instructorRowFilterService: InstructorRowFilterService;

  _isMounted: boolean = false;

  dragHelper = new Drag({});

  activeRole: string;

  constructor(props) {
    super(props);
    this.activeRole = this.identityService.activeRole;

    this.state = {
      maskRequestTable: null,
      barMargin: 5,
      eventsVersion: 0,
      resourcesVersion: 0,
      events: [],
      resources: [],
      showAdditionalFilters: true,
      showRequestColumn: true,
      requests: [],
      requestsRef: null,
      filterValues: this.schedulerEventFilterService.getDefaultFilterValues(),
      resourceTimeRanges: null,
      filtersDisabled: true,
      selectedEvent: null,
      currentRequestsQuery: null,
      hasMoreRequest: true,
      eventPreview: null,
      mainRequest: null,
      mergeRequestIds: [],
      instructorRowFilterValues: this.instructorRowFilterService.getDefaultFilterValues(),
      eventEdition: null,
      instructors: null,
      loading: false
    };
  }

  myUser: UserDto = null;
  pageSize = 15;

  requestsListRef = React.createRef<HTMLDivElement>();

  refs: { scheduler: BryntumScheduler };

  public get schedulerDatePeriod() {
    return this.schedulerPeriodService.toSchedulerPeriod(this.state.filterValues.alwaysVisibleFilterValues.period);
  }

  handleSelectionChange = selected => this._isMounted && this.setState({ selectedEvent: (selected.length && selected[0].name) || '' });

  initScheduler = async () => {
    const { filterValues, instructorRowFilterValues } = this.state;
    this._isMounted && this.setState({ loading: true });
    const maskScheduler: any = WidgetHelper.mask(this.refs.scheduler?.schedulerEngine.element, 'FETCHING DATA');
    const orderBy: OrderDefinition[] = [
      { direction: 'Ascending', field: 'enabled', useProfile: false },
      { direction: 'Ascending', field: 'surname', useProfile: false }
    ];

    let additionalEventsFilters = [];
    let additionalInstructorsFilters = [];
    if (this.hasActiveManageByPlannerFilter(true)) {
      additionalEventsFilters = this.getAdditionalEventsFilters(filterValues.alwaysVisibleFilterValues);
      additionalInstructorsFilters = this.getAdditionalInstructorsFilters(filterValues.alwaysVisibleFilterValues);
    }

    const queryInstructors = { searchQuery: '', orderBy, skip: 0, take: 1000, filter: additionalInstructorsFilters };
    const allQuery = { searchQuery: '', orderBy: [], skip: 0, take: 1000 };

    const newEventsPromise = this.getEvents(this.schedulerDatePeriod, additionalEventsFilters);
    const instructorsPromise = this.props.users.getAllUsersWithRoleAsync(queryInstructors, 'Instructor');
    const publicHolidaysPromise = this.props.publicHolidayConfiguration.getAllAsync(allQuery);
    const workingDaysPromise = this.props.workingDays.getAllAsync(allQuery);

    let promises = [newEventsPromise, instructorsPromise, publicHolidaysPromise, workingDaysPromise];

    let newEvents: QueryResult<EventDto> = { count: 0, items: [] };
    let instructors: QueryResult<UserDto> = { count: 0, items: [] };
    let publicHolidays: QueryResult<PublicHolidayDto> = { count: 0, items: [] };
    let workingDays: QueryResult<WorkingDayDto> = { count: 0, items: [] };

    let promiseResp = await Promise.allSettled(promises);

    if (promiseResp.every(x => x.status === 'fulfilled' && x.value)) {
      let results = promiseResp.map(x => (x as PromiseFulfilledResult<QueryResult<any>>).value);
      newEvents = results[0];
      instructors = results[1];
      publicHolidays = results[2];
      workingDays = results[3];
    }

    this.myUser = instructors?.items?.find(({ id }) => id === this.identityService.userId);
    const resourceTimeRanges = this.mapToResourceTimeRanges(instructors.items, publicHolidays.items, workingDays.items);
    this.refs.scheduler?.setResourceTimeRanges(resourceTimeRanges);
    const eventsPerInstructor = this.mapToEventInstructor(newEvents.items);
    const events = this.mapToBryntumEvents(eventsPerInstructor);
    const resources = this.mapToBryntumResources(instructors.items);

    const data = { events, resources, eventsVersion: 1, resourcesVersion: 1, instructors, resourceTimeRanges };
    this._isMounted && this.setState({ ...data, loading: false, filtersDisabled: false });
    maskScheduler?.close();

    this.refs.scheduler &&
      this.filterInstructors(instructorRowFilterValues, filterValues.alwaysVisibleFilterValues, filterValues.invisibleFilterValues);
  };

  componentDidMount() {
    this._isMounted = true;
    this.refs.scheduler.rowHeightHandler(64);
    this.initScheduler();
    !this.identityService.isInRole(Roles.Instructor) && this.initRequest();
    ReactDOM.render(
      <InstructorRowFilter
        eventTypesStore={this.props.eventTypesStore}
        functionalSubareasStore={this.props.functionalSubareasStore}
        machineModelsStore={this.props.machineModelsStore}
        professionsStore={this.props.professionsStore}
        traininglevelsStore={this.props.traininglevelsStore}
        equipmentTypeStore={this.props.equipmentTypeStore}
        placeholder={this.props.t("Instructor's Expertise")}
        className="instructor-row-filter"
        onChange={value => this.handleOnInstructorFilterValueChange(value)}
      />,
      document.getElementById('InstructorsFilters')
    );
  }

  initRequest = () => {
    const filter = getInstructorDefaultRequestFilterValue(this.activeRole, this.identityService.getUserInfo());
    const orderBy: OrderDefinition[] = [{ direction: 'Descending', field: nameof<RequestDto>('createdOn'), useProfile: false }];
    const query: Query = { searchQuery: '', orderBy, filter, skip: 0, take: this.pageSize };

    this.loadRequests(query, true);
  };

  loadRequests = (query: Query, replaceCurrentRequest: boolean) => {
    this._isMounted && this.setState({ maskRequestTable: WidgetHelper.mask(this.requestsListRef.current, 'FETCHING DATA') });

    return this.props.requestsStore.getAllRequestListAsync(query).then(response => {
      const requests = [...this.state.requests];
      response.items =
        this.identityService.isInRole(Roles.Planner) || this.identityService.isInRole(Roles.PlannerMTC)
          ? response.items.filter(request => request.eventType.instructor === 'Yes' || request.eventType.instructor === 'EventOnly')
          : response.items;

      const totalRequests = replaceCurrentRequest ? response.items : requests.concat(response.items);

      this._isMounted &&
        this.setState({ currentRequestsQuery: query, requests: totalRequests, hasMoreRequest: totalRequests.length < response.count });

      this.refs.scheduler &&
        (this.identityService.isInRole(Roles.Planner) || this.identityService.isInRole(Roles.PlannerMTC)) &&
        this.dragHelper.setConfig({
          dropTargetSelector: '.sch-instructors__container',
          targetSelector: '.request-table-list-item',
          onRequestEventMerge: this.onRequestEventMerge,
          onRequestsMerge: this.onRequestsMerge,
          onNewEventFromRequestDragOnInstructor: this.onNewEventFromRequestDragOnInstructor,
          constrain: false,
          data: totalRequests,
          schedule: this.refs.scheduler.schedulerEngine
        });
      !this.state.maskRequestTable.isDestroyed && this.state.maskRequestTable.close();

      return totalRequests;
    });
  };

  handleOnInstructorFilterValueChange = (instructorRowFilterValues: InstructorRowFilterValues): void => {
    const { alwaysVisibleFilterValues, invisibleFilterValues } = this.state.filterValues;
    this._isMounted && this.setState({ instructorRowFilterValues });
    this.filterInstructors(instructorRowFilterValues, alwaysVisibleFilterValues, invisibleFilterValues);
  };

  onRequestEventMerge = async (request: RequestDto, event: EventDto) => {
    try {
      this.setState({ loading: true });
      const data: any = await this.props.changeEventsStore.mergeRequestToEvent(request.id, event.id);
      this.reloadRequests();
      if (data.isSuccess) {
        ToastComponent({ text: 'Operation completed', type: 'success-toast' });
        this.reloadEvents(event.id);
      } else {
        data.messages.forEach(msg => ToastComponent({ text: msg.body, type: 'error-toast' }));
        this.reloadEvents();
      }
    } catch (error) {
      console.error({ error });
      (error?.response?.data?.messages || []).forEach(({ body }) => ToastComponent({ text: body, type: 'error-toast' }));
    } finally {
      this.setState({ loading: false });
    }
  };

  onRequestsMerge = async (sourceRequest: RequestDto, targetRequest: RequestDto) => {
    if (!sourceRequest?.id || !targetRequest?.id) return;
    const { changeRequestsStore, t } = this.props;
    if ((targetRequest?.status || '').toString() === 'InProgress') {
      ToastComponent({ text: t('Cannot merge with In Progress request'), type: 'error-toast' });
      return;
    }

    const mergeRequestsIds = [sourceRequest.id, targetRequest.id];
    this.setState({ loading: true });
    try {
      const response = await changeRequestsStore.mergeRequests(sourceRequest.id, mergeRequestsIds);
      if (!response?.isSuccess) response.messages.forEach(msg => ToastComponent({ text: msg.body, type: 'error-toast' }));
      else {
        ToastComponent({ text: 'Valid operation', type: 'success-toast' });
        const eventPreview = { ...response.item, requests: [sourceRequest, targetRequest], parentRequestId: sourceRequest.id };
        this._isMounted && this.setState({ mainRequest: sourceRequest, eventPreview, mergeRequestIds: mergeRequestsIds });
      }
    } catch (error) {
      console.error({ error });
      (error?.response?.data?.messages || []).forEach(({ body }) => ToastComponent({ text: body, type: 'error-toast' }));
    } finally {
      this.setState({ loading: false });
    }
  };

  onNewEventFromRequestDragOnInstructor = async (request: RequestDto, instructor: UserDto, date: string) => {
    try {
      const { t } = this.props;

      if (!isExtendedWorkflow(request.eventType.eventTypeCategory)) {
        if (
          this.isPlannerTFT() &&
          !(instructor.pillar && (instructor.pillar.managedBy === PlanitPlannerTypes.plannerTFT || !instructor.pillar.managedBy))
        ) {
          ToastComponent({ text: t('Planner TFT cannot create a new event for this MTC instructor.'), type: 'error-toast' });
          return;
        }

        if (
          this.isPlannerMTC() &&
          !(instructor.pillar && (instructor.pillar.managedBy === PlanitPlannerTypes.plannerMTC || !instructor.pillar.managedBy))
        ) {
          ToastComponent({ text: t('Planner MTC cannot create a new event for this TFT instructor.'), type: 'error-toast' });
          return;
        }
      }

      this.setState({ loading: true });
      const { item } = await this.props.changeRequestsStore.mergeRequests(request.id, [request.id]);
      this.setState({ loading: false });
      ToastComponent({ text: 'Valid operation', type: 'success-toast' });
      const from = date;
      const to = DateTimeService.toMoment(date)
        .add(item.plannedDuration > 0 ? +item.plannedDuration - 1 : 0, 'days')
        .toISOString();
      const instructorRole = instructor.roles.find(z => z.role.name === 'Instructor');
      item.instructors = [
        {
          ...item.instructors[0],
          instructorId: instructor.id,
          instructor: { ...instructor, location: instructorRole.location },
          location: instructorRole.location,
          locationId: instructorRole.location.id,
          period: { from, to },
          role: null,
          workingDays: null,
          workingHours: null
        }
      ];

      item.startDate = from;
      item.endDate = to;
      item.requests = [request];
      item.parentRequestId = request.id;

      this._isMounted && this.setState({ mainRequest: request, eventPreview: item });
    } catch (error) {
      console.error({ error });
      (error?.response?.data?.messages || []).forEach(({ body }) => ToastComponent({ text: body, type: 'error-toast' }));
    }
  };

  getEvents = (eventPeriods: DatePeriod, additionalFilters = []) => {
    const filters = [
      { eventInstructors: { any: [] } },
      { startDate: { le: new Date(eventPeriods.to) } },
      { endDate: { ge: new Date(eventPeriods.from) } }
    ];

    const queryEvents = {
      searchQuery: '',
      filter: [...filters, ...additionalFilters],
      orderBy: [],
      skip: 0,
      take: 100000,
      select:
        'Id, title, EventTypeId, EventTypeItem, EventStatus, pausePeriods, plannedDuration, startDate, endDate, eventWarnings,eventUpdatedFlag, FriendlyEventId, EventInstructors, EventTrainingDetails/NumStudentsAssigned, EventTrainingDetails/DeliveryMethodId, EventDetails/ProfessionId, EventDetails/PriorityId, EventDetails/LocationId,Requests/RequestingLocationId, SupportDetails/SupportPositions'
    };
    var events = this.props.events.getAllFromSchedulerAsync(queryEvents);

    return events;
  };

  setEvents = events => {
    this._isMounted &&
      this.setState(
        ({ eventsVersion }) => ({ eventsVersion: eventsVersion + 1, events }),
        () => this.filterEvents(this.state.filterValues.alwaysVisibleFilterValues, this.state.filterValues.invisibleFilterValues)
      );
  };

  getEventsPerInstructor = (period: DatePeriod, additionalFilters): Promise<EventPerInstructor[]> => {
    var result = this.getEvents(period, additionalFilters).then(events => {
      var instructors = this.mapToEventInstructor(events.items);
      var map = this.mapToBryntumEvents(instructors);

      return map;
    });

    return result;
  };

  mapToEventInstructor = (events: EventDto[]) => {
    const eventsPerInstructor: EventPerInstructor[] = [];
    events.forEach(event => eventsPerInstructor.push(...event.instructors.map(instructor => ({ event, instructor }))));
    return eventsPerInstructor;
  };

  mapToBryntumResources = (items: UserDto[]) => (items || []).map(instructor => ({ id: instructor.id, instructor }));

  mapToResourceTimeRanges = (instructors: UserDto[], publicHolidays: PublicHolidayDto[], workingDaysArr: WorkingDayDto[]) => {
    let resourceTimeRanges = [];
    (instructors || []).forEach(({ id, roles }) => {
      const instructor = (roles || []).filter(x => x.role.name === 'Instructor').firstOrDefault();
      let locationPublicHolidays = publicHolidays.find(x => x.locationId === instructor.location.id);
      if (locationPublicHolidays) {
        resourceTimeRanges.push(...getAllLocationPublicHolidays(locationPublicHolidays, id));
      }
    });
    return resourceTimeRanges;
  };

  mapToBryntumEvents = (eventsPerInstructor: EventPerInstructor[]) =>
    (eventsPerInstructor || []).map(({ event: e, instructor }) => {
      const status = e.status.toString();
      let draggable = false;
      if (isCurrentUserEventOwner(e) && (status === 'Draft' || status === 'Planned' || status === 'InProgress')) draggable = true;
      const event: any = { ...e };
      if (instructor.travelDays?.departure?.from || instructor.travelDays?.departure?.to)
        event.travelStart = instructor.travelDays.departure;
      if (instructor.travelDays?.arrival?.from || instructor.travelDays?.arrival?.to) event.travelEnd = instructor.travelDays.arrival;
      const startDate = DateTimeService.toSchedulerFromString(instructor.period.from);
      const endDate = DateTimeService.toSchedulerToString(instructor.period.to);
      const resourceId = instructor.instructorId;

      return { event, instructor, startDate, endDate, resourceId, draggable };
    });

  handleRowHeight = value => {
    this.refs.scheduler && this.refs.scheduler.rowHeightHandler(value);
  };

  handleViewPresetChange = presetValue => {
    this.setState(({ eventsVersion }) => ({ events: [], eventsVersion: eventsVersion + 1 }));
    if (this.refs.scheduler?.schedulerEngine?.resourceTimeRangeStore?.removeAll)
      this.refs.scheduler.schedulerEngine.resourceTimeRangeStore.removeAll();
    this.refs.scheduler && this.refs.scheduler.viewPresetChangeHandler(presetValue);
    this.refs.scheduler?.setResourceTimeRanges(this.state.resourceTimeRanges);
    this.reloadEvents();
  };

  onScrollEndHandler = ({ x, maxX }) => {
    const period = { ...this.state.filterValues.alwaysVisibleFilterValues.period };
    const hasMak = this.refs.scheduler?.schedulerEngine?.element?.hasOwnProperty('mask') || false;
    if (hasMak) {
      const entries = Object.entries(this.refs.scheduler?.schedulerEngine?.element);
      const plain = Object.fromEntries(entries.map(([k, v]) => [k, v]));
      const maskVal: any = plain['mask'];
      if (maskVal && maskVal._text) {
        return;
      }
    }
    const distance = 50;
    if (maxX - distance < x) {
      if (this.refs.scheduler?.schedulerEngine?.resourceTimeRangeStore?.removeAll)
        this.refs.scheduler.schedulerEngine.resourceTimeRangeStore.removeAll();
      period.to = DateTimeService.toMoment(period.to)
        .add(1, 'months')
        .toISOString();
      period.from = DateTimeService.toMoment(period.from)
        .add(1, 'months')
        .toISOString();

      this._isMounted &&
        this.setState(
          ({ filterValues: { alwaysVisibleFilterValues, ...rest }, eventsVersion }) => ({
            eventsVersion: eventsVersion + 1,
            events: [],
            filterValues: { ...rest, alwaysVisibleFilterValues: { ...alwaysVisibleFilterValues, period } }
          }),
          this.reloadEvents
        );
    } else if (x < distance) {
      if (this.refs.scheduler?.schedulerEngine?.resourceTimeRangeStore?.removeAll)
        this.refs.scheduler.schedulerEngine.resourceTimeRangeStore.removeAll();
      period.to = DateTimeService.toMoment(period.to)
        .subtract(1, 'months')
        .toISOString();
      period.from = DateTimeService.toMoment(period.from)
        .subtract(1, 'months')
        .toISOString();

      this._isMounted &&
        this.setState(
          ({ filterValues: { alwaysVisibleFilterValues, ...rest }, eventsVersion }) => ({
            eventsVersion: eventsVersion + 1,
            events: [],
            filterValues: { ...rest, alwaysVisibleFilterValues: { ...alwaysVisibleFilterValues, period } }
          }),
          this.reloadEvents
        );
    }
  };

  onToggleRequestHandler = () => {
    if (this.state.showRequestColumn && !this.state.maskRequestTable?.isDestroyed) this.state.maskRequestTable?.close();

    this._isMounted && this.setState(({ showRequestColumn }) => ({ showRequestColumn: !showRequestColumn }));
  };

  private handleOnFilterValueChange = async (filterValues: FilterValues, triggeredByInstructorRowFilter?: boolean) => {
    const schedulerPeriod = this.schedulerPeriodService?.toSchedulerPeriod(filterValues.alwaysVisibleFilterValues.period);
    const previousDatePeriod = { ...this.schedulerDatePeriod };
    const samePeriod = this.schedulerPeriodService?.areEquals(previousDatePeriod, schedulerPeriod);
    if (!samePeriod) {
      this.refs.scheduler?.schedulerEngine?.resourceTimeRangeStore?.removeAll?.();
      this._isMounted &&
        this.setState(({ eventsVersion }) => ({ events: [], eventsVersion: eventsVersion + 1, filterValues }), this.reloadEvents);
    } else this.setState({ filterValues });

    if (!samePeriod) return;

    if (this.hasActiveManageByPlannerFilter(triggeredByInstructorRowFilter)) {
      this._isMounted &&
        this.setState(({ eventsVersion }) => ({ events: [], eventsVersion: eventsVersion + 1, filterValues }), this.reloadEvents);

      await this.reloadEventsAndInstructors(filterValues.alwaysVisibleFilterValues);
    }

    this.filterEvents(filterValues.alwaysVisibleFilterValues, filterValues.invisibleFilterValues);
    this.filterInstructors(
      this.state.instructorRowFilterValues,
      filterValues.alwaysVisibleFilterValues,
      filterValues.invisibleFilterValues
    );
  };

  private filterEvents = (alwaysVisibleFilterValues: AlwaysVisibleFiltersValue, invisibleFilterValues: InvisibleFiltersValue) => {
    this.refs.scheduler?.eventStore?.filter?.({
      filters: eventRecord =>
        this.schedulerEventFilterService.fullfillsAllFilters(eventRecord, alwaysVisibleFilterValues, invisibleFilterValues),
      replace: true
    });
  };

  private isPlannerTFT = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlannerTFT(userinfo);
  };

  private isPlannerMTC = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlannerMTC(userinfo);
  };

  private hasActiveManageByPlannerFilter = (triggeredByInstructorRowFilter: boolean) =>
    (this.identityService.isInRole(Roles.Planner) || this.identityService.isInRole(Roles.PlannerMTC)) && triggeredByInstructorRowFilter;

  filterInstructors = (
    rowFilters: InstructorRowFilterValues,
    visibleFilters: AlwaysVisibleFiltersValue,
    invisibleFilters: InvisibleFiltersValue
  ): void => {
    const { events } = this.state;

    const instructorRowFilters = this.instructorRowFilterService.hasAnyActiveInstructorRowFilterValue(rowFilters);
    const hasAnyActiveFilter = this.instructorRowFilterService.hasAnyActiveFilter(rowFilters, visibleFilters, invisibleFilters);
    if (!hasAnyActiveFilter) {
      this.refs.scheduler?.resourceStore.filter({
        filters: resourceRecord => {
          resourceRecord.showStar = false;
          return true;
        },
        replace: true
      });
      return;
    }

    if (!instructorRowFilters && visibleFilters.filterByMe) {
      this.refs.scheduler?.resourceStore.filter({
        filters: ({ instructor, id, ...resourceRecord }) => {
          resourceRecord.showStar = false;
          const target = id === this.identityService.userId;
          const roleITarget =
            (instructor?.enabled || (!instructor?.enabled && events.map(x => x.instructor?.instructorId).includes(instructor?.id))) &&
            ((visibleFilters.roleInstructor === 'Instructor' &&
              ['Power Instructor', 'Instructor'].includes(instructor?.instructorRole?.name)) ||
              (visibleFilters.roleInstructor === 'Internal Trainer' &&
                instructor?.instructorRole?.name === visibleFilters.roleInstructor) ||
              (visibleFilters.pillarManagedBy && instructor?.pillar?.managedBy === PlanitPlannerTypes[visibleFilters.pillarManagedBy]));
          return visibleFilters.roleInstructor ? target && roleITarget : target;
        },
        replace: true
      });
      return;
    }

    if (!instructorRowFilters && visibleFilters.filterByMyPillar) {
      this.refs.scheduler?.resourceStore.filter({
        filters: ({ instructor, ...resourceRecord }) => {
          resourceRecord.showStar = false;
          const target = instructor?.pillarId === this.myUser?.pillarId;
          const roleITarget =
            (instructor?.enabled || (!instructor?.enabled && events.map(x => x.instructor?.instructorId).includes(instructor?.id))) &&
            ((visibleFilters.roleInstructor === 'Instructor' &&
              ['Power Instructor', 'Instructor'].includes(instructor?.instructorRole?.name)) ||
              (visibleFilters.roleInstructor === 'Internal Trainer' &&
                instructor?.instructorRole?.name === visibleFilters.roleInstructor) ||
              (visibleFilters.pillarManagedBy && instructor?.pillar?.managedBy === PlanitPlannerTypes[visibleFilters.pillarManagedBy]));
          return visibleFilters.roleInstructor ? target && roleITarget : target;
        },
        replace: true
      });
      return;
    }

    if (!instructorRowFilters) {
      this.refs.scheduler.resourceStore.filter({
        filters: resource => this.schedulerResourceFilterService.fullfillsAllFilters(resource, visibleFilters, invisibleFilters),
        replace: true
      });
      return;
    }

    this.instructorRowFilterService
      .getAllInstructorsThatFullfills(this.props.instructorExpertiseStore, rowFilters, visibleFilters)
      .then(expertisesByInstructor => {
        this.refs.scheduler?.resourceStore.filter({
          filters: resourceRecord => {
            const instructorExpertises = expertisesByInstructor[resourceRecord.id];

            if (!instructorExpertises) return false;
            resourceRecord.showStar = this.instructorRowFilterService.fullfillsAllFilters(instructorExpertises, rowFilters);
            if (visibleFilters.roleInstructor)
              resourceRecord.showStar =
                resourceRecord.showStar &&
                this.schedulerResourceFilterService.fullfillsAllFilters(resourceRecord, visibleFilters, invisibleFilters);

            return true;
          },
          replace: true
        });
      });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    this.dragHelper.destroy();
  };

  reloadEvents = async (newEventId?: string) => {
    this._isMounted && this.setState({ loading: true });
    const maskScheduler: any = WidgetHelper.mask(this.refs.scheduler?.schedulerEngine?.element, 'FETCHING DATA');

    const events = await this.getEventsPerInstructor(this.schedulerDatePeriod, []);

    if (newEventId) {
      let selectedEvent = (events || []).find(({ event }) => event?.id === newEventId)?.event;
      if (!selectedEvent) selectedEvent = await this.props.events.getById(newEventId);
      this._isMounted && this.setState({ selectedEvent });
    }

    this.setEvents(events);
    maskScheduler.close();
    this._isMounted && this.setState({ loading: false });

    await this.reloadInstructors();
  };

  reloadInstructors = async () => {
    const { filterValues } = this.state;

    this.filterInstructors(
      this.state.instructorRowFilterValues,
      filterValues.alwaysVisibleFilterValues,
      filterValues.invisibleFilterValues
    );
  };

  reloadEventsAndInstructors = async (visibleFilters: AlwaysVisibleFiltersValue) => {
    this._isMounted && this.setState({ loading: true });
    const maskScheduler: any = WidgetHelper.mask(this.refs.scheduler?.schedulerEngine?.element, 'FETCHING DATA');

    const additionalEventsFilters = this.getAdditionalEventsFilters(visibleFilters);
    const additionalInstructorsFilters = this.getAdditionalInstructorsFilters(visibleFilters);
    const orderBy: OrderDefinition[] = [
      { direction: 'Ascending', field: 'enabled', useProfile: false },
      { direction: 'Ascending', field: 'surname', useProfile: false }
    ];
    const queryInstructors = { searchQuery: '', orderBy, skip: 0, take: 130, filter: additionalInstructorsFilters };
    const allQuery = { searchQuery: '', orderBy: [], skip: 0, take: 1000 };

    const eventsPromise = this.getEventsPerInstructor(this.schedulerDatePeriod, additionalEventsFilters);
    const instructorsPromise = this.props.users.getAllUsersWithRoleAsync(queryInstructors, 'Instructor');
    const publicHolidaysPromise = this.props.publicHolidayConfiguration.getAllAsync(allQuery);
    const workingDaysPromise = this.props.workingDays.getAllAsync(allQuery);

    let promises = [eventsPromise, instructorsPromise, publicHolidaysPromise, workingDaysPromise];

    let events: QueryResult<EventDto> = { count: 0, items: [] };
    let instructors: QueryResult<UserDto> = { count: 0, items: [] };
    let publicHolidays: QueryResult<PublicHolidayDto> = { count: 0, items: [] };
    let workingDays: QueryResult<WorkingDayDto> = { count: 0, items: [] };
    let promiseResp = await Promise.allSettled(promises);

    if (promiseResp.every(x => x.status === 'fulfilled' && x.value)) {
      let results = promiseResp.map(x => (x as PromiseFulfilledResult<QueryResult<any>>).value);
      events = results[0];
      instructors = results[1];
      publicHolidays = results[2];
      workingDays = results[3];
    }

    const resourceTimeRanges = this.mapToResourceTimeRanges(instructors.items, publicHolidays.items, workingDays.items);
    this.refs.scheduler?.setResourceTimeRanges(resourceTimeRanges);

    const resources = this.mapToBryntumResources(instructors.items);

    const data = { events, resources, instructors, resourceTimeRanges };
    this._isMounted &&
      this.setState(({ eventsVersion, resourcesVersion }) => ({
        ...data,
        eventsVersion: eventsVersion + 1,
        resourcesVersion: resourcesVersion + 1,
        loading: false,
        filtersDisabled: false
      }));

    this.setEvents(events);
    maskScheduler.close();
  };

  getAdditionalEventsFilters = (visibleFilters?: AlwaysVisibleFiltersValue) => {
    let filter = [];

    if (visibleFilters?.pillarManagedBy) {
      filter = [{ extendedEventInstructors: { any: { Pillar: { ManagedBy: { eq: visibleFilters?.pillarManagedBy } } } } }];
    } else if (visibleFilters?.roleInstructor && visibleFilters?.roleInstructor === 'Internal Trainer') {
      filter = [{ extendedEventInstructors: { any: { InstructorRole: { Name: { eq: visibleFilters.roleInstructor } } } } }];
    }

    return filter;
  };

  getAdditionalInstructorsFilters = (visibleFilters?: AlwaysVisibleFiltersValue) => {
    let filter = [];
    let intarnalTrainer = 'Internal Trainer';

    if (visibleFilters?.pillarManagedBy) {
      filter = [
        { Pillar: { ManagedBy: { eq: visibleFilters?.pillarManagedBy } } },
        { Enabled: true },
        { InstructorRole: { Name: { ne: intarnalTrainer } } }
      ];
    } else if (visibleFilters?.roleInstructor && visibleFilters?.roleInstructor === intarnalTrainer) {
      filter = [{ InstructorRole: { Name: { eq: visibleFilters.roleInstructor } } }, { Enabled: true }];
    } else filter = [{ Enabled: true }];

    return filter;
  };

  handleOnCreatedEvent = (isSucess: boolean) => {
    if (isSucess) this.reloadEvents();
  };

  handleOnCreatedRequest = isSucess => {
    if (isSucess) this.reloadRequests();
  };

  handleOnCloseFormView = (actionPerformed?: EventFormActions, payload?: any) => {
    if (this._isMounted) {
      this.setState({ selectedEvent: null, eventEdition: null });
      if (!(actionPerformed && actionPerformed === 'close')) {
        this.reloadEvents();
        this.reloadRequests();
      }
    }
  };

  handleOnRequestValueChange = (value: RequestPopupFilterValues) => {
    const filter = buildRequestFilter(value, this.activeRole, this.identityService.getUserInfo());
    const query: Query = { ...this.state.currentRequestsQuery, skip: 0, take: this.pageSize, filter };
    this.loadRequests(query, true);
  };

  handleOnRequestListScrollEnd = () => {
    if (this.props.requestsStore.state.isBusy || !this.state.hasMoreRequest) return;

    const query: Query = { ...this.state.currentRequestsQuery, take: this.pageSize };
    query.skip = this.pageSize + query.skip;

    this.loadRequests(query, false);
  };

  handleOnRequestChanged = (request?: RequestDto, actionPerformed?: RequestFormActions) => {
    if (!(actionPerformed && actionPerformed === 'close')) {
      this.reloadRequests();

      if (request?.eventCreatedId && !isExtendedWorkflow(request.eventType.eventTypeCategory)) this.reloadEvents(request.eventCreatedId);
      else this.reloadEvents();
    }
  };

  updateLoadingState = (isLoading: boolean) => {
    this._isMounted && this.setState({ loading: isLoading });
  };

  reloadRequests = () => {
    const query = { ...this.state.currentRequestsQuery };
    query.take = query.skip;
    query.skip = 0;

    this.loadRequests(query, true);
  };

  handleOnCloseEventPreview = (actionPerformed?: EventFormActions, selectedEvent: any = null) => {
    this._isMounted &&
      this.setState({ mainRequest: null, eventPreview: null }, () => {
        if (!(actionPerformed && actionPerformed === 'close')) {
          this.reloadEvents();
          this.reloadRequests();
        }
        this._isMounted && this.setState({ selectedEvent });
      });
  };

  dropHandler = async ({ context: { draggedRecords, record, origStart, newResource, startDate: start, endDate, finalize } }) => {
    const { event }: { event: EventDto } = record;
    const { t } = this.props;
    let from: any = DateTimeService.toMoment(start, true).startOf('d');
    let to: any = DateTimeService.toMoment(endDate, true).startOf('d');
    finalize(false);
    const dateDifference = from.diff(origStart, 'd');
    if (dateDifference === 0 && record.resourceId === newResource.id) {
      record.setStartDate && record.setStartDate(from.toDate());
      record.setEndDate && record.setEndDate(to.endOf('d').toDate());
      return;
    }
    const operator = dateDifference >= 0 ? 'add' : 'subtract';

    this.setState({ loading: true });
    let eventComplete = await this.props.events.getById(event.id);
    this.setState({ loading: false });
    let copy = { ...eventComplete };
    //Set a copy of the instructors so the manage*Dates fn doesn't change event instrcutor info in calendar
    copy.instructors = [...eventComplete.instructors];
    let currentInstructor: EventInstructorDto = {} as any;
    //filter to take other instructors in this event, and the same instrutor in other period
    const filteredInstructors = (copy?.instructors || []).filter(instructor => {
      const { instructorId, period } = instructor;
      const filtered = !(
        instructorId === record.resourceId &&
        DateTimeService.toMoment(period.from, true).isSame(record.startDate, 'd') &&
        DateTimeService.toMoment(period.to, true).isSame(record.endDate, 'd')
      );
      if (!filtered) currentInstructor = instructor;
      return filtered;
    });

    const { instructors, ...rest } = manageEventDates({ ...copy, instructors: filteredInstructors }, dateDifference, operator);

    const instructorRole = ((newResource.instructor as UserDto).roles || []).find(item => item.role.name === 'Instructor');
    currentInstructor = manageInstructorDates(currentInstructor, dateDifference, operator);

    const newInstructor: EventInstructorDto = {
      ...currentInstructor,
      instructorId: newResource.instructor.id,
      instructor: { ...newResource.instructor, location: instructorRole?.location },
      location: instructorRole?.location,
      locationId: instructorRole?.location?.id,
      role: draggedRecords[0].instructor.role
    };

    const selectedEvent: EventDto = { ...rest, instructors: [...instructors, newInstructor] };

    if (
      !selectedEvent.unblockedForeignResourcesForPlannerTFT &&
      this.isPlannerTFT() &&
      !(
        newInstructor.instructor.pillar &&
        (newInstructor.instructor.pillar.managedBy === PlanitPlannerTypes.plannerTFT || !newInstructor.instructor.pillar.managedBy)
      )
    ) {
      ToastComponent({ text: t('Planner TFT cannot assign the event to this MTC instructor.'), type: 'error-toast' });
      this._isMounted && this.setState({ selectedEvent: null });
      return;
    }

    if (
      !selectedEvent.unblockedForeignResourcesForPlannerMTC &&
      this.isPlannerMTC() &&
      !(
        newInstructor.instructor.pillar &&
        (newInstructor.instructor.pillar.managedBy === PlanitPlannerTypes.plannerMTC || !newInstructor.instructor.pillar.managedBy)
      )
    ) {
      ToastComponent({ text: t('Planner MTC cannot assign the event to this TFT instructor.'), type: 'error-toast' });
      this._isMounted && this.setState({ selectedEvent: null });
      return;
    }

    this._isMounted && this.setState({ selectedEvent, eventEdition: false });
  };

  workingDaysPublicHolidaysHandler = async (data: PublicHolidayConf | CreateWorkingDaysDto = null) => {
    if (!data) return;
    const { instructors } = this.state;
    this._isMounted && this.setState({ loading: true });

    const maskScheduler: any = WidgetHelper.mask(this.refs.scheduler.schedulerEngine.element, 'FETCHING DATA');
    const commonQuery = { searchQuery: '', orderBy: [], skip: 0, take: 1000 };
    const publicHolidays = await this.props.publicHolidayConfiguration.getAllAsync(commonQuery);
    const workingDays = await this.props.workingDays.getAllAsync(commonQuery);
    const resourceTimeRanges = this.mapToResourceTimeRanges(instructors?.items, publicHolidays?.items, workingDays?.items);
    this._isMounted &&
      this.setState({ resourceTimeRanges }, () => {
        this.refs.scheduler?.setResourceTimeRanges(resourceTimeRanges);
        setTimeout(() => {
          this._isMounted && this.setState({ loading: false });
          maskScheduler.close();
        }, 1000);
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { eventsVersion, resourcesVersion, showRequestColumn, requests, selectedEvent, eventPreview } = this.state;
    if (
      nextState.eventsVersion === eventsVersion &&
      resourcesVersion === nextState.resourceVersion &&
      selectedEvent === nextState.selectedEvent &&
      requests === nextState.requests &&
      showRequestColumn === nextState.showRequestColumn &&
      eventPreview === nextState.eventPreview
    )
      return false;

    return true;
  }

  dblClickHandler = async ({ eventRecord: { event } }) => {
    if (this._isMounted) {
      this.setState({ loading: true });
      let elem = await this.props.events.getById(event.id);
      this.setState({ loading: false });
      this.setState({ selectedEvent: elem });
    }
  };

  beforeEventDrag = ({ eventRecord, ...rest }) => {
    const newEndDate = DateTimeService.toMoment(eventRecord.endDate, true).startOf('d');
    eventRecord.setEndDate && eventRecord.setEndDate(newEndDate.toDate());
    return { eventRecord, ...rest };
  };
  private RequestDtosToSelectionItems(requests: RequestDto[]) {
    let options: SelectionItem[] = [];
    if (requests.length > 0) {
      let types = requests
        .map(x => x.eventType)
        .distinct<string>(x => x.id)
        .toArray<EventTypeDto>();
      options = types.map(x => ({ value: x.id, text: x.name } as SelectionItem));
      // console.log(options);
    }
    return options;
  }

  render() {
    const { t } = this.props;
    const { resources, showRequestColumn, requests, selectedEvent, eventPreview, eventEdition, loading, resourceTimeRanges } = this.state;
    const { showAdditionalFilters, filterValues, filtersDisabled, barMargin, eventsVersion, resourcesVersion, events } = this.state;
    const isInstructor = this.identityService.isInRole(Roles.Instructor);
    const containerClassName = `sch-instructors__container${showAdditionalFilters ? '' : ' grow'}`;

    let schedulerClassName = 'b-react-scheduler-container';
    if (this.identityService.isInRole(Roles.Instructor)) schedulerClassName += ' full';
    else if (!this.identityService.isInRole(Roles.Instructor) && showRequestColumn)
      document.querySelector('.b-react-scheduler-container')?.classList.remove('collapse');
    else document.querySelector('.b-react-scheduler-container')?.classList.add('collapse');

    const requestsToggleClassName = `grid-splitter b-fa b-fa-angle-${showRequestColumn ? 'right' : 'left'}`;

    const ls = { eventDblClick: this.dblClickHandler, beforeEventDropFinalize: this.dropHandler, beforeEventDrag: this.beforeEventDrag };

    var render = (
      <div className={containerClassName}>
        <Dimmer active={loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading...')}</Loader>
        </Dimmer>
        <SchedulerHeaderComponent
          showInstructorFilters
          showInstructorFiltersToPlanners={this.identityService.isInRole(Roles.Planner) || this.identityService.isInRole(Roles.PlannerMTC)}
          filterValues={filterValues}
          filtersDisabled={filtersDisabled}
          onFilterValueChange={this.handleOnFilterValueChange}
          onRowHeightChange={this.handleRowHeight}
          onViewPresetChange={this.handleViewPresetChange}
          onCreatedEvent={this.handleOnCreatedEvent}
          onCreatedRequest={this.handleOnCreatedRequest}
          onReloadWorkingDaysPublicHolidays={this.workingDaysPublicHolidaysHandler}
        />
        <div className={`sch-instructors__scheduler-component${showAdditionalFilters ? '' : ' grow'}`}>
          <BryntumScheduler
            {...schedulerConfig}
            className={schedulerClassName}
            rtr={resourceTimeRanges}
            ref={'scheduler'}
            barMargin={barMargin}
            eventsVersion={eventsVersion}
            resourcesVersion={resourcesVersion}
            events={events}
            eventRenderer={data => schedulerConfig.eventRenderer(data, this.refs.scheduler?.schedulerEngine)}
            resources={resources}
            startDate={this.schedulerDatePeriod.from}
            endDate={this.schedulerDatePeriod.to}
            listeners={ls}
            onScrollEnd={this.onScrollEndHandler}
            eventDragFeature={this.identityService.isInRole(Roles.Planner) || this.identityService.isInRole(Roles.PlannerMTC)}
            onEventSelectionChange={this.handleSelectionChange}
          />
          {!isInstructor && (
            <>
              <div ref={this.requestsListRef} className={`request-table-container${!showRequestColumn ? ' collapse' : ''}`}>
                <SchedulerRequestList
                  requests={requests}
                  hide={!showRequestColumn}
                  onFilterValueChanged={this.handleOnRequestValueChange}
                  onScrollEnd={this.handleOnRequestListScrollEnd}
                  onRequestChanged={this.handleOnRequestChanged}
                  requestsStore={this.props.requestsStore}
                  updateLoadingState={this.updateLoadingState}
                  optionsFromRequests={this.RequestDtosToSelectionItems(requests)}
                />
              </div>
              <i onClick={this.onToggleRequestHandler} className={requestsToggleClassName}></i>
            </>
          )}
        </div>
        {selectedEvent && (
          <EventForm
            mode={'ViewDetails'}
            readOnly={eventEdition}
            event={{ ...buildEventViewModel(selectedEvent), fromScheduler: true }}
            onClose={this.handleOnCloseFormView}
          />
        )}
        {eventPreview && (
          <EventForm
            mode={(eventPreview?.requests || []).length <= 1 ? 'Preview' : 'Merge'}
            event={{ ...buildEventViewModel(eventPreview), fromScheduler: true }}
            onClose={this.handleOnCloseEventPreview}
          />
        )}
      </div>
    );

    return render;
  }
}

export default withTranslation()(SchedulerInstructorPage);
