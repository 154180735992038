import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Form, Grid } from 'semantic-ui-react';
import { OrderDefinition, Query, ItemReference } from 'stores/dataStore';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { PracticalFormListStore, PracticalFormItemDto, PracticalFormAnswerDetailsDto } from 'stores/assessments/forms/practical-form-store';
import { nameof, getProperties } from 'utils/object';
import { resolve } from 'inversify-react';
import { IdentityService } from 'services/identity-service';
import { TableModel, TableColumn, TableView } from 'widgets/collections/table';
import { trim, isNullOrWhiteSpaces, openInNewWindow, extractFriendlyIdNumber } from 'utils/useful-functions';
import LocationEditor from 'widgets/bussiness/location-editor';
import ProfessionEditor from 'widgets/bussiness/profession-editor';

export interface PracticalFormsListProps extends WithTranslation, RouteComponentProps {
  practicalFormListStore?: PracticalFormListStore;
}

export interface PracticalFormsListState {
  query: Query;
  activeFilters: { [key: string]: any };
  selectedItem: any;
  location: ItemReference;
  creationDateFrom: string;
  creationDateTo: string;
  deadlineFrom: string;
  deadlineTo: string;
  profile: ItemReference;
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  showDisabled: boolean;
  employee: string;
  someFilterOpened: boolean;
}

const baseOrder: OrderDefinition = { direction: 'Descending', field: nameof<PracticalFormItemDto>('createdOn'), useProfile: false };

@connect(['practicalFormListStore', PracticalFormListStore])
class PracticalFormsList extends React.Component<PracticalFormsListProps, PracticalFormsListState> {
  state: PracticalFormsListState = {
    activeFilters: [],
    selectedItem: null,
    query: {
      searchQuery: '',
      orderBy: [baseOrder],
      skip: 0,
      take: 10,
      parameters: {}
    },

    location: null,
    creationDateFrom: null,
    creationDateTo: null,
    deadlineFrom: null,
    deadlineTo: null,
    profile: null,
    cluster: null,
    equipmentType: null,
    oem: null,
    machineModel: null,
    showDisabled: false,
    employee: '',
    someFilterOpened: false
  };

  componentDidMount() {
    this.load();
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  private onOpenEmployee = (selectedItem: PracticalFormItemDto) => {
    this.setState({ selectedItem });
    openInNewWindow(`./employee-page/${selectedItem?.userId}/${selectedItem?.id}`);
  };

  private load = () => {
    const activeFilters = { ...this.state.activeFilters };
    let filter = getProperties(activeFilters)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    this.props.practicalFormListStore.getAllAsync(query);
  };

  private handleOrderBy = (newOrder: OrderDefinition[]) => {
    const orderBy: OrderDefinition[] = [...newOrder, baseOrder];
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  };

  private onDelete = async (item: PracticalFormItemDto) => await this.props.practicalFormListStore.deleteAsync(item.id);

  private openPracticalForms = (selectedItem: PracticalFormItemDto) => {
    this.setState({ selectedItem });
    openInNewWindow(`./assessments/practical-test/${selectedItem?.id}`);
  };

  private handleFilterPractical = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) delete activeFilters['IdDescription'];
    else {
      const input = value as string;
      const parts = [`contains(tolower(Title), '${input.toLowerCase()}')`];
      const friendlyId = extractFriendlyIdNumber(input, 'P');

      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('P')) parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        else parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
      }

      activeFilters['IdDescription'] = `(${parts.join(' or ')})`;
    }
    this.setState({ activeFilters }, this.load);
  };

  private handleFilterEvent = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) delete activeFilters['IdEventDescription'];
    else {
      const input = value as string;
      const parts = [`contains(tolower(Event/Title), '${input.toLowerCase()}')`];
      const friendlyId = extractFriendlyIdNumber(input, 'E');

      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('E')) parts.push(`cast(Event/FriendlyEventId, 'Edm.String') eq '${friendlyId}'`);
        else parts.push(`contains(cast(Event/FriendlyEventId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
      }

      activeFilters['IdEventDescription'] = `(${parts.join(' or ')})`;
    }
    this.setState({ activeFilters }, this.load);
  };

  private handleFilterEmployee = (employee: string) => {
    const parameters = { ...this.state.query.parameters } || {};

    if (!employee) delete parameters['EmployeeOrSfPosition'];
    else parameters['EmployeeOrSfPosition'] = employee;

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, employee }), this.load);
  };

  private handleFilterProfile = (profile: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!profile?.id) delete activeFilters['ProfileId'];
    else activeFilters['ProfileId'] = { Template: { ProfessionId: { eq: { type: 'guid', value: profile.id } } } };

    this.setState({ activeFilters, profile }, this.load);
  };

  private handleFilterLocation = (location: ItemReference) => {
    const parameters = this.state.query.parameters || {};

    if (location?.id) parameters['LocationId'] = location?.id;
    else delete parameters['LocationId'];

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, location }), this.load);
  };
  private showResultDetails(answerDetails: PracticalFormAnswerDetailsDto): string {
    if (answerDetails.answered) {
      return `${answerDetails.numberOfPassedQuestions} Passed / ${answerDetails.numberOfFailedQuestions} Failed`;
    }
    return '-';
  }
  private getColumnsModel = (): TableColumn<PracticalFormItemDto>[] => {
    const { t } = this.props;

    let columns: TableColumn<PracticalFormItemDto>[] = [
      {
        title: t('Practical ID'),
        tooltipRenderer: true,
        renderer: (data: PracticalFormItemDto) => data?.friendlyId,
        selectableHeader: true,
        sortDefinition: {
          field: nameof<PracticalFormItemDto>('friendlyId'),
          useProfile: false
        }
      },
      {
        title: t('Practical Title'),
        tooltipRenderer: true,
        renderer: (data: PracticalFormItemDto) => data?.title,
        selectableHeader: true
      },
      {
        title: t('Event'),
        tooltipRenderer: true,
        renderer: (data: PracticalFormItemDto) => `${data?.eventDetails?.friendlyId} - ${data?.eventDetails?.title}`,
        selectableHeader: true
      },
      {
        title: t('Employee'),
        tooltipRenderer: false,
        renderer: (data: PracticalFormItemDto) => trim(`${data?.user?.lastName}, ${data?.user?.firstName}`.trim(), ','),
        selectableHeader: true
      },
      {
        title: t('SAP ID'),
        tooltipRenderer: false,
        renderer: (data: PracticalFormItemDto) => data?.user?.employeeId,
        selectableHeader: true
      },
      {
        title: t('SF Position'),

        tooltipRenderer: true,
        renderer: (data: PracticalFormItemDto) => data?.user?.sfPosition,
        selectableHeader: true
      },
      {
        title: t('Location'),

        tooltipRenderer: true,
        renderer: (data: PracticalFormItemDto) => data?.user?.location?.location,
        selectableHeader: true
      },

      {
        title: t('Profile'),
        tooltipRenderer: false,
        renderer: (data: PracticalFormItemDto) =>
          data?.profile?.professionName ? <span className="assessment-forms-grey-tag"> {data.profile.professionName}</span> : null
      },
      {
        title: t('Total Actual Duration'),
        tooltipRenderer: false,
        renderer: (data: PracticalFormItemDto) => data?.answerDetails?.totalActualDuration || '-',
        selectableHeader: true
      },
      {
        title: t('Total Target Level'),
        tooltipRenderer: false,
        renderer: (data: PracticalFormItemDto) => data?.answerDetails?.totalTargetDuration,
        selectableHeader: true
      },
      {
        title: t('Result'),
        tooltipRenderer: false,
        renderer: (data: PracticalFormItemDto) => <span>{data.answerDetails ? this.showResultDetails(data.answerDetails) : '-'}</span>,
        selectableHeader: true
      }
    ];
    return columns;
  };

  private getExtraActions = () => {
    const { t } = this.props as any;

    let buttonMenuOptions = [
      {
        content: (
          <>
            <Icon name="file alternate" />
            {<span className="text__bold">{t('Open Practical Form')}</span>}
          </>
        ),
        onClick: this.openPracticalForms
      },
      {
        content: (
          <>
            <>
              <Icon name="eye" />
              {<span className="text__bold">{t('Open Employee Card')}</span>}
            </>
          </>
        ),
        onClick: this.onOpenEmployee
      },
      {
        content: (
          <>
            <Icon color="red" name="trash alternate" />
            {<span className="text__red text__bold">{t('Delete')}</span>}
          </>
        ),
        onClick: this.onDelete,
        isVisible: (item: PracticalFormItemDto) => true
      }
    ];
    return buttonMenuOptions;
  };

  handleOnEnterKeydown = item => {
    this.openPracticalForms(item);
  };

  handleOnRowDoubleClick = item => {
    this.openPracticalForms(item);
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t } = this.props;
    const { employee, profile, location, someFilterOpened } = this.state;
    const { state: storeState, clearMessages } = this.props.practicalFormListStore;

    const pocLocations = IdentityService.isPoc(this.identityService.getUserInfo())
      ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
      : [];

    const tableModel = {
      columns: this.getColumnsModel(),
      data: storeState
    } as TableModel<PracticalFormItemDto>;

    return (
      <div className="question-bank__wrapper practical-form-list__wrapper">
        <Grid className="event-types-list-grid">
          {storeState.result && !storeState.result.isSuccess && (storeState.result.messages || []).length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                list={storeState.result.messages.map(o => o.body)}
                onDismiss={clearMessages}
              />
            </Grid.Row>
          )}

          <div id="assessment-forms-list-filters__admin-or-poc-container">
            <div id="assessment-forms-list-filters__admin-or-poc-container__lef-container">
              <Form.Field>
                <ClearableTimerInput
                  icon="search"
                  placeholder={t('Search in Title or ID')}
                  onChange={this.handleFilterPractical}
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </Form.Field>
              <Form.Field>
                <ClearableTimerInput
                  icon="search"
                  placeholder={t('Search Event Related')}
                  onChange={this.handleFilterEvent}
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </Form.Field>

              <Form.Field>
                <ClearableTimerInput
                  icon="search"
                  placeholder={t('Search Employee')}
                  onChange={(e, { value }) => this.handleFilterEmployee(value)}
                  value={employee || ''}
                  key={'searchEmployee'}
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </Form.Field>

              <Form.Field>
                <ProfessionEditor
                  clearable
                  placeholder={t('Role')}
                  nullable
                  value={profile?.id ? { id: profile.id, title: '' } : null}
                  onChange={this.handleFilterProfile}
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </Form.Field>

              <Form.Field>
                <LocationEditor
                  placeholder={t('Location')}
                  clearable
                  locationsReceived={pocLocations}
                  value={location}
                  onChange={this.handleFilterLocation}
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </Form.Field>
              <Form.Field></Form.Field>
            </div>
          </div>
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={true}
              onHideCheckbox={true}
              selectionType={'allRow'}
              //maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              isRowDisableLayout={item => !(item as any).user.enabled}
              model={tableModel}
              extraActions={this.getExtraActions()}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              onPageChange={this.handlePageChange}
              canCreateNew={false}
              canEdit={false}
              canDelete={false}
            />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(PracticalFormsList);
