import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Icon, Message, Grid } from 'semantic-ui-react';

import { PlcTypeDto, PlcTypesStore } from '../../../../../stores/configuration/machinery/plc-types-store';
import { ItemState, OrderDefinition, Query } from '../../../../../stores/dataStore';
import { CommandResult } from '../../../../../stores/types';
import { nameof } from '../../../../../utils/object';
import EquipmentTypeEditor from '../../../../../widgets/bussiness/equipment-type-editor';
import OemEditor from '../../../../../widgets/bussiness/oem-editor';
import { TableModel, TableView } from '../../../../../widgets/collections/table';
import { TextBoxFilter } from '../../../../../widgets/collections/table-filters/textbox-filter';
import NewPlcTypeView from './new-plc-type';
import ChangePlcTypeView from './edit-plc-type';

export interface PlcTypesListProps extends WithTranslation, RouteComponentProps {
  plcTypes: PlcTypesStore;
  readOnly: boolean;
}

export interface PlcTypesListState {
  query: Query;
  newPlcTypeShown: boolean;
  changePlcTypeShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['plcTypes', PlcTypesStore])
class PlcTypesListPage extends React.Component<PlcTypesListProps, PlcTypesListState> {
  handleOnEnterKeydown = item => {
    !this.props.readOnly && this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Ascending', field: nameof<PlcTypeDto>('name'), useProfile: false }],
        skip: 0,
        take: 10
      },
      newPlcTypeShown: false,
      changePlcTypeShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.plcTypes.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) });
    this.load();
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: PlcTypeDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.plcTypes.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: PlcTypeDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.plcTypes.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newPlcTypeShown: true });
  }

  @autobind
  private onNewItemClosed() {
    this.setState({ newPlcTypeShown: false });
    this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changePlcTypeShown: true });
  }

  @autobind
  private onEditItemClosed() {
    this.setState({ changePlcTypeShown: false });
    this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Cluster'),
          renderer: data => <>{data.clusterName}</>,
          tooltipRenderer: true,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Cluster')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<PlcTypeDto>('clusterName'), `contains(tolower(EquipmentType/Cluster/Name), '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<PlcTypeDto>('clusterName'))}
              active={activeFilters.includes(nameof<PlcTypeDto>('clusterName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'EquipmentType/Cluster/Name',
            useProfile: false
          }
        },
        {
          title: t('Equipment Type'),
          tooltipRenderer: true,
          renderer: data => data.equipmentTypeName,
          editor: (data, onChange) => (
            <EquipmentTypeEditor
              nullable
              value={{ id: data.equipmentTypeId, title: data.equipmentTypeName }}
              onChange={c => {
                data.equipmentTypeId = c == null ? null : c.id;
                data.equipmentTypeName = c == null ? '' : c.title;
                onChange();
              }}
            />
          ),
          //<Input value={data.clusterName} fluid onChange={(e, { value }) => { data.clusterName = value; onChange(); }} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Equipment Type')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<PlcTypeDto>('equipmentTypeName'), `contains(tolower(equipmentType/Name), '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<PlcTypeDto>('equipmentTypeName'))}
              active={activeFilters.includes(nameof<PlcTypeDto>('equipmentTypeName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'equipmentType/Name',
            useProfile: false
          }
        },
        {
          title: t('OEM'),
          tooltipRenderer: true,
          renderer: data => data.oemName,
          editor: (data, onChange) => (
            <OemEditor
              nullable
              value={{ id: data.oemId, title: data.oemName }}
              onChange={c => {
                data.oemId = c == null ? null : c.id;
                data.oemName = c == null ? '' : c.title;
                onChange();
              }}
            />
          ),
          //<Input value={data.clusterName} fluid onChange={(e, { value }) => { data.clusterName = value; onChange(); }} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by OEM')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<PlcTypeDto>('oemName'), `contains(tolower(oem/Name), '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<PlcTypeDto>('oemName'))}
              active={activeFilters.includes(nameof<PlcTypeDto>('oemName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: 'oem/Name',
            useProfile: false
          }
        },
        {
          title: t('PLC Type'),
          tooltipRenderer: true,
          renderer: data => data.name,
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(e, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by PLC Type')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<PlcTypeDto>('name'), `contains(tolower(${nameof<PlcTypeDto>('name')}), '${value.toLowerCase()}')`)
              }
              onClear={() => onClear(nameof<PlcTypeDto>('name'))}
              active={activeFilters.includes(nameof<PlcTypeDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<PlcTypeDto>('name'),
            useProfile: false,
            active: 'Ascending'
          }
        }
      ],
      data: this.props.plcTypes.state
    } as TableModel<PlcTypeDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.readOnly && <h2>{t('PLC Types')}</h2>}
          {this.props.plcTypes.state.result && !this.props.plcTypes.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.plcTypes.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newPlcTypeShown && !this.state.changePlcTypeShown && !this.props.readOnly}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={
                !this.props.readOnly
                  ? [
                      {
                        content: (
                          <>
                            <Icon name="edit" />
                            &nbsp;{t('Edit')}
                          </>
                        ),
                        onClick: item => {
                          this.setState({ selectedItem: item }, () => this.onEditItem());
                        }
                      }
                    ]
                  : []
              }
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={!this.props.readOnly}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={!this.props.readOnly}
              createNewButtonTitle={t('Add PLC Type')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newPlcTypeShown && <NewPlcTypeView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changePlcTypeShown && <ChangePlcTypeView onClose={this.onEditItemClosed} currentPLC={this.state.selectedItem} />)}
      </>
    );
  }
}

export default withTranslation()(PlcTypesListPage);
