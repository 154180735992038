import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Grid, Icon, Message } from 'semantic-ui-react';
import { DeliveryMethodSfMapsStore, DeliveryMethodSfMapDto } from 'stores/configuration/sf-mappings/delivery-method-sf-map-store';
import { ItemState, Query, OrderDefinition } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import NewDeliveryMethodSFMAPView from './new-delivery-method';
import ChangeDeliveryMethodSFMAPView from './edit-delivery-method';
import { DropDownDeliveryTypesStore, DeliveryTypeDto } from 'stores/configuration/events-n-requests/delivery-types-store';
import { DataStoreFilter } from 'widgets/collections/table-filters/data-store-filter';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';

export interface DeliveryMethodSFMAPListProps extends WithTranslation, RouteComponentProps {
  deliveryMethodsSfStore: DeliveryMethodSfMapsStore;
  deliveryTypeDropDownStore: DropDownDeliveryTypesStore;
}

export interface DeliveryMethodSFMAPListState {
  query: Query;
  newDeliveryMethodSFMAPShown: boolean;
  changeDeliveryMethodSFMAPShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['deliveryMethodsSfStore', DeliveryMethodSfMapsStore], ['deliveryTypeDropDownStore', DropDownDeliveryTypesStore])
class DeliveryMethodSFMAPListPage extends React.Component<DeliveryMethodSFMAPListProps, DeliveryMethodSFMAPListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newDeliveryMethodSFMAPShown: false,
      changeDeliveryMethodSFMAPShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.deliveryMethodsSfStore.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: DeliveryMethodSfMapDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.deliveryMethodsSfStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: DeliveryMethodSfMapDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.deliveryMethodsSfStore.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newDeliveryMethodSFMAPShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newDeliveryMethodSFMAPShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeDeliveryMethodSFMAPShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeDeliveryMethodSFMAPShown: false });
    if (isSuccess) this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Delivery Type'),
          tooltipRenderer: true,
          renderer: data => <span>{data.deliveryTypeName}</span>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<DeliveryTypeDto>
              filterTitle={t('Filter by DeliveryType')}
              triggerTitle={title}
              onFilter={(value: string) =>
                onFilter(nameof<DeliveryMethodSfMapDto>('deliveryTypeName'), { DeliveryTypeId: { eq: { type: 'guid', value } } })
              }
              onClear={() => onClear(nameof<DeliveryMethodSfMapDto>('deliveryTypeName'))}
              active={activeFilters.includes(nameof<DeliveryMethodSfMapDto>('deliveryTypeName'))}
              getItems={q => this.props.deliveryTypeDropDownStore.getAllAsync(q)}
              parameters="id,name"
              orderBy={[{ direction: 'Ascending', field: nameof<DeliveryTypeDto>('name'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(name)': { startswith: search.toLowerCase() } })}
              valueSelector={(u: DeliveryTypeDto) => u.id}
              titleSelector={(u: DeliveryTypeDto) => u.name}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Mapping to SF'),
          tooltipRenderer: true,
          renderer: data => <span>{data.mappingToSF}</span>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Mapping Sf')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<DeliveryMethodSfMapDto>('mappingToSF'),
                  `contains(${nameof<DeliveryMethodSfMapDto>('mappingToSF')}, '${value}')`
                )
              }
              onClear={() => onClear(nameof<DeliveryMethodSfMapDto>('mappingToSF'))}
              active={activeFilters.includes(nameof<DeliveryMethodSfMapDto>('mappingToSF'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<DeliveryMethodSfMapDto>('mappingToSF'),
            useProfile: false
          }
        }
      ],
      data: this.props.deliveryMethodsSfStore.state
    } as TableModel<DeliveryMethodSfMapDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.deliveryMethodsSfStore.state.result && !this.props.deliveryMethodsSfStore.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.deliveryMethodsSfStore.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newDeliveryMethodSFMAPShown && !this.state.changeDeliveryMethodSFMAPShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add Delivery Method Map')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newDeliveryMethodSFMAPShown && <NewDeliveryMethodSFMAPView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeDeliveryMethodSFMAPShown && (
            <ChangeDeliveryMethodSFMAPView onClose={this.onEditItemClosed} currentDto={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(DeliveryMethodSFMAPListPage);
