import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Grid, Icon, Message } from 'semantic-ui-react';
import { EventTypeSfMapsStore, EventTypeSfMapDto } from 'stores/configuration/sf-mappings/event-type-sf-map-store';
import { ItemState, Query, OrderDefinition } from 'stores/dataStore';

import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import NewEventTypeSFMapView from './new-event-type';
import ChangeEventTypeSFMapView from './edit-event-type';
import { DataStoreFilter } from 'widgets/collections/table-filters/data-store-filter';
import { EventTypesStore, EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { OptionsFilter } from 'widgets/collections/table-filters/option-filter';
import { decamelCase } from 'utils/event-utils';

export interface EventTypeSFMapListProps extends WithTranslation, RouteComponentProps {
  eventTypesSfStore: EventTypeSfMapsStore;
  eventTypesDropDownStore: EventTypesStore;
}

export interface EventTypeSFMapListState {
  query: Query;
  newEventTypeSFMapShown: boolean;
  changeEventTypeSFMapShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['eventTypesSfStore', EventTypeSfMapsStore], ['eventTypesDropDownStore', EventTypesStore])
class EventTypeSFMapListPage extends React.Component<EventTypeSFMapListProps, EventTypeSFMapListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newEventTypeSFMapShown: false,
      changeEventTypeSFMapShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.eventTypesSfStore.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: EventTypeSfMapDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.eventTypesSfStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: EventTypeSfMapDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.eventTypesSfStore.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newEventTypeSFMapShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newEventTypeSFMapShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeEventTypeSFMapShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeEventTypeSFMapShown: false });
    if (isSuccess) this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Event Type'),
          renderer: data => <span>{data?.eventTypeName}</span>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<EventTypeDto>
              filterTitle={t('Filter by Event Type')}
              triggerTitle={title}
              onFilter={(value: string) =>
                onFilter(nameof<EventTypeSfMapDto>('eventTypeId'), { eventTypeId: { eq: { type: 'guid', value } } })
              }
              onClear={() => onClear(nameof<EventTypeSfMapDto>('eventTypeId'))}
              active={activeFilters.includes(nameof<EventTypeSfMapDto>('eventTypeId'))}
              getItems={q => this.props.eventTypesDropDownStore.getAllAsync(q)}
              parameters="id,name"
              orderBy={[{ direction: 'Ascending', field: nameof<EventTypeDto>('name'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(name)': { startswith: search.toLowerCase() } })}
              valueSelector={(u: EventTypeDto) => u.id}
              titleSelector={(u: EventTypeDto) => u.name}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Item Type'),
          tooltipRenderer: true,
          renderer: data => <span>{data.itemType}</span>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Item Type')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(nameof<EventTypeSfMapDto>('itemType'), `contains(${nameof<EventTypeSfMapDto>('itemType')}, '${value}')`)
              }
              onClear={() => onClear(nameof<EventTypeSfMapDto>('itemType'))}
              active={activeFilters.includes(nameof<EventTypeSfMapDto>('itemType'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<EventTypeSfMapDto>('itemType'),
            useProfile: false
          }
        },
        {
          title: t('Status'),
          tooltipRenderer: true,
          renderer: data => <span>{decamelCase(data.status.toString())}</span>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <OptionsFilter
              filterTitle={t('Filter by Status')}
              options={[
                { text: t('Draft'), value: 'Draft' },
                { text: t('Planned'), value: 'Planned' },
                { text: t('InProgress'), value: 'InProgress' },
                { text: t('Completed'), value: 'Completed' },
                { text: t('Closed'), value: 'Closed' }
              ]}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<EventTypeSfMapDto>('status'), { [nameof<EventTypeSfMapDto>('status')]: value })}
              onClear={() => onClear(nameof<EventTypeSfMapDto>('status'))}
              active={activeFilters.includes(nameof<EventTypeSfMapDto>('status'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<EventTypeSfMapDto>('status'),
            useProfile: false
          }
        },
        {
          title: t('Mapping to SF'),
          tooltipRenderer: true,
          renderer: data => <span>{data.mappingToSF}</span>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Mapping Sf')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<EventTypeSfMapDto>('mappingToSF'),
                  `contains(tolower(${nameof<EventTypeSfMapDto>('mappingToSF')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<EventTypeSfMapDto>('mappingToSF'))}
              active={activeFilters.includes(nameof<EventTypeSfMapDto>('mappingToSF'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<EventTypeSfMapDto>('mappingToSF'),
            useProfile: false
          }
        }
      ],
      data: this.props.eventTypesSfStore.state
    } as TableModel<EventTypeSfMapDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.eventTypesSfStore.state.result && !this.props.eventTypesSfStore.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.eventTypesSfStore.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newEventTypeSFMapShown && !this.state.changeEventTypeSFMapShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add Event Type Map')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newEventTypeSFMapShown && <NewEventTypeSFMapView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeEventTypeSFMapShown && (
            <ChangeEventTypeSFMapView onClose={this.onEditItemClosed} currentDto={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(EventTypeSFMapListPage);
