import { injectable } from 'inversify';
import { AlwaysVisibleFiltersValue } from './always-visible-filters';
import { InvisibleFiltersValue } from './invisible-filters';
import { EventDto, DateTimePeriod } from 'stores/events/events-store';
import { FilterValues } from '../scheduler-header.component';
import { Cluster } from 'widgets/bussiness/multiple-selector/multiple-cluster-editor';
import { IdentityService, Roles } from 'services/identity-service';
import { container } from 'inversify.config';
import { DateTimeService } from 'services/datetime-service';

const defaultSubtractMonths = 3;
const defaultAddMonths = 9;
const start = DateTimeService.today().subtract(defaultSubtractMonths, 'months');
const from = start.toISOString();

const end = DateTimeService.today().add(defaultAddMonths, 'months');
const to = end.toISOString();
@injectable()
export class SchedulerEventFilterService {
  private identityService: IdentityService;

  constructor() {
    this.identityService = container.get(IdentityService);
  }

  public getDefaultFilterValues(): FilterValues {
    return {
      alwaysVisibleFilterValues: this.getDefaultAlwaysVisibleFilters(),
      invisibleFilterValues: this.getDefaultAdditionalFilters()
    };
  }

  public getDefaultAdditionalFilters = (): InvisibleFiltersValue => ({
    deliveryMethods: [],
    machineModel: '',
    professions: [],
    trainingLevels: [],
    functionalAreas: [],
    pillars: [],
    regions: [],
    eventLocationRegions: [],
    category: null,
    mrClusters: [],
    nmrClusters: []
  });

  public getDefaultDateTimePeriod = (): DateTimePeriod => ({ from, to });

  public getDefaultAlwaysVisibleFilters = (): AlwaysVisibleFiltersValue => {
    const isPowerInstructor = IdentityService.isPowerInstructor(this.identityService.getUserInfo());
    return {
      eventLocations: [],
      eventType: [],
      eventTitle: '',
      instructorLocations: [],
      instructors: [],
      period: this.getDefaultDateTimePeriod(),
      requestLocations: [],
      status: [],
      filterByActiveUpdateFlag: false,
      handleAlertFilter: false,
      filterByMe: this.identityService.isInRole(Roles.Instructor) && !isPowerInstructor,
      filterByMyPillar: isPowerInstructor,
      priorities: [],
      requestsRegions: [],
      roleInstructor: this.identityService.isInRole(Roles.Instructor)
        ? 'Instructor'
        : this.identityService.isInRole(Roles.PoC)
        ? 'Internal Trainer'
        : '',
      pillarManagedBy: this.identityService.isInRole(Roles.Planner)
        ? 'PlannerTFT'
        : this.identityService.isInRole(Roles.PlannerMTC)
        ? 'PlannerMTC'
        : ''
    };
  };

  public fullfillsAllFilters = (resourceData, filters: AlwaysVisibleFiltersValue, additionalFilters?: InvisibleFiltersValue) => {
    const { event }: { event: EventDto } = resourceData;
    return (
      this.fullfillsFilters(event, filters) && (!additionalFilters || this.fullfillsAdditionalFilters(resourceData, additionalFilters))
    );
  };

  private fullfillsAdditionalFilters = (resourceData, filter: InvisibleFiltersValue): boolean => {
    const { event }: { event: EventDto; resource: any } = resourceData;
    if (
      (filter.professions || []).length > 0 &&
      !filter.professions.some(profession => event.eventDetails && event.eventDetails.professionId === profession.id)
    )
      return false;

    if (
      (filter.deliveryMethods || []).length > 0 &&
      !filter.deliveryMethods.some(deliveryMethod => event.eventTrainingDetails?.deliveryMethodId === deliveryMethod.id)
    )
      return false;

    if (
      (filter.trainingLevels || []).length > 0 &&
      !filter.trainingLevels.some(trainingLevel => event.eventTrainingDetails?.trainingLevelId === trainingLevel.id)
    )
      return false;

    if (
      (filter.functionalAreas || []).length > 0 &&
      !filter.functionalAreas.some(functionalArea => event.eventDetails?.nmrFunctionalAreaId === functionalArea.id)
    )
      return false;

    if ((filter.regions || []).length > 0 && !filter.regions.some(x => event.eventDetails?.location?.regionId === x.id)) return false;

    if (
      (filter.eventLocationRegions || []).length &&
      !filter.eventLocationRegions.some(({ id }) => event.eventDetails?.location?.regionId === id)
    )
      return false;

    if ((filter.nmrClusters || []).length && !filter.nmrClusters.some(({ id }) => event.eventDetails?.nmrClusterId === id)) return false;

    if (filter.machineModel && !(event.eventDetails?.requestedMachines || []).some(x => x.machineModelId === filter.machineModel))
      return false;

    if (
      (filter.mrClusters || []).length &&
      !(event.eventDetails?.requestedMachines || []).some(x => filter.mrClusters.any(cluster => cluster.id === x.machineRelatedClusterId))
    )
      return false;

    if (filter.category && event.eventDetails?.category !== filter.category) return false;

    return true;
  };

  private fullfillsFilters = (event: EventDto, filter: AlwaysVisibleFiltersValue): boolean => {
    if (!event || !filter) return true;

    if (
      (filter.requestLocations || []).length > 0 &&
      !filter.requestLocations.some(location => event.requests && event.requests.some(x => x.requestLocation.id === location.id))
    )
      return false;

    if (
      (filter.eventLocations || []).length > 0 &&
      !filter.eventLocations.some(location => event.eventDetails && event.eventDetails.locationId === location.id)
    )
      return false;

    if ((filter.status || []).length > 0 && !filter.status.some(x => event.status === x)) return false;

    if ((filter.eventType || []).length > 0 && !filter.eventType.some(id => id === event.eventType?.originalEventTypeId)) return false;

    if (filter.eventTitle && !event.title.match(new RegExp(filter.eventTitle, 'i'))) return false;

    if (
      (filter.requestsRegions || []).length &&
      !filter.requestsRegions.some(({ id }) => (event.requests || []).some(({ requestLocation }) => requestLocation.regionId === id))
    )
      return false;

    if (filter.filterByActiveUpdateFlag) return event.eventUpdatedFlag;

    if (filter.handleAlertFilter) return event.eventWarnings[0] !== undefined;

    if (
      filter.priorities.length !== 0 &&
      !filter.priorities.some(priority => event.eventDetails && event.eventDetails.priorityId === priority.id)
    )
      return false;

    return true;
  };

  hasCluster = (event: EventDto, cluster: Cluster): boolean => {
    if (cluster.machineRelated)
      return (
        event.eventDetails.requestedMachines &&
        event.eventDetails.requestedMachines.some(x => cluster.value.id === x.machineRelatedClusterId)
      );

    return event.eventDetails.nmrClusterId === cluster.value.id;
  };
}
