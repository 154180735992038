import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import {
  Button,
  Checkbox,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  Loader,
  Menu,
  Message,
  Popup,
  Visibility
} from 'semantic-ui-react';
import {
  EventFieldGroups,
  EventTypeDto,
  EventTypesStore,
  EventTypeCategory,
  roleToTitleDict
} from 'stores/configuration/events-workflow/event-types-store';
import { Query } from 'stores/dataStore';
import { getProperties, nameof, clone, getKeyValuePairs } from 'utils/object';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import LineSeparator from 'widgets/bussiness/line-separator';
import NewEventTypeModal, { CreationType } from './new-event-type';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { getEventOwners } from 'utils/event-type-utils';

export interface EventTypesListProps extends WithTranslation, RouteComponentProps {
  eventTypes: EventTypesStore;
}

export interface EventTypesListState {
  query: Query;
  newEventTypeModalShown: boolean;

  showFilters: boolean;
  activeFilters: { [key: string]: any };

  creationType: CreationType;
  initialData: EventTypeDto;

  actionMenuOpened: string;
}

@connect(['eventTypes', EventTypesStore])
class EventTypesListPage extends React.Component<EventTypesListProps, EventTypesListState> {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: nameof<EventTypeDto>('modifiedOn'), useProfile: false }],
        skip: 0,
        take: 1000000
      },
      newEventTypeModalShown: false,
      showFilters: false,
      activeFilters: { active: [{ active: true }] },
      creationType: CreationType.Normal,
      initialData: null,
      actionMenuOpened: null
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    const activeFilters = this.state.activeFilters;
    const filter = [].concat(
      ...getProperties(activeFilters)
        .filter(({ value }) => !!value)
        .map(({ value }) => value)
    );
    let orderBy = [{ direction: 'Descending', field: nameof<EventTypeDto>('modifiedOn'), useProfile: false }];
    if (activeFilters['active'] == null) {
      orderBy = [{ direction: 'Ascending', field: nameof<EventTypeDto>('active'), useProfile: false }, ...orderBy];
    }
    const query = Object.assign(this.state.query, { filter, orderBy });
    this.setState({ query }, () => {
      this.props.eventTypes.getAllAsync(this.state.query);
    });
  }

  private onEventTypeFilterChanged = (e, { value }) => {
    const activeFilters = this.state.activeFilters;
    if (isNullOrWhiteSpaces(value)) {
      delete activeFilters['name'];
    } else {
      activeFilters['name'] = [`contains(name, '${value}')`];
    }
    this.setState(
      {
        activeFilters
      },
      this.load
    );
  };

  private onParticipantsFilterChanged = (value?) => {
    const activeFilters = this.state.activeFilters;
    if (value == null) {
      delete activeFilters['participants'];
    } else {
      activeFilters['participants'] = [{ participants: !!value }];
    }
    this.setState(
      {
        activeFilters
      },
      this.load
    );
  };

  private onInstructorFilterChanged = (e, { value }) => {
    const activeFilters = this.state.activeFilters;
    if (isNullOrWhiteSpaces(value)) {
      delete activeFilters['instructors'];
    } else {
      activeFilters['instructors'] = [{ instructor: value }];
    }
    this.setState(
      {
        activeFilters
      },
      this.load
    );
  };

  private onFeedbackFilterChanged = (value?) => {
    const activeFilters = this.state.activeFilters;
    if (value == null) {
      delete activeFilters['feedbacks'];
    } else {
      activeFilters['feedbacks'] = [{ feedbacks: !!value }];
    }
    this.setState(
      {
        activeFilters
      },
      this.load
    );
  };

  private onDirectEventFilterChanged = (value?) => {
    const activeFilters = this.state.activeFilters;
    if (value == null) {
      delete activeFilters['directEvent'];
    } else {
      activeFilters['directEvent'] = [{ directEventsAllowed: !!value }];
    }
    this.setState(
      {
        activeFilters
      },
      this.load
    );
  };

  private onActiveChanged = (value?) => {
    const activeFilters = this.state.activeFilters;
    if (value == null) {
      delete activeFilters['active'];
    } else {
      activeFilters['active'] = [{ active: !!value }];
    }
    this.setState(
      {
        activeFilters
      },
      this.load
    );
  };

  private onTestsFilterChanged = (e, { value }) => {
    const activeFilters = this.state.activeFilters;
    const keys = (value as string[]) || [];

    if (keys.length === 0) {
      delete activeFilters['tests'];
    } else if (keys.includes('none')) {
      activeFilters['tests'] = ['preEventTest', 'practicalTest', 'postEventTest'].map(v => ({ [v]: false }));
    } else {
      activeFilters['tests'] = value.map(v => ({ [v]: true }));
    }
    this.setState(
      {
        activeFilters
      },
      this.load
    );
  };

  private toggleFilters = () => {
    const { showFilters, activeFilters } = this.state;
    this.setState(
      {
        activeFilters: activeFilters['active'] == null ? {} : { active: activeFilters['active'] },
        showFilters: !showFilters,
        actionMenuOpened: null
      },
      () => {
        if (!this.state.showFilters) this.load();
      }
    );
  };

  private onVisibilityUpdate = (e, { calculations }) => {
    // consol e.log("visibility: ", calculations);
  };

  private getValue = (key: string, subkey: string): boolean | null => {
    const { activeFilters } = this.state;
    if (activeFilters[key] && activeFilters[key].length !== 0) return !!activeFilters[key][0][subkey];
    return null;
  };

  private onNewItemOpen = () => {
    this.setState({ newEventTypeModalShown: true, creationType: CreationType.Normal, initialData: null, actionMenuOpened: null });
  };
  private onEditItem = (item: EventTypeDto) => {
    if (EventTypeCategory[item.eventTypeCategory] === EventTypeCategory.Vacations) return;
    this.setState({ newEventTypeModalShown: true, creationType: CreationType.Edit, initialData: clone(item), actionMenuOpened: null });
  };
  private onCloneItem = (item: EventTypeDto) => {
    if (EventTypeCategory[item.eventTypeCategory] === EventTypeCategory.Vacations) return;
    this.setState({ newEventTypeModalShown: true, creationType: CreationType.Clone, initialData: clone(item), actionMenuOpened: null });
  };

  private onActionMenuOpen = (item: EventTypeDto) => {
    const { actionMenuOpened } = this.state;
    if (EventTypeCategory[item.eventTypeCategory] === EventTypeCategory.Vacations) return;
    if (actionMenuOpened === item.id) {
      this.setState({
        actionMenuOpened: null
      });
    } else {
      this.setState(
        {
          actionMenuOpened: null
        },
        () =>
          this.setState({
            actionMenuOpened: item.id
          })
      );
    }
  };

  private onNewItemClosed = (isSuccess: boolean) => {
    this.setState({ newEventTypeModalShown: false });
    if (isSuccess) this.load();
  };

  @autobind
  private async toggleAvailability(e: any, data: any, item: EventTypeDto) {
    await this.props.eventTypes.toggleAvailability(item);
    this.props.eventTypes.getAllAsync(this.state.query);
  }

  render() {
    const t = this.props.t;
    const storeState = this.props.eventTypes.state;
    const { items, result, isBusy } = storeState;
    const { showFilters, newEventTypeModalShown, creationType, initialData, actionMenuOpened, activeFilters } = this.state;

    const noneSelected =
      (activeFilters['tests'] || []).length !== 0 && activeFilters['tests'].every(x => getKeyValuePairs(x).every(p => !p.value));
    return (
      <>
        <h3>{t("Events' Workflow")}</h3>
        <LineSeparator />
        <div className="event-types-list-wrapper">
          <Grid className="event-types-list-grid">
            <div className="event-types-options-list-wrapper">
              <Grid.Row className="event-types-list-filter-row" textAlign="right">
                <Form>
                  <Form.Group>
                    <Form.Field>
                      <label>
                        <span style={{ visibility: 'hidden' }}>.</span>
                      </label>
                      <Button
                        style={{ width: 36 }}
                        className="inverted-color-btn event-type-filter-icon-btn"
                        onClick={() => this.onActiveChanged(this.getValue('active', 'active') === true ? null : true)}
                        icon
                      >
                        <Icon.Group>
                          <Icon style={{ margin: 0 }} name={this.getValue('active', 'active') ? 'delete calendar' : 'calendar check'} />
                        </Icon.Group>
                      </Button>
                    </Form.Field>
                    <Form.Field id="add-event-type">
                      <label>
                        <span style={{ visibility: 'hidden' }}>.</span>
                      </label>
                      <Button className="inverted-color-btn" onClick={this.onNewItemOpen}>
                        {t('New Event Type')}&nbsp;
                        <Icon name="plus circle" />
                      </Button>
                    </Form.Field>
                    <Form.Field>
                      <label>
                        <span style={{ visibility: 'hidden' }}>.</span>
                      </label>
                      <Button
                        style={{ width: 36 }}
                        className="inverted-color-btn event-type-filter-icon-btn"
                        onClick={this.toggleFilters}
                        icon
                      >
                        <Icon.Group>
                          <Icon style={showFilters ? undefined : { margin: 0 }} name="filter" />
                          {showFilters && (
                            <Icon style={{ textShadow: 'unset', fontSize: '0.5em' }} corner className="hide-filter" name="remove" />
                          )}
                        </Icon.Group>
                      </Button>
                    </Form.Field>
                    {showFilters && (
                      <>
                        <Form.Field>
                          <label>{t('Direct Event Creation')}</label>
                          <Button.Group className="filter-button-group">
                            <Button
                              basic
                              className={this.getValue('directEvent', 'directEventsAllowed') === true ? 'active' : ''}
                              onClick={() =>
                                this.onDirectEventFilterChanged(this.getValue('directEvent', 'directEventsAllowed') === true ? null : true)
                              }
                            >
                              {t('Yes')}
                            </Button>
                            <Button
                              basic
                              className={this.getValue('directEvent', 'directEventsAllowed') === false ? 'active' : ''}
                              onClick={() =>
                                this.onDirectEventFilterChanged(
                                  this.getValue('directEvent', 'directEventsAllowed') === false ? null : false
                                )
                              }
                            >
                              {t('No')}
                            </Button>
                          </Button.Group>
                        </Form.Field>
                        <Form.Field>
                          <label>{t('Feedback')}</label>
                          <Button.Group className="filter-button-group">
                            <Button
                              basic
                              className={this.getValue('feedbacks', 'feedbacks') === true ? 'active' : ''}
                              onClick={() => this.onFeedbackFilterChanged(this.getValue('feedbacks', 'feedbacks') === true ? null : true)}
                            >
                              {t('Yes')}
                            </Button>
                            <Button
                              basic
                              className={this.getValue('feedbacks', 'feedbacks') === false ? 'active' : ''}
                              onClick={() => this.onFeedbackFilterChanged(this.getValue('feedbacks', 'feedbacks') === false ? null : false)}
                            >
                              {t('No')}
                            </Button>
                          </Button.Group>
                        </Form.Field>
                        <Form.Field>
                          <label>{t('Tests')}</label>
                          <Dropdown
                            search
                            selection
                            multiple
                            clearable
                            options={[
                              {
                                key: 'none',
                                text: t('None'),
                                value: 'none',
                                disabled: (activeFilters['tests'] || []).length !== 0 && !noneSelected
                              },
                              { key: 'preEventTest', text: t('Pre-event Test'), value: 'preEventTest', disabled: noneSelected },
                              { key: 'practicalTest', text: t('Practical Test'), value: 'practicalTest', disabled: noneSelected },
                              { key: 'postEventTest', text: t('Post-event Test'), value: 'postEventTest', disabled: noneSelected }
                            ]}
                            placeholder={t('Tests')}
                            onChange={this.onTestsFilterChanged}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>{t('Instructors')}</label>
                          <Dropdown
                            search
                            selection
                            clearable
                            options={[
                              { key: 'Yes', text: t('Yes'), value: 'Yes' },
                              { key: 'EventOnly', text: t('Event Only'), value: 'EventOnly' },
                              { key: 'No', text: t('No'), value: 'No' }
                            ]}
                            placeholder={t('Instructor')}
                            onChange={this.onInstructorFilterChanged}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>{t('Participants')}</label>
                          <Button.Group className="filter-button-group">
                            <Button
                              basic
                              className={this.getValue('participants', 'participants') === true ? 'active' : ''}
                              onClick={() =>
                                this.onParticipantsFilterChanged(this.getValue('participants', 'participants') === true ? null : true)
                              }
                            >
                              {t('Yes')}
                            </Button>
                            <Button
                              basic
                              className={this.getValue('participants', 'participants') === false ? 'active' : ''}
                              onClick={() =>
                                this.onParticipantsFilterChanged(this.getValue('participants', 'participants') === false ? null : false)
                              }
                            >
                              {t('No')}
                            </Button>
                          </Button.Group>
                        </Form.Field>

                        <Form.Field id="event-type-search-input">
                          <label>{t('Event Type')}</label>
                          <ClearableTimerInput icon="search" placeholder={t('Event Type')} onChange={this.onEventTypeFilterChanged} />
                        </Form.Field>
                      </>
                    )}
                  </Form.Group>
                </Form>
              </Grid.Row>
            </div>
            {result && !result.isSuccess && (
              <Grid.Row className="event-types-list-error-row">
                <Message
                  className="error-message__style"
                  icon="exclamation circle"
                  error
                  header={t('An error ocurred')}
                  list={result.messages.map(o => o.body)}
                />
              </Grid.Row>
            )}
            <Grid.Row className="event-types-list-items-row">
              <Dimmer active={isBusy} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)', position: 'fixed' }}>
                <Loader indeterminate>{t('Loading')}</Loader>
              </Dimmer>
              <Visibility style={{ width: '99%' }} onUpdate={this.onVisibilityUpdate}>
                {items
                  .map(x => x.item)
                  .map((item: EventTypeDto, idx) => (
                    <Grid
                      columns={4}
                      key={item.id + ' ' + idx}
                      className={`event-types-list-item ${!item.active ? 'inactive-event-type__item' : ''}`}
                    >
                      <Grid.Row className="row-0" verticalAlign="top">
                        {' '}
                        <Label circular size="mini" style={{ backgroundColor: item.eventsColor }}>
                          {' '}
                        </Label>{' '}
                        <Header as="h3">{item.name}</Header>
                      </Grid.Row>

                      <Icon className="event-workflow__item__icon" name={item.eventsIcon as SemanticICONS} style={{ color: '#474b4f' }} />

                      <Grid.Row className="row-1" verticalAlign="top">
                        <Grid.Column className="column-1">
                          <Grid.Row>
                            {item.feedbacks && (
                              <Label circular className="event-type-label label-1">
                                {t('Feedback')}
                              </Label>
                            )}
                            {['Yes', 'EventOnly'].includes(item.instructor) && (
                              <Label circular className="event-type-label label-1">
                                {t('Instructor')}
                              </Label>
                            )}
                            {item.practicalTest && (
                              <Label circular className="event-type-label label-1">
                                {t('Practical Test')}
                              </Label>
                            )}
                            {item.preEventTest && (
                              <Label circular className="event-type-label label-1">
                                {t('Pre-Event Test')}
                              </Label>
                            )}
                            {item.postEventTest && (
                              <Label circular className="event-type-label label-1">
                                {t('Post-Event Test')}
                              </Label>
                            )}
                            {item.participants && (
                              <Label circular className="event-type-label label-1">
                                {t('Employee')}
                              </Label>
                            )}
                            {(item.requestFieldGroups || []).includes(String(EventFieldGroups[EventFieldGroups.RequestDetails])) && (
                              <Label circular className="event-type-label label-2">
                                {t('Request Details')}
                              </Label>
                            )}
                            {(item.requestFieldGroups || []).includes(String(EventFieldGroups[EventFieldGroups.EventDetails])) && (
                              <Label circular className="event-type-label label-2">
                                {t('Event Details')}
                              </Label>
                            )}
                            {EventTypeCategory[item.eventTypeCategory] === EventTypeCategory.Extended &&
                              (item.requestFieldGroups || []).includes(String(EventFieldGroups[EventFieldGroups.SupportDetails])) && (
                                <Label circular className="event-type-label label-2">
                                  {t('Support Details')}
                                </Label>
                              )}
                          </Grid.Row>
                        </Grid.Column>
                        <Grid.Column className="central-column">
                          <Grid columns={4}>
                            {(item.rolesThatCreateRequests || []).length !== 0 && (
                              <Grid.Column
                                className={
                                  EventTypeCategory[item.eventTypeCategory] === EventTypeCategory.Extended ? 'column-6' : 'column-2'
                                }
                              >
                                <h5>{t('Request Creator')}</h5>
                                {item.rolesThatCreateRequests.map(x => (
                                  <Label key={`req-creator-${x}`} circular className="event-type-label label-3">
                                    {t(roleToTitleDict[x])}
                                  </Label>
                                ))}
                              </Grid.Column>
                            )}
                            {EventTypeCategory[item.eventTypeCategory] !== EventTypeCategory.Extended &&
                              !isNullOrWhiteSpaces(item.roleThatValidateRequests) && (
                                <Grid.Column className="column-3">
                                  <h5>{t('Request Validator')}</h5>
                                  <Label circular className="event-type-label label-3">
                                    {t(roleToTitleDict[item.roleThatValidateRequests])}
                                  </Label>
                                </Grid.Column>
                              )}
                            {EventTypeCategory[item.eventTypeCategory] === EventTypeCategory.Extended &&
                              (item.rolesThatValidateRequestsExtended || []).length > 0 && (
                                <Grid.Column className="column-6">
                                  <h5>{t('Request Validator')}</h5>
                                  {item.rolesThatValidateRequestsExtended.map(x => (
                                    <Label key={`req-validator-${x}`} circular className="event-type-label label-3">
                                      {t(roleToTitleDict[x])}
                                    </Label>
                                  ))}
                                </Grid.Column>
                              )}

                            {getEventOwners(item).length !== 0 && (
                              <Grid.Column
                                className={
                                  EventTypeCategory[item.eventTypeCategory] === EventTypeCategory.Extended ? 'column-6' : 'column-5'
                                }
                              >
                                <h5>{t('Event Owner')}</h5>
                                {getEventOwners(item).map(x => (
                                  <Label key={`req-owner-${x}`} circular className="event-type-label label-3">
                                    {t(roleToTitleDict[x])}
                                  </Label>
                                ))}
                              </Grid.Column>
                            )}

                            {EventTypeCategory[item.eventTypeCategory] !== EventTypeCategory.Extended &&
                              (item.rolesThatCreateDirectEvents || []).length !== 0 && (
                                <Grid.Column className="column-5">
                                  <h5>{t('Direct Event Creator')}</h5>
                                  {item.rolesThatCreateDirectEvents.map(x => (
                                    <Label key={`direct-event-creator-${x}`} circular className="event-type-label label-3">
                                      {t(roleToTitleDict[x])}
                                    </Label>
                                  ))}
                                </Grid.Column>
                              )}
                          </Grid>
                        </Grid.Column>
                        <Grid.Column className="column-6">
                          {((item.draft && item.draft.active) ||
                            (item.planned && item.planned.active) ||
                            (item.inProgress && item.inProgress.active) ||
                            (item.completed && item.completed.active) ||
                            (item.closed && item.closed.active) ||
                            (item.closed &&
                              item.closed.active &&
                              item.closed.rolesNotifiedPastTime &&
                              getProperties(item.closed.rolesNotifiedPastTime).length !== 0)) && <h5>{t('Workflow status')}</h5>}

                          {item.draft && item.draft.active && (
                            <Label circular className="event-type-label label-4">
                              {t('Draft')}
                            </Label>
                          )}
                          {item.planned && item.planned.active && (
                            <Label circular className="event-type-label label-4">
                              {t('Planned')}
                            </Label>
                          )}
                          {item.inProgress && item.inProgress.active && (
                            <Label circular className="event-type-label label-4">
                              {t('In Progress')}
                            </Label>
                          )}
                          {item.completed && item.completed.active && (
                            <Label circular className="event-type-label label-4">
                              {t('Completed')}
                            </Label>
                          )}
                          {item.closed && item.closed.active && (
                            <Label circular className="event-type-label label-4">
                              {t('Closed')}
                            </Label>
                          )}
                          {item.closed &&
                            item.closed.active &&
                            item.closed.rolesNotifiedPastTime &&
                            getProperties(item.closed.rolesNotifiedPastTime).length !== 0 && (
                              <Label circular className="event-type-label label-4">
                                {t('6 Months Reminder')}
                              </Label>
                            )}
                        </Grid.Column>
                        <Grid.Column className="column-7" textAlign="right" verticalAlign="middle">
                          {EventTypeCategory[item.eventTypeCategory] !== EventTypeCategory.Vacations && (
                            <Checkbox
                              disabled={EventTypeCategory[item.eventTypeCategory] === EventTypeCategory.Vacations}
                              toggle
                              defaultChecked={item.active}
                              onChange={(e, data) => this.toggleAvailability(e, data, item)}
                            />
                          )}
                          {EventTypeCategory[item.eventTypeCategory] !== EventTypeCategory.Vacations && (
                            <Popup
                              on="click"
                              position="bottom right"
                              open={actionMenuOpened === item.id}
                              size="tiny"
                              className="event-type-pop-up"
                              onClose={() => {
                                this.onActionMenuOpen(item);
                              }}
                              trigger={
                                <Icon
                                  style={{ fontSize: '15px', color: '#474b4f' }}
                                  name="ellipsis vertical"
                                  className={
                                    EventTypeCategory[item.eventTypeCategory] === EventTypeCategory.Vacations ? '' : 'clickable-icon'
                                  }
                                  disabled={EventTypeCategory[item.eventTypeCategory] === EventTypeCategory.Vacations}
                                  onClick={() => this.onActionMenuOpen(item)}
                                />
                              }
                            >
                              <Menu className="event-type-pop-menu" size="mini" vertical secondary>
                                <Menu.Item key="clone" position="left" onClick={() => this.onCloneItem(item)}>
                                  <Icon name="clone" style={{ color: '#474b4f' }} />
                                  &nbsp;{t('Clone')}
                                </Menu.Item>
                                <Menu.Item key="edit" onClick={() => this.onEditItem(item)}>
                                  <Icon name="edit" style={{ color: '#474b4f' }} />
                                  &nbsp;{t('Edit')}
                                </Menu.Item>
                              </Menu>
                            </Popup>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  ))}
              </Visibility>
            </Grid.Row>
          </Grid>
        </div>
        {newEventTypeModalShown && (
          <NewEventTypeModal onClose={this.onNewItemClosed} {...this.props} creationType={creationType} initialData={initialData} />
        )}
      </>
    );
  }
}

export default withTranslation()(EventTypesListPage);
