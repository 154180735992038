import { container } from 'inversify.config';
import { AbstractValidator } from 'fluent-ts-validator';
import { repository } from 'redux-scaffolding-ts';
import { LocationDto } from 'stores/configuration/locations/locations-store';

import i18n from '../../i18n';
import { DataStore, Query, QueryResult } from '../dataStore';
import { FormStore } from '../formStore';
import { RoleDto } from '../roles/roles-store';
import { BaseDto } from '../types';
import HttpService from 'services/http-service';
import { LanguageDto } from 'stores/configuration/locations/languages-store';
import { PillarDto } from 'stores/configuration/profiles/pillars-store';
import { DateTimeService } from 'services/datetime-service';

export interface InstructorDto extends BaseDto {
  userId: string;
  name: string;
  surname: string;
  instructorRoleId: string;
  instructorRole: RoleDto;
  locationId: string;
  location: LocationDto;
  languages: LanguageDto[];
  enabled: boolean;
  pillarId: string;
  pillar: PillarDto;
  isPowerInstructor: boolean;
}

export interface CreateInstructorDto {
  userId: string;
  name: string;
  surname: string;
  instructorRoleId: string;
  locationId: string;
  languages: LanguageDto[];
  pillarId: string;
}

export interface ChangeInstructorDto {
  id: string;
  userId: string;
  name: string;
  surname: string;
  instructorRoleId: string;
  locationId: string;
  languages: LanguageDto[];
  pillarId: string;
}
export interface InstructorHistoryDto extends BaseDto {
  id: string;
  pillar: PillarDto;
  instructorRole: RoleDto;
  userInstructor: InstructorDto;
  IsInstructor: boolean;
  fromDate: string;
  deleted: boolean;
}

export interface ChangeInstructorHistoryDto {
  historyId: string;
  fromDate: string;
}

export class CreateInstructorValidator extends AbstractValidator<CreateInstructorDto> {
  constructor() {
    super();

    this.validateIfString(o => o.userId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('An User must be selected'));

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('A Location must be selected'));

    this.validateIf(o => o.languages)
      .isNotEmpty()
      .withFailureMessage(i18n.t('At least one Language must be selected'));

    this.validateIfString(o => o.pillarId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('A Pillar must be selected'));

    this.validateIfString(o => o.instructorRoleId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t("An Instructor's Role must be selected"));
  }
}

export class ChangeInstructorValidator extends AbstractValidator<ChangeInstructorDto> {
  constructor() {
    super();

    this.validateIfString(o => o.userId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('An User must be selected'));

    this.validateIfString(o => o.locationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('A Location must be selected'));

    this.validateIf(o => o.languages)
      .isNotEmpty()
      .withFailureMessage(i18n.t('At least one Language must be selected'));

    this.validateIfString(o => o.pillarId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('A Pillar must be selected'));

    this.validateIfString(o => o.instructorRoleId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t("An Instructor's Role must be selected"));
  }
}

@repository('@@INSTRUCTORS', 'instructors.summary')
export class InstructorStore extends DataStore<InstructorDto> {
  baseUrl = 'identity/api';
  //baseUrl = 'https://localhost:44399/api';
  //baseUrl = 'https://localhost:5001/api';
  createPath = 'v1/create-instructor';
  retrievePath = 'v2/get-instructors';
  updatePath = 'v1/update-instructor';
  retrieveOnePath = 'v1/get-instructor';
  deletePath = '';

  protected validate(item: InstructorDto) {
    return new ChangeInstructorValidator().validate(item as any);
  }

  constructor() {
    super('INSTRUCTOR', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllInstuctorsAsync(query: Query, data?: any): Promise<QueryResult<InstructorDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<InstructorDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
    );
    return result.data;
  }

  public async getUserById(id: string): Promise<InstructorDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<InstructorDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }
}

@repository('@@INSTRUCTORS', 'instructors.new')
export class NewInstructorStore extends FormStore<CreateInstructorDto> {
  baseUrl = 'identity/api';
  //baseUrl = 'https://localhost:44399/api';
  // baseUrl = 'https://localhost:5001/api';
  createPath = 'v1/create-instructor';
  retrievePath = 'v2/get-instructors';
  updatePath = 'v1/update-instructor';
  deletePath = 'v1/delete-instructor';
  retrieveOnePath = 'v1/get-instructor';

  protected validate(item: CreateInstructorDto) {
    return new CreateInstructorValidator().validate(item);
  }

  public async getUserById(id: string): Promise<InstructorDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<InstructorDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  constructor() {
    super('NEW_INSTRUCTOR', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@INSTRUCTORS', 'instructors.change')
export class ChangeInstructorStore extends FormStore<ChangeInstructorDto> {
  baseUrl = 'identity/api';
  //baseUrl = 'https://localhost:5001/api';
  createPath = 'v1/create-instructor';
  retrievePath = 'v2/get-instructors';
  updatePath = 'v1/update-instructor';
  deletePath = 'v1/delete-instructor';
  retrieveOnePath = 'v1/get-instructor';

  protected validate(item: ChangeInstructorDto) {
    return new ChangeInstructorValidator().validate(item);
  }

  constructor() {
    super('CHANGE_INSTRUCTOR', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }

  public async getUserById(id: string): Promise<InstructorDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<InstructorDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }
}

@repository('@@INSTRUCTORS', 'instructors.history')
export class InstructorHistoryStore extends DataStore<InstructorHistoryDto> {
  baseUrl = 'identity/api';
  //seUrl = 'https://localhost:5001/api';
  createPath = ' ';
  retrievePath = 'v1/get-instructors-history';
  updatePath = '';
  retrieveOnePath = 'v1/get-instructor-history';
  deletePath = '';

  protected validate(item: InstructorHistoryDto) {
    return new ChangeInstructorHistoryValidator().validate(item as any);
  }

  constructor() {
    super('INSTRUCTOR_HISTORY', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllHistoryAsync(query: Query, data?: any): Promise<QueryResult<InstructorHistoryDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<InstructorHistoryDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
    );
    return result.data;
  }

  public async getHistoryByUserId(userId: string): Promise<InstructorHistoryDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.retrieveOnePath,
      httpService.get<InstructorHistoryDto>(`${this.baseUrl}/${this.retrieveOnePath}/${userId}`)
    );
    return result.data;
  }
}

export class ChangeInstructorHistoryValidator extends AbstractValidator<ChangeInstructorHistoryDto> {
  constructor() {
    super();

    this.validateIfString(o => o.historyId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('An User must be selected'));

    this.validateIf(x => x.fromDate)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('Date is required'));

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.isValid(x.fromDate))
      .withFailureMessage(i18n.t('Invalid Date'));
  }
}
@repository('@@INSTRUCTORS', 'instructors.history.change')
export class ChangeInstructorHistoryStore extends FormStore<ChangeInstructorHistoryDto> {
  baseUrl = 'identity/api';
  //baseUrl = 'https://localhost:5001/api';
  createPath = '';
  retrievePath = ' ';
  updatePath = 'v1/update-instructor-history';
  deletePath = ' ';
  retrieveOnePath = '';

  protected validate(item: ChangeInstructorHistoryDto) {
    return new ChangeInstructorHistoryValidator().validate(item);
  }

  constructor() {
    super('CHANGE_INSTRUCTOR_HISTORY', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
