import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Icon, Message, Grid } from 'semantic-ui-react';
import { RequestReasonDto, RequestReasonStore } from 'stores/configuration/events-n-requests/request-reasons-store';
import { ItemState, OrderDefinition, Query } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import NewRequestReasonView from './new-request-reason';
import ChangeRequestReasonView from './edit-request-reason';
import { EventTypesStore } from 'stores/configuration/events-workflow/event-types-store';

export interface RequestReasonsListProps extends WithTranslation, RouteComponentProps {
  requestReasons: RequestReasonStore;
  eventTypes: EventTypesStore;
}

export interface RequestReasonsListState {
  query: Query;
  newRequestReasonsShown: boolean;
  changeRequestReasonShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['requestReasons', RequestReasonStore], ['eventTypes', EventTypesStore])
class RequestReasonsListPage extends React.Component<RequestReasonsListProps, RequestReasonsListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newRequestReasonsShown: false,
      changeRequestReasonShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.requestReasons.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: RequestReasonDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.requestReasons.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: RequestReasonDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.requestReasons.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newRequestReasonsShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newRequestReasonsShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeRequestReasonShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeRequestReasonShown: false });
    if (isSuccess) this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          tooltipRenderer: false,
          renderer: data => data.name,
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(e, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<RequestReasonDto>('name'), `contains(${nameof<RequestReasonDto>('name')}, '${value}')`)}
              onClear={() => onClear(nameof<RequestReasonDto>('name'))}
              active={activeFilters.includes(nameof<RequestReasonDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<RequestReasonDto>('name'),
            useProfile: false
          }
        },
        {
          title: t('Event types'),
          tooltipRenderer: false,
          selectableHeader: true,
          renderer: data => (
            <>
              {' '}
              {data.eventTypes.map(({ name, originalEventTypeId }, idx) => (
                <React.Fragment key={originalEventTypeId}>
                  {idx !== 0 && <br />}
                  {name}
                </React.Fragment>
              ))}{' '}
            </>
          )
        }
      ],
      data: this.props.requestReasons.state
    } as TableModel<RequestReasonDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.requestReasons.state.result && !this.props.requestReasons.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.requestReasons.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newRequestReasonsShown && !this.state.changeRequestReasonShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add Request Reason')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newRequestReasonsShown && <NewRequestReasonView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeRequestReasonShown && (
            <ChangeRequestReasonView onClose={this.onEditItemClosed} currentRequestReason={this.state.selectedItem} {...this.props} />
          ))}
      </>
    );
  }
}

export default withTranslation()(RequestReasonsListPage);
