import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Header } from 'semantic-ui-react';
import { Query, OrderDefinition } from 'stores/dataStore';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber } from 'utils/useful-functions';
import { nameof } from 'utils/object';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { SsaTemplatesStore, SsaTemplateDto } from 'stores/assessments/templates/ssa-templates-store';

interface ChooseSsaTemplatesViewProps extends WithTranslation {
  onAddTemplate?: (template: SsaTemplateDto) => void;
  onCloseTemplateModal?: () => void;
  alreadySelectedTemplateId?: string;
  ssatemplateStore?: SsaTemplatesStore;
  profileId: string;
}

interface ChooseTemplatesFilters {
  templateTitleOrId: string;
}

interface ChooseSsaTemplatesViewState {
  activeFilters: string[];
  query: Query;
  selectedTemplate: SsaTemplateDto;
  filters: ChooseTemplatesFilters;
}

@connect(['ssatemplateStore', SsaTemplatesStore])
class ChooseSsaTemplatesView extends React.Component<ChooseSsaTemplatesViewProps, ChooseSsaTemplatesViewState> {
  state: ChooseSsaTemplatesViewState = {
    query: {
      searchQuery: ``,
      orderBy: [],
      filter: [],
      skip: 0,
      take: 10
    },
    activeFilters: [],
    selectedTemplate: undefined,
    filters: {
      templateTitleOrId: undefined
    }
  };

  componentDidMount() {
    this.refreshTable(this.state.filters);
  }

  componentDidUpdate() {
    if (!this.state.selectedTemplate) {
      const item = (this.props.ssatemplateStore.state.items || []).firstOrDefault();
      if (item && item.item) this.setState({ selectedTemplate: item.item as any });
    }
  }

  private handleOnSelectedTemplate = (items: unknown[]) => {
    if (items && items.length > 0) this.setState({ selectedTemplate: items[0] as any });
  };

  private handleOnAddTemplate = () => {
    const { selectedTemplate } = this.state;
    if (selectedTemplate == null) return;

    this.props.onAddTemplate && this.props.onAddTemplate(selectedTemplate);
    this.props.onCloseTemplateModal();
  };

  private handleOnCancel = () => {
    this.props.onCloseTemplateModal();
  };

  private load = () => {
    this.props.ssatemplateStore.getAllAsync(this.state.query);
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  };

  private handleFilterChange = (filters, oDatafilters) => {
    const parameters = {};
    let query = { ...this.state.query, filter: oDatafilters, skip: 0, parameters };
    this.setState({ filters, query }, this.load);
  };

  private handleFilterByTemplateTitleOrId(value: string) {
    let filters = { ...this.state.filters };
    filters.templateTitleOrId = value;
    this.refreshTable(filters);
  }

  private refreshTable(filters: ChooseTemplatesFilters) {
    const oDataFilters = this.buildODataFilter(filters);
    this.handleFilterChange(filters, oDataFilters);
  }

  private buildODataFilter(filters?: ChooseTemplatesFilters) {
    const oDataFilters = [];

    if (!isNullOrWhiteSpaces(this.props.alreadySelectedTemplateId)) {
      oDataFilters.push({ Id: { ne: { value: this.props.alreadySelectedTemplateId, type: 'guid' } } });
    }

    if (!isNullOrWhiteSpaces(this.props.profileId)) {
      oDataFilters.push({ profileItemId: { eq: { type: 'guid', value: this.props.profileId } } });
    }

    if (!isNullOrWhiteSpaces(filters.templateTitleOrId)) {
      const input = filters.templateTitleOrId;
      const parts = [`contains(tolower(title), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'TS');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('TS')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
        }
      }

      const f = `(${parts.join(' or ')})`;
      oDataFilters.push(f);
    }

    oDataFilters.push('isActive eq true');

    return oDataFilters;
  }

  public render() {
    const { t } = this.props as any;
    const { selectedTemplate } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Template ID'),
          tooltipRenderer: true,
          renderer: data => data.friendlyId,
          sortDefinition: {
            field: nameof<SsaTemplateDto>('friendlyId'),
            useProfile: false
          },
          selectableHeader: true
        },
        {
          title: t('Template'),
          tooltipRenderer: false,
          renderer: data => data.title
        }
      ],
      data: this.props.ssatemplateStore.state
    } as TableModel<SsaTemplateDto>;

    return (
      <Modal
        size="large"
        className="template-search__modal"
        open
        closeOnEscape={true}
        onClose={this.handleOnCancel}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="borderless-header">
          <Header as="h2" className="modal-header-title">
            {t('Select Template')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="choose-template-search__first-row__column-filters">
            <ClearableTimerInput
              icon="search"
              placeholder={t('Search in Template or ID')}
              onChange={(event, data) => this.handleFilterByTemplateTitleOrId(data.value)}
            />
          </div>

          <Container className="template-list-table">
            <TableView
              model={tableModel}
              selectable={true}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              hiddeMenu={true}
              onSelectionChange={this.handleOnSelectedTemplate}
              onPageChange={this.handlePageChange}
              maxSelection={1}
              selectionType={'checkbox'}
              unselectFirstRow
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.handleOnCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" disabled={selectedTemplate == null} onClick={this.handleOnAddTemplate} positive>
            {t('Select Template')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(ChooseSsaTemplatesView);
