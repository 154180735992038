import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';

import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify.config';
import { Modal, Button, Icon, Tab, Label, Input, Dimmer, Loader } from 'semantic-ui-react';

import EventsChat from '../chat/events-chat';
import { RouteComponentProps } from 'react-router';
import { decamelCase, isPocOrFactoryLeadInExtendedWf } from '../../../../utils/event-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import '../../shared/events-and-requests/events-requests-form.less';
import EventFomMenuTab from './tabs/event-form-menu-tab';
import { EventFormStore, EventFormViewModel } from 'stores/events/event-form-store';
import ActionButtonsComponent, { EventFormActions } from './buttons/action-buttons';
import { EventChatStore } from 'stores/events/events-chat-store';
import { FormPracticalListStore } from 'stores/events/practical-form-store';
import { FormTheoreticalListStore } from 'stores/events/theoretical-form-store';
import { isUserEventOwner, isCurrentUserEventInstructor } from 'utils/event-utils';
import { DateTimeService } from 'services/datetime-service';
import { InstructorExpertiseStore } from 'stores/skills/instructor-expertise-store';
import { EventsStore } from 'stores/events/events-store';

export type EventFormMode = 'ViewDetails' | 'Merge' | 'Preview';

interface EventFormViewProps extends WithTranslation {
  onClose?: (actionPerformed?: EventFormActions, payload?: any) => void;
  eventFormStore?: EventFormStore;
  eventChatStore?: EventChatStore;
  theoreticalStore?: FormTheoreticalListStore;
  practicalStore?: FormPracticalListStore;
  event: EventFormViewModel;
  route?: RouteComponentProps;
  mode: EventFormMode;
  readOnly?: boolean;
  instructorExpertiseStore?: InstructorExpertiseStore;
  eventsStore?: EventsStore;
}

export interface EventFormState {
  readOnly: boolean;
  isOnlySupportDetailsEnabled: boolean;
  loadChat: boolean;
  primaryChatSelected: boolean;
  studentsChatSelected: boolean;
  loading: boolean;
  additionalTabs?: AdditionalTabs;
  saveDone: boolean;
}

export interface AdditionalTabs {
  practical: boolean;
  theoretical: boolean;
}

@connect(
  ['eventFormStore', EventFormStore],
  ['eventChatStore', EventChatStore],
  ['instructorExpertiseStore', InstructorExpertiseStore],
  ['eventsStore', EventsStore],
  ['theoreticalStore', FormTheoreticalListStore],
  ['practicalStore', FormPracticalListStore]
)
class EventForm extends Component<EventFormViewProps, EventFormState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: Readonly<EventFormViewProps>) {
    super(props);
    const { eventFormStore, readOnly, event, mode } = this.props;
    this.state = {
      readOnly: readOnly != null ? readOnly : mode === 'ViewDetails',
      isOnlySupportDetailsEnabled: false,
      loadChat: false,
      primaryChatSelected: this.initializeVisiblePrimaryChat(),
      studentsChatSelected: this.initializeVisibleStudentsChat(),
      loading: false,
      additionalTabs: { practical: false, theoretical: false },
      saveDone: false
    };
    const startDate = DateTimeService.toString(event.startDate);
    const endDate = DateTimeService.toString(event.endDate);
    eventFormStore.change({ ...event, startDate, endDate });
  }

  componentDidMount = () => {
    //TODO getById here , buildEventViewModel() with nullables
    const myBoundMethod = function() {
      this.renderAdditionalTabs();
    }.bind(this);
    setTimeout(myBoundMethod, 0.5 * 1000);
  };

  componentWillUnmount() {
    this.props.eventFormStore.reset();
    this.props.eventChatStore.reset();
  }

  private handleSupportDetailsTab = (readonly: boolean) => {
    return isPocOrFactoryLeadInExtendedWf(this.props.event) && readonly;
  };

  private handleOnActionPerformed = (actionName: EventFormActions, payload: any = null) => {
    if (actionName === 'edit') {
      this.setState({ readOnly: false, isOnlySupportDetailsEnabled: this.handleSupportDetailsTab(true), loadChat: false });
    } else if (actionName === 'save') {
      this.setState({ readOnly: true, isOnlySupportDetailsEnabled: this.handleSupportDetailsTab(false), saveDone: true, loadChat: true });
    } else if (actionName === 'requestChanges') this.setState({ loadChat: true });
    else {
      const action = this.replaceActionOnClose(actionName);
      this.closeWindowModal(action, payload);
    }
  };

  private closeWindowModal = (actionName?: EventFormActions, payload: any = null) => {
    const action = this.replaceActionOnClose(actionName);
    if (this.props.onClose) this.props.onClose(action, payload);
    if (this.props.route) this.props.route.history.replace('/events');
  };

  private replaceActionOnClose = (actionName?: EventFormActions): EventFormActions => {
    let action: EventFormActions = actionName;
    if (action === 'close' && this.state.saveDone) {
      action = 'save';
    }
    return action;
  };
  private handleOnValueChanged = (property: keyof EventFormViewModel, value: any) => {
    const change: Partial<EventFormViewModel> = {};
    change[property as string] = value;
    this.props.eventFormStore.change(change);
  };

  private getPrimaryChannel = (): string => 'Channel1';

  private getStudentsChannel = (): string => 'Channel2';

  private initializeVisiblePrimaryChat(): boolean {
    if (!this.identityService.isInRole(Roles.Employee)) return true;
    else return false;
  }

  private initializeVisibleStudentsChat(): boolean {
    if (this.identityService.isInRole(Roles.Employee)) return true;
    else return false;
  }

  private isPrimaryChatVisible(): boolean {
    return !this.identityService.isInRole(Roles.Employee);
  }

  private isStudentsChatVisible(): boolean {
    const event = this.props.eventFormStore.state.item;
    if (event.studentsAssigned == null) return false;
    else return event.status.toString() === 'InProgress' || event.status.toString() === 'Completed' || event.status.toString() === 'Closed';
  }

  private currentUserCanSendChatMessage(): boolean {
    const event = this.props.eventFormStore.state.item;

    if (
      event.status.toString() === 'Closed' ||
      this.identityService.isInRole(Roles.Reporting) ||
      this.identityService.isInRole(Roles.GlobalEngineering) ||
      this.identityService.isInRole(Roles.GlobalManufacturing) ||
      this.identityService.isInRole(Roles.RegionalManufacturingVP)
    )
      return false;
    else
      return (
        this.identityService.getUserInfo().activeRole !== 'Instructor' ||
        (event.hasInstructors && (event.instructors || []).any(x => x.user && x.user.id === this.identityService.userId))
      );
  }

  renderAdditionalTabs = async () => {
    const { status, id } = this.props.eventFormStore.state.item;
    const isInProgress = (status || '').toString() === 'InProgress';
    const isCompleted = (status || '').toString() === 'Completed';
    const isClosed = (status || '').toString() === 'Closed';

    const isEmployee = this.identityService.isInRole(Roles.Employee);

    const isEOInstructorAdminPoc = this.isCurrentUserEventOwnerOrInstructorOrAdmin();

    if (!this.isTheoreticalTabInVisible() && (isEOInstructorAdminPoc || (!isEOInstructorAdminPoc && isEmployee)))
      this.setTheoreticalTab(id, status.toString());

    if ((isInProgress || isCompleted || isClosed) && (isEOInstructorAdminPoc || (!isEOInstructorAdminPoc && isEmployee)))
      this.setPracticalTab(id, status.toString());
  };

  private isCurrentUserEventOwnerOrInstructorOrAdmin = (): boolean => {
    const { item } = this.props.eventFormStore.state;
    return (
      isUserEventOwner(item) ||
      (this.identityService.isInRole(Roles.Instructor) && isCurrentUserEventInstructor(item)) ||
      this.identityService.isInRole(Roles.Admin) ||
      this.identityService.isInRole(Roles.PoC)
    );
  };

  setTheoreticalTab = (id: string, status: string) => {
    const { theoreticalStore } = this.props;

    this.setState({ loading: true });
    theoreticalStore.getTemplatesByEventId(id, status, false).then(resp => {
      try {
        const templates = resp;
        const hasItems = (templates?.data?.items || []).length > 0;

        if (hasItems) this.setState(({ additionalTabs }) => ({ additionalTabs: { ...additionalTabs, theoretical: true } }));
      } catch (error) {
        console.error(error);
      }
    });
    this.setState({ loading: false });
  };

  setPracticalTab = (id: string, status: string) => {
    const { practicalStore } = this.props;

    this.setState({ loading: true });

    practicalStore.getTemplatesByEventId(id, status, false).then(resp => {
      try {
        const templates = resp;

        const hasItems = (templates?.data?.items || []).length > 0;

        if (hasItems) this.setState(({ additionalTabs }) => ({ additionalTabs: { ...additionalTabs, practical: true } }));
      } catch (error) {
        console.error(error);
      }
    });
    this.setState({ loading: false });
  };

  private isTheoreticalTabInVisible = () => {
    const { status, trainingDetails, hasStudents, students, eventType } = this.props.eventFormStore.state.item;

    const eventStatus = status.toString();

    if (
      (eventStatus === 'InProgress' && eventType.preEventTest) ||
      (eventStatus === 'Completed' && eventType.postEventTest) ||
      eventStatus === 'Closed'
    ) {
      return !trainingDetails || !hasStudents || students?.length <= 0;
    } else return true;
  };

  public loadingHandler = (loading: boolean) => this.setState({ loading });

  public render() {
    const { mode, eventFormStore, t, eventsStore, instructorExpertiseStore: iES } = this.props;
    const { readOnly, loadChat, isOnlySupportDetailsEnabled, loading, additionalTabs } = this.state;
    const { item: event, isBusy } = eventFormStore.state;
    const chatPanes = [];
    const isLoading = isBusy || loading || eventsStore.state.isBusy || iES.state.isBusy;

    this.isPrimaryChatVisible() &&
      chatPanes.push({
        menuItem: (
          <Button className="chat-container-btn" icon key="chat-btn-primary">
            <div className="chat-div__users-icon-btn">
              <FontAwesomeIcon className="chat-div__users-icon" icon={faUserFriends} />
            </div>
          </Button>
        ),

        pane: (
          <Tab.Pane attached active className="tab-panes-chat " key="tab-panes-chat-primary">
            <EventsChat
              initialChannel={this.getPrimaryChannel()}
              initialId={event.id}
              forceReload={loadChat}
              readonly={!this.currentUserCanSendChatMessage()}
            />
          </Tab.Pane>
        )
      });

    this.isStudentsChatVisible() &&
      chatPanes.push({
        menuItem: (
          <Button className="chat-container-btn" icon key="chat-btn-student">
            <div className="chat-div__users-icon-btn-students">
              <FontAwesomeIcon className="chat-div__users-icon-students" icon={faUserFriends} />
            </div>
          </Button>
        ),

        pane: (
          <Tab.Pane attached className="tab-panes-chat" key="tab-panes-chat-student">
            <EventsChat
              initialChannel={this.getStudentsChannel()}
              initialId={event.id}
              forceReload={loadChat}
              readonly={!this.currentUserCanSendChatMessage()}
            />
          </Tab.Pane>
        )
      });

    const modalClass = 'planit-generic-form__modal events-form__modal';
    return (
      <Modal
        className={modalClass}
        open
        closeOnEscape={true}
        onClose={() => {
          this.closeWindowModal('close');
        }}
        closeOnDimmerClick={false}
      >
        <Dimmer active={isLoading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading event form')}</Loader>
        </Dimmer>
        <div className="form__modal__left-container">
          <div className="form__modal__close-menu-left-container">
            <div className="form__header-top-container">
              <Button
                className="form__modal__close-btn"
                icon
                onClick={() => {
                  this.closeWindowModal('close');
                }}
              >
                <Icon name="times" />
              </Button>

              <div className="form__header-title-status">
                <div className="flex-start-center">
                  {event.friendlyId && (
                    <Input className="form__header__title-input__friendlyId" readOnly value={`${event.friendlyId}   - `} />
                  )}
                  <Input
                    className="form__header__title-input"
                    readOnly={readOnly}
                    value={event.title}
                    onChange={(_, { value }) => this.handleOnValueChanged('title', value)}
                  />
                </div>

                <Label floated="right" className="form__header__status-tag" circular compact="true">
                  {decamelCase(event.status.toString())}
                </Label>
              </div>
            </div>

            <EventFomMenuTab
              toLoadingState={this.loadingHandler}
              additionalTabs={additionalTabs}
              mode={mode}
              readOnly={readOnly}
              isOnlySupportDetailsEnabled={isOnlySupportDetailsEnabled}
            />

            <Modal.Actions className="form__action-buttons-wrapper">
              <ActionButtonsComponent mode={mode} readOnly={readOnly} onActionPerformed={this.handleOnActionPerformed} />
            </Modal.Actions>
          </div>
        </div>

        {mode === 'ViewDetails' && (
          <div className="form__modal__right-container__chat">
            <Tab panes={chatPanes} renderActiveOnly={false} menu={{ attached: true, tabular: true }} />
          </div>
        )}
      </Modal>
    );
  }
}

export default withTranslation()(EventForm);
