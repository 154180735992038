import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Modal, Icon, Header, Popup } from 'semantic-ui-react';
import { nameof } from 'utils/object';
import { Query, OrderDefinition, ItemReference } from 'stores/dataStore';
import { UserStore, UserDto } from 'stores/users/users-store';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import LocationEditor from 'widgets/bussiness/location-editor';
import { openInNewWindow, isNullOrWhiteSpaces } from 'utils/useful-functions';
import { GetLocationNameAndCountry } from 'utils/userinfo-functions';
import PositionCodeEditor from 'widgets/bussiness/position-code-editor';
import { PositionCodeDto } from 'stores/configuration/profiles/position-codes-store';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';

interface ChooseStudentsViewProps extends WithTranslation {
  onAddParticipants?: (students: UserDto[]) => void;
  onCloseUsersModal?: () => void;
  alreadySelectedUserIds?: string[];
  usersStore?: UserStore;
  locationIds?: string[];
  positionCodeIds?: string[];
  maxSelection?: number;
  addButtonText?: string;
  selectionRequired?: boolean;
  showSFPositionFields?: boolean;
  showPositionCodeFields?: boolean;
}

interface ChooseStudentsFilters {
  id: string;
  firstNameOrLastName: string;
  locationIds: string[];
  sfPosition: string;
  positionCode: string;
}

interface ChooseStudentsViewState {
  activeFilters: string[];
  query: Query;
  selectedUsers: UserDto[];
  filters: ChooseStudentsFilters;
}

@connect(['usersStore', UserStore])
class ChooseStudentsView extends Component<ChooseStudentsViewProps, ChooseStudentsViewState> {
  constructor(props: ChooseStudentsViewProps) {
    super(props);

    this.state = {
      query: {
        searchQuery: ``,
        orderBy: [],
        filter: this.buildODataFilter().map(x => x.filter),
        skip: 0,
        take: 10
      },
      activeFilters: [],
      selectedUsers: [],
      filters: {
        id: '',
        firstNameOrLastName: '',
        locationIds: this.props.locationIds,
        sfPosition: '',
        positionCode: ''
      }
    };
    this.load();
  }

  private onAddParticipants = () => {
    this.props.onAddParticipants(this.state.selectedUsers);
    this.props.onCloseUsersModal();
  };

  private onCancel = () => {
    this.props.onCloseUsersModal();
  };

  private load = () => {
    this.props.usersStore.getUsersByRoleAndLocationsAndOData(this.state.query, this.state.filters.locationIds, 'Employee');
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) });
    this.load();
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  };

  private handleFilterByUserId = (value: string) =>
    this.setState(
      ({ filters: { id, ...rest } }) => ({
        filters: { ...rest, id: value }
      }),
      () => this.refreshTable(this.state.filters)
    );

  private handleFilterByName = (value: string) =>
    this.setState(
      ({ filters: { firstNameOrLastName, ...rest } }) => ({ filters: { ...rest, firstNameOrLastName: value } }),
      () => this.refreshTable(this.state.filters)
    );

  private handleFilterSFPosition = (value: string) =>
    this.setState(
      ({ filters: { sfPosition, ...rest } }) => ({ filters: { ...rest, sfPosition: value } }),
      () => this.refreshTable(this.state.filters)
    );

  private handleFilterByLocation = (location: ItemReference) =>
    this.setState(
      ({ filters: { locationIds, ...rest } }, { locationIds: propsLocationIds }) => ({
        filters: { ...rest, locationIds: location?.id ? [location.id] : propsLocationIds }
      }),
      () => this.refreshTable(this.state.filters)
    );

  private refreshTable(filters: ChooseStudentsFilters) {
    const tableFilter = this.buildODataFilter(filters);
    this.handleFilterChange(tableFilter);
  }

  private buildODataFilter(filters?: ChooseStudentsFilters) {
    const oDataFilters = [];

    oDataFilters.push({ id: nameof<UserDto>('enabled'), filter: { enabled: true } });

    if (this.props.alreadySelectedUserIds && this.props.alreadySelectedUserIds.length > 0)
      oDataFilters.push({
        id: nameof<UserDto>('id'),
        filter: `not(${nameof<UserDto>('id')} in (${this.props.alreadySelectedUserIds.join(',')}))`
      });

    if (this.props.positionCodeIds) {
      oDataFilters.push({
        id: nameof<UserDto>('positionCodeId'),
        filter: `${nameof<UserDto>('positionCodeId')} in (${this.props.positionCodeIds.join(',')})`
      });
    }

    if (!filters) return oDataFilters;

    if (filters?.sfPosition)
      oDataFilters.push({
        id: nameof<UserDto>('sfPosition'),
        filter: `contains(toLower(sfPosition),'${filters.sfPosition}')`
      });

    if (filters?.positionCode)
      oDataFilters.push({
        id: nameof<UserDto>('positionCodeId'),
        filter: `${nameof<UserDto>('positionCodeId')} eq (${filters.positionCode})`
      });

    if (filters.firstNameOrLastName) {
      let words = filters.firstNameOrLastName
        .split(' ')
        .map(x => x.trim())
        .filter(x => !isNullOrWhiteSpaces(x));
      if (words.length > 1) {
        oDataFilters.push({
          id: nameof<UserDto>('firstName'),
          filter: this.buildNameComplexFilter(words)
        });
      } else {
        oDataFilters.push({
          id: nameof<UserDto>('firstName'),
          filter: `(contains(tolower(surname),'${filters.firstNameOrLastName.toLocaleLowerCase()}') or contains(tolower(name),'${filters.firstNameOrLastName.toLocaleLowerCase()}'))`
        });
      }
    }
    if (filters.id)
      oDataFilters.push({
        id: nameof<UserDto>('employeeId'),
        filter: `startsWith(tolower(${nameof<UserDto>('employeeId')}),'${filters.id.toLocaleLowerCase()}')`
      });

    return oDataFilters;
  }

  private onStudentClicked(id: string) {
    openInNewWindow(`./employee-page/${id}`);
  }

  private buildNameComplexFilter(words: string[]): string {
    let complexFilters: string[] = [];
    words.forEach(word => {
      let complexFilter = `((contains(tolower(surname),'${word.toLocaleLowerCase()}') or contains(tolower(name),'${word.toLocaleLowerCase()}')))`;
      complexFilters.push(complexFilter);
    });
    let joinFilter = complexFilters.join(' and ');
    let completeFilter = `( ${joinFilter} )`;
    return completeFilter;
  }

  private handlePositionCode = (pc: PositionCodeDto) => {
    console.log({ pc });
    this.setState(
      ({ filters: { positionCode, ...rest } }) => ({ filters: { ...rest, positionCode: pc?.id || '' } }),
      () => this.refreshTable(this.state.filters)
    );
  };

  selectedUsers(items: unknown[]): void {
    this.setState({ selectedUsers: items as any });
  }

  renderSelectedIcon(isSelected: boolean) {
    if (!isSelected) return <></>;

    return <Icon name="check" color="black" />;
  }

  public render() {
    const { t, showSFPositionFields, selectionRequired, showPositionCodeFields, usersStore, maxSelection, ...rest } = this.props;
    const { locationIds, addButtonText } = rest;
    const { selectedUsers, filters } = this.state;
    let btnDisabled = false;
    if (!!selectionRequired) btnDisabled = (selectedUsers || []).length === 0;

    const tableModel: TableModel<UserDto> = {
      columns: [
        { title: t('SAP ID'), tooltipRenderer: true, renderer: data => data.employeeId },
        { title: t('Name'), tooltipRenderer: true, renderer: data => data.firstName },
        { title: t('Surname'), tooltipRenderer: true, renderer: data => data.lastName },
        { title: t('Location'), tooltipRenderer: true, renderer: data => <span>{GetLocationNameAndCountry(data)}</span> },
        ...(showSFPositionFields
          ? [{ title: t('SF Position'), tooltipRenderer: true, renderer: ({ sfPosition }: UserDto) => sfPosition }]
          : []),
        ...(showPositionCodeFields
          ? [
              {
                title: t('Position Code'),
                tooltipRenderer: true,
                renderer: ({ positionCode }: UserDto) => positionCode?.code || ''
              }
            ]
          : []),
        {
          title: t(''),
          renderer: data => (
            <div>
              <Icon.Group>
                <Popup
                  trigger={
                    <Icon
                      className="clickable-icon"
                      name="eye"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.onStudentClicked(data.id);
                      }}
                    />
                  }
                  position="top right"
                  content={t('Open Employee Card')}
                />
              </Icon.Group>
            </div>
          )
        }
      ],
      data: usersStore.state
    };

    return (
      <Modal
        size="large"
        className="employee-search__modal search__modal"
        open
        closeOnEscape={true}
        onClose={this.onCancel}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="search__modal__header">
          <Header as="h2" className="modal-header-title">
            {t('Employee Search')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <div className="flex-start-center flex-wrap">
            <ClearableTimerInput
              className="employee-search-filter"
              icon="search"
              placeholder={t('User ID')}
              onChange={(_, { value }) => this.handleFilterByUserId(value)}
            />

            <ClearableTimerInput
              className="employee-search-filter"
              icon="search"
              placeholder={t('Surname, Name')}
              onChange={(_, { value }) => this.handleFilterByName(value)}
            />

            {showSFPositionFields && (
              <ClearableTimerInput
                className="employee-search-filter"
                icon="search"
                placeholder={t('SF Position')}
                onChange={(_, { value }) => this.handleFilterSFPosition(value)}
              />
            )}
            {showPositionCodeFields && (
              <PositionCodeEditor
                className="user-dropdown employee-search-filter"
                value={filters.positionCode}
                onChange={this.handlePositionCode}
                clearable
                nullable
                placeholder={t('Position Code')}
              />
            )}
            <LocationEditor
              className="user-dropdown employee-search-filter"
              nullable
              clearable
              locationsReceived={locationIds}
              value={null}
              placeholder={t('Location')}
              onChange={location => this.handleFilterByLocation(location)}
            />
          </div>

          <div className="student-list-table">
            <TableView
              hiddeMenu
              model={tableModel}
              selectable={true}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onSelectionChange={users => this.selectedUsers(users)}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              maxSelection={maxSelection}
            />
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button disabled={btnDisabled} className="add-participants-button" onClick={this.onAddParticipants} positive>
            {addButtonText || t('Add Participants')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(ChooseStudentsView);
