import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Query, OrderDefinition, ItemState } from 'stores/dataStore';
import React from 'react';
import * as autobind from 'autobind';
import { CheckListStore, CheckListDto } from 'stores/configuration/events-n-requests/checklists-store';
import { connect } from 'redux-scaffolding-ts';
import { CommandResult } from 'stores/types';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { Message, Icon, Grid } from 'semantic-ui-react';
import NewCheckListView from './new-checklist';
import ChangeCheckListView from './edit-checklist';
import { DataStoreFilter } from 'widgets/collections/table-filters/data-store-filter';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { DropDownRolesStore } from 'stores/roles/roles-store';
import { EventTypesStore, EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import { GetRoleName } from 'utils/userinfo-functions';
import { OptionsFilter } from 'widgets/collections/table-filters/option-filter';

export interface CheckListProps extends WithTranslation, RouteComponentProps {
  checklists: CheckListStore;
  dropdownroles: DropDownRolesStore;
  eventTypes: EventTypesStore;
}

export interface CheckListState {
  query: Query;
  newCheckListShown: boolean;
  changeCheckListShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['checklists', CheckListStore], ['dropdownroles', DropDownRolesStore], ['eventTypes', EventTypesStore])
class CheckListPage extends React.Component<CheckListProps, CheckListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'modifiedOn', useProfile: false }], skip: 0, take: 10 },
      newCheckListShown: false,
      changeCheckListShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.checklists.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: CheckListDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.checklists.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: CheckListDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.checklists.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newCheckListShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newCheckListShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeCheckListShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeCheckListShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private getRoles = (data: string[]) => {
    const content = (data || []).map(role => GetRoleName(role)).join(' ');

    return content;
  };

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Event Type'),
          tooltipRenderer: true,
          newClassFromComponent: data => 'table__more-text-long',
          renderer: data => data.eventTypeName,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<EventTypeDto>
              filterTitle={t('Filter by Event Type')}
              triggerTitle={title}
              onFilter={(value: string) =>
                onFilter('EventType/OriginalEventTypeId', { 'EventType/OriginalEventTypeId': { eq: { type: 'guid', value } } })
              }
              onClear={() => onClear(nameof<CheckListDto>('eventTypeId'))}
              active={activeFilters.includes(nameof<CheckListDto>('eventTypeId'))}
              getItems={q => this.props.eventTypes.getAllAsync(q)}
              parameters="id,name,originalEventTypeId"
              orderBy={[{ direction: 'Ascending', field: nameof<EventTypeDto>('name'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(name)': { startswith: search.toLowerCase() } })}
              valueSelector={(u: EventTypeDto) => u.originalEventTypeId}
              titleSelector={(u: EventTypeDto) => u.name}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<CheckListDto>('eventTypeId'),
            useProfile: false,
            active: 'Ascending'
          }
        },
        {
          title: t('Description'),
          tooltipRenderer: true,
          renderer: data => data.description,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by description')}
              triggerTitle={title}
              onFilter={value => onFilter('description', { description: { contains: value } })}
              onClear={() => onClear(nameof<CheckListDto>('description'))}
              active={activeFilters.includes(nameof<CheckListDto>('description'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<CheckListDto>('description'),
            useProfile: false
          }
        },

        {
          title: t('Roles'),
          tooltipRenderer: true,
          renderer: data => this.getRoles(data.rolesThatCreateCheckLists),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <OptionsFilter
              filterTitle={t('Filter by Role')}
              options={[
                { text: 'Employee', value: 'Employee' },
                { text: 'Planner TFT', value: 'Planner' },
                { text: 'PoC', value: 'PoC' },
                { text: 'Instructor', value: 'Instructor' },
                { text: 'Planner MTC', value: 'PlannerMTC' }
              ]}
              triggerTitle={title}
              onFilter={(value: string) =>
                onFilter(
                  nameof<CheckListDto>('rolesThatCreateCheckLists'),
                  `CheckListConfig/RolesThatCreateCheckLists/any(d: d eq '` + value + `' )`
                )
              }
              onClear={() => onClear(nameof<CheckListDto>('rolesThatCreateCheckLists'))}
              active={activeFilters.includes(nameof<CheckListDto>('rolesThatCreateCheckLists'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        }
      ],
      data: this.props.checklists.state
    } as TableModel<CheckListDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.checklists.state.result && !this.props.checklists.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.checklists.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newCheckListShown && !this.state.changeCheckListShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={true}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={true}
              createNewButtonTitle={t('Add CheckList')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newCheckListShown && <NewCheckListView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeCheckListShown && (
            <ChangeCheckListView onClose={this.onEditItemClosed} currentCheckList={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(CheckListPage);
