import * as autobind from 'autobind';
import React, { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import { Button, Modal, Header } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RequestReasonStore, RequestReasonDto } from 'stores/configuration/events-n-requests/request-reasons-store';
import { nameof } from 'utils/object';
import { QueryResult, ItemReference } from 'stores/dataStore';
import { TableModel, TableView } from 'widgets/collections/table';

export interface ReasonLinkedEventsProps extends WithTranslation {
  onAdddRequestReason?: (requestReason: RequestReasonDto) => void;
  onCloseUsersModal?: () => void;
  requestReasons?: RequestReasonStore;
  selectedRequestReasonId?: string;
}

interface ReasonLinkedEventsState {
  query?: (searchQuery: string) => Promise<QueryResult<ItemReference>>;
  selectedRequestReason: RequestReasonDto;
}

@connect(['requestReasons', RequestReasonStore])
class ReasonLinkedEventsPopup extends Component<ReasonLinkedEventsProps, ReasonLinkedEventsState> {
  private get requestReasonsStore() {
    return this.props.requestReasons;
  }

  private onCancel = () => {
    this.props.onCloseUsersModal();
  };

  constructor(props: ReasonLinkedEventsProps) {
    super(props);

    this.state = {
      query: this.getRequestReasons(),
      selectedRequestReason: undefined
    };
  }

  @autobind
  private getRequestReasons() {
    const method = async (search: string) => {
      const filter = [];

      const result = await this.requestReasonsStore.getAllAsync({
        searchQuery: '',
        skip: 0,
        take: 100000,
        orderBy: [{ direction: 'Ascending', field: nameof<RequestReasonDto>('name'), useProfile: false }],
        filter
      });

      return Object.assign({}, result, { items: result.items.map(c => ({ id: c.id, title: c.name })) }) as QueryResult<ItemReference>;
    };
    return method;
  }

  GetEventTypes(requestReason: RequestReasonDto) {
    if (requestReason == null) return null;
    const eventTypes = requestReason.eventTypes;

    let names = '<div>';
    eventTypes.forEach(event => {
      names = names.concat(`<p>${event.name}</p>`);
    });

    names = names.concat('</div>');
    return names;
  }

  @autobind
  private handleOnAddRequestReason = () => {
    const { selectedRequestReason } = this.state;
    if (selectedRequestReason == null) return;

    this.props.onAdddRequestReason && this.props.onAdddRequestReason(selectedRequestReason);
    this.props.onCloseUsersModal();
  };

  handleOnSelectedRequestReason = (items: RequestReasonDto[]) => {
    if (items && items.length > 0) {
      this.setState({ selectedRequestReason: items[0] as any });
    }
  };

  componentDidUpdate(prevProps) {
    if (!this.state.selectedRequestReason) {
      const item = this.props.selectedRequestReasonId
        ? (this.props.requestReasons.state.items || []).firstOrDefault(x => x.item.id === this.props.selectedRequestReasonId)
        : (this.props.requestReasons.state.items || []).firstOrDefault();
      if (item && item.item) this.setState({ selectedRequestReason: item.item as any });
    }
  }

  render() {
    const { ...rest } = this.props;
    const { requestReasons, t } = rest;
    const { selectedRequestReason } = this.state;

    const tableModel = {
      columns: [
        { title: t('Request Reason'), tooltipRenderer: false, renderer: data => data.name },
        {
          title: t('Event types'),
          tooltipRenderer: false,
          renderer: data => {
            const eventsList = [];
            data.eventTypes.forEach(event => eventsList.push(event));
            const allEvents = eventsList.map((item, index) => (
              <span key={`feedback__event-code_${index}`} className={item && item.name ? 'question-bank__cell__tag' : ''}>
                {item && item.name ? item.name : ''}
              </span>
            ));
            return (
              <div className={allEvents && allEvents.length > 1 ? 'table-cell__more-width question-bank__language__div' : ''}>
                {allEvents}
              </div>
            );
          }
        }
      ],
      data: requestReasons.state
    } as TableModel<RequestReasonDto>;

    return (
      <Modal size="large" open closeOnEscape={true} onClose={this.onCancel}>
        <Modal.Header className="search__modal__header">
          <Header as="h2" className="modal-header-title">
            {t('Select Request Reasons')}
          </Header>
        </Modal.Header>

        <Modal.Content className="modal-content">
          <TableView
            model={tableModel}
            selectable={true}
            hiddeMenu={true}
            onRefresh={() => null}
            canEdit={false}
            canDelete={false}
            onSelectionChange={this.handleOnSelectedRequestReason}
            maxSelection={1}
            selectionType={'checkbox'}
            selectedRow={selectedRequestReason}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-template-button" disabled={selectedRequestReason == null} onClick={this.handleOnAddRequestReason} positive>
            {t('Accept')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(ReasonLinkedEventsPopup);
