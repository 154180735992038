import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Input, Icon, Button, Loader, Dimmer } from 'semantic-ui-react';
import LocationSelector from 'widgets/bussiness/selectors/location-selector';
import EventInstructorsSelector from 'widgets/bussiness/selectors/instructors-selector';
import {
  EventInstructorRole,
  DateTimePeriod,
  EventStatus,
  EventsStore,
  EventDto,
  EventInstructorDto,
  EventUserDto
} from 'stores/events/events-store';
import PeriodInput from 'widgets/form/period-input';
import { ItemReference } from 'stores/dataStore';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import ChooseInstructorsView from 'widgets/form/choose-instructors-form';
import EventInstructorRoleEditor from 'widgets/bussiness/event-instructor-role-editor';
import { InstructorExpertiseDto, InstructorExpertiseStore } from 'stores/skills/instructor-expertise-store';
import { connect } from 'redux-scaffolding-ts';
import { DateTimeService } from 'services/datetime-service';
import { RoleInLocationDto } from 'stores/users/users-store';
import { InstructorStore } from 'stores/instructors/instructors-store';
import { guidIsNullOrEmpty } from 'utils/useful-functions';

interface EventPerInstructor {
  event: EventDto;
  instructor: EventInstructorDto;
}

export interface EventInstructorRow {
  location: ItemReference;
  user: EventUserDto;
  role: EventInstructorRole;
  period: DateTimePeriod;
  workingDays: number;
  workingHours: number;
  notValid?: boolean;
  notInDate?: boolean;
  notConflict?: boolean;
  isNewRow?: boolean;
}

interface EventInstructorsProps extends WithTranslation {
  onEditMode: boolean;
  showWorkingDays?: boolean;
  instructorRows?: EventInstructorRow[];
  eventNextStatus?: EventStatus;
  onChange?: (instructors: EventInstructorRow[]) => void;
  defaultDateFrom: string;
  defaultDateTo: string;
  eventTypeId: string;
  roleId: string;
  machineModelIds: string[];
  functionalSubAreaId: string;
  trainingLevelId: string;
  instructorExpertiseStore?: InstructorExpertiseStore;
  instructorStore?: InstructorStore;
  isMachineRelated: boolean;
  events?: EventsStore;
}

interface EventInstructorsState {
  intructorSelectorShown: boolean;
  instructorRows: EventInstructorRow[];
  notConflictOtherEventsDates?: boolean;
  recomendedInstructorsByExpertise: EventUserDto[];
  editingRowIndex: number;
  isBusy: boolean;
}

interface EventInstructorsState {}
@connect(['events', EventsStore], ['instructorStore', InstructorStore])
class EventInstructors extends PureComponent<EventInstructorsProps, EventInstructorsState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: EventInstructorsProps) {
    super(props);

    this.state = {
      instructorRows: this.props.instructorRows || [],
      intructorSelectorShown: false,
      notConflictOtherEventsDates: false,
      recomendedInstructorsByExpertise: [],
      editingRowIndex: 0,
      isBusy: false
    };
  }

  private isPlannerTFT = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlannerTFT(userinfo);
  };

  private isPlannerMTC = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlannerMTC(userinfo);
  };

  private checkOtherEventsInstructorConflicts(eventsPerInstructor: EventPerInstructor[]): boolean {
    for (let i = 0; i < eventsPerInstructor.length; i++) {
      if (
        DateTimeService.dateRangeOverlaps(
          DateTimeService.toDateRange(eventsPerInstructor[i].event.startDate, eventsPerInstructor[i].event.endDate),
          DateTimeService.toDateRange(this.props.defaultDateFrom, this.props.defaultDateTo)
        )
      ) {
        return true;
      }
    }

    return false;
  }

  private mapEventInstructorToInstructorExpertise(instructors: EventInstructorRow[], index: number): InstructorExpertiseDto[] {
    let instructorUsers = instructors.filter(x => !!x.user);
    const instructorRole = {
      role: {
        name: 'Instructor'
      },
      location: {
        id: instructors[index].location.id,
        location: instructors[index].location.title
      }
    } as RoleInLocationDto;
    let instructorExpertises = instructorUsers.map(x => {
      let model = {
        id: x.user.id,
        instructorId: x.user.id,
        user: {
          id: x.user.id,
          roles: [instructorRole]
        }
      };
      return model as InstructorExpertiseDto;
    });

    return instructorExpertises;
  }

  private handleOnEditRow(index) {
    this.setState({
      editingRowIndex: index,
      intructorSelectorShown: true
    });
  }

  private async getInstructorEventsById(id: string) {
    let filters = [{ eventInstructors: { any: [{ InstructorId: { eq: { type: 'guid', value: id } } }] } }];
    let queryEvents = {
      searchQuery: '',
      filter: filters,
      orderBy: [],
      skip: 0,
      take: 100000
    };

    return this.props.events.getAllAsync(queryEvents);
  }

  async getEventInstructorAvailability(newInstructors: InstructorExpertiseDto[], index: number): Promise<EventInstructorRow[]> {
    if (newInstructors.length > 0) {
      const instructorId = newInstructors[index].instructorId;
      let eventsInstructors = await this.getInstructorEventsById(instructorId).then(events => this.mapToEventInstructor(events.items));

      let finalEvents = eventsInstructors.filter(x => x.instructor.instructorId === instructorId);

      let newInstructorRows = newInstructors.map((instructorExpertise: InstructorExpertiseDto) => {
        let newInstructorRow: EventInstructorRow = {
          location: {
            id: instructorExpertise.user.roles.find(({ role }) => role.name === 'Instructor')
              ? instructorExpertise.user.roles.find(({ role }) => role.name === 'Instructor')?.location?.id
              : '0',
            title: instructorExpertise.user.roles.find(({ role }) => role.name === 'Instructor')
              ? instructorExpertise.user.roles.find(({ role }) => role.name === 'Instructor')?.location?.location
              : ''
          },
          period: {
            from: this.props.defaultDateFrom,
            to: this.props.defaultDateTo
          },
          role: undefined,
          user: instructorExpertise.user,
          workingDays: null,
          workingHours: null,
          notValid: false,
          notInDate: false,
          notConflict: false
        };

        newInstructorRow.notValid = !this.isRecommendedInstructorToValided(newInstructorRow);
        newInstructorRow.notConflict = this.checkOtherEventsInstructorConflicts(finalEvents);

        return newInstructorRow;
      });
      return newInstructorRows;
    }
  }

  async handleInstructorChange(user: EventUserDto, index: number) {
    let instructors = this.props.instructorRows;
    instructors[index].user = user;
    if (instructors && instructors.length > 0 && instructors.some(x => !!x.user)) {
      if (!instructors[index].location) {
        instructors[index].location = {} as ItemReference;
        instructors[index].location.id = user.location.id;
        instructors[index].location.title = user.location.location;
      }
      let instructorExpertises = this.mapEventInstructorToInstructorExpertise(instructors, index);
      let eventInstructors = await this.getEventInstructorAvailability(instructorExpertises, index);
      if (eventInstructors && eventInstructors.length > 0) {
        instructors[index].notConflict = eventInstructors[index].notConflict;
        instructors[index].notValid = eventInstructors[index].notValid;
      }
    }
    this.raiseOnChange(instructors);
  }

  componentDidMount() {
    let query = {
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: []
    };

    //    this.props.instructorExpertiseStore.getAllAsync(query, true).then(instructorExpertises => {
    //      let recomendedInstructorsByExpertise: EventUserDto[] = instructorExpertises.items
    //        .filter(x => x && x.user && (x.user.roles || []).any(y => y && y.role && y.role.name === 'Instructor'))
    //        .map(x => ({
    //          firstName: x.user.firstName,
    //          id: x.user.id,
    //          lastName: x.user.lastName,
    //          location: x.user.roles.find(y => y.role.name === 'Instructor').location,
    //          pillar: x.user.pillar
    //        }));
    //
    //      this.setState({
    //        recomendedInstructorsByExpertise: recomendedInstructorsByExpertise
    //      });
    //    });

    this.props.instructorStore.getAllAsync(query).then(instructor => {
      let recomendedInstructors: EventUserDto[] = instructor.items.map(x => ({
        firstName: x.name,
        id: x.userId,
        lastName: x.surname,
        location: x.location,
        pillar: x.pillar
      }));

      this.setState({
        recomendedInstructorsByExpertise: recomendedInstructors
      });
    });
  }

  private buildODataQueryFilter() {
    const filters: any[] = [{ EventTypeId: { eq: { type: 'guid', value: this.props.eventTypeId } } }];

    if (this.props.roleId) {
      filters.push({ or: [{ RoleId: { eq: null } }, { RoleId: { eq: { type: 'guid', value: this.props.roleId } } }] });
    }

    if (this.props.trainingLevelId) {
      filters.push({
        or: [{ TrainingLevelId: { eq: null } }, { TrainingLevelId: { eq: { type: 'guid', value: this.props.trainingLevelId } } }]
      });
    }

    const hasFuncSubArea = !guidIsNullOrEmpty(this.props.functionalSubAreaId);
    const hasMachineModel = (this.props.machineModelIds || []).length !== 0;

    if (!hasFuncSubArea && !hasMachineModel) return filters;

    if (!!this.props.isMachineRelated && hasMachineModel) {
      filters.push({
        and: [
          {
            or: [{ MachineModelId: { eq: null } }, { MachineModelId: { in: { type: 'guid', value: this.props.machineModelIds } } }]
          },
          {
            NMRFunctionalSubAreaId: { eq: null }
          }
        ]
      });
    }

    if (!this.props.isMachineRelated && hasFuncSubArea) {
      filters.push({
        and: [
          {
            or: [
              { NMRFunctionalSubAreaId: { eq: null } },
              { NMRFunctionalSubAreaId: { eq: { type: 'guid', value: this.props.functionalSubAreaId } } }
            ]
          },
          {
            MachineModelId: { eq: null }
          }
        ]
      });
    }

    return filters;
  }

  OnSelectedIntructorClose() {
    this.setState({ intructorSelectorShown: false });
  }

  mapToEventInstructor(events: EventDto[]) {
    let eventsPerInstructor: EventPerInstructor[] = [];
    events.forEach(event =>
      eventsPerInstructor.push(
        ...event.instructors.map(instructor => ({
          event: event,
          instructor: instructor
        }))
      )
    );
    return eventsPerInstructor;
  }

  onSelectedInstructor = async (newInstructors: InstructorExpertiseDto[]) => {
    this.setState({ isBusy: true });

    if (newInstructors.length <= 0) {
      this.setState({ isBusy: false });
      return;
    }
    const newInstructorRows = await this.getEventInstructorAvailability(newInstructors, 0);

    let instructors = { ...this.props.instructorRows };
    if (this.state.editingRowIndex && instructors) {
      instructors = [
        ...instructors.slice(0, this.state.editingRowIndex),
        ...newInstructorRows,
        ...instructors.slice(this.state.editingRowIndex + 1, instructors.length)
      ];
      this.setState({ editingRowIndex: null });
    } else instructors = [...(this.props.instructorRows || []), ...newInstructorRows];

    this.raiseOnChange(instructors);
    this.setState({ isBusy: false });
  };

  renderInstructor(instructor: EventInstructorRow, index: number): React.ReactNode {
    if (!this.props.onEditMode)
      return <Input className="event-form-input" transparent readOnly value={instructor.user.firstName + ' ' + instructor.user.lastName} />;

    if (!instructor.user || this.isRecommendedInstructor(instructor)) {
      return (
        <EventInstructorsSelector
          className="planit-eventform-inputs"
          value={instructor.user ? instructor.user.id : null}
          locationId={instructor.location ? instructor.location.id : null}
          onChange={user => {
            this.handleInstructorChange(user, index);
            if (user && user.location) {
              this.handleLocationChange({ id: user.location.id, title: user?.location?.location }, index);
            }
          }}
          options={this.state.recomendedInstructorsByExpertise}
          onlyInstructorsPlannerTFT={this.isPlannerTFT()}
          onlyInstructorsPlannerMTC={this.isPlannerMTC()}
        />
      );
    }

    return (
      <EventInstructorsSelector
        readOnly
        className="planit-eventform-inputs"
        value={instructor.user ? instructor.user.id : null}
        options={[instructor.user]}
        onlyInstructorsPlannerTFT={this.isPlannerTFT()}
        onlyInstructorsPlannerMTC={this.isPlannerMTC()}
      />
    );
  }

  isRecommendedInstructorToValided(instructor: EventInstructorRow): boolean {
    if (!instructor.user) return false;
    else return !!this.state.recomendedInstructorsByExpertise.find(x => x.id === instructor.user.id);
  }

  isRecommendedInstructor(instructor: EventInstructorRow): boolean {
    return !!this.state.recomendedInstructorsByExpertise.find(x => x.id === instructor.user.id);
  }

  renderInstructorRole(instructor: EventInstructorRow, index: number): React.ReactNode {
    if (!this.props.onEditMode)
      return <Input className="event-form-input" transparent readOnly value={EventInstructorRole[EventInstructorRole[instructor.role]]} />;

    return (
      <EventInstructorRoleEditor
        key={`roleeditor-${instructor.role ? instructor.role.toString() : 'undefined'}-${index}`}
        className="planit-eventform-inputs"
        value={instructor.role}
        onChange={role => this.handleInstructorRoleChange(role, index)}
      />
    );
  }

  renderLocation(instructor: EventInstructorRow, index: number): React.ReactNode {
    if (!this.props.onEditMode)
      return (
        <Input
          className="event-form-input eventform__instructors__input-info"
          transparent
          readOnly
          value={instructor.location ? instructor.location.title : ''}
        />
      );

    if (!instructor.user || this.isRecommendedInstructor(instructor))
      return (
        <LocationSelector
          className="planit-eventform-inputs wizard__instructors-step-dropdowns"
          value={instructor.location ? instructor.location.id : null}
          nullable
          clearable
          onChangeLocation={location => {
            this.handleLocationChange(location, index);
            if (!location) this.handleInstructorChange(undefined, index);
          }}
        />
      );

    return (
      <LocationSelector
        className="planit-eventform-inputs wizard__instructors-step-dropdowns"
        value={instructor.location ? instructor.location.id : null}
        readOnly
      />
    );
  }

  handleLocationChange(location: ItemReference, index: number): void {
    let instructors = this.props.instructorRows;
    instructors[index].location = location;
    this.raiseOnChange(instructors);
  }

  handleInstructorRoleChange(role: EventInstructorRole, index: number): void {
    let instructors = this.props.instructorRows;
    instructors[index].role = role;

    this.raiseOnChange(instructors);
  }

  handleOnRemoveInstructor(instructor: EventInstructorRow, index: number) {
    let instructors = this.props.instructorRows.filter((x, i) => index !== i);

    this.raiseOnChange(instructors);
  }

  handleWorkingHoursChange(workingHours: string, index: number): void {
    let instructors = this.props.instructorRows;
    instructors[index].workingHours = +workingHours;

    this.raiseOnChange(instructors);
  }

  handlePeriodChange(period: DateTimePeriod, index: number) {
    let instructors = this.props.instructorRows;
    instructors[index].period = period;

    if (
      DateTimeService.toMoment(instructors[index].period.from).isBefore(this.props.defaultDateFrom, 'day') ||
      DateTimeService.toMoment(instructors[index].period.from).isAfter(this.props.defaultDateTo, 'day') ||
      DateTimeService.toMoment(instructors[index].period.to).isBefore(this.props.defaultDateFrom, 'day') ||
      DateTimeService.toMoment(instructors[index].period.to).isAfter(this.props.defaultDateTo, 'day')
    )
      instructors[index].notInDate = true;
    else instructors[index].notInDate = false;

    this.raiseOnChange(instructors);
  }

  handleWorkingDaysChange(workingDays: string, index: number): void {
    let instructors = this.props.instructorRows;
    instructors[index].workingDays = +workingDays;

    this.raiseOnChange(instructors);
  }

  canEditWorkingTime(instructor: EventInstructorRow) {
    return (
      this.props.eventNextStatus &&
      this.props.eventNextStatus.toString() === 'Closed' &&
      instructor.user &&
      instructor.user.id === this.identityService.userId
    );
  }

  addInstructorRow(): void {
    let emptyInstructorRow: EventInstructorRow = {
      location: null,
      period: {
        from: this.props.defaultDateFrom.toString(),
        to: this.props.defaultDateTo.toString()
      },
      role: null,
      user: null,
      workingDays: null,
      workingHours: null,
      isNewRow: true
    };

    let instructors = [...(this.props.instructorRows || []), emptyInstructorRow];

    this.raiseOnChange(instructors);
  }

  raiseOnChange(instructors: EventInstructorRow[]) {
    if (!this.props.onChange) return;

    this.setState({ instructorRows: instructors });

    this.props.onChange(instructors);
  }

  render() {
    const { t } = this.props;
    const instructors = this.state.instructorRows || [];

    return (
      <>
        <Dimmer active={this.state.isBusy} inverted>
          <Loader indeterminate inverted></Loader>
        </Dimmer>
        <div className="events-wizard__instructors-step__wrapper">
          {!this.props.instructorStore.state.isBusy && (
            <div className="form__instructors-tab__buttons-row">
              <div className="instructors-tab__add-instructor-button">
                {this.props.onEditMode && (
                  <Button
                    labelPosition={'right'}
                    icon={'user plus'}
                    content={t('Add instructor')}
                    className="form__add-instructor-btn-event-creation"
                    onClick={() => this.addInstructorRow()}
                  />
                )}
              </div>
            </div>
          )}
          <div className="events-wizard__instructors-step__table">
            {!this.props.instructorStore.state.isBusy && instructors && instructors.length > 0 && (
              <>
                <p>{t("Instructor's Location")}</p>
                <p>{t("Instructor's Name")}</p>
                <p>{t('Role')}</p>
                <p className="instructortab__period-instructor__name">{t('Period per Instructor')}</p>
                <div className="text__transparent">x</div>
                <div className="text__transparent">x</div>
              </>
            )}
            <>
              {this.props.instructorStore.state.isBusy && (
                <Dimmer active inverted>
                  <Loader size="large" active inverted />
                </Dimmer>
              )}

              {!this.props.instructorStore.state.isBusy &&
                instructors &&
                instructors.length > 0 &&
                instructors.map((instructor, index) => (
                  <React.Fragment key={`instructor-${instructor.location ? instructor.location.title : 'undefined'}-${index}`}>
                    {this.renderLocation(instructor, index)}
                    {this.renderInstructor(instructor, index)}
                    {this.renderInstructorRole(instructor, index)}

                    <div className="wizard__inputs-dates-wrapper">
                      <PeriodInput
                        id={'event-wizard-instructor-' + index}
                        iconPosition={'right'}
                        minMaxDate
                        readOnly={!this.props.onEditMode}
                        value={instructor.period}
                        onChange={value => this.handlePeriodChange(value, index)}
                      />
                    </div>

                    <div className="instructors__edit-icon__container">
                      {instructor.isNewRow ? (
                        <div></div>
                      ) : (
                        <Icon className="clickable-icon" name="edit" size="large" onClick={inst => this.handleOnEditRow(index)} />
                      )}
                    </div>

                    <div className="instructors__delete-icon__container">
                      {this.props.onEditMode && (
                        <Icon
                          className="clickable-icon"
                          color="red"
                          name="remove"
                          size="large"
                          onClick={inst => this.handleOnRemoveInstructor(inst, index)}
                        />
                      )}
                    </div>
                  </React.Fragment>
                ))}
            </>
          </div>

          {this.state.intructorSelectorShown && (
            <ChooseInstructorsView
              alreadySelected={this.state.instructorRows}
              onSelectInstructors={this.onSelectedInstructor}
              onCloseModal={() => this.setState({ intructorSelectorShown: false })}
              onlyInstructorsPlannerTFT={this.isPlannerTFT()}
              onlyInstructorsPlannerMTC={this.isPlannerMTC()}
            />
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(EventInstructors);
