import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Icon, Message, Checkbox, Grid } from 'semantic-ui-react';

import { UsefulLinksStore, UsefulLinkDto, ChangeUsefulLinkStore } from 'stores/configuration/useful-links/useful-links-store';
import { ItemState, Query, OrderDefinition } from 'stores/dataStore';
import { CommandResult } from 'stores/types';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import NewUsefulLinkView from './new-useful-link';
import ChangeUsefulLinkView from './edit-useful-link';
import ViewUsefulLinkView from './view-useful-link';
import { CheckboxFilter } from 'widgets/collections/table-filters/checkbox-filter';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify.config';

export interface UsefulLinksListProps extends WithTranslation, RouteComponentProps {
  usefulLinks: UsefulLinksStore;
  changeUsefulLinkStore?: ChangeUsefulLinkStore;
}

export interface UsefulLinksListState {
  query: Query;
  newUsefulLinkShown: boolean;
  changeUsefulLinkShown: boolean;
  viewUsefulLinkShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['usefulLinks', UsefulLinksStore], ['changeUsefulLinkStore', ChangeUsefulLinkStore])
class UsefulLinksListPage extends React.Component<UsefulLinksListProps, UsefulLinksListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onViewItem());
  };
  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };
  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [], skip: 0, take: 10 },
      newUsefulLinkShown: false,
      changeUsefulLinkShown: false,
      viewUsefulLinkShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  componentDidMount() {
    this.load();
  }

  private load = () => {
    const currentUserInfo = this.identityService.getUserInfo();

    const isAdmin = IdentityService.isAdmin(currentUserInfo);
    const { query } = this.state;
    const q = { ...query };
    if (!isAdmin) q.filter = [...((query.filter as any[]) || []), { active: true }];
    this.props.usefulLinks.getAllAsync(q);
  };

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, () => {
      this.load();
    });
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  @autobind
  private async onSaveRow(item: UsefulLinkDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.usefulLinks.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: UsefulLinkDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.usefulLinks.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newUsefulLinkShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newUsefulLinkShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeUsefulLinkShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeUsefulLinkShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onViewItem() {
    this.setState({ viewUsefulLinkShown: true });
  }

  @autobind
  private onViewItemClosed(isSuccess: boolean) {
    this.setState({ viewUsefulLinkShown: false });
    if (isSuccess) this.load();
  }

  handleToggleAvailability = async (item: UsefulLinkDto) => {
    if (!item) return;
    const { id, name, link, role, active } = item;
    this.props.changeUsefulLinkStore.change({ id, name, link, role, active: !active });
    await this.props.changeUsefulLinkStore.update();
    this.load();
  };

  onDismiss = () => {
    const { usefulLinks, changeUsefulLinkStore } = this.props;
    usefulLinks.clearMessages();
    changeUsefulLinkStore.clearMessages();
  };

  private getLinks = (link: string) => {
    const parser = new DOMParser(),
      dom = parser.parseFromString(link, 'text/html');

    var anchors = [],
      documentLinks = dom.links;

    for (var i = 0; i < documentLinks.length; i++) {
      anchors.push(documentLinks[i].href);
    }

    return anchors;
  };

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t, usefulLinks, changeUsefulLinkStore } = this.props;
    const { activeFilters, someFilterOpened } = this.state;
    const currentUserInfo = this.identityService.getUserInfo();
    const isAdmin = IdentityService.isAdmin(currentUserInfo);

    let errMsgs = (usefulLinks.state.result?.messages || [])?.map(o => o.body);
    errMsgs = [...errMsgs, ...(changeUsefulLinkStore.state.result?.messages || [])?.map(x => x.body)];

    const tableModel = {
      columns: [
        {
          title: t('Name'),
          tooltipRenderer: true,
          renderer: data => data.name,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<UsefulLinkDto>('name'), `contains(${nameof<UsefulLinkDto>('name')}, '${value}')`)}
              onClear={() => onClear(nameof<UsefulLinkDto>('name'))}
              active={activeFilters.includes(nameof<UsefulLinkDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<UsefulLinkDto>('name'),
            useProfile: false
          }
        },
        {
          title: t('Role'),
          tooltipRenderer: true,
          renderer: data => data.role,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by role')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<UsefulLinkDto>('role'), `contains(${nameof<UsefulLinkDto>('role')}, '${value}')`)}
              onClear={() => onClear(nameof<UsefulLinkDto>('role'))}
              active={activeFilters.includes(nameof<UsefulLinkDto>('role'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<UsefulLinkDto>('role'),
            useProfile: false
          }
        },
        {
          title: t('Active'),
          renderer: data => (
            <Checkbox disabled={!isAdmin} toggle checked={data.active} onChange={() => this.handleToggleAvailability(data)} />
          ),
          selectableHeader: true,
          headerRenderer: isAdmin
            ? (title: string, onFilter, onClear) => (
                <CheckboxFilter
                  filterTitle={t('Filter by Active/Inactive')}
                  trueLabel={t('Active')}
                  falseLabel={t('Inactive')}
                  triggerTitle={title}
                  onFilter={value => onFilter(nameof<UsefulLinkDto>('active'), { active: value })}
                  onClear={() => onClear(nameof<UsefulLinkDto>('active'))}
                  active={activeFilters.includes(nameof<UsefulLinkDto>('active'))}
                  onActivate={this.handleOnActivateFilter}
                />
              )
            : () => <span>{t('Active')}</span>
        },
        {
          title: t('Link'),
          tooltipRenderer: true,
          renderer: data =>
            data.active && (this.getLinks(data.link) || []).length > 0 ? (
              (this.getLinks(data.link) || []).map(l => (
                <div>
                  <a href={l} target="_blank" rel="noopener noreferrer" title={data.name}>
                    View
                  </a>
                </div>
              ))
            ) : (
              <div />
            ),
          selectableHeader: true
        }
      ],
      data: this.props.usefulLinks.state
    } as TableModel<UsefulLinkDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {errMsgs.length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                onDismiss={this.onDismiss}
                error
                header={t('An error ocurred')}
                list={errMsgs}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newUsefulLinkShown && !this.state.changeUsefulLinkShown && !this.state.viewUsefulLinkShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="edit" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  isVisible: () => isAdmin,
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onEditItem());
                  }
                },
                {
                  content: (
                    <>
                      <Icon name="eye" />
                      &nbsp;{t('View')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, () => this.onViewItem());
                  }
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              isRowDisableLayout={(item: UsefulLinkDto) => !item.active}
              onRefresh={this.load}
              canEdit={false}
              canDelete={isAdmin}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={isAdmin}
              createNewButtonTitle={t('Add Useful Link')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newUsefulLinkShown && <NewUsefulLinkView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeUsefulLinkShown && (
            <ChangeUsefulLinkView onClose={this.onEditItemClosed} currentUL={this.state.selectedItem} />
          )) ||
          (this.state.viewUsefulLinkShown && <ViewUsefulLinkView onClose={this.onViewItemClosed} currentDto={this.state.selectedItem} />)}
      </>
    );
  }
}

export default withTranslation()(UsefulLinksListPage);
