import React, { Component } from 'react';
import { connect } from 'redux-scaffolding-ts';
import {
  TheoreticalTemplatesStore,
  TheoreticalTemplateItemDto,
  RelatedToType,
  NewTheoreticalTemplatesStore,
  ChangeTheoreticalTemplatesStore
} from 'stores/assessments/templates/theoretical-templates-store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ItemReference, Query, OrderDefinition } from 'stores/dataStore';
import { TableColumn, TableModel, TableView } from 'widgets/collections/table';
import i18n from 'i18n';
import { Message, Icon, Modal, Button, Dimmer, Loader, Grid } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import ChipInfo from 'widgets/bussiness/chip-info';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import ProfessionEditor from 'widgets/bussiness/profession-editor';
import BooleanEditor from 'widgets/bussiness/boolean-editor';
import TheoreticalRelatedToEditor from 'widgets/bussiness/theoretical-related-to-editor';
import { extractFriendlyIdNumber } from 'utils/useful-functions';
import './templates-tab-pane.less';
import { nameof } from 'utils/object';

export interface TemplatesTabPaneProps extends WithTranslation, RouteComponentProps {
  theoreticalTemplatesStore?: TheoreticalTemplatesStore;
  newTheoreticalTemplatesStore?: NewTheoreticalTemplatesStore;
  changeTheoreticalTemplatesStore?: ChangeTheoreticalTemplatesStore;
}

interface TemplatesTabPaneFilters {
  templateId: string;
  role: ItemReference;
  relatedToType?: RelatedToType;
  machineRelated?: boolean;
  isActive: boolean;
}

interface TemplatesTabPaneState {
  showModal: 'DisableModal' | 'CloneModal' | 'None';
  query: Query;
  filters: TemplatesTabPaneFilters;
  selectedItem: TheoreticalTemplateItemDto;
  loading: boolean;
  someFilterOpened: boolean;
}

@connect(
  ['theoreticalTemplatesStore', TheoreticalTemplatesStore],
  ['newTheoreticalTemplatesStore', NewTheoreticalTemplatesStore],
  ['changeTheoreticalTemplatesStore', ChangeTheoreticalTemplatesStore]
)
class TemplatesTabPane extends Component<TemplatesTabPaneProps, TemplatesTabPaneState> {
  state: TemplatesTabPaneState = {
    loading: false,
    query: {
      searchQuery: ``,
      orderBy: [
        { direction: 'Descending', field: nameof<TheoreticalTemplateItemDto>('friendlyId'), useProfile: false },
        { direction: 'Descending', field: 'modifiedOn', useProfile: false }
      ],
      filter: [],
      skip: 0,
      take: 10,
      parameters: {}
    },
    filters: {
      templateId: '',
      role: null,
      relatedToType: null,
      machineRelated: null,
      isActive: true
    },
    showModal: 'None',
    selectedItem: null,
    someFilterOpened: false
  };

  private columns: TableColumn<TheoreticalTemplateItemDto>[] = [
    {
      title: i18n.t('Template ID'),
      tooltipRenderer: true,
      renderer: data => data.friendlyId,
      selectableHeader: true,
      sortDefinition: {
        field: nameof<TheoreticalTemplateItemDto>('friendlyId'),
        useProfile: false,
        active: 'Descending'
      }
    },
    {
      title: i18n.t('Template'),
      tooltipRenderer: true,
      renderer: data => data.title
    },
    {
      title: i18n.t('Roles'),
      tooltipRenderer: true,
      renderer: data =>
        data.typeRelated.toString() === 'Event' ? data.profession && data.profession.profession : data.profile && data.profile.name
    },
    {
      title: i18n.t('Type'),
      tooltipRenderer: true,
      renderer: data => data.typeRelated.toString()
    },
    {
      title: i18n.t('Machine Related'),
      tooltipRenderer: true,
      renderer: data => (data.isMachineRelated ? 'Yes' : 'No')
    },
    {
      title: i18n.t('Machinery'),
      renderer: data =>
        data.theoreticalTemplateMRDetails && (
          <ChipInfo
            info={[
              data.theoreticalTemplateMRDetails.cluster && data.theoreticalTemplateMRDetails.cluster.name,
              data.theoreticalTemplateMRDetails.equipmentType && data.theoreticalTemplateMRDetails.equipmentType.name,
              data.theoreticalTemplateMRDetails.oem && data.theoreticalTemplateMRDetails.oem.name,
              data.theoreticalTemplateMRDetails.machineModel && data.theoreticalTemplateMRDetails.machineModel.name
            ]}
          />
        )
    },
    {
      title: i18n.t('Relation'),
      renderer: data =>
        data.nmrTheoreticalTemplateDetails && (
          <ChipInfo
            info={[
              data.nmrTheoreticalTemplateDetails.nmrCluster && data.nmrTheoreticalTemplateDetails.nmrCluster.name,
              data.nmrTheoreticalTemplateDetails.functionalArea && data.nmrTheoreticalTemplateDetails.functionalArea.name,
              data.nmrTheoreticalTemplateDetails.trainingName && data.nmrTheoreticalTemplateDetails.trainingName.name,
              data.nmrTheoreticalTemplateDetails.functionalSubArea && data.nmrTheoreticalTemplateDetails.functionalSubArea.name
            ]}
          />
        )
    }
  ];

  onNewItem = () => {
    const { history } = this.props;
    history.push({ pathname: '/assessments/theoretical/template/create' });
  };

  handleOnEnterKeydown = item => {
    this.handleOnEditItem(item);
  };
  handleOnRowDoubleClick = item => {
    this.handleOnEditItem(item);
  };

  componentDidMount() {
    this.load();
  }

  private load = () => {
    const query = this.buildODataQuery(this.state.filters);
    this.props.theoreticalTemplatesStore.getAllAsync(query);
  };

  private buildODataQuery = (filters: TemplatesTabPaneFilters) => {
    const query: Query = Object.assign(this.state.query);
    const oDataFilter = [];

    if (!!filters.templateId) {
      const parts = [`contains(tolower(Title), '${filters.templateId.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(filters.templateId, 'TTh');
      if (!Number.isNaN(friendlyId)) {
        if (filters.templateId.startsWith('TTh')) {
          parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        } else {
          parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : filters.templateId}')`);
        }
      }

      oDataFilter.push(`(${parts.join(' or ')})`);
    }

    if (filters.role && filters.role.id) oDataFilter.push({ ProfessionItemId: { eq: { type: 'guid', value: filters.role.id } } });
    if (filters.machineRelated != null) oDataFilter.push({ IsMachineRelated: { eq: filters.machineRelated } });
    if (!!filters.relatedToType) oDataFilter.push({ TypeRelated: { eq: filters.relatedToType } });

    query.filter = oDataFilter;

    return query;
  };

  private handleFilterChange = (property: keyof TemplatesTabPaneFilters, value: any) => {
    const filters = { ...this.state.filters };
    filters[property as string] = value;

    this.setState({ filters: filters }, this.load);
  };

  private handleFilterRole = (role: ItemReference) => {
    const parameters = this.state.query.parameters || {};

    if (!role || !role.id) {
      delete parameters['RoleId'];
    } else {
      parameters['RoleId'] = role.id;
    }
    const query = Object.assign(this.state.query, { skip: 0, parameters });

    this.setState(
      {
        query
      },
      this.load
    );
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  };

  private handleOnEditItem = (selectedItem: TheoreticalTemplateItemDto) => {
    const { history } = this.props;
    history.location.state = selectedItem;
    selectedItem && history.push({ pathname: '/assessments/theoretical/template/' + selectedItem?.id, state: selectedItem });
  };

  private hideModal = () => {
    this.setState({ selectedItem: null, showModal: 'None' });
  };

  private onCloneItem = async () => {
    this.hideModal();
    this.setState({ loading: true });
    const { selectedItem } = this.state;

    this.props.newTheoreticalTemplatesStore.createNew({
      header: selectedItem.header,
      hoursDuration: selectedItem.hoursDuration,
      isActive: selectedItem.isActive,
      isMachineRelated: selectedItem.isMachineRelated,
      professionItemId: selectedItem.professionItemId,
      status: selectedItem.status,
      title: selectedItem.title,
      trainingLevelId: selectedItem.trainingLevelId,
      typeRelated: selectedItem.typeRelated,
      profileId: selectedItem.profileId,
      nmrTheoreticalTemplateDetails: selectedItem.nmrTheoreticalTemplateDetails && {
        nmrClusterId: selectedItem.nmrTheoreticalTemplateDetails.nmrClusterId,
        trainingNameId: selectedItem.nmrTheoreticalTemplateDetails.trainingNameId,
        nmrTheoreticalQuestions: (selectedItem.nmrTheoreticalTemplateDetails.nmrTheoreticalQuestions || []).map(x => x.id),
        functionalAreaId: selectedItem.nmrTheoreticalTemplateDetails.functionalAreaId,
        functionalSubareaId: selectedItem.nmrTheoreticalTemplateDetails.functionalSubareaId
      },
      theoreticalTemplateMRDetails: selectedItem.theoreticalTemplateMRDetails && {
        clusterId: selectedItem.theoreticalTemplateMRDetails.clusterId,
        equipmentTypeId: selectedItem.theoreticalTemplateMRDetails.equipmentTypeId,
        machineModelId: selectedItem.theoreticalTemplateMRDetails.machineModelId,
        oemId: selectedItem.theoreticalTemplateMRDetails.oemId,
        theoreticalQuestionsMR: (selectedItem.theoreticalTemplateMRDetails.theoreticalQuestionsMR || []).map(x => x.id)
      }
    });

    try {
      await this.props.newTheoreticalTemplatesStore.submit();
      this.setState({ selectedItem: null, showModal: 'None', loading: false });
      this.load();
    } catch (error) {
      console.error({ error });
      this.setState({ loading: false });
      this.setState({ selectedItem: null, showModal: 'None', loading: false });
    }
  };

  private onToggleAvailabilityItem = async () => {
    const { selectedItem } = this.state;

    this.props.changeTheoreticalTemplatesStore.createNew({
      id: selectedItem.id,
      header: selectedItem.header,
      hoursDuration: selectedItem.hoursDuration,
      isActive: !selectedItem.isActive,
      isMachineRelated: selectedItem.isMachineRelated,
      professionItemId: selectedItem.professionItemId,
      status: selectedItem.status,
      title: selectedItem.title,
      trainingLevelId: selectedItem.trainingLevelId,
      typeRelated: selectedItem.typeRelated,
      nmrTheoreticalTemplateDetails: selectedItem.nmrTheoreticalTemplateDetails && {
        nmrClusterId: selectedItem.nmrTheoreticalTemplateDetails.nmrClusterId,
        trainingNameId: selectedItem.nmrTheoreticalTemplateDetails.trainingNameId,
        nmrTheoreticalQuestions: (selectedItem.nmrTheoreticalTemplateDetails.nmrTheoreticalQuestions || []).map(x => x.id),
        functionalAreaId: selectedItem.nmrTheoreticalTemplateDetails.functionalAreaId,
        functionalSubareaId: selectedItem.nmrTheoreticalTemplateDetails.functionalSubareaId
      },
      theoreticalTemplateMRDetails: selectedItem.theoreticalTemplateMRDetails && {
        clusterId: selectedItem.theoreticalTemplateMRDetails.clusterId,
        equipmentTypeId: selectedItem.theoreticalTemplateMRDetails.equipmentTypeId,
        machineModelId: selectedItem.theoreticalTemplateMRDetails.machineModelId,
        oemId: selectedItem.theoreticalTemplateMRDetails.oemId,
        theoreticalQuestionsMR: (selectedItem.theoreticalTemplateMRDetails.theoreticalQuestionsMR || []).map(x => x.id)
      }
    });

    try {
      await this.props.changeTheoreticalTemplatesStore.update();
      this.load();
      this.setState({ selectedItem: null, showModal: 'None' });
    } catch (error) {
      console.error({ error });
    }
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(
      ({ query }) => ({
        query: { ...query, orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }] }
      }),
      this.load
    );
  };
  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  render() {
    const { t, theoreticalTemplatesStore: store } = this.props;
    const { filters, someFilterOpened } = this.state;
    const messages = (store.state.result && !store.state.result.isSuccess && store.state.result.messages) || [];

    const tableModel = {
      columns: this.columns,
      data: this.props.theoreticalTemplatesStore.state
    } as TableModel<TheoreticalTemplateItemDto>;

    return (
      <>
        <Dimmer active={this.state.loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading')}</Loader>
        </Dimmer>
        <div className="theoretical-templates__wrapper">
          <Grid className="event-types-list-grid">
            {messages.length > 0 && (
              <Grid.Row className="event-types-list-error-row">
                <Message
                  className="error-message__style"
                  icon="exclamation circle"
                  error
                  header={t('An error ocurred')}
                  list={messages.map(o => o.body)}
                  onDismiss={() => store.clearMessages()}
                />
              </Grid.Row>
            )}

            <div className="theoretical-templates__filters table__filters-share-main-actions">
              <ClearableTimerInput
                icon="search"
                className="custom-editor-search"
                value={filters.templateId}
                placeholder={t('Search in Template or ID')}
                onChange={(_, { value }) => this.handleFilterChange('templateId', value)}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
              <ProfessionEditor
                clearable
                nullable
                className="custom-editor"
                placeholder={t('Role')}
                value={filters.role}
                onChange={value => this.handleFilterRole(value)}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
              <BooleanEditor
                clearable
                className="custom-editor"
                placeholder={t('Machine Related')}
                value={filters.machineRelated}
                onChange={value => this.handleFilterChange('machineRelated', value)}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
              <TheoreticalRelatedToEditor
                clearable
                className="custom-editor"
                placeholder={t('Type')}
                value={filters.relatedToType}
                onChange={value => this.handleFilterChange('relatedToType', value)}
                onBlur={this.onBlurHandler}
                onFocus={this.onFocusHandler}
              />
            </div>
            <Grid.Row className="event-types-list-items-row request-list__table-view">
              <TableView
                /////////////////For build table keyboard navegation/////////////////
                selectable={this.state.showModal === 'None'}
                onHideCheckbox={true}
                selectionType={'allRow'}
                maxSelection={1}
                onEnterKeydown={this.handleOnEnterKeydown}
                onRowDoubleClick={this.handleOnRowDoubleClick}
                preventEnterKeyDownEvent={someFilterOpened}
                //showActionsConfirmModal={true}
                /////////////////For build table keyboard navegation/////////////////

                model={tableModel}
                extraActions={[
                  {
                    content: (
                      <>
                        <Icon name="pencil" />
                        {<span className="text__bold">{t('Edit')}</span>}
                      </>
                    ),
                    onClick: this.handleOnEditItem
                  },
                  {
                    content: (
                      <>
                        <Icon name="clone" />
                        {<span className="text__bold">{t('Clone')}</span>}
                      </>
                    ),
                    onClick: (item: TheoreticalTemplateItemDto) => {
                      this.setState({ selectedItem: item, showModal: 'CloneModal' });
                    }
                  },
                  {
                    content: (
                      <>
                        <Icon name="toggle off" color="red" />
                        <span className="text__bold text__red">{t('Deactivate')}</span>
                      </>
                    ),
                    isVisible: (item: TheoreticalTemplateItemDto) => item.isActive,
                    onClick: (item: TheoreticalTemplateItemDto) => {
                      this.setState({ selectedItem: item, showModal: 'DisableModal' });
                    }
                  },
                  {
                    content: (
                      <>
                        <Icon name="toggle on" />
                        <span className="text__bold">{t('Activate')}</span>
                      </>
                    ),
                    isVisible: (item: TheoreticalTemplateItemDto) => !item.isActive,
                    onClick: (item: TheoreticalTemplateItemDto) => {
                      this.setState({ selectedItem: item, showModal: 'DisableModal' });
                    }
                  }
                ]}
                createButtonIcon={{ hasIcon: false }}
                onOrderByChanged={this.handleOrderBy}
                onNewItem={this.onNewItem}
                onRefresh={this.load}
                canEdit={false}
                canDelete={false}
                onPageChange={this.handlePageChange}
                canCreateNew={true}
                createNewButtonTitle={t('New Theoretical Template')}
                isRowDisableLayout={item => !(item as any).isActive}
              ></TableView>
            </Grid.Row>
          </Grid>
          {this.state.showModal === 'DisableModal' && (
            <Modal open size="tiny" className="change-status-popup" closeOnEscape={true} onClose={this.hideModal}>
              <Modal.Content className="change-status-popup__content">
                <p>{t(`You are going to change status to ${this.state.selectedItem.isActive ? 'disabled' : 'active'}`)}</p>
                <p>{t('Are you sure you want to continue?')}</p>
              </Modal.Content>
              <div className="change-status-popup__buttons-container">
                <Button className="change-status-popup__btn" content={t('No')} onClick={this.hideModal} />
                <Button
                  className="change-status-popup__btn change-status-popup__pending-btn"
                  content={t('Yes')}
                  onClick={this.onToggleAvailabilityItem}
                />
              </div>
            </Modal>
          )}

          {this.state.showModal === 'CloneModal' && (
            <Modal open size="tiny" className="change-status-popup" closeOnEscape={true} onClose={this.hideModal}>
              <Modal.Content className="change-status-popup__content">
                <p>{t(`You are going to clone this template with id ${this.state.selectedItem.friendlyId}`)}</p>
                <p>{t('Are you sure you want to continue?')}</p>
              </Modal.Content>
              <div className="change-status-popup__buttons-container">
                <Button className="change-status-popup__btn" content={t('No')} onClick={this.hideModal} />
                <Button
                  className="change-status-popup__btn change-status-popup__pending-btn"
                  content={t('Yes')}
                  onClick={this.onCloneItem}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(TemplatesTabPane);
