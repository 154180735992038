import React, { Component, RefObject } from 'react';
import ReactDOM from 'react-dom';

import './bryntum-scheduler.less';
import './PresetsManager';

import { Scheduler, ObjectHelper, Widget, SchedulerConfig, EventStore, ResourceStore, WidgetHelper } from '@planit/bryntum-scheduler';
import { MyResourceTimeRangeStore } from 'site/pages/landing-pages/shared-scheduler-components/time-ranges';
import { DateTimeService } from 'services/datetime-service';

interface BryntumSchedulerProps {
  [key: string]: any;
  className: string;
}

class BryntumScheduler extends Component<BryntumSchedulerProps, any> {
  resourceTimeRangesStore = new MyResourceTimeRangeStore();

  today = DateTimeService.today(true).toDate();
  currentWeek = DateTimeService.now(true)
    .startOf('w')
    .add(1, 'd')
    .toDate();

  currentMonth = DateTimeService.now(true)
    .startOf('month')
    .toDate();
  // defaults for scheduler. Feel free to adjust it
  static defaultProps = {
    viewPreset: 'weekAndMonthJTI',
    autoAdjustTimeAxis: false,
    barMargin: 2,
    createEventOnDblClick: false,
    loadMask: 'Loading...',
    responsiveLevels: {
      small: 400,
      medium: 600,
      large: '*'
    },
    showRemoveRowInContextMenu: false
  };

  featureRe = /Feature$/;

  /* #region Features */
  features = [
    'cellEditFeature',
    'cellTooltipFeature',
    'columnDragToolbarFeature',
    'columnLinesFeature',
    'columnPickerFeature',
    'columnReorderFeature',
    'columnResizeFeature',
    'contextMenuFeature',
    'dependenciesFeature',
    'dependencyEditFeature',
    'eventContextMenuFeature',
    'eventDragFeature',
    'eventDragCreateFeature',
    'eventDragSelectFeature',
    'eventEditFeature',
    'eventFilterFeature',
    'eventResizeFeature',
    'eventTooltipFeature',
    'filterBarFeature',
    'filterFeature',
    'groupFeature',
    'groupSummaryFeature',
    'headerContextMenuFeature',
    'headerZoomFeature',
    'labelsFeature',
    'nonWorkingTimeFeature',
    'panFeature',
    'quickFindFeature',
    'regionResizeFeature',
    'resourceTimeRangesFeature',
    'rowReorderFeature',
    'scheduleContextMenuFeature',
    'scheduleTooltipFeature',
    'searchFeature',
    'simpleEventEditFeature',
    'sortFeature',
    'stripeFeature',
    'summaryFeature',
    'timeRangesFeature',
    'excelExporter',
    'treeFeature'
  ];
  /* #endregion */

  /* #region Configs */
  configs = [
    'assignments',
    'assignmentStore',
    'autoHeight',
    'barMargin',
    'columns',
    'crudManager',
    'dependencyStore',
    'displayDateFormat',
    'emptyText',
    'endDate',
    'eventBodyTemplate',
    'eventColor',
    'eventLayout',
    'eventRenderer',
    'events',
    'eventStyle',
    'eventStore',
    'fillTicks',
    'maxHeight',
    'maxWidth',
    'maxZoomLevel',
    'milestoneAlign',
    'minZoomLevel',
    'mode',
    'height',
    'minHeight',
    'minWidth',
    'partner',
    'readOnly',
    'resources',
    'resourceStore',
    'resourceTimeRanges',
    'responsiveLevels',
    'rowHeight',
    'scrollLeft',
    'scrollTop',
    'selectedEvents',
    'snap',
    'startDate',
    'tickWidth',
    'timeRanges',
    'timeResolution',
    'viewportCenterDate',
    'viewPreset',
    'zoomLevel'
  ];
  /* #endregion */

  state = {
    portals: new Set(),
    generation: 0
  };

  el: RefObject<HTMLDivElement>;
  resourceStore: ResourceStore;
  eventStore: EventStore;
  schedulerEngine: Scheduler;
  lastEvents: any;
  lastResources: any;

  rowHeightHandler = value => {
    (this.schedulerEngine as any).rowHeight = value;
  };

  releaseReactCell(cellElement) {
    const { state } = this,
      cellElementData = cellElement._domData;

    // Cell already has a react component in it, remove
    if (cellElementData.reactPortal) {
      state.portals.delete(cellElementData.reactPortal);
      this.setState(({ generation }) => ({ portals: state.portals, generation: generation + 1 }));
      cellElementData.reactPortal = null;
    }
  }
  setResourceTimeRanges = items => {
    if (!this.schedulerEngine || !this.schedulerEngine.features) return;
    const maskScheduler: any = WidgetHelper.mask(this.el, 'LOADING...');
    this.schedulerEngine.features.resourceTimeRanges.store.data = items;
    // (this.schedulerEngine.resourceTimeRangeStore?.records || []).forEach((x: any) => x?.removeOccurrences && x.removeOccurrences());
    // this.schedulerEngine.resourceTimeRangeStore.add(items);
    maskScheduler?.close && maskScheduler.close();
  };

  setResourceTimeRange = changes => {
    this.schedulerEngine.features.resourceTimeRanges.store.data.forEach(item => {
      const { resourceId, ...rest } = item;
      const matchWeekend = changes.find(({ cls, resourceId: id }) => cls === 'weekend' && cls === rest.cls && resourceId === id) != null;
      if (matchWeekend) item = { ...item, matchWeekend };
    });
  };

  currentDateHandler = viewPreset => {
    const { timeRanges } = this.schedulerEngine.features;
    if (viewPreset === 'weekAndDayJTI') timeRanges.store.data = [{ startDate: this.today, cls: 'today', duration: 1, durationUnit: 'day' }];
    if (viewPreset === 'weekAndMonthJTI')
      timeRanges.store.data = [{ startDate: this.currentWeek, cls: 'today', duration: 1, durationUnit: 'week' }];
    if (viewPreset === 'monthAndYearJTI')
      timeRanges.store.data = [{ startDate: this.currentMonth, cls: 'today', duration: 1, durationUnit: 'month' }];
  };

  viewPresetChangeHandler = viewPreset => {
    this.currentDateHandler(viewPreset);
    this.schedulerEngine.viewPreset = viewPreset;
  };

  // React component rendered to DOM, render scheduler to it
  componentDidMount() {
    const { props } = this,
      config: Partial<SchedulerConfig> = {
        appendTo: this.el,
        callOnFunctions: true,
        enableDeleteKey: false,
        zoomOnTimeAxisDoubleClick: false,
        features: {
          cache: false,
          myRecurringTimeSpans: { store: this.resourceTimeRangesStore },
          resourceTimeRanges: { store: this.resourceTimeRangesStore },
          pan: false, // Makes the scheduler's timeline pannable by dragging with the mouses.
          cellEdit: false, // Cell editing in the columns part
          columnLines: true, // Column lines in the schedule part
          columnPicker: false, // Header context menu item to toggle visible columns
          columnReorder: false, // Reorder columns in grid part using drag and drop
          columnResize: false, // Resize columns in grid part using the mouse
          regionResize: false, // Makes the splitter between grid section draggable so you can resize grid sections.
          contextMenu: false, // Context menu for cells and headers in the grid part
          eventContextMenu: false, // Context menu for events
          eventDrag: true, // Dragging events
          eventDragCreate: false, // Drag creating events
          eventEdit: false, // Event editor dialog
          eventFilter: false, // Filtering events using header context menu
          group: false, // Row grouping
          headerContextMenu: true, // Header context menu for schedule part
          scheduleContextMenu: false, // Context menu for empty parts of the schedule
          scheduleTooltip: true, // Tooltip for empty parts of the schedule
          sort: false,
          eventResize: false,
          nonWorkingTime: true
        },
        // Hook called by engine when requesting a cell editor
        processCellEditor: ({ editor, field }) => {
          // String etc handled by feature, only care about fns returning React components here
          if (typeof editor !== 'function') {
            return;
          }

          // Wrap React editor in an empty widget, to match expectations from CellEdit/Editor and make alignment
          // etc. work out of the box
          const wrapperWidget = new Widget({
            name: field // For editor to be hooked up to field correctly
          } as any);

          // Ref for accessing the React editor later
          (wrapperWidget as any).reactRef = React.createRef();

          // column.editor is expected to be a function returning a React component (can be JSX). Function is
          // called with the ref from above, it has to be used as the ref for the editor to wire things up
          const reactComponent = editor((wrapperWidget as any).reactRef);
          if (reactComponent.$$typeof !== Symbol.for('react.element')) {
            throw new Error('Expect a React element');
          }

          let editorValidityChecked = false;

          // Add getter/setter for value on the wrapper, relaying to getValue()/setValue() on the React editor
          Object.defineProperty(wrapperWidget, 'value', {
            enumerable: true,
            get: function() {
              return (wrapperWidget as any).reactRef.current.getValue();
            },
            set: function(value) {
              const component = (wrapperWidget as any).reactRef.current;

              if (!editorValidityChecked) {
                const misses = ['setValue', 'getValue', 'isValid', 'focus'].filter(fn => !(fn in component));

                if (misses.length) {
                  throw new Error(`
                      Missing function${misses.length > 1 ? 's' : ''} ${misses.join(', ')} in ${
                    component.constructor.name
                  }. Cell editors must implement setValue, getValue, isValid and focus`);
                }

                editorValidityChecked = true;
              }

              const context = (wrapperWidget.owner as any).cellEditorContext;
              component.setValue(value, context);
            }
          });

          // Add getter for isValid to the wrapper, mapping to isValid() on the React editor
          Object.defineProperty(wrapperWidget, 'isValid', {
            enumerable: true,
            get: function() {
              return (wrapperWidget as any).reactRef.current.isValid();
            }
          });

          // Override widgets focus handling, relaying it to focus() on the React editor
          wrapperWidget.focus = () => {
            (wrapperWidget as any).reactRef.current.focus && (wrapperWidget as any).reactRef.current.focus();
          };

          // Create a portal, making the React editor belong to the React tree although displayed in a Widget
          const portal = ReactDOM.createPortal(reactComponent, wrapperWidget.element);
          (wrapperWidget as any).reactPortal = portal;

          const { state } = this;
          // Store portal in state to let React keep track of it (inserted into the Grid component)
          state.portals.add(portal);
          this.setState({
            portals: state.portals,
            generation: state.generation + 1
          });

          return { editor: wrapperWidget };
        },

        // Hook called by engine when rendering cells, creates portals for JSX supplied by renderers
        processCellContent: ({ cellContent, cellElement, cellElementData, record }) => {
          let shouldSetContent = Boolean(cellContent);

          // Release any existing React component
          this.releaseReactCell(cellElement);

          // Detect React component
          if (cellContent && cellContent.$$typeof === Symbol.for('react.element')) {
            // Excluding special rows for now to keep renderers simpler
            if (!record.meta.specialRow) {
              // Clear any non-react content
              const firstChild = cellElement.firstChild;
              if (!cellElementData.reactPortal && firstChild) {
                firstChild.data = '';
              }

              // Create a portal, belonging to the existing React tree but render in a cell
              const portal = ReactDOM.createPortal(cellContent, cellElement);
              cellElementData.reactPortal = portal;

              const { state } = this;
              // Store in state for React to not loose track of the portals
              state.portals.add(portal);
              this.setState({
                portals: state.portals,
                generation: state.generation + 1
              });
            }
            shouldSetContent = false;
          }

          return shouldSetContent;
        }
      } as any;

    // relay properties with names matching this.featureRe to features
    this.features.forEach(featureName => {
      if (featureName in props) config.features[featureName.replace(this.featureRe, '')] = props[featureName];
    });

    // Handle config (relaying all props except those used for features to scheduler)
    Object.keys(props).forEach(propName => {
      if (!propName.match(this.featureRe) && undefined !== props[propName]) config[propName] = props[propName];
    });

    // Create the actual scheduler, used as engine for the wrapper
    const engine = (this.schedulerEngine = props.schedulerClass ? new props.schedulerClass(config) : new Scheduler(config));
    this.currentDateHandler('weekAndMonthJTI');

    // Release any contained React components when a row is removed
    engine.rowManager.on({ removeRow: ({ row }) => row.cells.forEach(cell => this.releaseReactCell(cell)) });

    // Relay all store events, to allow <BryntumGrid onResourceLoad=... /> etc
    engine.resourceStore.relayAll(engine, 'resourceStore', true);
    engine.eventStore.relayAll(engine, 'eventStore', true);

    // Make stores easier to access
    this.resourceStore = engine.resourceStore;
    this.eventStore = engine.eventStore;

    // Map all features from schedulerEngine to scheduler to simplify calls
    Object.keys(engine.features).forEach(key => {
      const featureName = key + 'Feature';
      if (!this[featureName]) this[featureName] = engine.features[key];
    });

    // Shortcut to set syncDataOnLoad on the stores
    if (props.syncDataOnLoad) {
      engine.resourceStore.syncDataOnLoad = true;
      engine.eventStore.syncDataOnLoad = true;
    }

    if (config.events) this.lastEvents = config.events.slice();

    if (config.resources) this.lastResources = config.resources.slice();

    const scrollRegion = engine.getSubGrid('normal');

    scrollRegion.scrollable.on('scrollend', () => this.onScrollEnd());
    engine.scrollToDate(this.today, {
      highlight: false,
      animate: false,
      block: 'center'
    });
  }

  // React component removed, destroy engine
  componentWillUnmount() {
    this.schedulerEngine.destroy();
  }

  // Component about to be updated, from changing a prop using state. React to it depending on what changed and
  // prevent react from re-rendering our component.
  shouldComponentUpdate(nextProps, nextState) {
    const { props, schedulerEngine: engine } = this,
      // These props are ignored or has special handling below
      excludeProps = [
        'adapter',
        'children',
        'columns',
        'events',
        'eventsVersion',
        'listeners', // #9114 prevents the crash when listeners are changed at runtime
        'ref',
        'resources',
        'resourcesVersion',
        'timeRanges',
        ...this.features
      ];

    // Reflect configuration changes. Since most scheduler configs are reactive the scheduler will update automatically
    Object.keys(props).forEach(propName => {
      // Only apply if prop has changed
      if (!excludeProps.includes(propName) && !ObjectHelper.isEqual(props[propName], nextProps[propName])) {
        engine[propName] = nextProps[propName];
      }
    });

    if (nextProps.startDate !== this.props.startDate || nextProps.endDate !== this.props.endDate)
      nextProps.rtr && this.setResourceTimeRanges(nextProps.rtr);

    if (
      // resourceVersion used to flag that data has changed
      nextProps.resourcesVersion !== props.resourcesVersion ||
      nextProps.eventsVersion !== props.eventsVersion
      // if not use do deep equality check against previous dataset
      // TODO: Might be costly for large datasets
      // (!('resourcesVersion' in nextProps) &&
      //   !('resourcesVersion' in props) &&
      //   !ObjectHelper.isDeeplyEqual(this.lastResources, nextProps.resources))
    ) {
      engine.resources = nextProps.resources;
      this.lastResources = nextProps.resources && nextProps.resources.slice();
    }

    if (
      // eventVersion used to flag that data has changed
      nextProps.eventsVersion !== props.eventsVersion
      // if not use do deep equality check against previous dataset
      // TODO: Might be costly for large datasets
      // (!('eventsVersion' in nextProps) && !('eventsVersion' in props) && !ObjectHelper.isDeeplyEqual(this.lastEvents, nextProps.events))
    ) {
      try {
        engine.events = nextProps.events;
        this.lastEvents = nextProps.events && nextProps.events.slice();
      } catch (error) {
        console.error({ error });
        alert('Oops something went wrong! You might need to refresh the page.');
      }
    }

    // Reflect feature config changes
    this.features.forEach(featureName => {
      const currentProp = props[featureName],
        nextProp = nextProps[featureName];

      if (featureName in props && !ObjectHelper.isDeeplyEqual(currentProp, nextProp)) {
        engine.features[featureName.replace(this.featureRe, '')].setConfig(nextProp);
      }
    });

    // console.log({ engine });

    // Reflect JSX cell changes
    return nextState.generation !== this.state.generation;
  }

  onScrollEnd = () => this.props.onScrollEnd(this.schedulerEngine.getSubGrid('normal').scrollable);

  render() {
    return (
      <div className={this.props.className || 'b-react-scheduler-container'} ref={el => (this.el = el as any)}>
        {this.state.portals}
      </div>
    );
  }
}

export default BryntumScheduler;
