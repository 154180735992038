import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Input, Icon, Message, Grid } from 'semantic-ui-react';

import { EquipmentTypeDto, EquipmentTypesStore } from '../../../../../stores/configuration/machinery/equipment-types-store';
import { ItemState, OrderDefinition, Query } from '../../../../../stores/dataStore';
import { CommandResult } from '../../../../../stores/types';
import { nameof } from '../../../../../utils/object';
import MRClusterEditor from '../../../../../widgets/bussiness/mrcluster-editor';
import { TableModel, TableView } from '../../../../../widgets/collections/table';
import { TextBoxFilter } from '../../../../../widgets/collections/table-filters/textbox-filter';
import NewEquipmentTypeView from './new-equipment-type';
import ChangeEquipmentTypeView from './edit-equipment-type';

export interface EquipmentTypesListProps extends WithTranslation, RouteComponentProps {
  equipmentTypes: EquipmentTypesStore;
  readOnly: boolean;
}

export interface EquipmentTypesListState {
  query: Query;
  newEquipmentTypeShown: boolean;
  changeEquipmentTypeShown: boolean;
  activeFilters: string[];
  selectedItem: any;
  someFilterOpened: boolean;
}

@connect(['equipmentTypes', EquipmentTypesStore])
class EquipmentTypesListPage extends React.Component<EquipmentTypesListProps, EquipmentTypesListState> {
  handleOnEnterKeydown = item => {
    this.setState({ selectedItem: item }, () => this.onEditItem());
  };

  handleOnRowDoubleClick = item => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Ascending', field: nameof<EquipmentTypeDto>('name'), useProfile: false }],
        skip: 0,
        take: 10
      },
      newEquipmentTypeShown: false,
      changeEquipmentTypeShown: false,
      activeFilters: [],
      selectedItem: null,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.equipmentTypes.getAllAsync(this.state.query);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }]
        })
      },
      this.load
    );
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) });
    this.load();
  }

  @autobind
  private async onSaveRow(item: EquipmentTypeDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.equipmentTypes.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: EquipmentTypeDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') {
      await this.props.equipmentTypes.deleteAsync(item.id, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newEquipmentTypeShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newEquipmentTypeShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeEquipmentTypeShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeEquipmentTypeShown: false });
    if (isSuccess) this.load();
  }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Cluster'),
          tooltipRenderer: false,
          renderer: data => <span>{data.clusterName}</span>,
          editor: (data, onChange) => (
            <MRClusterEditor
              nullable={false}
              value={{ id: data.clusterId, title: data.clusterName }}
              onChange={c => {
                data.clusterId = c.id;
                data.clusterName = c.title;
                onChange();
              }}
            />
          ),
          //<Input value={data.clusterName} fluid onChange={(e, { value }) => { data.clusterName = value; onChange(); }} />,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Cluster')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<EquipmentTypeDto>('clusterName'),
                  `contains(tolower(${nameof<EquipmentTypeDto>('clusterName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<EquipmentTypeDto>('clusterName'))}
              active={activeFilters.includes(nameof<EquipmentTypeDto>('clusterName'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<EquipmentTypeDto>('clusterName'),
            useProfile: false
          }
        },
        {
          title: t('Equipment Type'),
          tooltipRenderer: false,
          renderer: data => <span>{data.name}</span>,
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(e, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Equipment Type')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<EquipmentTypeDto>('name'),
                  `contains(tolower(${nameof<EquipmentTypeDto>('name')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<EquipmentTypeDto>('name'))}
              active={activeFilters.includes(nameof<EquipmentTypeDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<EquipmentTypeDto>('name'),
            useProfile: false,
            active: 'Ascending'
          }
        }
      ],
      data: this.props.equipmentTypes.state
    } as TableModel<EquipmentTypeDto>;
    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.readOnly && <h2>{t('Equipment Types')}</h2>}
          {this.props.equipmentTypes.state.result && !this.props.equipmentTypes.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.equipmentTypes.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!this.state.newEquipmentTypeShown && !this.state.changeEquipmentTypeShown}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraActions={
                !this.props.readOnly
                  ? [
                      {
                        content: (
                          <>
                            <Icon name="edit" />
                            &nbsp;{t('Edit')}
                          </>
                        ),
                        onClick: item => {
                          this.setState({ selectedItem: item }, () => this.onEditItem());
                        }
                      }
                    ]
                  : []
              }
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              onRefresh={this.load}
              canEdit={false}
              canDelete={!this.props.readOnly}
              onDeleteRow={this.onDelete}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={!this.props.readOnly}
              createNewButtonTitle={t('Add Equipment Type')}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newEquipmentTypeShown && <NewEquipmentTypeView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeEquipmentTypeShown && (
            <ChangeEquipmentTypeView onClose={this.onEditItemClosed} currentET={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(EquipmentTypesListPage);
