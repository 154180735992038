import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Form, Button, Modal, Grid } from 'semantic-ui-react';
import { OrderDefinition, Query, ItemReference } from 'stores/dataStore';
import { getProperties } from 'utils/object';
import { TableModel, TableView, TableColumn } from 'widgets/collections/table';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces, openInNewWindow, extractFriendlyIdNumber, trim } from 'utils/useful-functions';
import MultipleLocationEditor from 'widgets/bussiness/multiple-selector/multiple-location-editor';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { DatesRangeInput } from 'widgets/form/datesRangeInput';
import MRClusterEditor from 'widgets/bussiness/mrcluster-editor';
import EquipmentTypeEditor from 'widgets/bussiness/equipment-type-editor';
import OemEditor from 'widgets/bussiness/oem-editor';
import MachineModelEditor from 'widgets/bussiness/machine-model-editor';
import { TnaFormListDto } from 'stores/assessments/forms/tna-forms-store';
import { DateTimeService } from 'services/datetime-service';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import ProfileEditor from 'widgets/bussiness/profile-editor';

import { TnaTheoreticalTestItemDto, TnaTheoreticalTestListStore } from 'stores/assessments/forms/tna-theoretical-tests-store';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { LocationDto } from 'stores/configuration/locations/locations-store';

export interface TnaTheoreticalTestListProps extends WithTranslation, RouteComponentProps {
  tnaTheoreticalTestListStore?: TnaTheoreticalTestListStore;
}

export interface TnaTheoreticalTestListState {
  query: Query;
  activeFilters: { [key: string]: any };
  selectedItem: TnaTheoreticalTestItemDto;
  locations: LocationDto[];
  creationDateFrom: string;
  creationDateTo: string;
  deadlineFrom: string;
  deadlineTo: string;
  profile: ProfileItemDto;
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  showDisabled: boolean;
  employee: string;
  showDeleteConfirmModal: boolean;
  someFilterOpened: boolean;
}

@connect(['tnaTheoreticalTestListStore', TnaTheoreticalTestListStore])
class TnaTheoreticalTestList extends React.Component<TnaTheoreticalTestListProps, TnaTheoreticalTestListState> {
  handleOnEnterKeydown = item => {
    this.openTnaTheoreticalTest(item);
  };

  handleOnRowDoubleClick = item => {
    this.openTnaTheoreticalTest(item);
  };

  constructor(props) {
    super(props);
    this.state = {
      activeFilters: [],
      selectedItem: null,

      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: nameof<TnaFormListDto>('createdOn'), useProfile: false }],
        skip: 0,
        take: 10,
        parameters: {}
      },

      locations: [],
      creationDateFrom: null,
      creationDateTo: null,
      deadlineFrom: null,
      deadlineTo: null,
      profile: null,
      cluster: null,
      equipmentType: null,
      oem: null,
      machineModel: null,
      showDisabled: false,
      employee: '',
      showDeleteConfirmModal: false,
      someFilterOpened: false
    };
  }

  componentDidMount() {
    this.load();
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  private onOpenEmployee = (user: TnaTheoreticalTestItemDto) => {
    openInNewWindow(`./employee-page/${user.userId}/${user.id}`);
  };

  private load = () => {
    const activeFilters = { ...this.state.activeFilters };
    let filter = getProperties(activeFilters)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);
    filter = [{ TypeRelated: 'TNA' }, ...filter];
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    this.setState({ query }, () => this.props.tnaTheoreticalTestListStore.getAllAsync(this.state.query));
  };

  private handleOrderBy = (newOrder: OrderDefinition[]) => {
    const orderBy: OrderDefinition[] = [
      ...newOrder,
      { direction: 'Descending', field: nameof<TnaFormListDto>('createdOn'), useProfile: false }
    ];

    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  };

  private onDelete = async (id: string) => {
    this.hideConfirmModal();
    await this.props.tnaTheoreticalTestListStore.deleteAsync(id);
  };

  private showDeleteConfirmationModal = (selectedItem: TnaTheoreticalTestItemDto) => {
    this.setState({ selectedItem, showDeleteConfirmModal: true });
  };

  private hideConfirmModal = () => {
    this.setState({ showDeleteConfirmModal: false, selectedItem: null });
  };

  private onNewItem = () => {
    const { history } = this.props;
    history.push({ pathname: '/assessments/tna-theoretical-test/create' });
  };

  private openTnaTheoreticalTest = (tna: TnaTheoreticalTestItemDto) => {
    this.setState({ selectedItem: tna });
    openInNewWindow(`./assessments/theoretical-test/${tna.id}`);
  };

  private handleFilterTna = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) delete activeFilters['IdDescription'];
    else {
      const input = value as string;
      const parts = [`contains(tolower(TnaDetails/Title), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'The');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('The')) parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        else parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
      }

      activeFilters['IdDescription'] = `(${parts.join(' or ')})`;
    }
    this.setState({ activeFilters }, this.load);
  };

  private handleFilterEmployee = (employee: string) => {
    const parameters = { ...(this.state.query?.parameters || {}) };

    if (isNullOrWhiteSpaces(employee)) delete parameters['EmployeeOrSfPosition'];
    else parameters['EmployeeOrSfPosition'] = employee;

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, employee }), this.load);
  };

  private handleFilterProfile = (profile: ProfileItemDto) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!profile || !profile.id) delete activeFilters['ProfileId'];
    else activeFilters['ProfileId'] = { TnaDetails: { ProfileId: { eq: { type: 'guid', value: profile.id } } } };

    this.setState({ activeFilters, profile }, this.load);
  };

  private handleFilterLocations = (locations: LocationDto[]) => {
    const parameters = { ...(this.state.query?.parameters || {}) };

    if ((locations || []).length !== 0) parameters['LocationId'] = locations.map(x => x.id);
    else delete parameters['LocationId'];

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, locations }), this.load);
  };

  private handleFilterCreateAt = (from: string, to: string) => {
    const { activeFilters } = this.state;
    const fromIsNull = isNullOrWhiteSpaces(from);
    const toIsNull = isNullOrWhiteSpaces(to);

    if (fromIsNull && toIsNull) delete activeFilters['creationDate'];
    else
      activeFilters['creationDate'] = {
        createdOn: {
          ge: { type: 'raw', value: DateTimeService.toString(fromIsNull ? to : from, null, 'startOfDay') },
          le: { type: 'raw', value: DateTimeService.toString(toIsNull ? from : to, null, 'endOfDay') }
        }
      };

    this.setState({ activeFilters, creationDateFrom: from, creationDateTo: to }, this.load);
  };

  private handleFilterDeadline = (from: string, to: string) => {
    const { activeFilters } = this.state;
    const fromIsNull = isNullOrWhiteSpaces(from);
    const toIsNull = isNullOrWhiteSpaces(to);

    if (fromIsNull && toIsNull) delete activeFilters['deadline'];
    else
      activeFilters['deadline'] = {
        TnaDetails: {
          Deadline: {
            ge: { type: 'raw', value: DateTimeService.toString(fromIsNull ? to : from, null, 'startOfDay') },
            le: { type: 'raw', value: DateTimeService.toString(toIsNull ? from : to, null, 'endOfDay') }
          }
        }
      };

    this.setState({ activeFilters, deadlineFrom: from, deadlineTo: to }, this.load);
  };

  private onClusterEditorChange = (value: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || !value.id) delete activeFilters['ClusterId'];
    else activeFilters['ClusterId'] = { TnaDetails: { ClusterId: { eq: { type: 'guid', value: value.id } } } };

    this.setState({ activeFilters, cluster: value, equipmentType: null, oem: null, machineModel: null }, this.load);
  };

  private onEquipmentTypeFilterChange = (value: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || !value.id) delete activeFilters['EquipmentTypeId'];
    else activeFilters['EquipmentTypeId'] = { TnaDetails: { EquipmentTypeId: { eq: { type: 'guid', value: value.id } } } };

    this.setState({ activeFilters, equipmentType: value, oem: null, machineModel: null }, this.load);
  };

  private onOemFilterChange = (value: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || !value.id) delete activeFilters['OemId'];
    else activeFilters['OemId'] = { TnaDetails: { OemId: { eq: { type: 'guid', value: value.id } } } };

    this.setState({ activeFilters, oem: value, machineModel: null }, this.load);
  };

  private onChangeMachineModelFilter = (value: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || !value.id) delete activeFilters['MachineModelId'];
    else activeFilters['MachineModelId'] = { TnaDetails: { MachineModelId: { eq: { type: 'guid', value: value.id } } } };

    this.setState({ activeFilters, machineModel: value }, this.load);
  };

  private getStatus = (item: TnaTheoreticalTestItemDto): 'Done' | 'Out of Date' | 'New' => {
    if (item.finishedAt != null) return 'Done';
    if (item.tnaDetails.outOfDate) return 'Out of Date';
    return 'New';
  };

  private getColumnsModel = (): TableColumn<TnaTheoreticalTestItemDto>[] => {
    const { t } = this.props;

    let columns: TableColumn<TnaTheoreticalTestItemDto>[] = [
      {
        title: t('Theoretical ID'),
        tooltipRenderer: true,
        renderer: data => data?.friendlyId,
        selectableHeader: true,
        sortDefinition: {
          field: nameof<TnaTheoreticalTestItemDto>('friendlyId'),
          useProfile: false
        }
      },
      { title: t('Theoretical Title'), tooltipRenderer: true, renderer: data => data?.tnaDetails?.title, selectableHeader: true },
      {
        title: t('Employee'),
        tooltipRenderer: false,
        renderer: data => trim(`${data?.user?.lastName}, ${data?.user?.firstName}`.trim(), ','),
        selectableHeader: true
      },
      { title: t('SAP ID'), tooltipRenderer: false, renderer: data => data?.user?.employeeId, selectableHeader: true },
      { title: t('SF Position'), tooltipRenderer: true, renderer: data => data?.user?.sfPosition, selectableHeader: true },
      { title: t('Position Code'), tooltipRenderer: true, renderer: data => data?.user?.positionCodeItem?.code, selectableHeader: true },
      { title: t('Location'), tooltipRenderer: true, renderer: data => data?.user?.location?.location, selectableHeader: true },
      {
        title: t('Role'),
        tooltipRenderer: false,
        renderer: data => <span className="assessment-forms-grey-tag"> {data?.tnaDetails?.profileName}</span>
      },
      {
        title: t('Creation Date'),
        tooltipRenderer: false,
        renderer: data => <span>{DateTimeService.toDateInputString(data?.createOn ?? '')}</span>,
        selectableHeader: true
      },
      {
        title: t('Deadline'),
        tooltipRenderer: false,
        renderer: data => <span>{DateTimeService.toDateInputString(data?.tnaDetails?.deadline ?? '')}</span>,
        selectableHeader: true,
        sortDefinition: { field: 'TnaDetails/deadline', useProfile: false }
      },
      {
        title: t('Status'),
        tooltipRenderer: false,
        renderer: data => {
          const generalClass = 'assessment-forms__table__tag  assessment-forms';
          const status = this.getStatus(data);
          if (status === 'New') return <span className={`${generalClass}-employee-tag`}>{t('New')}</span>;
          if (status === 'Done') return <span className={`${generalClass}-done-tag`}>{t('Done')}</span>;
          if (status === 'Out of Date') return <span className={`${generalClass}-out-of-date-tag`}>{t('Out of Date')}</span>;
          return null;
        },
        selectableHeader: true
      }
    ];
    return columns;
  };

  private getExtraActions = () => {
    const { t } = this.props;

    let buttonMenuOptions = [
      {
        content: (
          <>
            <Icon name="file alternate" />
            {<span className="text__bold">{t('Open Theoretical Form')}</span>}
          </>
        ),
        onClick: this.openTnaTheoreticalTest
      },
      {
        content: (
          <>
            <>
              <Icon name="eye" />
              {<span className="text__bold">{t('Open Employee Card')}</span>}
            </>
          </>
        ),
        onClick: (item: TnaTheoreticalTestItemDto) => {
          this.setState({ selectedItem: item }, () => this.onOpenEmployee(item));
        }
      },
      {
        content: (
          <>
            <Icon color="red" name="trash alternate" />
            {<span className="text__red text__bold">{t('Delete')}</span>}
          </>
        ),
        onClick: this.showDeleteConfirmationModal,

        isVisible: (item: TnaTheoreticalTestItemDto) => this.getStatus(item) === 'New' || this.getStatus(item) === 'Out of Date'
      }
    ];
    return buttonMenuOptions;
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t, tnaTheoreticalTestListStore } = this.props as any;
    const { state: storeState } = tnaTheoreticalTestListStore;
    const {
      showDeleteConfirmModal,
      selectedItem,
      employee,
      profile,
      locations,
      creationDateFrom,
      creationDateTo,
      someFilterOpened
    } = this.state;
    const { deadlineFrom, deadlineTo, cluster, equipmentType, machineModel, oem } = this.state;

    const pocLocations = IdentityService.isPoc(this.identityService.getUserInfo())
      ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
      : [];

    const tableModel = {
      columns: this.getColumnsModel(),
      data: storeState
    } as TableModel<TnaTheoreticalTestItemDto>;

    return (
      <div className="question-bank__wrapper tna-theoretical__wrapper">
        <Grid className="event-types-list-grid">
          {storeState.result && !storeState.result.isSuccess && (storeState.result?.messages || []).length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                list={storeState.result.messages.map(o => o.body)}
                onDismiss={tnaTheoreticalTestListStore.clearMessages}
              />
            </Grid.Row>
          )}

          <div id="assessment-forms-list-filters__admin-or-poc-container">
            <div id="assessment-forms-list-filters__admin-or-poc-container__lef-container" className="specific-margin-top-equal-filters">
              <div className="above-row row">
                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search in Title or ID')}
                    onChange={this.handleFilterTna}
                    key={'searchTna'}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search Employee')}
                    onChange={(e, employee) => this.handleFilterEmployee(employee.value)}
                    value={employee || ''}
                    key={'searchEmployee'}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <ProfileEditor
                    placeholder={t('Role')}
                    nullable
                    value={profile?.id || ''}
                    onChange={this.handleFilterProfile}
                    clearable={true}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <MultipleLocationEditor
                    placeholder={t('Locations')}
                    clearable
                    locationsReceived={pocLocations}
                    value={locations}
                    onChange={this.handleFilterLocations}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <DatesRangeInput
                  dontUseRedClearIconColor={true}
                  iconPosition="right"
                  fromValue={creationDateFrom}
                  toValue={creationDateTo}
                  onChange={this.handleFilterCreateAt}
                  placeholder={t('Creation Date')}
                  clearable={true}
                  style={{ height: 36 }}
                  allowSameEndDate
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
                <DatesRangeInput
                  dontUseRedClearIconColor={true}
                  iconPosition="right"
                  fromValue={deadlineFrom}
                  toValue={deadlineTo}
                  onChange={this.handleFilterDeadline}
                  placeholder={t('Deadline')}
                  clearable={true}
                  style={{ height: 36 }}
                  allowSameEndDate
                  onBlur={this.onBlurHandler}
                  onFocus={this.onFocusHandler}
                />
              </div>
              <div className="below-row row">
                <Form.Field>
                  <MRClusterEditor
                    clearable={true}
                    nullable
                    value={cluster}
                    onChange={this.onClusterEditorChange}
                    placeholder={t('Cluster')}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>
                <Form.Field>
                  <EquipmentTypeEditor
                    nullable
                    clearable={true}
                    clusterId={cluster?.id || undefined}
                    readOnly={!cluster?.id}
                    value={equipmentType}
                    onChange={this.onEquipmentTypeFilterChange}
                    placeholder={t('Equipment Type')}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <OemEditor
                    nullable
                    clearable={true}
                    value={oem}
                    readonly={!equipmentType?.id}
                    onChange={this.onOemFilterChange}
                    equipmentId={equipmentType?.id || undefined}
                    placeholder={t('OEM')}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <MachineModelEditor
                    cascade="true"
                    nullable
                    clearable={true}
                    oemId={oem?.id || undefined}
                    equipmentId={equipmentType?.id || undefined}
                    value={machineModel}
                    onChange={this.onChangeMachineModelFilter}
                    placeholder={t('Machine Model')}
                    readonly={!oem?.id}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>
              </div>
            </div>
            <div id="assessment-forms-list-filters__admin-or-poc-container__right-container">
              <Form.Field>
                <Button className="inverted-color-btn" onClick={this.onNewItem}>
                  {t('Create New Theoretical')}&nbsp;
                </Button>
              </Form.Field>
            </div>
          </div>
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!showDeleteConfirmModal}
              onHideCheckbox={true}
              selectionType={'allRow'}
              maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////

              isRowDisableLayout={item => !(item as any).user.enabled}
              model={tableModel}
              extraActions={this.getExtraActions()}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              onPageChange={this.handlePageChange}
              canCreateNew={false}
              canEdit={false}
              canDelete={false}
            />
          </Grid.Row>
        </Grid>

        <Modal
          open={showDeleteConfirmModal}
          size="mini"
          className="have-warnings-popup"
          closeOnEscape={true}
          onClose={this.hideConfirmModal}
        >
          <Modal.Content className="have-warnings-popup__content">
            <p>
              {t(
                `This TNA theoretical test ${selectedItem?.tnaDetails?.title} will be deleted. Are you sure that you want to delete this item?`
              )}
            </p>
          </Modal.Content>
          <div className="have-warnings-popup__buttons-container">
            <Button className="have-warnings-popup__btn" content={t('No')} onClick={this.hideConfirmModal} />
            <Button
              className="have-warnings-popup__btn have-warnings-popup__pending-btn"
              content={t('Yes')}
              onClick={() => this.onDelete(selectedItem?.id)}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(TnaTheoreticalTestList);
