import i18n from 'i18n';
import { SemanticICONS } from 'semantic-ui-react';
import { FormStore } from '../stores/formStore';

export function openInNewWindow(url: string, focus: boolean = false) {
  const win = window.open(url, '_blank');
  if (focus) {
    win.focus();
  }
}
export function isNullOrWhiteSpaces(str: string): boolean {
  return str == null || /^\s*$/.test(str);
}

export function guidIsNullOrEmpty(guid: string): boolean {
  if (isNullOrWhiteSpaces(guid) || guid === '00000000-0000-0000-0000-000000000000') {
    return true;
  } else {
    return false;
  }
}

export function isNullOrEmpty(str: string): boolean {
  return str == null || str === '';
}

export function replaceAll(str: string, from: string, to: string) {
  return str.split(from).join(to);
}

export function trimEnd(str: string, char: string = ' ') {
  return str.replace(new RegExp(`${char}+$`), '');
}

export function trimStart(str: string, char: string = ' ') {
  return str.replace(new RegExp(`^${char}+`), '');
}

export function trim(str: string, char: string = ' ') {
  return trimEnd(trimStart(str, char), char);
}

export function formatCurrency(n: number, millards?: boolean, currencySimbol: string = '') {
  const s = n.toLocaleString('en', { minimumFractionDigits: 2 });
  if (millards) {
    return `${currencySimbol}${s}K`;
  } else {
    return `${currencySimbol}${s}`;
  }
}

export function truncate(txt: string, length: number): string {
  return txt.length > length ? txt.substr(0, length - 1) + ' ...' : txt;
}

export function subset<T>(left: T[], right: T[], comparer: (x: T, y: T) => boolean): boolean {
  if ((left || []).length === 0) return true;
  if ((right || []).length === 0) return false;
  if (left === right) return true;
  if (left.length > right.length) return false;
  return left.all(x => right.any(y => comparer(x, y)));
}

export function setEquals<T>(left: T[], right: T[], comparer: (x: T, y: T) => boolean): boolean {
  const l = left || [];
  const r = right || [];
  if (l.length !== r.length) return false;
  if (left === right) return true;
  return subset(l, r, comparer) && subset(r, l, comparer);
}

export function union<T>(left: T[], right: T[], comparer: (x: T, y: T) => boolean): T[] {
  return left.concat(right.filter(r => left.findIndex(l => comparer(l, r)) === -1));
}

export function difference<T>(left: T[], right: T[], comparer: (x: T, y: T) => boolean): T[] {
  return left.filter(l => right.findIndex(r => comparer(l, r)) === -1);
}

export function intersection<T>(left: T[], right: T[], comparer: (x: T, y: T) => boolean): T[] {
  return left.filter(l => right.findIndex(r => comparer(l, r)) !== -1);
}

export function swap(arr: any[], i: number, j: number): void {
  const tmp = arr[i];
  arr[i] = arr[j];
  arr[j] = tmp;
}

export function humanFileSize(bytes: number, si = true) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}

export function getIconName(type: string, defaultIcon: SemanticICONS = 'file outline'): SemanticICONS {
  if (type.includes('audio')) {
    return 'file audio outline';
  }
  if (type.includes('video')) {
    return 'file video outline';
  }
  if (type.includes('image')) {
    return 'file image outline';
  }
  if (type.includes('excel') || type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
    return 'file excel outline';
  }
  if (
    type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
    type.includes('msword') ||
    type.includes('ms-word')
  ) {
    return 'file word';
  }
  if (
    type.includes('presentation') ||
    type.includes('powerpoint') ||
    type.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation')
  ) {
    return 'file powerpoint outline';
  }
  if (type.includes('pdf')) {
    return 'file pdf outline';
  }
  if (type.includes('zip-compressed')) {
    return 'file archive outline';
  }
  if (type.includes('text')) {
    return 'file alternate outline';
  }
  return defaultIcon;
}

export function reloadPage() {
  window.location.reload();
}

export function goToPage(url: string) {
  window.location.replace(url);
}

export function shuffle([...arr]: any[]): any[] {
  let m = arr.length;
  while (m) {
    const i = Math.floor(Math.random() * m--);
    [arr[m], arr[i]] = [arr[i], arr[m]];
  }
  return arr;
}

export function sampleSize([...arr]: any[], n = 1): any[] {
  return shuffle(arr).slice(0, n);
}

export function randomString(n = 1, seed = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890') {
  return sampleSize([...seed], n).join('');
}

export function randomNumber(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function randomInteger(min: number, max: number, inclusiveTop: boolean = false) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + (inclusiveTop ? 1 : 0))) + min;
}

export const getCurrentLng = (defaultVal: string = 'es') => i18n.language || window.localStorage.i18nextLng || defaultVal;

export const firstToLowerCase = (str: string) => {
  if (isNullOrWhiteSpaces(str)) return str;
  return str[0].toLowerCase() + str.slice(1);
};

export const firstToUpperCase = (str: string) => {
  if (isNullOrWhiteSpaces(str)) return str;
  return str[0].toUpperCase() + str.slice(1);
};

export function formStoreHandler<T>(formStore: FormStore<T>, property: keyof T, value: any) {
  const change: Partial<T> = {};
  change[property as string] = value;
  formStore.change(change);
}

export function parseFriendlyIdToInt(value: string) {
  const reg = /\d+/g;
  try {
    return parseInt(value.match(reg).toString());
  } catch {
    return null;
  }
}

export function customEqualCompare(x: any, y: any): boolean {
  if (x == null && y == null) return true;
  return x === y;
}

export function arrayValuesCompare(x: any, y: any) {
  if (!x && !y) return true;
  let equals: boolean;
  if (Array.isArray(x) && Array.isArray(y)) {
    if (x.length === 0 && y.length === 0) return true;
    if (x.length !== y.length) return false;
    equals = x.map(u => makeObjectComparable(u)).toString() === y.map(v => makeObjectComparable(v)).toString();
    return equals;
  }
  equals = JSON.stringify(x) === JSON.stringify(y);
  return equals;
}

export function makeObjectComparable(x: any) {
  let comp = Object.values(x).map(z => {
    if (!z) return null;
    if (typeof z === 'object') {
      if ((z as Object).hasOwnProperty('id')) {
        return z['id'];
      }
      return Object.values(z);
    }
    return z;
  });
  return comp;
}

export function isOnlyDigits(str: string): boolean {
  return /^\d+$/.test(str);
}

export function extractFriendlyIdNumber(input: string, preffix?: string): number {
  if (isNullOrWhiteSpaces(input)) return Number.NaN;
  try {
    let str = trim(input);
    if (!isNullOrWhiteSpaces(preffix) && str.startsWith(preffix)) str = str.slice(preffix.length);

    str = trimStart(str, '0');

    if (str.length === 0) str = '0';

    if (!isOnlyDigits(str)) return Number.NaN;

    return Number.parseInt(str);
  } catch {
    return Number.NaN;
  }
}

export function customIsNumber(value: string | number): boolean {
  return value != null && value !== '' && !isNaN(Number(value.toString()));
}

export function getFormattedPhase(value: string | number): string {
  let NaNText = 'N/A';

  if (customIsNumber(value)) {
    if (value >= 0) return value.toString();
    else return NaNText;
  } else return NaNText;
}
