import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { RouteComponentProps } from 'react-router';
import { NMRPracticalQuestionBanksStore, NMRPracticalTestQBWizardStore } from 'stores/assessments/questionBank/practical-test-store';
import { Query, ItemState, OrderDefinition, ItemReference } from 'stores/dataStore';
import { nameof, getProperties } from 'utils/object';
import { CommandResult, FileInfo } from 'stores/types';
import { isNullOrWhiteSpaces, extractFriendlyIdNumber } from 'utils/useful-functions';
import { TableModel, TableView } from 'widgets/collections/table';
import { Message, Icon, Button, Modal, Dimmer, Loader, Popup, Menu, Grid } from 'semantic-ui-react';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import withDelayedInput from 'widgets/editors/delay-input';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import MultiProfessionEditor from 'widgets/bussiness/multi-profession-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faFileExcel, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import multiLanguageEditor from 'widgets/bussiness/multiple-selector/multi-language-editor';
import { resolve } from 'inversify-react';
import { IdentityService } from 'services/identity-service';
import QuestionManager, { ModalMode } from '../shared/practical-question-manager';
import { plainObj, plainNMR } from '../shared/util';
import { DropdownLanguagesStore, LanguageDto } from 'stores/configuration/locations/languages-store';
import ChipInfo from 'widgets/bussiness/chip-info';

import MultiSelectorNMRFilters, { NMrTagsFiltersValues } from 'widgets/bussiness/multiple-selector/multiple-nmr-editor';
import { NmrPracticalTestQuestionItemDto } from 'stores/assessments/questionBank/practical-test-store';
import clone from 'clone';
import { UploadNmrPracticalQBStore, DownloadNmrPracticalQBStore } from 'stores/assessments/questionBank/nmr-practical-test-load-store';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

const DelayedMultiLanguageEditor = withDelayedInput(multiLanguageEditor, 1500, true);

interface NMRPracticalQuestionBankListProps extends WithTranslation, RouteComponentProps {
  nmrQuestionBankStore?: NMRPracticalQuestionBanksStore;
  nmWizardStore?: NMRPracticalTestQBWizardStore;
  languagesStore?: DropdownLanguagesStore;
  nmrPracticalUploadStore?: UploadNmrPracticalQBStore;
  nmrPracticalDownloadStore?: DownloadNmrPracticalQBStore;
}

interface NMRPracticalQuestionBankListState {
  query: Query;
  activeFilters: { [key: string]: any };
  modalMode: ModalMode;
  selectedItem: NmrPracticalTestQuestionItemDto;
  languagesIdentifiers: string[];
  languageIdentifier: string;
  showDeleteConfirm: boolean;
  questionManager: boolean;
  uploadMenuOpen: boolean;
  showUploadMsg: boolean;
  showDownloadMsg: boolean;
  uploadLoadingMessage: string;
  modalLoading: boolean;
  defaultLanguage: LanguageDto;
  deleteModalText: string;
  someFilterOpened: boolean;
}

@connect(
  ['nmrQuestionBankStore', NMRPracticalQuestionBanksStore],
  ['nmWizardStore', NMRPracticalTestQBWizardStore],
  ['languagesStore', DropdownLanguagesStore],
  ['nmrPracticalUploadStore', UploadNmrPracticalQBStore],
  ['nmrPracticalDownloadStore', DownloadNmrPracticalQBStore]
)
class NMRPracticalQuestionBankList extends Component<NMRPracticalQuestionBankListProps, NMRPracticalQuestionBankListState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  state: NMRPracticalQuestionBankListState = {
    query: {
      searchQuery: '',
      orderBy: [
        { direction: 'Descending', field: nameof<NmrPracticalTestQuestionItemDto>('friendlyId'), useProfile: false },
        { direction: 'Descending', field: 'modifiedOn', useProfile: false }
      ],
      skip: 0,
      take: 10
    },
    selectedItem: null,
    activeFilters: [],
    modalMode: null,
    languagesIdentifiers: [],
    languageIdentifier: '',
    showDeleteConfirm: false,
    questionManager: false,
    uploadMenuOpen: false,
    showUploadMsg: false,
    showDownloadMsg: false,
    uploadLoadingMessage: '',
    modalLoading: false,
    defaultLanguage: null,
    deleteModalText: '',
    someFilterOpened: false
  };

  handleOnEnterKeydown = item => {
    this.onEditItem(item);
  };

  handleOnRowDoubleClick = item => {
    this.onEditItem(item);
  };

  componentDidMount() {
    this.load();
    this.loadDefaultLanguage();
  }

  private loadDefaultLanguage() {
    let languageQuery: Query = {
      searchQuery: '',
      orderBy: [],
      skip: 0,
      take: 1000,
      filter: [{ or: [{ LanguageCode: 'EN-en' }, { Language: 'English' }] }]
    };
    this.props.languagesStore.getAllAsync(languageQuery).then(e => {
      this.setState({ defaultLanguage: e.items[0] });
    });
  }

  private load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const filter = [].concat(
      ...getProperties(activeFilters)
        .filter(({ value }) => !!value)
        .map(({ value }) => value)
    );
    let orderBy = [...this.state.query.orderBy];
    const query = { ...this.state.query, filter, orderBy };
    this.setState({ query }, () => this.props.nmrQuestionBankStore.getAllAsync(this.state.query));
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(
      ({ query }) => ({
        query: { ...query, orderBy: [...orderBy, { direction: 'Descending', field: 'modifiedOn', useProfile: false }] }
      }),
      this.load
    );
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  };

  private onSaveRow = async (item: any, state: ItemState): Promise<CommandResult<any>> => {
    if (state !== 'New') {
      await this.props.nmrQuestionBankStore.saveAsync(item, state);
    }
    return { isSuccess: true, items: [], messages: [] };
  };

  private onDelete = async () => {
    if (!this.state.selectedItem) return;
    await this.props.nmrQuestionBankStore.deleteAsync(this.state.selectedItem.id);
    this.hideConfirmModal();
  };

  private showDeleteModal = async selectedItem => {
    const { t, nmrQuestionBankStore } = this.props;
    if (selectedItem.state !== 'New') {
      const count = await nmrQuestionBankStore.getUsedPracticalNRMQuestionCountById(selectedItem.id);
      const modalText =
        count > 0
          ? `Question ${selectedItem.friendlyId} is being used in ${count} template${count === 1 ? '' : 's'} and the template${
              count === 1 ? '' : 's'
            }. Are you sure that you want to DELETE the question?`
          : 'Are you sure you want to DELETE this Question?';

      this.setState({ selectedItem, showDeleteConfirm: true, deleteModalText: t(modalText) });
    }
  };

  private hideConfirmModal = () => {
    this.setState({ showDeleteConfirm: false, selectedItem: null });
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  };
  private onNewItem = () => {
    this.setState({ questionManager: true, modalMode: 'New' });
  };

  private onCloneItem = (selectedItem: NmrPracticalTestQuestionItemDto) => {
    this.setState({ selectedItem, modalMode: 'Clone', questionManager: true });
  };

  private onEditItem = (selectedItem: NmrPracticalTestQuestionItemDto) => {
    this.setState({ selectedItem, modalMode: 'Edit', questionManager: true });
  };

  private onFilterQuestionOrId = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) {
      delete activeFilters['IdDescription'];
    } else {
      const input = value as string;
      const parts = [`contains(tolower(question/text), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'QNMRP');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('QNMRP')) parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        else parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
      }
      activeFilters['IdDescription'] = `(${parts.join(' or ')})`;
    }

    this.setState({ activeFilters }, this.load);
  };

  private multiChangeLanguagesFilters = (languagesIds: string[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (languagesIds != null && languagesIds.length > 0) {
      activeFilters['languages'] = {
        or: [
          { QuestionTranslations: { any: { LanguageId: { in: { type: 'guid', value: languagesIds } } } } },
          { Question: { LanguageId: { in: { type: 'guid', value: languagesIds } } } }
        ]
      };
    } else delete activeFilters['languages'];
    this.setState({ activeFilters }, () => this.load());
  };

  private rolesFilterHandlerHandler = (roles: string[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (roles != null && roles.length > 0)
      activeFilters['roles'] = { professions: { any: { id: { in: { type: 'guid', value: roles } } } } };
    else delete activeFilters['roles'];
    this.setState({ activeFilters }, () => this.load());
  };

  private onTrainingLevelFilterChange = (value: ItemReference) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value) delete activeFilters.trainingLevel;
    else activeFilters.trainingLevel = { trainingLevelId: { eq: { type: 'guid', value: value.id } } };
    this.setState({ activeFilters }, () => this.load());
  };

  private toggleQuestionManager = () => {
    this.props.nmWizardStore.clearMessages();
    this.setState(({ questionManager }) => ({ selectedItem: null, questionManager: !questionManager }));
  };

  handleModalSubmit = async (newItem: NmrPracticalTestQuestionItemDto) => {
    this.setState({ modalLoading: true });
    if (!newItem) return;
    const { nmWizardStore } = this.props;
    nmWizardStore.createNew(clone(newItem));
    const finalize: any = { selectedItem: null, questionManager: false, modalLoading: false, modalMode: null };
    const { modalMode } = this.state;
    try {
      if (modalMode === 'New' || modalMode === 'Clone') await nmWizardStore.submitNMQuestion();
      else if (modalMode === 'Edit') await nmWizardStore.submitNMQuestion(true);
      nmWizardStore.clear();
      this.load();
    } catch (error) {
      console.error({ error });
      delete finalize.selectedItem;
      delete finalize.questionManager;
      delete finalize.modalMode;
    }
    if (nmWizardStore.state.result) {
      nmWizardStore.state.result.isSuccess && this.load();
      const errMsg = nmWizardStore.state.result.messages || [];
      finalize.errMsg = errMsg;
    }
    this.setState(finalize);
  };

  onDissmissModal = () => {
    const { nmWizardStore } = this.props;
    nmWizardStore.clearMessages();
  };

  handleMultiTagSelector = (filters: NMrTagsFiltersValues) => {
    const activeFilters = { ...this.state.activeFilters };
    if (filters.nmClusters.length > 0) activeFilters['nmClusters'] = { nmrClusterId: { in: { value: filters.nmClusters, type: 'guid' } } };
    else delete activeFilters['nmClusters'];
    if (filters.functionalArea.length > 0)
      activeFilters['functionalAreas'] = { functionalAreaId: { in: { type: 'guid', value: filters.functionalArea } } };
    else delete activeFilters['functionalAreas'];

    if (filters.trainingName.length > 0)
      activeFilters['trainingNames'] = { trainingNameId: { in: { type: 'guid', value: filters.trainingName } } };
    else delete activeFilters['trainingNames'];

    if (filters.functionalSubarea.length > 0)
      activeFilters['functionalSubareas'] = { functionalSubareaId: { in: { type: 'guid', value: filters.functionalSubarea } } };
    else delete activeFilters['functionalSubareas'];
    this.setState({ activeFilters }, () => this.load());
  };

  private onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file: File;
    if (event && event.target && event.target.files && event.target.files.length !== 0 && event.target.files[0])
      file = event.target.files[0];
    else return;
    const { t } = this.props;
    this.setState({ showUploadMsg: true, uploadMenuOpen: false, uploadLoadingMessage: t('Queuing file for import background process') });
    const usr = this.identityService.getUserInfo();
    const fileInfo: FileInfo = {
      fileName: file.name,
      fileSize: file.size,
      content: file,
      userId: usr.userId,
      userName: usr.userName
    };
    this.props.nmrPracticalUploadStore
      .uploadFile(fileInfo)

      .then(res => {
        res.data.isSuccess &&
          ToastComponent({ text: this.props.t('File has been uploaded. Merging the list is in progress'), type: 'success-toast' });
        !res.data.isSuccess && ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File uploaded failed'), type: 'error-toast' });
      });
  };

  private handleUploadMsgDismiss = () => {
    this.setState({ showUploadMsg: false });
  };

  private handleDownloadMsgDismiss = () => {
    this.setState({ showDownloadMsg: false });
  };

  private getBackgroundUploadMessage = () => {
    const { isBusy, result } = this.props.nmrPracticalUploadStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    let error = false;
    if (result.error || (result.response && !result.response.isSuccessful)) {
      header = t('Something went wrong with the file');
      content = (result.error || {}).message;
      error = true;
    } else if (result.error == null && result.response && result.response.isSuccessful) {
      header = t('File uploaded successfully');
      content = t('You will receive an email when the process ends');
      error = false;
    } else return null;

    return (
      <Message
        className={error && 'error-message__style'}
        icon={error && 'exclamation circle'}
        onDismiss={this.handleUploadMsgDismiss}
        header={header}
        content={content}
        error={error}
        success={!error}
      />
    );
  };

  private getBackgroundDownloadMessage = () => {
    const { isBusy, result } = this.props.nmrPracticalDownloadStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    if (!result.isSuccess) {
      header = t('Something went wrong');
      content = (result.messages || []).map(x => x.body).join('. ');
    } else {
      header = t('Background process initialized successfully');
      content = t('You will receive an email when the process ends');
    }

    return (
      <Message
        className={!result.isSuccess && 'error-message__style'}
        icon={!result.isSuccess && 'exclamation circle'}
        onDismiss={this.handleDownloadMsgDismiss}
        header={header}
        content={content}
        error={!result.isSuccess}
        success={result.isSuccess}
      />
    );
  };
  private initExportQuestionBankProcess = () => {
    this.props.nmrPracticalDownloadStore.initBackgroundDownload();
    this.setState({ showDownloadMsg: true });
  };

  private downloadTemplate = () => {
    const { t } = this.props;
    this.props.nmrPracticalUploadStore
      .downloadTemplate()

      .then(res => {
        ToastComponent({ text: this.props.t('File download successfully!'), type: 'success-toast' });
      })
      .catch(error => {
        console.error(error);
        ToastComponent({ text: this.props.t('File download failed'), type: 'error-toast' });
      });

    this.setState({ uploadMenuOpen: false, showUploadMsg: true, uploadLoadingMessage: t('Downloading the file') });
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t, nmrQuestionBankStore, nmWizardStore, nmrPracticalUploadStore, nmrPracticalDownloadStore } = this.props;
    const {
      selectedItem,
      showDeleteConfirm,
      questionManager,
      modalMode,
      modalLoading,
      showUploadMsg,
      showDownloadMsg,
      someFilterOpened
    } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Question ID'),
          tooltipRenderer: true,
          renderer: ({ friendlyId }) => friendlyId,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<any>('friendlyId'),
            useProfile: false,
            active: 'Descending'
          }
        },
        {
          title: t('Questions'),
          tooltipRenderer: false,
          renderer: ({ question }) => question && question.text,
          selectableHeader: true
        },
        {
          title: t('Roles'),
          tooltipRenderer: false,
          renderer: ({ professions }) => {
            const allProfessions = (professions || []).map(({ profession, id }) => (
              <span key={id} className={professions && profession ? 'question-bank__cell__tag' : ''}>
                {profession}
              </span>
            ));

            return <div className={professions && professions.length > 1 ? 'table-cell__more-width' : ''}>{allProfessions}</div>;
          }
        },

        {
          title: t('Relation'),
          tooltipRenderer: false,
          renderer: ({ nmrCluster, functionalArea, trainingName, functionalSubArea }) => {
            const info = [];
            nmrCluster && info.push(nmrCluster.name);
            functionalArea && info.push(functionalArea.name);
            trainingName && info.push(trainingName.name);
            functionalSubArea && info.push(functionalSubArea.name);
            return info.length > 0 && <ChipInfo info={info} />;
          }
        },

        {
          title: t('Training Level'),
          tooltipRenderer: false,
          renderer: ({ trainingLevel }) => trainingLevel?.name
        },
        {
          title: t('Target Level'),
          tooltipRenderer: false,
          renderer: ({ targetLevelFormatted }) => targetLevelFormatted && targetLevelFormatted
        },
        {
          title: t('Languages'),
          tooltipRenderer: false,
          renderer: ({ questionTranslations, question }) => {
            const languagesList = question.language ? [question.language] : [];
            questionTranslations.forEach(questionTranslation => {
              if (questionTranslation.language) languagesList.push(questionTranslation.language);
            });
            const allLanguages = languagesList.map(({ languageCode, id }, i) => (
              <span key={id + i} className={'question-bank__cell__tag'}>
                {languageCode}
              </span>
            ));
            return (
              <div className={allLanguages && allLanguages.length > 1 ? 'table-cell__more-width question-bank__language__div' : ''}>
                {allLanguages}
              </div>
            );
          }
        }
      ],
      data: nmrQuestionBankStore.state
    } as TableModel<NmrPracticalTestQuestionItemDto>;

    const showErrorMessage =
      nmrQuestionBankStore.state.result &&
      !nmrQuestionBankStore.state.result.isSuccess &&
      (nmrQuestionBankStore.state.result.messages || []).length !== 0;
    const errMsg = [];
    if (nmWizardStore.state.result && (nmWizardStore.state.result.messages || []).length > 0)
      errMsg.push(...nmWizardStore.state.result.messages.map(({ body }) => body));

    return (
      <div id="nmr-theoretical-question-bank-wrapper" className="question-bank__wrapper">
        {nmrPracticalUploadStore.state.isBusy && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{this.state.uploadLoadingMessage}</Loader>
          </Dimmer>
        )}
        {showUploadMsg && this.getBackgroundUploadMessage()}

        {nmrPracticalDownloadStore.state.isBusy && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Initializing download background process')}</Loader>
          </Dimmer>
        )}
        {showDownloadMsg && this.getBackgroundDownloadMessage()}

        <Grid className="event-types-list-grid">
          {showErrorMessage && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                hidden={!showErrorMessage}
                error
                header={t('An error ocurred')}
                list={(nmrQuestionBankStore.state.result.messages || []).map(({ body }) => body)}
                onDismiss={nmrQuestionBankStore.clearMessages}
              />
            </Grid.Row>
          )}
          <div className="tna__inputs-wrapper table__filters-share-main-actions">
            <ClearableTimerInput
              id="nmr-theoretical-questions-bank-list__filters__search"
              icon="search"
              placeholder={t('Search in Question or ID')}
              onChange={this.onFilterQuestionOrId}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <MultiProfessionEditor
              placeholder={t('Role')}
              value={[]}
              onChange={this.rolesFilterHandlerHandler}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <TrainingLevelEditor
              value={null}
              onChange={this.onTrainingLevelFilterChange}
              placeholder={t('Training Level')}
              searchable
              clearable
              nullable
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <DelayedMultiLanguageEditor
              id="nmr-theoretical-questions-bank-list__filters__langs"
              value={this.state.languagesIdentifiers}
              onChange={this.multiChangeLanguagesFilters}
              placeholder={t('Languages')}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />

            <MultiSelectorNMRFilters
              placeholder={t('Filter tags')}
              className="question-bank__multiple-dropdown"
              onChange={this.handleMultiTagSelector}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
            />
          </div>
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={!showDeleteConfirm && !questionManager}
              onHideCheckbox={true}
              selectionType={'allRow'}
              //maxSelection={1}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              extraMenu={[
                {
                  className: 'menu-item-download',
                  content: (
                    <Popup
                      on="click"
                      position="bottom center"
                      open={this.state.uploadMenuOpen}
                      onOpen={() => this.setState({ uploadMenuOpen: true })}
                      onClose={() => this.setState({ uploadMenuOpen: false })}
                      trigger={
                        <Button
                          icon
                          size="mini"
                          className="custom-table-upload-btn custom-table-upload-btn__up-down transparent-btn"
                          onClick={() => this.setState({ uploadMenuOpen: true })}
                          data-tooltip={t('Import')}
                        >
                          <FontAwesomeIcon className="solid" icon={faFileUpload} size="lg" />
                        </Button>
                      }
                    >
                      <Menu vertical secondary>
                        <Menu.Item key="upload" position="left" as="label" htmlFor="file" className="turquose-onhover">
                          <FontAwesomeIcon className="solid float-right" icon={faFileUpload} size="lg" />
                          <input type="file" id="file" style={{ display: 'none' }} onChange={this.onUpload} />
                          &nbsp;{t('Upload File')}
                        </Menu.Item>

                        <Menu.Item className="turquose-onhover" key="template" position="left" onClick={this.downloadTemplate}>
                          <FontAwesomeIcon className="solid float-right" icon={faFileExcel} size="lg" />
                          &nbsp;{t('Download Template')}
                        </Menu.Item>
                      </Menu>
                    </Popup>
                  )
                },
                {
                  className: 'menu-item-download',
                  content: (
                    <Button
                      icon
                      size="medium"
                      className="custom-table-upload-btn black-btn"
                      onClick={this.initExportQuestionBankProcess}
                      data-tooltip={t('Export')}
                    >
                      <FontAwesomeIcon className="solid" icon={faFileDownload} size="lg" />
                    </Button>
                  )
                }
              ]}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil" />
                      {<span className="text__bold">{t('Edit')}</span>}
                    </>
                  ),
                  onClick: this.onEditItem
                },
                {
                  content: (
                    <>
                      <Icon name="clone" />
                      {<span className="text__bold">{t('Clone')}</span>}
                    </>
                  ),
                  onClick: this.onCloneItem
                },
                {
                  content: (
                    <>
                      <Icon color="red" name="trash alternate" />
                      {<span className="text__red text__bold">{t('Delete')}</span>}
                    </>
                  ),
                  onClick: this.showDeleteModal
                }
              ]}
              onOrderByChanged={this.handleOrderBy}
              onNewItem={this.onNewItem}
              canCreateNew={true}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onSaveRow={this.onSaveRow}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              createNewButtonTitle={t('New NMR Practical Question')}
            ></TableView>
          </Grid.Row>
        </Grid>
        <Modal open={showDeleteConfirm} size="mini" className="have-warnings-popup" closeOnEscape={true} onClose={this.hideConfirmModal}>
          <Modal.Content className="have-warnings-popup__content">
            <p>{this.state.deleteModalText}</p>
          </Modal.Content>
          <div className="have-warnings-popup__buttons-container">
            <Button className="have-warnings-popup__btn" content={t('No')} onClick={this.hideConfirmModal} />
            <Button className="have-warnings-popup__btn have-warnings-popup__pending-btn" content={t('Yes')} onClick={this.onDelete} />
          </div>
        </Modal>
        {questionManager && modalMode && (
          <QuestionManager
            errMsg={errMsg}
            onDismiss={this.onDissmissModal}
            loading={modalLoading}
            type="NMR"
            mode={modalMode}
            onClose={this.toggleQuestionManager}
            data={selectedItem ?? { ...plainObj, question: { ...plainObj.question, language: this.state.defaultLanguage }, ...plainNMR }}
            onSubmit={this.handleModalSubmit}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(NMRPracticalQuestionBankList);
